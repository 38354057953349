import { Helpers, Services } from '@cc/cc-app-commons';
// @ts-ignore
import tpl from '../../templates/order-positions/orderArticleNumber.hbs';
import orderArticleLinksTemplate from '../../templates/order-positions/orderArticleLinks.hbs';
import popoverTemplate from '../../templates/common/popover.hbs';
import { ArticleNumberModel } from '../models/Article';
import { ViewModel } from './types';

const { renderTo } = Helpers.ClientHelpers;

export default class OrderArticleNumberViewModel {
  static ARTICLE_LINKS_ELEMENT_SELECTOR = '.oa-orderArticleNumber__links';
  static ARTICLE_ADMIN_LINK_ELEMENT_SELECTOR = '.oa-orderArticleLinks__articleAdminLink';
  static SHOP_ARTICLE_LINK_ELEMENT_SELECTOR = '.oa-orderArticleLinks__shopArticleLink';

  private model: ArticleNumberModel;
  private readonly el: HTMLElement;
  private readonly context: ViewModel.Context;
  private popoverTargetElement?: HTMLElement;
  private popoverElementContainer?: HTMLElement;

  constructor(domElement: HTMLElement, model: ArticleNumberModel, context: ViewModel.Context) {
    this.el = domElement;
    this.model = model;
    this.context = context;
  }

  render(): void {
    renderTo(
      this.el,
      tpl({ articleNumber: this.model.articleNumber, hasLinks: this.model.hasLinks() }),
    );
    this.initLinksPopover();
  }

  destroy(): void {
    this.destroyLinksPopover();
  }

  private initLinksPopover() {
    if (!this.model.hasLinks()) {
      return;
    }

    this.configurePopover();
  }

  private configurePopover() {
    this.popoverElementContainer = this.el.querySelector(
      OrderArticleNumberViewModel.ARTICLE_LINKS_ELEMENT_SELECTOR,
    );

    this.popoverTargetElement = $(this.el).find('a.oa-orderArticleNumber__linksOpener').get(0);
    $(this.popoverTargetElement).on('click', (e) => {
      e.preventDefault();
    });

    // @ts-ignore
    $(this.popoverTargetElement).popover({
      content: function (): string {
        return orderArticleLinksTemplate(this.model.articleLinks.toData());
      }.bind(this),
      container: this.el.querySelector(OrderArticleNumberViewModel.ARTICLE_LINKS_ELEMENT_SELECTOR),
      html: true,
      placement: 'auto top',
      template: popoverTemplate(),
      trigger: 'focus',
    });
    $(this.popoverTargetElement).on('shown.bs.popover', () => {
      this.attachArticleLinkHandlers();
    });
    $(this.popoverTargetElement).on('hide.bs.popover', () => {
      this.detachArticleLinkHandlers();
    });
  }

  private destroyLinksPopover() {
    if (this.popoverTargetElement) {
      $(this.popoverTargetElement).off('click');
      $(this.popoverTargetElement).popover('destroy');
    }
    delete this.popoverTargetElement;
    delete this.popoverElementContainer;
  }

  private attachArticleLinkHandlers(): void {
    this.popoverElementContainer
      .querySelectorAll(OrderArticleNumberViewModel.ARTICLE_ADMIN_LINK_ELEMENT_SELECTOR)
      .forEach((el) => {
        el.addEventListener('click', () => {
          this.notifyArticleAdminLinkClicked();
        });
      });
    this.popoverElementContainer
      .querySelectorAll(OrderArticleNumberViewModel.SHOP_ARTICLE_LINK_ELEMENT_SELECTOR)
      .forEach((el) => {
        el.addEventListener('click', () => {
          this.notifyShopArticleLinkClicked();
        });
      });
  }

  private notifyArticleAdminLinkClicked(): void {
    this.notifyLinkClicked(Services.AppActivityAction.ARTICLE_ADMIN_LINK__CLICKED);
  }

  private notifyShopArticleLinkClicked(): void {
    this.notifyLinkClicked(Services.AppActivityAction.SHOP_ARTICLE_LINK__CLICKED);
  }

  private notifyLinkClicked(eventType: Services.AppActivityAction): void {
    this.context.metric.logAppActivity(eventType);
  }

  private detachArticleLinkHandlers() {
    this.el
      .querySelectorAll(OrderArticleNumberViewModel.ARTICLE_ADMIN_LINK_ELEMENT_SELECTOR)
      .forEach((el) => {
        el.removeEventListener('click', this.notifyArticleAdminLinkClicked);
      });
    this.el
      .querySelectorAll(OrderArticleNumberViewModel.SHOP_ARTICLE_LINK_ELEMENT_SELECTOR)
      .forEach((el) => {
        el.removeEventListener('click', this.notifyShopArticleLinkClicked);
      });
  }
}
