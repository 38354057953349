import { Models, Services } from '@cc/cc-app-commons';
import { OrderResolutionService } from './OrderResolutionService';
import { OrdersService } from './OrdersService';
import { CustomerService } from './CustomerService';
import OrderResolutionMetadata from '../../models/OrderResolutionMetadata';
import { Order } from '../../models/Order';
import ClientBasedAppOrderResolutionMetadata from '../../models/ClientBasedAppOrderResolutionMetadata';
import Ticket = Models.Ticket;

const { Errors } = Models;

export class ClientBasedAppOrderResolutionService extends OrderResolutionService {
  private customerService: CustomerService;
  private monitoringService: Services.MonitoringService;
  private readonly ticket: Ticket;

  constructor(
    ticket: Ticket,
    ordersService: OrdersService,
    customerService: CustomerService,
    monitoringService: Services.MonitoringService,
  ) {
    super(ticket.customerId, ticket.orderId, undefined, ordersService);
    this.customerService = customerService;
    this.monitoringService = monitoringService;
    this.ticket = ticket;
  }

  async obtainCustomerId(): Promise<number> {
    try {
      const customerData = await this.customerService.fetchCustomerForTicket(this.ticket);
      return customerData.zooId;
    } catch (e) {
      if (!this.specificOrder) {
        if (e instanceof Errors.CustomerNotFoundAppError) {
          this.monitoringService.logCustomerNotFoundForTicket(this.ticket, 'cc-order-app');
        }
        throw e;
      }
    }
  }

  currentOrderId(): number {
    return super.currentOrderId() || this.ticket?.orderId;
  }

  async getOrderResolutionMetadata(order: Order): Promise<OrderResolutionMetadata> {
    return new ClientBasedAppOrderResolutionMetadata(this.ticket, order);
  }
}
