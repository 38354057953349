import { Models } from '@cc/cc-app-commons';

enum ParcelCancellationErrors {
  GENERAL_PARCEL_CANCELLATION_ERROR = 'general_parcel_cancellation_error',
  USER_NOT_FOUND_ERROR = 'user_not_found_error',
  PARCEL_NOT_FOUND_ERROR = 'parcel_not_found_error',
  PARCEL_ALREADY_CANCELLED = 'parcel_already_cancelled',
  PARCEL_IN_PARCEL_EXPORT = 'parcel_in_parcel_export',
}

export class ParcelCancellationError extends Models.Errors.AppError {
  constructor(
    originError: any = {},
    code: string = ParcelCancellationErrors.GENERAL_PARCEL_CANCELLATION_ERROR,
  ) {
    super(null, code, originError);
  }
}

export class UserNotFoundError extends ParcelCancellationError {
  constructor() {
    super({}, ParcelCancellationErrors.USER_NOT_FOUND_ERROR);
  }
}

export class ParcelNotFoundError extends ParcelCancellationError {
  constructor() {
    super({}, ParcelCancellationErrors.PARCEL_NOT_FOUND_ERROR);
  }
}

export class ParcelAlreadyCancelledError extends ParcelCancellationError {
  constructor() {
    super({}, ParcelCancellationErrors.PARCEL_ALREADY_CANCELLED);
  }
}

export class ParcelInParcelExportError extends ParcelCancellationError {
  constructor() {
    super({}, ParcelCancellationErrors.PARCEL_IN_PARCEL_EXPORT);
  }
}
