/**
 * Get property from an object.
 * Useful in a template when you want to get a field's value from an object but you have that field's name stored in another variable.
 * Example: {{#if ([data/get] someObject fieldNameWithinThatObject)}}{{/if}}
 *
 * @param obj
 * @param key
 * @returns {null|*}
 */
export default function (obj, key) {
  if (!obj || typeof obj !== 'object' || !key) {
    return null;
  }
  return obj[key];
}
