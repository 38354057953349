import { ViewModel } from '../types';
import { Helpers } from '@cc/cc-app-commons';

const { clear, renderTo } = Helpers.ClientHelpers;
const tplRelaxDeliveryInfo = require('../../../templates/order-header/relaxDeliveryInfo.hbs');

export default class RelaxDeliveryViewModel {
  private static TOOLTIP_ELEMENT_SELECTOR = '.oa-relaxDelivery .oa-tooltipWrapper';

  private readonly el: Element;
  private readonly context: ViewModel.Context;

  constructor(domElement: Element, context: ViewModel.Context) {
    this.el = domElement;
    this.context = context;
  }

  async render() {
    const order = await this.context.data.currentOrder;
    const zooSuborder = order.suborders.find((so) => so.isZooplusSuborder);
    if (zooSuborder) {
      renderTo(this.el, tplRelaxDeliveryInfo(zooSuborder.toTemplateData()));
      this.attachPluginsAndEventHandlers();
    }
  }

  private attachPluginsAndEventHandlers() {
    $(this.el.querySelector(RelaxDeliveryViewModel.TOOLTIP_ELEMENT_SELECTOR)).tooltip();
  }

  private detachPluginsAndEventHandlers() {
    $(this.el.querySelector(RelaxDeliveryViewModel.TOOLTIP_ELEMENT_SELECTOR)).tooltip('destroy');
  }

  destroy() {
    this.detachPluginsAndEventHandlers();
    clear(this.el);
  }
}
