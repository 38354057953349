import KeycloakUser from '../../models/KeycloakUser';

export default class KeycloakService {
  constructor(private readonly _keycloak: any) {}

  getLoggedInKeycloakUser(): KeycloakUser {
    const zooUserId = this._keycloak?.tokenParsed.legacyUserId;
    // eslint-disable-next-line
    const username = this._keycloak?.tokenParsed.preferred_username
    const email = this._keycloak?.tokenParsed.email;
    return new KeycloakUser().fromData({ zooUserId, username, email });
  }
}
