import { Models } from '@cc/cc-app-commons';
import {
  ParcelAlreadyCancelledError,
  ParcelCancellationError,
  ParcelInParcelExportError,
  ParcelNotFoundError,
  UserNotFoundError,
} from './ParcelCancellationError';

export default class ParcelCancellationResult extends Models.BaseModel {
  success: boolean = undefined;
  reason: string = undefined;

  get error(): ParcelCancellationError {
    if (this.success) {
      return null;
    }
    if (this.reason === 'User not found in DB.') {
      return new UserNotFoundError();
    }
    if (this.reason === 'Parcel not found in DB.') {
      return new ParcelNotFoundError();
    }
    if (this.reason === 'Parcel is already cancelled.') {
      return new ParcelAlreadyCancelledError();
    }
    if (this.reason === 'Parcel is already in parcel export so it cannot be cancelled') {
      return new ParcelInParcelExportError();
    }
    return new ParcelCancellationError();
  }
}
