import { Services } from '@cc/cc-app-commons';
import { AppDataContext } from '../../common/AppDataContext';
import { ViewModel } from '../../../types';
import { AppContext } from '../../common/AppContext';
import Context = ViewModel.Context;
import FreshchatAppContextServices from './FreshchatAppContextServices';
import { CommonAppMetricContext } from '../../common/metrics/CommonAppMetricContext';

export class FreshchatAppContext extends AppContext {
  static async create(
    client: any,
    customerId: number,
    connector: Services.KeycloakSecuredZooServicesConnector,
    gatewayUrl: string,
    keycloak: any,
  ): Promise<Context> {
    const appContextServices = new FreshchatAppContextServices(
      client,
      customerId,
      connector,
      gatewayUrl,
      keycloak,
    );
    const appContext = new FreshchatAppContext(appContextServices, keycloak);
    await appContext.createAppDataContext();
    appContext.createAppMetricContext();
    return appContext;
  }

  private createAppDataContext() {
    this._data = new AppDataContext(this.services);
    return this._data.initCurrentOrderModel();
  }

  private createAppMetricContext() {
    this._metric = new CommonAppMetricContext(this.services, this.data);
  }
}
