var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-monetaryValue oa-monetaryValue--primary "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"textAlign") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":57},"end":{"line":2,"column":112}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inline") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":113},"end":{"line":2,"column":158}}})) != null ? stack1 : "")
    + "\"\n       data-test=\"monetaryValue.amount\">"
    + alias2(__default(require("../helpers/formatting/numberToFixed.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"amount") : depth0),2,{"name":"formatting/numberToFixed","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":45}}}))
    + "<span class=\"u-pl-s oa-currency--smaller\">"
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"currency") : depth0), depth0))
    + "</span>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "oa-monetaryValue--"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"textAlign") : depth0), depth0));
},"4":function(container,depth0,helpers,partials,data) {
    return "oa-monetaryValue--inline";
},"6":function(container,depth0,helpers,partials,data) {
    return "  "
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"default.not_available",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":8,"column":34}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/logic/not.js")).call(alias1,__default(require("../helpers/nullOrUndefined.js")).call(alias1,depth0,{"name":"nullOrUndefined","hash":{},"data":data,"loc":{"start":{"line":1,"column":20},"end":{"line":1,"column":42}}}),{"name":"logic/not","hash":{},"data":data,"loc":{"start":{"line":1,"column":7},"end":{"line":1,"column":43}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":9}}})) != null ? stack1 : "");
},"useData":true});