import { Models } from '@cc/cc-app-commons';

export default class OrderRelation extends Models.BaseModel {
  orderId: number = 0;
  relationType: OrderRelationType = null;
}

export enum OrderRelationType {
  PARENT = 'PARENT',
  CHILD = 'CHILD',
}
