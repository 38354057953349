import { ServerResponse } from '../types';

export default class OrderWarning {
  code: string;
  message: string;

  constructor(code: string = '', message: string = '') {
    this.code = code;
    this.message = message;
  }
}

export class OrderParcelsWarning extends OrderWarning {
  constructor(message: string) {
    super('ZEN-101', message);
  }
}

export class RelaxDeliveryWarning extends OrderWarning {
  constructor(message: string) {
    super('ZEN-102', message);
  }
}

export class InvoiceNumberWarning extends OrderWarning {
  constructor(message: string) {
    super('ZEN-103', message);
  }
}

export const orderWarningMap = new Map([
  ['ZEN-101', OrderParcelsWarning],
  ['ZEN-102', RelaxDeliveryWarning],
  ['ZEN-103', InvoiceNumberWarning],
]);

export const orderWarningFactory = ({
  code,
  message,
}: ServerResponse.OrderWarning): OrderWarning =>
  orderWarningMap.has(code)
    ? new (orderWarningMap.get(code))(message)
    : new OrderWarning(code, message);
