var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-topNavigation\" data-test=\"appTopNavigation\">\n  <div class=\"oa-topNavigation__backToSearch u-pt-s u-pb-s\" data-test=\"appTopNavigation-backToSearchButton\">\n    <a href=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"backToSearchUrl") : depth0), depth0))
    + "\"><span class=\"glyphicon glyphicon-search u-pr-s\"></span>"
    + alias1(__default(require("./helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"navigation.back_to_search",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":89},"end":{"line":3,"column":125}}}))
    + "</a>\n  </div>\n  <div component=\"--divider\"></div>\n</div>\n";
},"useData":true});