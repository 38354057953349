var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"oa-claim-drafts\" data-test=\"claim_drafts\">\n    <table class=\"table table-condensed u-table\">\n      <thead>\n      <tr>\n        <th colspan=\"3\">\n          <span class=\"glyphicon glyphicon-pencil u-pr-s\"></span>\n          "
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(alias1,"order.claim_drafts.header",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":8,"column":46}}}))
    + "\n        </th>\n      </tr>\n      </thead>\n      <tbody class=\"oa-claim-drafts__tableContent\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"claimDrafts") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":31,"column":17}}})) != null ? stack1 : "")
    + "      </tbody>\n    </table>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <tr data-test=\"claim_draft\">\n            <td data-test=\"claim_draft.id\">\n              <a class=\"oa-claim-drafts__claimLink\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"claimDraftUrl") : depth0), depth0))
    + "\" target=\"_blank\" data-test=\"claim_draft.claimLink\">\n                <strong>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"claimDraftId") : depth0), depth0))
    + "</strong>\n              </a>\n            </td>\n            <td class=\"oa-claim-drafts__creationDate\" data-test=\"claim_draft.creationDate\">\n              <span title=\""
    + alias2(__default(require("../helpers/i18n.js")).call(alias3,"order.claims.creation_date",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":21,"column":27},"end":{"line":21,"column":64}}}))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"parsedCreationDate") : depth0), depth0))
    + "</span>\n            </td>\n            <td class=\"oa-claim-drafts__resolutions\" data-test=\"claim_draft.resolutions\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias3,(depth0 != null ? lookupProperty(depth0,"resolution") : depth0),{"name":"with","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":14},"end":{"line":27,"column":23}}})) != null ? stack1 : "")
    + "            </td>\n            <td></td>\n          </tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./claimDraftResolutionOption.hbs"),depth0,{"name":"claimDraftResolutionOption","hash":{"resolution":(depth0 != null ? lookupProperty(depth0,"obligation") : depth0)},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./claimDraftResolutionOption.hbs"),depth0,{"name":"claimDraftResolutionOption","hash":{"resolution":(depth0 != null ? lookupProperty(depth0,"compensation") : depth0)},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/conditionals/gt.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"claimDrafts") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),0,{"name":"conditionals/gt","hash":{},"data":data,"loc":{"start":{"line":1,"column":6},"end":{"line":1,"column":46}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":35,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});