export default class ShopCurrency {
  private constructor(private _currency: string) {}

  get currency() {
    return this._currency;
  }

  static create(currency: any): ShopCurrency {
    return new ShopCurrency(<string>currency);
  }
}
