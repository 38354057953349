export default function addressServiceExtractor(evt: Event) {
  const htmlFormElement = evt.target as HTMLFormElement;
  return Object.freeze({
    firstName: htmlFormElement.firstName.value,
    lastName: htmlFormElement.lastName.value,
    street: htmlFormElement.street.value,
    city: htmlFormElement.city.value,
    postCode: htmlFormElement.postalCode.value,
    countryISOCode: htmlFormElement.countryISOCode.value,
    phone: htmlFormElement.phone.value,
  });
}
