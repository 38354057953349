import { Services } from '@cc/cc-app-commons';
import { ServerResponse } from '../../types';
import ArticleBasedDiscount from '../../models/ArticleBasedDiscount';
import ShopCurrency from '../../models/ShopCurrency';
import { ArticleBasedDiscountsAppError } from '../../models/Errors';

export class ArticleBasedDiscountsService {
  connector: Services.KeycloakSecuredZooServicesConnector;
  gatewayUrl: string;

  constructor(connector: Services.KeycloakSecuredZooServicesConnector, gatewayUrl: string) {
    this.connector = connector;
    this.gatewayUrl = gatewayUrl;
  }

  async getAbdTrackingData(
    suborderId: string,
    shopCurrency: ShopCurrency,
  ): Promise<ArticleBasedDiscount[]> {
    try {
      const abdTrackingResponse = await this.connector.fetchData<
        ServerResponse.ArticleWithDiscount[]
      >(this.gatewayUrl, `abd-tracking-api/v1/orders/${suborderId}`);
      return abdTrackingResponse.map((abdItemResponse) =>
        new ArticleBasedDiscount().fromData(abdItemResponse, shopCurrency),
      );
    } catch (err) {
      throw new ArticleBasedDiscountsAppError(err);
    }
  }
}
