var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"claims") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":42,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr data-test=\"claim\">\n          <td data-test=\"claim.id\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"claimBrowserClaimUrl") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":22,"column":19}}})) != null ? stack1 : "")
    + "            <span data-test=\"claim.copyClaimIdButton\" class=\"oa-copyButton oa-claims__copyClaimIdButton\"\n                  data-claim-id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"claimId") : depth0), depth0))
    + "\"\n                  data-clipboard-text=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"claimId") : depth0), depth0))
    + "\">\n              <span class=\"glyphicon glyphicon-duplicate\"></span>\n            </span>\n          </td>\n          <td class=\"oa-claims__creationDate\" data-test=\"claim.creationDate\">\n            <span title=\""
    + alias3(__default(require("../helpers/i18n.js")).call(alias1,"order.claims.creation_date",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":30,"column":25},"end":{"line":30,"column":62}}}))
    + "\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"parsedCreationDate") : depth0), depth0))
    + "</span>\n          </td>\n          <td class=\"oa-claims__resolutions\" data-test=\"claim.resolutions\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"resolution") : depth0),{"name":"with","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":36,"column":21}}})) != null ? stack1 : "")
    + "          </td>\n          <td>\n            <span class=\"oa-claims__status__indicator oa-claims__status--"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"status") : depth0), depth0))
    + " u-pl-s u-pr-s u-pb-xs u-pt-xs\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"status") : depth0), depth0))
    + "</span>\n          </td>\n        </tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <a class=\"oa-claims__claimLink\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"claimBrowserClaimUrl") : depth0), depth0))
    + "\" target=\"_blank\" data-test=\"claim.claimLink\">\n                <strong>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"claimId") : depth0), depth0))
    + "</strong>\n              </a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <strong>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"claimId") : depth0), depth0))
    + "</strong>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./claimResolutionOption.hbs"),depth0,{"name":"./claimResolutionOption","hash":{"status":(depths[1] != null ? lookupProperty(depths[1],"status") : depths[1]),"isResolved":(depths[1] != null ? lookupProperty(depths[1],"isResolved") : depths[1]),"resolution":(depth0 != null ? lookupProperty(depth0,"obligation") : depth0)},"data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./claimResolutionOption.hbs"),depth0,{"name":"./claimResolutionOption","hash":{"status":(depths[1] != null ? lookupProperty(depths[1],"status") : depths[1]),"isResolved":(depths[1] != null ? lookupProperty(depths[1],"isResolved") : depths[1]),"resolution":(depth0 != null ? lookupProperty(depth0,"compensation") : depth0)},"data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <tr>\n        <td colspan=\"4\" data-test=\"claims.notAvailable\">\n"
    + ((stack1 = container.invokePartial(require("../partials/noResultsFound.hbs"),depth0,{"name":"noResultsFound","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </td>\n      </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-claims\" data-test=\"claims\">\n  <table class=\"table table-condensed u-table\">\n    <thead>\n    <tr>\n      <th colspan=\"3\">\n        <span class=\"glyphicon glyphicon-flash u-pr-s\"></span>\n        "
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(alias1,"order.claims.header",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":38}}}))
    + "\n      </th>\n    </tr>\n    </thead>\n    <tbody class=\"oa-claims__tableContent\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/conditionals/gt.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"claims") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),0,{"name":"conditionals/gt","hash":{},"data":data,"loc":{"start":{"line":12,"column":10},"end":{"line":12,"column":45}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":49,"column":11}}})) != null ? stack1 : "")
    + "    </tbody>\n  </table>\n</div>";
},"usePartial":true,"useData":true,"useDepths":true});