var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"oa-orderSwitcher-orderProblemIndicatorTooltipWrapper\">\n      <div class=\"oa-orderSwitcher-orderProblemIndicatorTooltip oa-tooltipWrapper\"\n           data-toggle=\"tooltip\"\n           data-placement=\"bottom\"\n           data-html=\"true\"\n           data-container=\"body\"\n           title=\""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"tooltipConfig") : depth0)) != null ? lookupProperty(stack1,"content") : stack1), depth0)) != null ? stack1 : "")
    + "\">\n        <div class=\"oa-orderSwitcher-orderProblemIndicator oa-orderSwitcher-orderProblemIndicator--"
    + container.escapeExpression(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tooltipConfig") : depth0)) != null ? lookupProperty(stack1,"alertType") : stack1)) != null ? lookupProperty(stack1,"code") : stack1), depth0))
    + "\"></div>\n      </div>\n    </div>\n    <div component=\"--fraudIcon\"></div>\n    <div component=\"--relaxDeliveryInfo\"></div>\n    <div component=\"--subscriptionInfo\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-orderSwitcher-orderItem oa-orderSwitcher-orderItem--"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\">\n  <div class=\"oa-orderSwitcher-selectedOrderLabel\">\n    <div class=\"oa-orderSwitcher-orderNumber\">\n      <strong>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "</strong>\n      <div data-test=\"orderSwitcher.copyOrderIdButton\" class=\"oa-orderSwitcher__copyOrderIdButton\"\n           data-placement=\"bottom\"\n           data-clipboard-text=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n        <span class=\"glyphicon glyphicon-duplicate\"></span>\n      </div>\n    </div>\n    <div class=\"oa-orderSwitcher-orderDate\" data-test=\"orderSwitcher.orderDate\">\n      "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"creationDate") : stack1), depth0))
    + "\n    </div>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,__default(require("../helpers/conditionals/neq.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"listItem",{"name":"conditionals/neq","hash":{},"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":15,"column":44}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":2},"end":{"line":29,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});