export interface AppInterfaceService {
  showModal(title: string, template: string, data: Object): void;

  openAppInNewWindow(orderId?: number): void;

  getTopNavigationConfiguration(): TopNavigationConfiguration;
}

export class TopNavigationConfiguration {
  private readonly _shouldBeDisplayed: boolean;
  private readonly _backToSearchUrl?: string;

  constructor(shouldBeDisplayed: boolean, backToSearchUrl: string = null) {
    this._shouldBeDisplayed = shouldBeDisplayed;
    this._backToSearchUrl = backToSearchUrl;
  }

  get shouldBeDisplayed(): boolean {
    return this._shouldBeDisplayed;
  }

  get backToSearchUrl(): string | null {
    return this._backToSearchUrl;
  }
}
