import { Models } from '@cc/cc-app-commons';

export default class Address extends Models.BaseModel {
  firstName: string = undefined;
  lastName: string = undefined;
  company: string = undefined;
  street: string = undefined;
  city: string = undefined;
  postalCode: string = undefined;
  countryISOCode: string = undefined;
  phone: string = undefined;

  get flagCode(): string {
    return Models.Flag.getCodeForCountryCode(this.countryISOCode);
  }
}
