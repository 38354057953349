// @ts-ignore
import { ProxyingFdClient } from 'ticketing-app';
import _ from 'lodash';
import { IndependentAppContextFactory } from '../view/context/independent/IndependentAppContextFactory';
import { FreshdeskAppTicketIndependentContextFactory } from '../view/context/clientbased/freshdesk/ticketindependent/FreshdeskAppTicketIndependentContextFactory';
import { FreshdeskAppContextFactory } from '../view/context/clientbased/freshdesk/ticketview/FreshdeskAppContextFactory';
import { FreshchatAppContextFactory } from '../view/context/clientbased/freshchat/FreshchatAppContextFactory';
import { IndependentAppEventsHandler } from '../view/context/independent/IndependentAppEventsHandler';
import { FreshchatAppEventsHandler } from '../view/context/clientbased/freshchat/FreshchatAppEventsHandler';
import { FreshdeskAppTicketIndependentEventsHandler } from '../view/context/clientbased/freshdesk/ticketindependent/FreshdeskAppTicketIndependentEventsHandler';
import { FreshdeskAppEventsHandler } from '../view/context/clientbased/freshdesk/ticketview/FreshdeskAppEventsHandler';

export class AppContextFactoryProvider {
  private readonly ticketId: any;
  private readonly orderId: any;
  private readonly customerId: any;
  private readonly invoiceId: any;
  private readonly cidOrOidOrIidProvided: boolean;
  private readonly tidProvided: boolean;
  private readonly proxyingFdClient: any;
  constructor(ticketId: number, customerId: number, orderId: number, invoiceId: string) {
    this.ticketId = ticketId;
    this.customerId = customerId;
    this.orderId = orderId;
    this.invoiceId = invoiceId;
    this.cidOrOidOrIidProvided =
      _.isNumber(this.customerId) || _.isNumber(this.orderId) || _.isString(this.invoiceId);
    this.tidProvided = _.isNumber(this.ticketId);
    this.proxyingFdClient = new ProxyingFdClient();
  }

  createAppContextFactory(clientType: string) {
    if (clientType === undefined) {
      return new IndependentAppContextFactory(this.customerId, this.orderId, this.invoiceId);
    }
    if (clientType === 'freshdesk') {
      return this.cidOrOidOrIidProvided && !this.tidProvided
        ? new FreshdeskAppTicketIndependentContextFactory(
            this.proxyingFdClient,
            this.customerId,
            this.orderId,
            this.invoiceId,
          )
        : new FreshdeskAppContextFactory(this.proxyingFdClient);
    } else if (clientType === 'freshchat') {
      return new FreshchatAppContextFactory(this.proxyingFdClient);
    }
  }

  createEventHandlerFactory(clientType: string) {
    if (clientType === undefined) {
      return new IndependentAppEventsHandler();
    }
    if (clientType === 'freshdesk') {
      return this.cidOrOidOrIidProvided && !this.tidProvided
        ? new FreshdeskAppTicketIndependentEventsHandler(this.proxyingFdClient)
        : new FreshdeskAppEventsHandler(this.proxyingFdClient);
    } else if (clientType === 'freshchat') {
      return new FreshchatAppEventsHandler(this.proxyingFdClient);
    }
  }
}

export default AppContextFactoryProvider;
