import { Models } from '@cc/cc-app-commons';
import ParcelPhysicalArticle from './ParcelPhysicalArticle';
import createProductAdminUrl from '../util/createProductAdminUrl';
import { TemplateData } from './types';

export default class ParcelArticle extends Models.BaseModel implements TemplateData {
  articleNumber: string = '';
  name: string = '';
  productId: number = undefined;
  parcelPhysicalArticles: ParcelPhysicalArticle[] = [];
  shopArticleUrl?: string = undefined;

  get articleAdminUrl(): string {
    return createProductAdminUrl(this.productId);
  }

  toTemplateData(...context: any): any {
    return this.toData(...context);
  }
}
