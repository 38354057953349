var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-standaloneViewOpener\">\n  <div class=\"oa-standaloneViewOpener-content\">\n"
    + ((stack1 = container.invokePartial(require("./selectedOrderLabel.hbs"),depth0,{"name":"./selectedOrderLabel","hash":{"tooltipConfig":(depth0 != null ? lookupProperty(depth0,"tooltipConfig") : depth0),"order":(depth0 != null ? lookupProperty(depth0,"order") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"oa-standaloneViewOpener-actions oa-standaloneViewOpener-actions--hidden\">\n    <div data-test=\"selectedOrderLabel.copyOrderIdButton\" class=\"oa-copyButton oa-standaloneViewOpener-copyOrderIdButton\"\n         data-placement=\"bottom\"\n         data-clipboard-text=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n      <span class=\"glyphicon glyphicon-duplicate\"></span>\n    </div>\n    <div class=\"oa-standaloneViewOpener-actionsSeparator\"></div>\n    <div class=\"oa-standaloneViewOpener-link\">\n      <div class=\"oa-standaloneViewOpener-linkContent\">\n        <div class=\"oa-standaloneViewOpener-linkContentText\">\n          <strong>"
    + alias1(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"standalone_opener.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":18},"end":{"line":15,"column":52}}}))
    + "</strong>\n        </div>\n        <div class=\"oa-standaloneViewOpener-linkContentIcon\">\n          <span class=\"glyphicon glyphicon-arrow-right\" aria-hidden=\"true\"></span>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"usePartial":true,"useData":true});