import OrderWarningViewModel from './OrderWarningViewModel';

export default class UnknownWarningViewModel extends OrderWarningViewModel {
  constructor() {
    super(null, 'Unknown warning');
  }

  render() {
    console.warn('Unknown warning type. Please implement proper Model and ViewModel');
  }
}
