import OrderV1 from './OrderV1';
import { Order, Suborder } from './Order';

export function mapToOrder(orderV1: OrderV1): Order {
  const order = new Order();
  order.id = orderV1.id;
  order.customerId = orderV1.customerId;
  order.domain = orderV1.domain;
  order.creationDate = orderV1.creationDate;
  order.shop = orderV1.shop;
  order.shopCurrency = orderV1.shopCurrency;
  order.isFraud = orderV1.isFraud;
  order.relaxDeliveryStatus = orderV1.relaxDeliveryStatus;
  order.channel = orderV1.channel;
  order.totalValue = orderV1.totalValue;
  order.totalMonetaryValue = orderV1.totalMonetaryValue;
  order.suborders = mapToSuborder(orderV1);
  order.shippingAddress = orderV1.shippingAddress;
  order.billingAddress = orderV1.billingAddress;
  order.billingAddressModifiable = orderV1.billingAddressModifiable;
  order.shippingAddressModifiable = orderV1.shippingAddressModifiable;
  return order;
}

function mapToSuborder(orderV1: OrderV1): Suborder[] {
  const suborder = new Suborder();
  suborder.id = `${orderV1.id}`;
  suborder.invoiceId = orderV1.invoiceId;
  suborder.locale = orderV1.locale;
  suborder.index = 0;
  suborder.sellerName = mapDomainToSeller(orderV1.domain);
  suborder.orderType = '1P-REGULAR';
  suborder.status = orderV1.status;
  suborder.articles = orderV1.articles;
  suborder.discounts = orderV1.discounts;
  suborder.fees = orderV1.fees;
  suborder.articleBasedDiscounts = orderV1.articleBasedDiscounts;
  suborder.parcels = orderV1.parcels;
  suborder.totalValue = orderV1.totalValue;
  suborder.totalMonetaryValue = orderV1.totalMonetaryValue;
  suborder.isZooplusSuborder = true;
  suborder.warnings = orderV1.warnings;
  suborder.relaxDeliveryStatus = orderV1.relaxDeliveryStatus;
  suborder.relatedOrders = orderV1.relatedOrders;
  return [suborder];
}

function mapDomainToSeller(domain: string) {
  if (domain.includes('bitiba')) {
    return 'Bitiba';
  }
  return 'Zooplus';
}
