import { Helpers, Models, Services } from '@cc/cc-app-commons';
import { ViewModel } from '../types';
import { Order } from '../../models/Order';
import mediaTypeDetector from '../../util/MediaTypeDetector';
import CancelOrderReasonViewModel from './CancelOrderReasonViewModel';
import OrderActionsModalHelper from './OrderActionsModalHelper';

const AppActivityAction = Services.AppActivityAction;

const { clear, renderTo } = Helpers.ClientHelpers;
const tplPlugin = require('../../../templates/order-actions/pluginOrderActionButtonsForModal.hbs');
const tplStandalone = require('../../../templates/order-actions/standaloneOrderActionButtons.hbs');

export default class OrderActionButtonsViewModel {
  private static ORDER_ACTIONS_SELECTOR = '.oa-orderActions .oa-orderActionButton';
  private static CANCEL_ORDER_BUTTON_SELECTOR =
    '.oa-orderActions .oa-orderActionButton__cancelOrder a';

  el: Element;
  private readonly context: ViewModel.Context;
  eventRemovers: Models.Remover[] = [];
  order: Order;

  constructor(el: Element, context: ViewModel.Context) {
    this.el = el;
    this.context = context;
  }

  async render() {
    await this.ensureDataLoaded();
    await this.renderOrderActions();
    this.attachPluginsAndEventHandlers();
  }

  private async ensureDataLoaded(): Promise<void> {
    this.order = await this.context.data.currentOrder;
  }

  private async renderOrderActions() {
    const tpl = mediaTypeDetector.isPlugin() ? tplPlugin : tplStandalone;
    const isOrderCancellable = await this.context.data.isOrderCancellable(this.order.id);
    renderTo(
      this.el,
      tpl({
        orderId: this.order.id,
        claimWizardQueryParams: this.context.origin.getUrlParamsForClaimWizard(),
        isOrderCancellable: isOrderCancellable,
        isArticleBasedDiscountsEnabled:
          await this.context.data.isArticleBasedDiscountsEnabledForCurrentOrder(),
        authorization: {
          canUseClaimWizard: this.context.authorization.isAuthorizedToOpenClaimWizard(),
          canCancelOrder: this.context.authorization.isAuthorizedToCancelOrder(),
        },
      }),
    );
  }

  private attachPluginsAndEventHandlers() {
    this.el
      .querySelectorAll(OrderActionButtonsViewModel.ORDER_ACTIONS_SELECTOR)
      .forEach((el) => $(el).tooltip());

    const onCancelOrderActionButtonClicked = this.proceedToOrderCancellationAction.bind(this);
    const cancelOrderActionButton = this.el.querySelector(
      OrderActionButtonsViewModel.CANCEL_ORDER_BUTTON_SELECTOR,
    );
    if (cancelOrderActionButton) {
      cancelOrderActionButton.addEventListener('click', onCancelOrderActionButtonClicked);
      this.eventRemovers.push(
        Models.Remover.createFor(
          function (): void {
            cancelOrderActionButton.removeEventListener('click', onCancelOrderActionButtonClicked);
          }.bind(this) // eslint-disable-line
        ),
      );
    }

    this.attachArticleBasedDiscountsHandler();

    this.initMetricsListeners();
  }

  private proceedToOrderCancellationAction(e: Event) {
    e.preventDefault();
    const modalWrapper = OrderActionsModalHelper.getModal();
    modalWrapper.renderContainer(
      CancelOrderReasonViewModel,
      this.context,
      this.onOrderCancel.bind(this),
    );
    modalWrapper.open();
  }

  private async onOrderCancel() {
    this.context.data.refreshCurrentOrder();
    if (mediaTypeDetector.isStandalone()) {
      this.rerender();
    }
  }

  private attachArticleBasedDiscountsHandler() {
    const actionButtonClicked = () => {
      this.context.data.enrichCurrentOrderWithArticleBasedDiscountsData();
    };
    const actionButton = this.el.querySelector(
      '.oa-orderActions .oa-orderActionButton__fetchArticleBasedDiscountsInfo a',
    );
    if (actionButton) {
      actionButton.addEventListener('click', actionButtonClicked);
      this.eventRemovers.push(
        Models.Remover.createFor(() =>
          actionButton.removeEventListener('click', actionButtonClicked),
        ),
      );
    }
  }

  private rerender() {
    this.destroy();
    this.render();
  }

  private initMetricsListeners() {
    const callback = () => {
      this.context.metric.logAppActivity(AppActivityAction.CLAIM_WIZARD_LINK__CLICKED);
    };
    const claimWizardActionButton = this.el.querySelector('.oa-orderActionButton__claimWizardLink');
    if (claimWizardActionButton) {
      claimWizardActionButton.addEventListener('click', callback);
      this.eventRemovers.push(
        Models.Remover.createFor(
          function (): void {
            claimWizardActionButton.removeEventListener('click', callback);
          }.bind(this) // eslint-disable-line
        ),
      );
    }
  }

  private detachPluginsAndEventHandlers() {
    this.eventRemovers.forEach((eventHandlerRemover: Models.Remover) =>
      eventHandlerRemover.remove(),
    );
  }

  destroy() {
    this.detachPluginsAndEventHandlers();
    clear(this.el);
  }
}
