import { Models } from '@cc/cc-app-commons';

export default class RelaxDeliveryStatus extends Models.BaseModel {
  maxWorkingDays?: number = undefined;
  isRelaxDelivery?: boolean = undefined;
  isUnknown?: boolean = undefined;

  fromData(json: any): any {
    let relaxDelivery = new RelaxDeliveryStatus();
    if (!json) {
      relaxDelivery.isUnknown = true;
    } else {
      relaxDelivery = super.fromData(json);
    }
    return relaxDelivery;
  }
}
