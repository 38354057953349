import OrderWarning, {
  InvoiceNumberWarning,
  OrderParcelsWarning,
  RelaxDeliveryWarning,
} from '../../models/OrderWarning';
import OrderParcelsWarningViewModel from './OrderParcelsWarningViewModel';
import UnknownWarningViewModel from './UnknownWarningViewModel';

export default (domElement: HTMLElement, error: OrderWarning, suborderId: string) => {
  if (error instanceof OrderParcelsWarning) {
    return new OrderParcelsWarningViewModel(domElement, suborderId);
  } else if (error instanceof RelaxDeliveryWarning || error instanceof InvoiceNumberWarning) {
    return undefined;
  } else {
    return new UnknownWarningViewModel();
  }
};
