import { ViewModel } from '../../types';
import OriginContext = ViewModel.OriginContext;

export class AppOriginContext implements OriginContext {
  private queryParameters: Map<string, string>;

  constructor(queryParameters: Map<string, string>) {
    this.queryParameters = queryParameters;
  }

  getUrlParamsForClaimWizard(): string {
    let queryUrlString = '';
    this.queryParameters.forEach((value, key) => {
      queryUrlString += key + '=' + value + '&';
    });
    if (queryUrlString !== '') {
      queryUrlString = '?' + queryUrlString.substr(0, queryUrlString.length - 1);
    }
    return queryUrlString;
  }
}
