var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-claim-drafts__resolutionOption\" data-test=\"claim_draft.resolutions.option\">\n  <div class=\"oa-claim-drafts__resolutionOptionIcon\"\n       data-toggle=\"tooltip\"\n       data-placement=\"bottom\"\n       data-trigger=\"hover\"\n       title=\""
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,__default(require("../helpers/concat.js")).call(alias1,"order.claims.resolution.",((stack1 = (depth0 != null ? lookupProperty(depth0,"resolution") : depth0)) != null ? lookupProperty(stack1,"translationKeyPhrase") : stack1),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":6,"column":22},"end":{"line":6,"column":89}}}),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":14},"end":{"line":6,"column":91}}}))
    + "\"\n  >\n    <i class=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"resolution") : depth0)) != null ? lookupProperty(stack1,"icon") : stack1), depth0))
    + " icon icon--large\"></i>\n  </div>\n</div>";
},"useData":true});