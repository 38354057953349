import { Tooltip } from '../types';
import { View } from '@cc/cc-app-commons';

const OrderIdTooltipContent = Tooltip.Order.Id.Content;

const basicTooltipParagraphTpl = require('../../templates/basicTooltipParagraph.hbs');

const Alert = View.Errors.ErrorType;

export default abstract class OrderResolutionMetadata {
  private readonly providedCustomerId: number;
  private readonly providedOrderId: number;
  private readonly ordersCustomerId: number;
  private readonly customerOrderMismatchTooltipContent: any;

  protected constructor(
    providedCustomerId: number,
    providedOrderId: number,
    customerIdFromOrder: number,
    customerOrderMismatchTooltipContent = OrderIdTooltipContent.CUSTOMER_ORDER_MISMATCH,
  ) {
    this.providedCustomerId = providedCustomerId;
    this.providedOrderId = providedOrderId;
    this.ordersCustomerId = customerIdFromOrder;
    this.customerOrderMismatchTooltipContent = customerOrderMismatchTooltipContent;
  }

  get alertType(): any {
    return this.providedCustomerId && this.providedCustomerId !== this.ordersCustomerId
      ? Alert.ERROR
      : Alert.INFO;
  }

  get content() {
    const tooltipConfig = this.contentConfiguration();
    return basicTooltipParagraphTpl({
      translationKey: `${tooltipConfig.namespace}.${tooltipConfig.contentKey}`,
      context: tooltipConfig.context,
    });
  }

  protected contentConfiguration(): Tooltip.Order.Id.Model {
    let context = {};
    let content;
    if (this.providedCustomerId && this.providedCustomerId !== this.ordersCustomerId) {
      context = {
        ordersCustomerId: this.ordersCustomerId,
        providedCustomerId: this.providedCustomerId,
      };
      content = this.customerOrderMismatchTooltipContent;
    } else if (this.providedOrderId) {
      context = {
        orderId: this.providedOrderId,
      };
      content = OrderIdTooltipContent.ORDER_BY_ORDER_ID;
    } else if (this.providedCustomerId) {
      context = {
        customerId: this.providedCustomerId,
      };
      content = OrderIdTooltipContent.ORDER_BY_CUSTOMER_ID;
    }
    return {
      namespace: 'order.additional_info',
      contentKey: content,
      context,
    };
  }
}
