import { Models } from '@cc/cc-app-commons';

enum AddressModificationErrors {
  GENERAL_ADDRESS_MODIFICATION_ERROR = 'general_address_modification_error',
}

export class AddressModificationError extends Models.Errors.AppError {
  constructor(
    originError: any = {},
    code: string = AddressModificationErrors.GENERAL_ADDRESS_MODIFICATION_ERROR,
  ) {
    super(null, code, originError);
  }
}
