var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "          <div class=\"oa-mailsStandalone\">\n            <div class=\"oa-mailsStandaloneHeader\">\n              <h3>"
    + container.escapeExpression(__default(require("./helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"transactional_mail.standalone.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":31,"column":18},"end":{"line":31,"column":64}}}))
    + "</h3>\n              <button type=\"button\" class=\"close oa-mailsStandalonePreviewCloseButton\">\n                <span class=\"glyphicon glyphicon-remove\"></span>\n              </button>\n            </div>\n            <iframe name=\"mailPreviewContainer\" class=\"oa-mailStandaloneContent\"></iframe>\n          </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container-fluid oa-order\">\n\n  <!-- Nav tabs -->\n  <ul class=\"nav nav-tabs\" role=\"tablist\" data-test=\"orderNavigationTabs\">\n    <li role=\"presentation\" class=\"active\" data-test=\"orderSummaryTabLink\">\n      <a id=\"orderSummaryTabLink\" href=\"#orderSummaryTabContent\" role=\"tab\" data-toggle=\"tab\">"
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"order.tabs.summary",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":94},"end":{"line":6,"column":123}}}))
    + "</a>\n    </li>\n    <li role=\"presentation\" data-test=\"orderTimelineTabLink\">\n      <a id=\"orderTimelineTabLink\" href=\"#orderTimelineTabContent\" role=\"tab\" data-toggle=\"tab\">"
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"order.tabs.timeline",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":96},"end":{"line":9,"column":127}}}))
    + "</a>\n    </li>\n  </ul>\n\n  <!-- Tab panes -->\n  <div class=\"tab-content\">\n    <div role=\"tabpanel\" class=\"tab-pane active\" id=\"orderSummaryTabContent\" data-test=\"orderSummaryTabContent\">\n\n      <div class=\"oa-orderTabContainer oa-orderSummaryContainer\"></div>\n\n    </div>\n    <div role=\"tabpanel\" class=\"tab-pane\" id=\"orderTimelineTabContent\" data-test=\"orderTimelineTabContent\">\n\n      <div class=\"oa-timelineTabContentWrapper\">\n        <div>\n          <div class=\"oa-orderTabContainer oa-orderTimelineContainer oa-orderPaymentTransactionsTimelineContainer\"></div>\n          <div class=\"oa-orderTabContainer oa-orderTimelineContainer oa-orderCommunicationTimelineContainer\"></div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/layout/isMediaTypeStandalone.js")).call(alias1,{"name":"layout/isMediaTypeStandalone","hash":{},"data":data,"loc":{"start":{"line":28,"column":14},"end":{"line":28,"column":46}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":38,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n\n</div>\n";
},"useData":true});