import { Helpers, Services } from '@cc/cc-app-commons';
import '../../templates/order-switcher/standaloneViewOpener.css';
import { ViewModel } from './types';
import OrderMetadata from './order/OrderMetadata';
import mediaTypeDetector from '../util/MediaTypeDetector';
import AppActivityAction = Services.AppActivityAction;

type ClipboardHelper = Helpers.ClipboardHelper;

const ClipboardHelper = Helpers.ClipboardHelper;
const { clear, renderTo } = Helpers.ClientHelpers;
const selectedOrderLabelWithStandaloneViewOpenerTemplate = require('../../templates/order-switcher/selectedOrderLabelWithStandaloneViewOpener.hbs');
const selectedOrderLabelTemplate = require('../../templates/order-switcher/selectedOrderLabel.hbs');

export default class SelectedOrderLabelViewModel {
  private static STANDALONE_VIEW_LINK_SELECTOR = '.oa-standaloneViewOpener-link';
  private static ACTIONS_SELECTOR = '.oa-standaloneViewOpener-actions';
  private static CONTENT_SELECTOR = '.oa-standaloneViewOpener-content';
  private static ACTIONS_HIDDEN_CLASS = 'oa-standaloneViewOpener-actions--hidden';
  private static ACTIONS_VISIBLE_CLASS = 'oa-standaloneViewOpener-actions--visible';
  private static CONTENT_TRANSPARENTIZED_SELECTOR =
    'oa-standaloneViewOpener-content--transparentized';
  private static ORDER_ID_COPY_BUTTON_ELEMENT_SELECTOR =
    '.oa-standaloneViewOpener-copyOrderIdButton';
  private el: Element;
  private standaloneLinkEl: HTMLElement;
  private actionsEl: HTMLElement;
  private contentEl: HTMLElement;
  private context: ViewModel.Context;
  private orderMetadata: OrderMetadata;
  private tooltipConfig: Object;
  private clipboardHelpers: ClipboardHelper[] = [];
  private ccAdminUrl: string;

  constructor(
    domElement: Element,
    context: ViewModel.Context,
    orderMetadata: OrderMetadata,
    tooltipConfig: Object,
    ccAdminUrl: string,
  ) {
    this.el = domElement;
    this.context = context;
    this.orderMetadata = orderMetadata;
    this.tooltipConfig = tooltipConfig;
    this.ccAdminUrl = ccAdminUrl;
  }

  render() {
    if (mediaTypeDetector.isPlugin()) {
      this.renderSelectedOrderLabelWithStandaloneViewOpener();
    } else {
      this.renderSelectedOrderLabel();
    }
  }

  private renderSelectedOrderLabelWithStandaloneViewOpener() {
    renderTo(
      this.el,
      selectedOrderLabelWithStandaloneViewOpenerTemplate({
        order: this.orderMetadata.order,
        tooltipConfig: this.tooltipConfig,
      }),
    );
    this.initPluginViewElements();
    this.initPluginViewListeners();
  }

  private renderSelectedOrderLabel() {
    renderTo(
      this.el,
      selectedOrderLabelTemplate({
        order: this.orderMetadata.order,
        url: this.ccAdminUrl,
        tooltipConfig: this.tooltipConfig,
      }),
    );
  }

  private initPluginViewElements() {
    this.standaloneLinkEl = this.el.querySelector(
      SelectedOrderLabelViewModel.STANDALONE_VIEW_LINK_SELECTOR,
    );
    this.contentEl = this.el.querySelector(SelectedOrderLabelViewModel.CONTENT_SELECTOR);
    this.actionsEl = this.el.querySelector(SelectedOrderLabelViewModel.ACTIONS_SELECTOR);
  }

  private initPluginViewListeners() {
    this.initPointerListeners();
    this.initClickListeners();
  }

  private initPointerListeners() {
    $(this.el).on('mouseover', this.showActions.bind(this));
    $(this.el).on('mouseout', this.hideActions.bind(this));
    this.el
      .querySelectorAll(SelectedOrderLabelViewModel.ORDER_ID_COPY_BUTTON_ELEMENT_SELECTOR)
      .forEach((el) => {
        this.clipboardHelpers.push(
          new ClipboardHelper(el, 'order.header.copy_orderid_button', { container: this.el }),
        );
      });
  }

  private initClickListeners() {
    $(this.standaloneLinkEl).on('click', this.openAppInNewWindow.bind(this));
    $(this.standaloneLinkEl).on('click', this.notifyStandaloneViewClicked.bind(this));
    $(this.standaloneLinkEl).on('click', this.hideActions.bind(this));
  }

  private showActions() {
    this.actionsEl.classList.remove(SelectedOrderLabelViewModel.ACTIONS_HIDDEN_CLASS);
    this.actionsEl.classList.add(SelectedOrderLabelViewModel.ACTIONS_VISIBLE_CLASS);
    this.contentEl.classList.add(SelectedOrderLabelViewModel.CONTENT_TRANSPARENTIZED_SELECTOR);
  }

  private hideActions() {
    this.actionsEl.classList.remove(SelectedOrderLabelViewModel.ACTIONS_VISIBLE_CLASS);
    this.actionsEl.classList.add(SelectedOrderLabelViewModel.ACTIONS_HIDDEN_CLASS);
    this.contentEl.classList.remove(SelectedOrderLabelViewModel.CONTENT_TRANSPARENTIZED_SELECTOR);
  }

  private async openAppInNewWindow() {
    this.context.interface.openAppInNewWindow((await this.context.data.currentOrder).id);
  }

  private notifyStandaloneViewClicked(): void {
    this.context.metric.logAppActivity(AppActivityAction.STANDALONE_VIEW__CLICKED);
  }

  destroy() {
    $(this.el).off('mouseover');
    $(this.el).off('mouseout');
    $(this.standaloneLinkEl).off('click');
    while (this.clipboardHelpers?.length) {
      const clipboardHelper = this.clipboardHelpers.shift();
      clipboardHelper.destroy();
    }
    clear(this.el);
  }
}
