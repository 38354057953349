import { Models } from '@cc/cc-app-commons';

type AppError = Models.Errors.AppError;

export default class ErrorForViewModel {
  constructor(
    private originError: AppError,
    private translationKeyPrefix: string,
  ) {}

  get error(): AppError {
    return this.originError;
  }

  get titleTranslationKey(): string {
    return `${this.translationKeyPrefix}.${this.error.name}.title`;
  }

  get messageTranslationKey(): string {
    return `${this.translationKeyPrefix}.${this.error.name}.message`;
  }
}
