var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"oa-orderActionButton oa-orderActionButton__cancelOrder\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/logic/not.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isOrderCancellable") : depth0),{"name":"logic/not","hash":{},"data":data,"loc":{"start":{"line":3,"column":20},"end":{"line":3,"column":52}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":92}}})) != null ? stack1 : "")
    + "\n              oa-orderActionButton--"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/conditionals/eq.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"mode") : depth0),"tile",{"name":"conditionals/eq","hash":{},"data":data,"loc":{"start":{"line":4,"column":42},"end":{"line":4,"column":73}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":4,"column":36},"end":{"line":4,"column":102}}})) != null ? stack1 : "")
    + "\"\n       title=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isOrderCancellable") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":5,"column":14},"end":{"line":5,"column":179}}})) != null ? stack1 : "")
    + "\"\n       data-placement=\"bottom\"\n       data-test=\"cancelOrderButton\"\n       data-test-order-id=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"orderId") : depth0), depth0))
    + "\">\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isOrderCancellable") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":9,"column":41}}})) != null ? stack1 : "")
    + "\n    <div class=\"oa-orderActionButton__label\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.actions.cancellation.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":45},"end":{"line":10,"column":88}}}))
    + "</div>\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isOrderCancellable") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":11,"column":42}}})) != null ? stack1 : "")
    + "\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "oa-orderActionButton--inactive";
},"4":function(container,depth0,helpers,partials,data) {
    return "tile";
},"6":function(container,depth0,helpers,partials,data) {
    return "listItem";
},"8":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.actions.cancellation.is_cancellable.tooltip",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":100}}}));
},"10":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.actions.cancellation.is_not_cancellable.tooltip",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":108},"end":{"line":5,"column":172}}}));
},"12":function(container,depth0,helpers,partials,data) {
    return "<a>";
},"14":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"canCancelOrder") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "");
},"useData":true});