var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-customerInfo\" data-test=\"orderHeader.customerInfo\">\n  <div>\n    <span class=\"oa-customerInfo__customerFullName\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"firstName") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"lastName") : depth0), depth0))
    + "</span>\n    (<span class=\"oa-customerInfo__customerId\">\n      <a class=\"oa-customerInfo__customerLink\"\n         href=\""
    + alias2(__default(require("../helpers/urls/ccAdminCustomerUrl.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"zooId") : depth0),(depth0 != null ? lookupProperty(depth0,"url") : depth0),{"name":"urls/ccAdminCustomerUrl","hash":{},"data":data,"loc":{"start":{"line":6,"column":15},"end":{"line":6,"column":54}}}))
    + "\"\n         target=\"_blank\"><strong>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"zooId") : depth0), depth0))
    + "</strong></a>\n      <a class=\"oa-copyButton oa-customerInfo__copyCustomerIdButton\"\n         data-placement=\"bottom\"\n         data-clipboard-text=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"zooId") : depth0), depth0))
    + "\">\n        <span class=\"glyphicon glyphicon-duplicate\"></span>\n      </a>\n    </span>)\n    <span class=\"label label-primary oa-customerInfo__icv\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"icv") : depth0), depth0))
    + "</span>\n    <span component=\"--fraudLabel\"></span>\n  </div>\n  <div class=\"oa-customerInfo__customerEmail\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "</div>\n</div>";
},"useData":true});