import { Helpers } from '@cc/cc-app-commons';
// @ts-ignore
import orderTimelineEntriesTemplate from '../../templates/order-timeline/orderTimelineEntries.hbs';
import TimelineEntryViewModel from './TimelineEntryViewModel';

const { clear, createChildElement, render } = Helpers.ClientHelpers;

export default class TimelineEntriesViewModel {
  el: Element;
  models: TimelineEntryViewModel[];
  context: any;
  private destroyFunctions: Function[] = [];

  constructor(domElement: Element, modelsToRender: TimelineEntryViewModel[], context: any) {
    this.el = domElement;
    this.models = modelsToRender;
    this.context = context;
  }

  render(): void {
    render(
      <any>createChildElement(this.el.querySelector('.timeline')),
      orderTimelineEntriesTemplate(),
    );

    this.models.forEach((timelineEntryViewModel: TimelineEntryViewModel) => {
      const wrapperElement = createChildElement(this.el.querySelector('.oa-timelineEntries'));
      timelineEntryViewModel.renderFor(wrapperElement, this.context);
      this.destroyFunctions.push(function () {
        timelineEntryViewModel.destroy();
      });
    });
  }

  destroy() {
    this.destroyFunctions.forEach((fun) => fun());
    clear(this.el);
  }
}
