var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <tr>\n    <td class=\"oa-button u-border-none\">\n      <div data-test=\"address.firstNameAndLastName\"> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"firstName") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"lastName") : stack1), depth0))
    + " </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"company") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "      <div data-test=\"address.street\"> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"street") : stack1), depth0))
    + " </div>\n      <div data-test=\"address.postalCodeAndCity\"> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"postalCode") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"city") : stack1), depth0))
    + " </div>\n      <div> "
    + ((stack1 = container.invokePartial(require("../partials/flag.hbs"),depth0,{"name":"flag","hash":{"flagTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"countryISOCode") : stack1),"flagCode":((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"flagCode") : stack1)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"countryISOCode") : stack1), depth0))
    + " </div>\n      <div data-test=\"address.phone\"> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"phone") : stack1), depth0))
    + " </div>\n    </td>\n  </tr>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div data-test=\"address.company\"> "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"company") : stack1), depth0))
    + " </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "  <tr data-test=\"address.notAvailable\">\n    <td>\n      "
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"default.not_available",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":6},"end":{"line":18,"column":38}}}))
    + "\n    </td>\n  </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/logic/and.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"address") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"flagCode") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"countryISOCode") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"postalCode") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"city") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"street") : stack1),{"name":"logic/and","hash":{},"data":data,"loc":{"start":{"line":1,"column":6},"end":{"line":1,"column":114}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":21,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});