var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"glyphicon glyphicon-equalizer u-pr-s\"></span>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <tr class=\"oa-parcelTable__parcel\" id=\"oa-parcelsTable__parcel--"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n      <td>\n        <div class=\"oa-parcelsTable__itemContainer\">\n          <div class=\"u-ml-s u-mb-m oa-parcelsTable__summaryRow\">\n            <div class=\"u-mr-m oa-parcelsTable__contentTTID\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"trackingLink") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":19,"column":14},"end":{"line":34,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"trackingNumber") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":14},"end":{"line":41,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n            <span>\n              "
    + alias2(__default(require("../helpers/i18n.js")).call(alias3,"order.parcels.standalone.status",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":44,"column":14},"end":{"line":44,"column":56}}}))
    + ": <strong data-test=\"orderSummary.parcel.status\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"status") : depth0), depth0))
    + "</strong>\n            </span>\n            <div component=\"--parcelActions_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "--standalone\"></div>\n          </div>\n          <div class=\"oa-parcelsTable__detailsRow\">\n            <div class=\"oa-parcelsTable__detailsRowItem\">\n              <div>\n                <span class=\"oa-parcelsTable__valueLabel\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias3,"order.parcels.standalone.fc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":51,"column":58},"end":{"line":51,"column":96}}}))
    + "</span>\n                <span data-test=\"orderSummary.parcel.fc\">"
    + alias2(__default(require("../helpers/withDefault.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"fulfilmentCenter") : depth0),__default(require("../helpers/i18n.js")).call(alias3,"default.not_available",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":52,"column":93},"end":{"line":52,"column":123}}}),{"name":"withDefault","hash":{},"data":data,"loc":{"start":{"line":52,"column":57},"end":{"line":52,"column":125}}}))
    + "</span>\n              </div>\n              <div>\n                <span class=\"oa-parcelsTable__valueLabel\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias3,"order.parcels.standalone.dsp",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":55,"column":58},"end":{"line":55,"column":97}}}))
    + "</span>\n                <span data-test=\"orderSummary.parcel.dsp\">"
    + alias2(__default(require("../helpers/withDefault.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"dspName") : depth0),__default(require("../helpers/i18n.js")).call(alias3,"default.not_available",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":56,"column":85},"end":{"line":56,"column":115}}}),{"name":"withDefault","hash":{},"data":data,"loc":{"start":{"line":56,"column":58},"end":{"line":56,"column":117}}}))
    + "</span>\n              </div>\n            </div>\n            <div class=\"oa-parcelsTable__detailsRowItem u-display-flex\">\n              <div class=\"oa-parcelsTable__detailsRowItemIcon\">\n                <span class=\"glyphicon glyphicon-calendar u-pr-xs\"></span>\n              </div>\n              <div>\n                <div>\n                  <span class=\"oa-parcelsTable__valueLabel\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias3,"order.parcels.standalone.released",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":65,"column":60},"end":{"line":65,"column":104}}}))
    + "</span>\n                  <span data-test=\"orderSummary.parcel.parcelPoolExitDate\">"
    + alias2(__default(require("../helpers/withDefault.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"parsedPoolExitDate") : depth0),__default(require("../helpers/i18n.js")).call(alias3,"default.not_available",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":66,"column":113},"end":{"line":66,"column":143}}}),{"name":"withDefault","hash":{},"data":data,"loc":{"start":{"line":66,"column":75},"end":{"line":66,"column":145}}}))
    + "</span>\n                </div>\n                <div>\n                  <span class=\"oa-parcelsTable__valueLabel\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias3,"order.parcels.standalone.shipped",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":69,"column":60},"end":{"line":69,"column":103}}}))
    + "</span>\n                  <span data-test=\"orderSummary.parcel.shipmentDate\">"
    + alias2(__default(require("../helpers/withDefault.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"parsedShipmentDate") : depth0),__default(require("../helpers/i18n.js")).call(alias3,"default.not_available",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":70,"column":107},"end":{"line":70,"column":137}}}),{"name":"withDefault","hash":{},"data":data,"loc":{"start":{"line":70,"column":69},"end":{"line":70,"column":139}}}))
    + "</span>\n                </div>\n                <div>\n                  <span class=\"oa-parcelsTable__valueLabel\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias3,"order.parcels.standalone.departed",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":73,"column":60},"end":{"line":73,"column":104}}}))
    + "</span>\n                  <span data-test=\"orderSummary.parcel.truckDepartureDate\">"
    + alias2(__default(require("../helpers/withDefault.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"parsedTruckDepartureDate") : depth0),__default(require("../helpers/i18n.js")).call(alias3,"default.not_available",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":74,"column":119},"end":{"line":74,"column":149}}}),{"name":"withDefault","hash":{},"data":data,"loc":{"start":{"line":74,"column":75},"end":{"line":74,"column":151}}}))
    + "</span>\n                </div>\n                <div>\n                  <span class=\"oa-parcelsTable__valueLabel\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias3,"order.parcels.standalone.delivered",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":77,"column":60},"end":{"line":77,"column":105}}}))
    + "</span>\n                  <span data-test=\"orderSummary.parcel.deliveryDate\">"
    + alias2(__default(require("../helpers/withDefault.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"parsedDeliveryDate") : depth0),__default(require("../helpers/i18n.js")).call(alias3,"default.not_available",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":78,"column":107},"end":{"line":78,"column":137}}}),{"name":"withDefault","hash":{},"data":data,"loc":{"start":{"line":78,"column":69},"end":{"line":78,"column":139}}}))
    + "</span>\n                </div>\n              </div>\n            </div>\n            <div class=\"oa-parcelsTable__detailsRowItem u-display-flex\">\n              <div class=\"oa-parcelsTable__detailsRowItemIcon\">\n                <span class=\"glyphicon glyphicon-scale u-pr-xs\"></span>\n              </div>\n              <div>\n                <div>\n                  <div>\n                    <span class=\"oa-parcelsTable__valueLabel\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias3,"order.parcels.standalone.weight.calc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":89,"column":62},"end":{"line":89,"column":109}}}))
    + "</span>\n                    <span data-test=\"orderSummary.parcel.weight.calc\">"
    + alias2(__default(require("../helpers/withDefault.js")).call(alias3,__default(require("../helpers/weightValue.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"calculatedWeight") : depth0),{"name":"weightValue","hash":{},"data":data,"loc":{"start":{"line":90,"column":84},"end":{"line":90,"column":119}}}),__default(require("../helpers/i18n.js")).call(alias3,"default.not_available",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":90,"column":120},"end":{"line":90,"column":150}}}),{"name":"withDefault","hash":{},"data":data,"loc":{"start":{"line":90,"column":70},"end":{"line":90,"column":152}}}))
    + "</span>\n                  </div>\n                  <div>\n                    <span class=\"oa-parcelsTable__valueLabel\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias3,"order.parcels.standalone.weight.real",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":93,"column":62},"end":{"line":93,"column":109}}}))
    + "</span>\n                    <span data-test=\"orderSummary.parcel.weight.real\">"
    + alias2(__default(require("../helpers/withDefault.js")).call(alias3,__default(require("../helpers/weightValue.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"realWeight") : depth0),{"name":"weightValue","hash":{},"data":data,"loc":{"start":{"line":94,"column":84},"end":{"line":94,"column":113}}}),__default(require("../helpers/i18n.js")).call(alias3,"default.not_available",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":94,"column":114},"end":{"line":94,"column":144}}}),{"name":"withDefault","hash":{},"data":data,"loc":{"start":{"line":94,"column":70},"end":{"line":94,"column":146}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isCalcAndRealWeightSignificantlyDifferent") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":20},"end":{"line":101,"column":27}}})) != null ? stack1 : "")
    + "                  </div>\n                </div>\n              </div>\n            </div>\n            <div class=\"u-ml-s oa-parcelsTable__detailsRowItem oa-parcelsTable__parcelPictureIcon oa-parcelsTable__parcelPictureIcon--inactive\"\n                 title=\""
    + alias2(__default(require("../helpers/i18n.js")).call(alias3,"order.parcels.picture_not_available",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":107,"column":24},"end":{"line":107,"column":70}}}))
    + "\"\n                 data-parcelid=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                 data-test=\"orderSummary.parcel.pictureIcon\">\n              <span class=\"glyphicon glyphicon-picture\"></span>\n            </div>\n          </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"parcelArticles") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":113,"column":10},"end":{"line":123,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n        <div id=\"oa-parcelsTable__parcelPictureModal--"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n             class=\"modal fade\"\n             tabindex=\"-1\"\n             role=\"dialog\"\n             aria-hidden=\"true\"\n             data-test=\"orderSummary.parcel.pictureModal\">\n          <div class=\"modal-dialog oa-parcelsTable__parcelPicturePreview\" role=\"document\">\n            <div class=\"modal-content\">\n              <div class=\"modal-header\">\n                <h3 class=\"modal-title\" data-test=\"orderSummary.parcel.pictureModal.title\">\n                  "
    + alias2(__default(require("../helpers/i18n.js")).call(alias3,"order.parcels.standalone.picture.modal_title","dsp",__default(require("../helpers/withDefault.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"dspName") : depth0),__default(require("../helpers/i18n.js")).call(alias3,"default.not_available",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":136,"column":57},"end":{"line":136,"column":87}}}),{"name":"withDefault","hash":{},"data":data,"loc":{"start":{"line":136,"column":31},"end":{"line":136,"column":88}}}),"ttid",__default(require("../helpers/withDefault.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"trackingNumber") : depth0),__default(require("../helpers/i18n.js")).call(alias3,"default.not_available",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":137,"column":65},"end":{"line":137,"column":95}}}),{"name":"withDefault","hash":{},"data":data,"loc":{"start":{"line":137,"column":32},"end":{"line":137,"column":96}}}),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":135,"column":18},"end":{"line":138,"column":20}}}))
    + "\n                </h3>\n              </div>\n              <div class=\"modal-body oa-parcelTable__parcelPicturePreviewContent \">\n                <img class=\"oa-parcelsTable__parcelPictureImage oa-parcelsTable__parcelPictureImageLoader\"\n                     data-test=\"orderSummary.parcel.pictureModal.image\"\n                     src=\"spinner.gif\"/>\n              </div>\n            </div>\n          </div>\n        </div>\n      </td>\n    </tr>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a data-test=\"orderSummary.parcel.trackingLink\"\n                   href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"trackingLink") : depth0), depth0))
    + "\" target=\"_blank\"\n                   class=\"u-ml-s oa-parcelsTable__trackingLink\"\n                   data-ttid=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"trackingNumber") : depth0), depth0))
    + "\"\n                   data-toggle=\"tooltip\"\n                   data-placement=\"top\"\n                   data-trigger=\"hover\"\n                   title=\""
    + alias2(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.parcels.open_tracking_link",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":26},"end":{"line":27,"column":69}}}))
    + "\">\n                  <span class=\"oa-parcelsTable__trackingNumber\"><span class=\"hash\">#</span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"trackingNumber") : depth0), depth0))
    + "</span>\n                </a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"trackingNumber") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":33,"column":23}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <span class=\"oa-parcelsTable__trackingNumber\"><span class=\"hash\">#</span>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"trackingNumber") : depth0), depth0))
    + "</span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span data-test=\"orderSummary.parcel.copyTtidButton\" class=\"u-ml-s oa-copyButton oa-parcelsTable__copyTtidButton\"\n                      data-ttid=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"trackingNumber") : depth0), depth0))
    + "\"\n                      data-clipboard-text=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"trackingNumber") : depth0), depth0))
    + "\">\n                  <span class=\"glyphicon glyphicon-duplicate\"></span>\n                </span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                      <span class=\"oa-parcelsTable__weightsDoNotMatch glyphicon glyphicon-exclamation-sign\"\n                            data-test=\"orderSummary.parcel.weight.exclamationMark\"\n                            aria-hidden=\"true\"\n                            title=\""
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.parcels.standalone.weight.difference_tooltip",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":99,"column":35},"end":{"line":99,"column":96}}}))
    + "\">\n                          </span>\n";
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <table class=\"table table-condensed u-mb-m u-table oa-parcelsTable__articles\">\n              <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"parcelArticles") : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":14},"end":{"line":120,"column":23}}})) != null ? stack1 : "")
    + "              </tbody>\n            </table>\n";
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n                  <td data-parcel-id=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "\" data-article-number=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"articleNumber") : depth0), depth0))
    + "\" data-test=\"orderSummary.parcel.article\"></td>\n                </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-condensed table-hover u-mb-m u-table oa-parcelsTable oa-parcelsTable--standaloneView\" data-test=\"orderSummary.parcel.standaloneView\">\n  <thead>\n  <tr class=\"oa-parcelsTable__header\">\n    <th class=\"oa-parcelsTable__headerText\" colspan=\"3\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/layout/isMediaTypeStandalone.js")).call(alias1,{"name":"layout/isMediaTypeStandalone","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":44}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":7,"column":13}}})) != null ? stack1 : "")
    + "      "
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(alias1,"order.parcels.standalone.header",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":6},"end":{"line":8,"column":48}}}))
    + "\n    </th>\n  </tr>\n  </thead>\n  <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"parcelsToShow") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":2},"end":{"line":151,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./noParcelsTableRow.hbs"),depth0,{"name":"noParcelsTableRow","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </tbody>\n</table>";
},"usePartial":true,"useData":true,"useDepths":true});