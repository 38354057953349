import { Config, Models, Services, View } from '@cc/cc-app-commons';
import manifest from '../../manifest.json';
import LoaderViewModel from '../view/LoaderViewModel';
import AppViewModel from '../view/AppViewModel';
import { ViewModel } from '../view/types';
import i18n from 'i18n';
import { KeycloakUriConfiguration } from '@cc/cc-app-commons/lib/js/view';
import { ContextFactory } from '../view/context/common/ContextFactory';
import { AppEventsHandler } from '../view/context/common/AppEventsHandler';
import AppInitialDataFormViewModel from '../view/AppInitialDataFormViewModel';
import { FreshdeskTicketContextAppError } from '../models/Errors';
import { resolveCCAdminUrl, resolveGatewayUrl } from '../helpers/connectionTypeResolver';
import AppActivityAction = Services.AppActivityAction;

type AppError = Models.Errors.AppError;
type ErrorViewModel = View.Errors.AppErrorViewModel;

export const APP_NAME = process.env.APP_NAME;
const { Errors } = Models;
const { appErrorViewModelFactory } = View.Errors;
const { EnvironmentConfiguration } = Config;

export class App {
  private connector: any;
  private gatewayUrl: string;
  private keycloak: any;
  private contextFactory: ContextFactory;
  private appEventsHandler: AppEventsHandler;
  private readonly loader: LoaderViewModel;
  private appViewModel: AppViewModel = undefined;
  private errorViewModel: ErrorViewModel = undefined;
  private readonly authorizationViewModel: View.AuthorizationViewModel;
  private isAppAuthorizedAndLoaded = false;
  private eventRemovers: Models.Remover[] = [];

  constructor(contextFactory: ContextFactory, appEventsHandler: AppEventsHandler) {
    this.contextFactory = contextFactory;
    this.appEventsHandler = appEventsHandler;
    this.loader = new LoaderViewModel(document.querySelector('.loader'));
    this.authorizationViewModel = new View.AuthorizationViewModel(
      document.querySelector('.oa-appAuthorization'),
    );
  }

  async init(): Promise<any> {
    try {
      await this.onAppInit();
      await this.renderApp();
      await this.onAppLoaded();
    } catch (err) {
      let targetError = err;
      if (!(err instanceof Errors.AppError)) {
        targetError = new Errors.UnexpectedAppError(err.message);
      }
      return this._handleError(targetError);
    }
  }

  private async onAppInit() {
    this.loadDefaultTranslations();
    return Promise.resolve();
  }

  private loadDefaultTranslations() {
    i18n.loadTranslations(manifest.defaultLocale);
  }

  async onAppLoaded() {
    await this.appEventsHandler.onAppLoaded(this);
    return Promise.resolve();
  }

  async renderApp() {
    return this.withLoader(async () => {
      try {
        await this.destroy();
        this.gatewayUrl = await resolveGatewayUrl();
        await this.withAuthorization(this.authorizationViewModel, async (keycloak: any) => {
          this.connector = new Services.KeycloakSecuredZooServicesConnector(keycloak, APP_NAME);
          this.keycloak = keycloak;
          const appContext = await this.createViewModelContext(
            this.connector,
            this.gatewayUrl,
            keycloak,
          );
          this.appEventsHandler.onAppContextCreated(appContext);
          this.initAfterUserAuthorized(this.connector, appContext);
          return this.renderOrders(appContext);
        });
      } catch (err) {
        return this._handleError(err);
      }
    }).then(() => this.appEventsHandler.onAppRendered(this));
  }

  async createViewModelContext(
    connector: Services.KeycloakSecuredZooServicesConnector,
    gatewayUrl: string,
    keycloak: any,
  ): Promise<ViewModel.Context> {
    return this.contextFactory.createAppContext(connector, gatewayUrl, keycloak);
  }

  private async withLoader(contentRenderFunction: Function) {
    this.loader.show();
    await contentRenderFunction();
    this.loader.hide();
  }

  protected async destroy() {
    this.detachEventHandlers();
    if (this.appViewModel) {
      this.appViewModel.destroy();
    }
    if (this.errorViewModel) {
      this.errorViewModel.destroy();
    }
  }

  private async withAuthorization(
    viewModel: View.AuthorizationViewModel,
    action: (keycloak: any) => Promise<any>,
  ) {
    const keycloak = await View.checkAuthorization(viewModel, {
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      redirectUri: `${window.location.origin}/login-successful.html`,
    } as KeycloakUriConfiguration);

    return keycloak ? action(keycloak) : Promise.resolve();
  }

  private async initAfterUserAuthorized(
    connector: Services.KeycloakSecuredZooServicesConnector,
    context: ViewModel.Context,
  ): Promise<void> {
    this.connector = connector;
    if (!this.isAppAuthorizedAndLoaded) {
      context.metric.logAppActivity(AppActivityAction.APP__LOADED);
    }
    this.isAppAuthorizedAndLoaded = true;
  }

  private async renderOrders(appContext: ViewModel.Context) {
    const wrapperElement = document.querySelector('.oa-appContent');
    this.appViewModel = new AppViewModel(wrapperElement, appContext);
    await this.appViewModel.render();
  }

  private async renderRequestForInitialData() {
    const wrapperElement = document.querySelector('.oa-appContent');
    const viewModel = new AppInitialDataFormViewModel(wrapperElement);
    await viewModel.render();
  }

  private async renderError(error: AppError) {
    const ccAdminUrl = await resolveCCAdminUrl();
    return this.withLoader(() => {
      try {
        const errorElementWrapper = document.querySelector('.oa-appError');
        this.errorViewModel = appErrorViewModelFactory(
          errorElementWrapper,
          error,
          new EnvironmentConfiguration().setCcAdminUrl(ccAdminUrl),
        );
        this.errorViewModel.render();
        this.initErrorListeners(errorElementWrapper);
      } catch (e) {
        console.error('A problem occurred when rendering the error', e);
      }
    });
  }

  private async initErrorListeners(errorElementWrapper: Element) {
    errorElementWrapper.querySelectorAll('.adminSearchLink').forEach((element) => {
      element.addEventListener('click', this.onAdminSearchLinkClickedListener.bind(this));
      this.eventRemovers.push(
        Models.Remover.createFor(
          function (): void {
            element.removeEventListener('click', this.onAdminSearchLinkClickedListener.bind(this));
          }.bind(this), // eslint-disable-line
        ),
      );
    });
  }

  async onAdminSearchLinkClickedListener(): Promise<any> {
    this.contextFactory
      .createMetricContext(this.connector, this.gatewayUrl, this.keycloak)
      .then((metricContext) => {
        metricContext.logAppActivity(AppActivityAction.CC_ADMIN_SEARCH_LINK__CLICKED);
      });
  }

  private detachEventHandlers() {
    this.eventRemovers.forEach((remover: Models.Remover) => remover.remove());
  }

  private _handleError(error: any) {
    let appError: AppError;
    if (!(error instanceof Errors.AppError)) {
      appError = new Errors.UnexpectedAppError(error);
    } else {
      appError = error;
      if (
        appError instanceof Errors.MissingSearchCriteriaAppError ||
        appError instanceof FreshdeskTicketContextAppError
      ) {
        return this.withAuthorization(this.authorizationViewModel, async () => {
          await this.renderRequestForInitialData();
        });
      }
    }
    return this.renderError(appError);
  }
}

export default App;
