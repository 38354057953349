import { Helpers, Models, Services } from '@cc/cc-app-commons';
import CancelOrderReason from '../../models/CancelOrderReason';
import { ViewModel } from '../types';
import OrderActionsModalHelper from './OrderActionsModalHelper';
import RefundCustomerMessageViewModel from './RefundCustomerMessageViewModel';
import { Order } from '../../models/Order';
import RefundCancelledOrderViewModel from './RefundCancelledOrderViewModel';
import ModalWrapper from '../ModalWrapper';
import { OrderBalanceInformationNotAvailableError } from '../../models/OrderCancellationError';
import CancelledOrderRefundData from '../../models/CancelledOrderRefundData';
import NotAuthorizedToRefundCancelledOrderViewModel from './NotAuthorizedToRefundCancelledOrderViewModel';

const AppActivityAction = Services.AppActivityAction;
const { clear, renderTo } = Helpers.ClientHelpers;
const tpl = require('../../../templates/order-actions/cancelOrderReason.hbs');

export default class CancelOrderReasonViewModel {
  private readonly el: Element;
  private readonly context: ViewModel.Context;
  private readonly onOrderCancelCallback: Function;
  private eventRemovers: Models.Remover[] = [];
  private _cancelOrderActionButton: JQuery<Element>;
  private _reasonsSection: JQuery<Element>;
  private _formElement: HTMLFormElement;

  constructor(domElement: Element, context: ViewModel.Context, onCancelCallback: Function) {
    this.el = domElement;
    this.context = context;
    this.onOrderCancelCallback = onCancelCallback;
  }

  get formElement() {
    if (!this._formElement) {
      this._formElement = this.el.querySelector('form');
    }
    return this._formElement;
  }

  get reasonsSection(): JQuery<Element> {
    if (!this._reasonsSection) {
      this._reasonsSection = $(this.el.querySelector(' .oa-cancelOrderReason__reasons'));
    }
    return this._reasonsSection;
  }

  get cancelOrderActionButton(): JQuery<Element> {
    if (!this._cancelOrderActionButton) {
      this._cancelOrderActionButton = $(
        this.el.querySelector(' .oa-cancelOrderReason-cancelOrderActionButton'),
      );
    }
    return this._cancelOrderActionButton;
  }

  async render() {
    renderTo(
      this.el,
      tpl({
        orderCancelReasons: Object.keys(CancelOrderReason).map(
          (key) => CancelOrderReason[key as keyof typeof CancelOrderReason],
        ),
      }),
    );
    this.disableCancelOrderButton();

    this.attachPluginsAndEventHandlers();
  }

  private attachPluginsAndEventHandlers() {
    const onSubmitCallback = this.submitOrderCancel.bind(this);
    this.formElement.addEventListener('submit', onSubmitCallback);
    this.eventRemovers.push(
      Models.Remover.createFor(
        function (): void {
          this.formElement.removeEventListener('submit', onSubmitCallback);
        }.bind(this) // eslint-disable-line
      ),
    );

    const radioOnChangeCallback = (e: Event) => {
      if ((e.currentTarget as HTMLInputElement).value) {
        this.enableCancelOrderButton();
      }
    };
    this.formElement.cancelOrderReason.forEach((element: HTMLInputElement) => {
      element.addEventListener('change', radioOnChangeCallback);
      this.eventRemovers.push(
        Models.Remover.createFor(
          function (): void {
            element.removeEventListener('change', radioOnChangeCallback);
          }.bind(this) // eslint-disable-line
        ),
      );
    });
  }

  private enableCancelOrderButton() {
    this.cancelOrderActionButton.prop('disabled', false);
  }

  private disableCancelOrderButton() {
    this.cancelOrderActionButton.prop('disabled', true);
  }

  private async submitOrderCancel(evt: Event) {
    evt.preventDefault();
    const cancelOrderReasonKey = (evt.target as HTMLFormElement).cancelOrderReason
      .value as keyof typeof CancelOrderReason;
    try {
      await this.onOrderCancel(CancelOrderReason[cancelOrderReasonKey]);
    } catch (e) {
      OrderActionsModalHelper.handleError(e);
    }
  }

  private async onOrderCancel(cancelOrderReason: CancelOrderReason) {
    await this.context.metric.logAppActivity(
      AppActivityAction.ORDER_CANCELLATION_ACTION__PERFORMED,
    );
    const order = await this.context.data.currentOrder;
    await this.context.data.cancelOrder(order.id, cancelOrderReason);
    const modalWrapper = OrderActionsModalHelper.getModal();
    modalWrapper.onModalDismissal(() => {
      this.onOrderCancelCallback();
      modalWrapper.destroy();
    });
    await this.proceedToRefundIfApplicable(order, modalWrapper);
  }

  private async proceedToRefundIfApplicable(order: Order, modalWrapper: ModalWrapper) {
    if (await this.isOrderBalanceInformationAvailable(order)) {
      await this.handleOrderBalanceInformationAvailable(order, modalWrapper);
    } else {
      this.handleOrderBalanceInformationNotAvailable();
    }
  }

  private async isOrderBalanceInformationAvailable(order: Order): Promise<boolean> {
    const orderPayments = await this.context.data.orderPayments(order.id);
    return orderPayments.orderBalance?.balance != null;
  }

  private async handleOrderBalanceInformationAvailable(order: Order, modalWrapper: ModalWrapper) {
    if (await this.isAtLeastPartiallyPaid(order)) {
      const refundData = await this.context.data.getCancelledOrderRefundData(order.id);
      if (refundData.isRefundPossible) {
        this.handleRefundPossibleViaOrderApp(refundData, modalWrapper);
      } else {
        await this.handleRefundNotPossibleViaOrderApp(modalWrapper);
      }
    } else {
      modalWrapper.close();
    }
  }

  private handleRefundPossibleViaOrderApp(
    refundData: CancelledOrderRefundData,
    modalWrapper: ModalWrapper,
  ) {
    if (this.context.authorization.isAuthorizedToRefundCancelledOrder()) {
      modalWrapper.renderContainer(RefundCancelledOrderViewModel, this.context, refundData);
    } else {
      modalWrapper.renderContainer(NotAuthorizedToRefundCancelledOrderViewModel, this.context);
    }
    modalWrapper.open();
  }

  private async handleRefundNotPossibleViaOrderApp(modalWrapper: ModalWrapper) {
    modalWrapper.renderContainer(RefundCustomerMessageViewModel, this.context);
    modalWrapper.open();
  }

  private handleOrderBalanceInformationNotAvailable() {
    const error = new OrderBalanceInformationNotAvailableError();
    OrderActionsModalHelper.handleError(error);
  }

  private async isAtLeastPartiallyPaid(order: Order): Promise<boolean> {
    const orderPayments = await this.context.data.orderPayments(order.id);
    const toBePaid = orderPayments.orderBalance.balance.negate();
    return toBePaid.amount < order.totalMonetaryValue.amount;
  }

  private detachPluginsAndEventHandlers() {
    this.eventRemovers.forEach((eventHandlerRemover: Models.Remover) =>
      eventHandlerRemover.remove(),
    );
  }

  destroy() {
    this.detachPluginsAndEventHandlers();
    clear(this.el);
  }
}
