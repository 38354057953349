import { ClaimObligationType, ClaimCompensationType } from '../models/ClaimResolution';
import { ServerResponse } from '../types';
import ClaimResolution = ServerResponse.ClaimResolution;

enum ClaimResolutionType {
  OBLIGATION = 'obligation',
  COMPENSATION = 'compensation',
}

class ClaimResolutionOption {
  translationKeyPhrase: string = '';

  constructor(
    public resolutionType: string,
    public type: string,
    public icon: string,
  ) {
    this.translationKeyPhrase = [resolutionType, type].join('.').toLocaleLowerCase();
  }
}

class ClaimResolutionObligation extends ClaimResolutionOption {
  constructor(public type: string) {
    super(ClaimResolutionType.OBLIGATION, type, obligationToIconMap.get(type));
  }
}

class ClaimResolutionCompensation extends ClaimResolutionOption {
  constructor(public type: string) {
    super(ClaimResolutionType.COMPENSATION, type, compensationToIconMap.get(type));
  }
}

export class ClaimResolutionTemplateData {
  obligation: ClaimResolutionOption = undefined;
  compensation: ClaimResolutionOption = undefined;

  static fromClaimResolution(claimResolution: ClaimResolution) {
    const resolution = new ClaimResolutionTemplateData();
    resolution.obligation = new ClaimResolutionObligation(claimResolution.obligation?.name);
    resolution.compensation = new ClaimResolutionCompensation(claimResolution.compensation?.name);
    return resolution;
  }
}

const obligationToIconMap = new Map<string, string>();
obligationToIconMap.set(ClaimObligationType.KEEP, 'home');
obligationToIconMap.set(ClaimObligationType.RETURN, 'warehouse');

const compensationToIconMap = new Map<string, string>();
// 'dollar sign' is replacement for 'piggy-bank' (fontAwesome)
compensationToIconMap.set(ClaimCompensationType.NONE, 'dollar sign');
compensationToIconMap.set(ClaimCompensationType.CREDIT, 'dollar sign');
compensationToIconMap.set(ClaimCompensationType.REDELIVERY, 'truck');
compensationToIconMap.set(ClaimCompensationType.REFUND, 'money bill alternate outline');
compensationToIconMap.set(ClaimCompensationType.VOUCHER, 'ticket alternate');
