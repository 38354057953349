enum CancelOrderReason {
  BETTER_DEAL = 'BETTER_DEAL',
  DOUBLE_ORDER = 'DOUBLE_ORDER',
  NOT_INTERESTED_ANYMORE = 'NOT_INTERESTED_ANYMORE',
  PAYMENT_MISSING_OR_FAILED = 'PAYMENT_MISSING_OR_FAILED',
  SYSTEM_OR_PROCESS_ISSUE = 'SYSTEM_OR_PROCESS_ISSUE',
  TEST_ORDER = 'TEST_ORDER',
  TOO_LONG_TO_PROCEED = 'TOO_LONG_TO_PROCEED',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export default CancelOrderReason;
export { CancelOrderReason as CancelParcelReason };
