import { Helpers } from '@cc/cc-app-commons';
import errorTemplate from '../../../templates/error';
import i18n from 'i18n';

const { renderTo } = Helpers.ClientHelpers;

export default class OrderWarningViewModel {
  name: string;
  domElement: HTMLElement;

  constructor(domElement: HTMLElement, name: string) {
    this.domElement = domElement;
    this.name = name;
  }

  get targetRenderElement(): HTMLElement {
    return null;
  }

  render(): void {
    renderTo(this.targetRenderElement, errorTemplate(this.title, this.message, 'warning'));
  }

  get title(): string {
    return i18n.t(`order.warnings.${this.name}.title`, null);
  }

  get message(): string[] {
    return [i18n.t(`order.warnings.${this.name}.message`, null)];
  }
}
