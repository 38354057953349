import { Models, Services } from '@cc/cc-app-commons';
import ShopArticle from '../../models/ShopArticle';
import ParcelPhysicalArticle from '../../models/ParcelPhysicalArticle';
import { ServerResponse } from '../../types';
import PhysicalArticleMasterdata = ServerResponse.PhysicalArticleMasterdata;

export class OrderItemService {
  constructor(
    private connector: Services.KeycloakSecuredZooServicesConnector,
    private gatewayUrl: string,
  ) {}

  async fetchArticleVariantData(
    shopArticleId: number,
    language: string = 'default',
  ): Promise<ShopArticle> {
    try {
      const json = await this.connector.fetchData(
        this.gatewayUrl,
        `articles-api/v1/articles/${shopArticleId}/localizations/${language}`,
      );
      return new ShopArticle().fromData(json);
    } catch (e) {
      if (e.status === 404) {
        return null;
      }
      throw new Models.Errors.UnexpectedAppError(e);
    }
  }

  async enrichPhysicalArticleWithName(
    parcelPhysicalArticle: ParcelPhysicalArticle,
  ): Promise<ParcelPhysicalArticle> {
    try {
      const json: PhysicalArticleMasterdata = await this.connector.fetchData(
        this.gatewayUrl,
        `article-admin-masterdata-api/v1/articles-physical/${parcelPhysicalArticle.articleId}`,
      );
      parcelPhysicalArticle.fromData({
        name: `${json.productName} ${json.shortText}`,
      });
    } catch (e) {
      console.warn(
        `Exception while getting physical article name for id ${parcelPhysicalArticle.articleId}`,
        e,
      );
    }
    return parcelPhysicalArticle;
  }
}
