var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"oa-parcelActionButton oa-parcelActionButton__cancelParcel\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/logic/not.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isParcelCancellable") : depth0),{"name":"logic/not","hash":{},"data":data,"loc":{"start":{"line":3,"column":20},"end":{"line":3,"column":53}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":93}}})) != null ? stack1 : "")
    + "\n              oa-parcelActionButton--"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/conditionals/eq.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"mode") : depth0),"tile",{"name":"conditionals/eq","hash":{},"data":data,"loc":{"start":{"line":4,"column":43},"end":{"line":4,"column":74}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":4,"column":37},"end":{"line":4,"column":103}}})) != null ? stack1 : "")
    + "\"\n       title=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isParcelCancellable") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":5,"column":14},"end":{"line":6,"column":76}}})) != null ? stack1 : "")
    + "\"\n       data-placement=\"bottom\"\n       data-test=\"cancelParcelButton\"\n       data-test-parcel-id=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"parcelId") : depth0), depth0))
    + "\">\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isParcelCancellable") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":10,"column":41}}})) != null ? stack1 : "")
    + "\n    <div class=\"oa-parcelActionButton__icon\">\n      <span class=\"glyphicon glyphicon-remove-sign\"></span>\n    </div>\n    <div class=\"oa-parcelActionButton__label\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"parcel.actions.cancellation.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":46},"end":{"line":14,"column":90}}}))
    + "</div>\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isParcelCancellable") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":15,"column":42}}})) != null ? stack1 : "")
    + "\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "oa-parcelActionButton--inactive";
},"4":function(container,depth0,helpers,partials,data) {
    return "tile";
},"6":function(container,depth0,helpers,partials,data) {
    return "listItem";
},"8":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"parcel.actions.cancellation.is_cancellable.tooltip",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":41},"end":{"line":5,"column":102}}}));
},"10":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"parcel.actions.cancellation.is_not_cancellable.tooltip",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":110},"end":{"line":6,"column":69}}}));
},"12":function(container,depth0,helpers,partials,data) {
    return "<a>";
},"14":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"canCancelParcel") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":17,"column":7}}})) != null ? stack1 : "");
},"useData":true});