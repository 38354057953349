var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "btn-block";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-header\">\n"
    + ((stack1 = container.invokePartial(require("../common/closeModalButton.hbs"),depth0,{"name":"../common/closeModalButton","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <h4 class=\"modal-title\" id=\"oaOrderActionsModalLabel\" data-test=\"notAuthorizedToRefundTitle\">\n    "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.actions.cancellation.modal.not_authorized_to_refund.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":78}}}))
    + "\n  </h4>\n</div>\n<div class=\"modal-body u-text-centered\" data-test=\"notAuthorizedToRefundMessage\">\n  <div>\n    "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.actions.cancellation.modal.not_authorized_to_refund.message",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":9,"column":80}}}))
    + "\n  </div>\n</div>\n<div class=\"modal-footer u-text-centered\">\n  <button class=\"btn btn-primary btm-sm "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/layout/isMediaTypePlugin.js")).call(alias1,{"name":"layout/isMediaTypePlugin","hash":{},"data":data,"loc":{"start":{"line":13,"column":46},"end":{"line":13,"column":74}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":40},"end":{"line":13,"column":92}}})) != null ? stack1 : "")
    + " oa-modal-notAuthorizedToRefundCcAdminLink\"\n          data-cc-admin-order-url=\""
    + alias2(__default(require("../helpers/urls/ccAdminOrderUrl.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"orderId") : depth0),(depth0 != null ? lookupProperty(depth0,"url") : depth0),{"name":"urls/ccAdminOrderUrl","hash":{},"data":data,"loc":{"start":{"line":14,"column":35},"end":{"line":14,"column":73}}}))
    + "\">\n    "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.actions.cancellation.modal.not_authorized_to_refund.refund_button",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":15,"column":86}}}))
    + "\n  </button>\n  <button class=\"btn btn-default btm-sm "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/layout/isMediaTypePlugin.js")).call(alias1,{"name":"layout/isMediaTypePlugin","hash":{},"data":data,"loc":{"start":{"line":17,"column":46},"end":{"line":17,"column":74}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":40},"end":{"line":17,"column":92}}})) != null ? stack1 : "")
    + " oa-modal-notAuthorizedToRefundMessageCloseButton\">\n    "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"default.actions.close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":18,"column":36}}}))
    + "\n  </button>\n</div>";
},"usePartial":true,"useData":true});