import { Helpers } from '@cc/cc-app-commons';
import { ViewModel } from '../types';

const { clear, renderTo } = Helpers.ClientHelpers;
const tplFraudLabel = require('../../../templates/order-header/fraudLabel.hbs');

export default class FraudLabelViewModel {
  private static TOOLTIP_LABEL_ELEMENT_SELECTOR = '.oa-fraudLabel__labelTittle.oa-tooltipWrapper';

  private readonly el: Element;
  private readonly context: ViewModel.Context;

  constructor(domElement: Element, context: ViewModel.Context) {
    this.el = domElement;
    this.context = context;
  }

  async render() {
    const order = await this.context.data.currentOrder;
    renderTo(this.el, tplFraudLabel(order.toTemplateData()));
    this.attachPluginsAndEventHandlers();
  }

  private attachPluginsAndEventHandlers() {
    $(this.el.querySelector(FraudLabelViewModel.TOOLTIP_LABEL_ELEMENT_SELECTOR)).tooltip();
  }

  private detachPluginsAndEventHandlers() {
    $(this.el.querySelector(FraudLabelViewModel.TOOLTIP_LABEL_ELEMENT_SELECTOR)).tooltip('destroy');
  }

  destroy() {
    this.detachPluginsAndEventHandlers();
    clear(this.el);
  }
}
