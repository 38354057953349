var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <tr class=\"oa-parcelTable__parcel\" data-test=\"orderSummary.parcel\">\n      <td class=\"oa-parcelTable__parcelDetail\" data-test=\"orderSummary.parcel.dsp\">\n        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"dspName") : depth0), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"trackingLink") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":37,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"trackingNumber") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":44,"column":15}}})) != null ? stack1 : "")
    + "      </td>\n      <td class=\"oa-parcelTable__parcelDetail\" data-test=\"orderSummary.parcel.parcelPoolExitDate\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"parsedPoolExitDate") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":47,"column":8},"end":{"line":51,"column":15}}})) != null ? stack1 : "")
    + "      </td>\n      <td class=\"oa-parcelTable__parcelDetail\" data-test=\"orderSummary.parcel.shipmentDate\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"parsedShipmentDate") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":54,"column":8},"end":{"line":58,"column":15}}})) != null ? stack1 : "")
    + "      </td>\n      <td class=\"oa-parcelTable__parcelDetail\" data-test=\"orderSummary.parcel.truckDepartureDate\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"parsedTruckDepartureDate") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":61,"column":8},"end":{"line":65,"column":15}}})) != null ? stack1 : "")
    + "      </td>\n      <td class=\"oa-parcelTable__parcelDetail oa-parcels__actionButtons\">\n        <div component=\"--parcelActions_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"></div>\n      </td>\n    </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <a data-test=\"orderSummary.parcel.trackingLink\"\n             href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"trackingLink") : depth0), depth0))
    + "\" target=\"_blank\"\n             class=\"u-ml-s oa-parcelsTable__trackingLink\"\n             data-ttid=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"trackingNumber") : depth0), depth0))
    + "\"\n             data-toggle=\"tooltip\"\n             data-placement=\"top\"\n             data-trigger=\"hover\"\n             title=\""
    + alias2(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.parcels.open_tracking_link",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":34,"column":20},"end":{"line":34,"column":63}}}))
    + "\">\n            <span class=\"glyphicon glyphicon-new-window\"></span>\n          </a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <span data-test=\"orderSummary.parcel.copyTtidButton\" class=\"oa-copyButton oa-parcelsTable__copyTtidButton\"\n                data-ttid=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"trackingNumber") : depth0), depth0))
    + "\"\n                data-clipboard-text=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"trackingNumber") : depth0), depth0))
    + "\">\n                        <span class=\"glyphicon glyphicon-duplicate\"></span>\n                    </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"parsedPoolExitDate") : depth0), depth0))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "          ---\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"parsedShipmentDate") : depth0), depth0))
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"parsedTruckDepartureDate") : depth0), depth0))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-condensed table-hover oa-parcelsTable oa-parcelsTable--pluginView\" data-test=\"orderSummary.parcel.pluginView\">\n  <thead>\n  <tr class=\"oa-parcelsTable__labeledHeader\">\n    <th>\n      "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.parcels.header_dsp",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":5,"column":41}}}))
    + " /\n      "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.parcels.header_tracking_number",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":6,"column":53}}}))
    + "\n    </th>\n    <th class=\"oa-parcelsTable__headerPoolExitDate\">\n      "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.parcels.header_pool_exit_date",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":9,"column":52}}}))
    + "\n    </th>\n    <th class=\"oa-parcelsTable__headerDate\">\n      "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.parcels.header_shipment_date",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":12,"column":51}}}))
    + "\n    </th>\n    <th class=\"oa-parcelsTable__headerTruckDepartureDate\">\n      "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.parcels.header_truck_departure_date",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":6},"end":{"line":15,"column":58}}}))
    + "\n    </th>\n    <th class=\"oa-parcelsTable__headerActionButtons\"></th>\n  </tr>\n  </thead>\n\n  <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"parcelsToShow") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":2},"end":{"line":71,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./noParcelsTableRow.hbs"),depth0,{"name":"noParcelsTableRow","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </tbody>\n</table>";
},"usePartial":true,"useData":true});