import { Models } from '@cc/cc-app-commons';
import { ServerResponse } from '../types';
import { TemplateData } from './types';
import ShopCurrency from './ShopCurrency';

export enum DiscountType {
  ABSOLUTE_DISCOUNT = 'ABSOLUTE_DISCOUNT',
  PERCENTAGE_DISCOUNT = 'PERCENTAGE_DISCOUNT',
}

export default class ArticleBasedDiscount extends Models.BaseModel implements TemplateData {
  articleId: number = undefined;
  quantity: number = undefined;
  orderPositionId: number = undefined;
  currency: ShopCurrency = undefined;
  discountValue: number = undefined;
  discountType: DiscountType = undefined;

  unitMonetaryValue: Models.MonetaryValue = null;
  totalMonetaryValue: Models.MonetaryValue = null;

  get hasPercentDiscount(): boolean {
    return this.discountType === DiscountType.PERCENTAGE_DISCOUNT;
  }

  get percentDiscountString(): string {
    return this.hasPercentDiscount ? Number(this.discountValue * 100).toFixed(0) : null;
  }

  get hasAbsoluteDiscount(): boolean {
    return this.discountType === DiscountType.ABSOLUTE_DISCOUNT;
  }

  get absoluteDiscountMonetaryValue(): Models.MonetaryValue {
    return this.hasAbsoluteDiscount
      ? Models.MonetaryValue.fromShopValue(
          this.discountValue,
          this.currency.currency,
          0, // we don't support exchangeRate anymore
        )
      : null;
  }

  fromData(json: ServerResponse.ArticleWithDiscount, shopCurrency: ShopCurrency) {
    super.fromData(json);
    this.quantity = json.orderedArticleQuantity;
    this.currency = shopCurrency;
    if (json.currency !== shopCurrency.currency) {
      console.warn(
        `Article Based Discounts: Shop currency [${shopCurrency.currency}] is other than ABD currency [${json.currency}]`,
      );
    }
    this.unitMonetaryValue = new Models.MonetaryValue().fromData({
      currency: json.currency,
      amount: json.actualPrice,
    });
    this.totalMonetaryValue = new Models.MonetaryValue().fromData({
      currency: json.currency,
      amount: json.orderedArticleQuantity * json.actualPrice,
    });
    return this;
  }

  toData(...context: any[]): any {
    const json = super.toData(...context);
    json.orderedArticleQuantity = this.quantity;
    json.currency = this.currency.currency;
    return json;
  }

  toTemplateData(...context: any): any {
    return this.toData(...context);
  }
}
