import { AppEventsHandler } from '../../common/AppEventsHandler';
import App from '../../../../modules/app';

import { ViewModel } from '../../../types';
import Context = ViewModel.Context;
import { resizeContainer } from '../../../../lib/clientHelpers';
import { captureMessage } from '@sentry/browser';

export class FreshchatAppEventsHandler implements AppEventsHandler {
  private readonly client: any;

  constructor(client: any) {
    this.client = client;
  }
  async onAppContextCreated(context: Context) {
    const loggedInUser = await context.user.loggedInUser;
    if (!loggedInUser.zooId) {
      captureMessage(`User email ${loggedInUser.email} does not have zooId assigned`, 'debug');
    }
  }

  onAppRendered(target: App) {
    return this.initialized().then(() => resizeContainer(this.client));
  }
  private async initialized(): Promise<any> {
    return this.client.initialized();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onAppLoaded(target: App): void {
    // Intentionally empty
  }
}
