import { Models } from '@cc/cc-app-commons';
import ModalWrapper from '../ModalWrapper';
import ErrorForViewModel from '../../util/ErrorForViewModel';

type AppError = Models.Errors.AppError;

export default class OrderActionsModalHelper {
  static ORDER_ACTIONS_MODAL_SELECTOR = '.oa-orderActions .oa-orderActionsModal';

  static getModal() {
    return ModalWrapper.getFor(
      document.querySelector(OrderActionsModalHelper.ORDER_ACTIONS_MODAL_SELECTOR),
    );
  }

  static handleError(error: AppError) {
    OrderActionsModalHelper.getModal().handleError(
      new ErrorForViewModel(error, 'order.actions.cancellation.modal.errors'),
    );
  }
}
