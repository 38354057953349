var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"oa-relaxDelivery\" data-test=\"orderHeader.relaxDelivery\">\n    <div class=\"badge "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"relaxDeliveryStatus") : depth0)) != null ? lookupProperty(stack1,"isUnknown") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":22},"end":{"line":3,"column":98}}})) != null ? stack1 : "")
    + " oa-tooltipWrapper\"\n         data-toggle=\"tooltip\"\n         data-placement=\"bottom\"\n         data-html=\"true\"\n         data-container=\"body\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"relaxDeliveryStatus") : depth0)) != null ? lookupProperty(stack1,"isUnknown") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":8,"column":6},"end":{"line":12,"column":13}}})) != null ? stack1 : "")
    + "    >\n      <span class=\"glyphicon glyphicon-time\"></span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/layout/isMediaTypeStandalone.js")).call(alias1,{"name":"layout/isMediaTypeStandalone","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":44}}}),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":6},"end":{"line":17,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " oa-relaxDeliveryBadge--unknown ";
},"4":function(container,depth0,helpers,partials,data) {
    return "         title=\""
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.relax_delivery.undefined_tooltip",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":65}}}))
    + "\"\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         title=\""
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.relax_delivery.tooltip","maxWorkingDays",((stack1 = (depth0 != null ? lookupProperty(depth0,"relaxDeliveryStatus") : depth0)) != null ? lookupProperty(stack1,"maxWorkingDays") : stack1),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":107}}}))
    + "\"\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "        <span data-test=\"relaxDelivery.caption\">"
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.relax_delivery.caption",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":48},"end":{"line":16,"column":87}}}))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/logic/or.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"relaxDeliveryStatus") : depth0)) != null ? lookupProperty(stack1,"isRelaxDelivery") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"relaxDeliveryStatus") : depth0)) != null ? lookupProperty(stack1,"isUnknown") : stack1),{"name":"logic/or","hash":{},"data":data,"loc":{"start":{"line":1,"column":6},"end":{"line":1,"column":84}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":20,"column":7}}})) != null ? stack1 : "");
},"useData":true});