export default (title = '', paragraphs = [], alertType = '') => {
  const alertCssClass = !alertType ? 'well well-sm' : `alert alert-${alertType}`;

  const paragraphsContent = paragraphs.reduce((result, paragraph) => {
    return result + `<p>${paragraph}</p>`;
  }, '');

  return `<div class="u-display-flex u-m-m">
      <div class="${alertCssClass} text-center oa-appErrorContent">
        <h4>${title}</h4>
        ${paragraphsContent}
      </div>
    </div>`;
};
