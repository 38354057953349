export default class LoaderViewModel {
  private loader: HTMLElement;

  constructor(el: HTMLElement) {
    this.loader = el;
  }

  hide(): void {
    this.setStyle('visibility', 'hidden');
  }

  show(): void {
    this.setStyle('visibility', 'visible');
  }

  private setStyle(property: any, value: string): void {
    this.loader.style[property] = value;
  }

  isVisible(): boolean {
    return this.loader.style.visibility === 'visible';
  }
}

export function withLoader(loader: LoaderViewModel, action: () => Promise<any>): Promise<any> {
  loader.show();
  return action().finally(() => loader.hide());
}
