var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "oa-positionsTable--articleBasedDiscount";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <tr class=\"oa-positionsItem "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/data/get.js")).call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"articleBasedDiscounts")),(depth0 != null ? lookupProperty(depth0,"orderPositionId") : depth0),{"name":"data/get","hash":{},"data":data,"loc":{"start":{"line":24,"column":38},"end":{"line":24,"column":94}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":32},"end":{"line":24,"column":141}}})) != null ? stack1 : "")
    + "\" data-articleNumber=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"articleNumber") : depth0), depth0))
    + "\" data-test=\"orderSummary.orderPosition\">\n      <td>\n        <span component=\"--articleNumber\"\n              data-articleNumber=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"articleNumber") : depth0), depth0))
    + "\"\n              data-test=\"orderSummary.orderPosition.number\">\n        </span>\n        <span data-test=\"orderSummary.orderPosition.name\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":31,"column":10},"end":{"line":35,"column":18}}})) != null ? stack1 : "")
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/logic/not.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"logic/not","hash":{},"data":data,"loc":{"start":{"line":37,"column":14},"end":{"line":37,"column":36}}}),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":41,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/data/get.js")).call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"articleBasedDiscounts")),(depth0 != null ? lookupProperty(depth0,"orderPositionId") : depth0),{"name":"data/get","hash":{},"data":data,"loc":{"start":{"line":42,"column":14},"end":{"line":42,"column":70}}}),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":59,"column":15}}})) != null ? stack1 : "")
    + "      </td>\n      <td class=\"text-center\" data-test=\"orderSummary.orderPosition.quantity\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"quantity") : depth0), depth0))
    + "</td>\n      <td class=\"text-center oa-positionsTable__cellMultiply\">&#215;</td>\n      <td class=\"text-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/data/get.js")).call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"articleBasedDiscounts")),(depth0 != null ? lookupProperty(depth0,"orderPositionId") : depth0),{"name":"data/get","hash":{},"data":data,"loc":{"start":{"line":64,"column":14},"end":{"line":64,"column":70}}}),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":8},"end":{"line":73,"column":15}}})) != null ? stack1 : "")
    + "        <div data-test=\"orderSummary.orderPosition.value\">"
    + ((stack1 = container.invokePartial(require("./partials/monetaryValue.hbs"),(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"monetaryValue","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n      </td>\n      <td class=\"text-right oa-positionsTable__totalPrice\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/data/get.js")).call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"articleBasedDiscounts")),(depth0 != null ? lookupProperty(depth0,"orderPositionId") : depth0),{"name":"data/get","hash":{},"data":data,"loc":{"start":{"line":79,"column":14},"end":{"line":79,"column":70}}}),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":8},"end":{"line":88,"column":15}}})) != null ? stack1 : "")
    + "        <div data-test=\"orderSummary.orderPosition.totalValue\">"
    + ((stack1 = container.invokePartial(require("./partials/monetaryValue.hbs"),(depth0 != null ? lookupProperty(depth0,"totalMonetaryValue") : depth0),{"name":"monetaryValue","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n      </td>\n    </tr>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "oa-positionsItem--articleBasedDiscount";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(__default(require("./helpers/i18n.js")).call(alias1,__default(require("./helpers/concat.js")).call(alias1,"order.positions.types.",__default(require("./helpers/formatting/removeUnderscores.js")).call(alias1,__default(require("./helpers/formatting/lowercase.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"formatting/lowercase","hash":{},"data":data,"loc":{"start":{"line":32,"column":84},"end":{"line":32,"column":117}}}),{"name":"formatting/removeUnderscores","hash":{},"data":data,"loc":{"start":{"line":32,"column":52},"end":{"line":32,"column":118}}}),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":32,"column":19},"end":{"line":32,"column":119}}}),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":32,"column":121}}}))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"oa-positionsItemVariant\" data-test=\"orderSummary.orderPosition.variant\">"
    + container.escapeExpression(__default(require("./helpers/withDefault.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"shopArticleVariant") : depth0),"",{"name":"withDefault","hash":{},"data":data,"loc":{"start":{"line":39,"column":12},"end":{"line":39,"column":56}}}))
    + "</div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(alias1,__default(require("./helpers/data/get.js")).call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"articleBasedDiscounts")),(depth0 != null ? lookupProperty(depth0,"orderPositionId") : depth0),{"name":"data/get","hash":{},"data":data,"loc":{"start":{"line":43,"column":18},"end":{"line":43,"column":74}}}),{"name":"with","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":10},"end":{"line":58,"column":19}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"u-mt-m text-warning\">\n              <i class=\"long arrow alternate right icon\"></i>\n              <span data-test=\"orderSummary.orderPosition.abd.description\">\n              "
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"features.abd.position.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":47,"column":14},"end":{"line":47,"column":52}}}))
    + " "
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"default.applied",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":47,"column":53},"end":{"line":47,"column":79}}}))
    + " ("
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,__default(require("./helpers/concat.js")).call(alias1,"features.abd.position.types.",(depth0 != null ? lookupProperty(depth0,"discountType") : depth0),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":48,"column":22},"end":{"line":48,"column":74}}}),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":48,"column":14},"end":{"line":48,"column":76}}}))
    + ":"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPercentDiscount") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":14},"end":{"line":51,"column":22}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAbsoluteDiscount") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":14},"end":{"line":54,"column":22}}})) != null ? stack1 : "")
    + ")\n              </span>\n            </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"percentDiscountString") : depth0), depth0))
    + "%\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/monetaryValue.hbs"),(depth0 != null ? lookupProperty(depth0,"absoluteDiscountMonetaryValue") : depth0),{"name":"monetaryValue","hash":{"inline":true},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(alias1,__default(require("./helpers/data/get.js")).call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"articleBasedDiscounts")),(depth0 != null ? lookupProperty(depth0,"orderPositionId") : depth0),{"name":"data/get","hash":{},"data":data,"loc":{"start":{"line":65,"column":18},"end":{"line":65,"column":74}}}),{"name":"with","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":10},"end":{"line":72,"column":19}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"text-warning oa-positionsItem_articleBasedDiscountPrice\"\n                 title=\""
    + container.escapeExpression(__default(require("./helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"features.abd.position.tooltip",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":67,"column":24},"end":{"line":67,"column":64}}}))
    + "\"\n                 data-test=\"orderSummary.orderPosition.abd.value\"\n            >"
    + ((stack1 = container.invokePartial(require("./partials/monetaryValue.hbs"),(depth0 != null ? lookupProperty(depth0,"unitMonetaryValue") : depth0),{"name":"monetaryValue","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(alias1,__default(require("./helpers/data/get.js")).call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"articleBasedDiscounts")),(depth0 != null ? lookupProperty(depth0,"orderPositionId") : depth0),{"name":"data/get","hash":{},"data":data,"loc":{"start":{"line":80,"column":18},"end":{"line":80,"column":74}}}),{"name":"with","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":10},"end":{"line":87,"column":19}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"text-warning oa-positionsItem_articleBasedDiscountPrice\"\n                 title=\""
    + container.escapeExpression(__default(require("./helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"features.abd.position.tooltip",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":82,"column":24},"end":{"line":82,"column":64}}}))
    + "\"\n                 data-test=\"orderSummary.orderPosition.abd.totalValue\"\n            >"
    + ((stack1 = container.invokePartial(require("./partials/monetaryValue.hbs"),(depth0 != null ? lookupProperty(depth0,"totalMonetaryValue") : depth0),{"name":"monetaryValue","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-condensed table-hover u-mb-s oa-positionsTable "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"articleBasedDiscountsLoaded") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":73},"end":{"line":1,"column":154}}})) != null ? stack1 : "")
    + "\"\n       data-test=\"orderSummary.orderPositionTable\"\n>\n  <thead>\n  <tr class=\"\">\n    <th class=\"\">\n      "
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"order.positions.header_article",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":7,"column":47}}}))
    + "\n    </th>\n    <th class=\"oa-positionsTable__headerQty\">\n      "
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"order.positions.header_qty",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":10,"column":43}}}))
    + "\n    </th>\n    <th class=\"oa-positionsTable__cellMultiply\"></th>\n    <th class=\"oa-positionsTable__headerUnitPrice text-right\">\n      "
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"order.positions.header_unit_price",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":6},"end":{"line":14,"column":50}}}))
    + "\n    </th>\n    <th class=\"oa-positionsTable__headerTotalPrice text-right\">\n      "
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"order.positions.header_total_price",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":17,"column":51}}}))
    + "\n    </th>\n  </tr>\n  </thead>\n\n  <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"positionsToShow") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":2},"end":{"line":94,"column":11}}})) != null ? stack1 : "")
    + "  </tbody>\n</table>\n";
},"usePartial":true,"useData":true});