import { AppInterfaceService, TopNavigationConfiguration } from '../AppInterfaceService';

export default class FreshchatAppInterfaceService implements AppInterfaceService {
  private readonly _client: any;

  constructor(client: any) {
    this._client = client;
  }

  showModal(title: string, template: string, data: Object) {
    this._client.interface.trigger('showModal', {
      title,
      template,
      data,
    });
  }

  openAppInNewWindow(orderId?: number): void {
    const params: { [key: string]: string | number } = {};
    orderId && (params.orderId = orderId);
    this._client.instance.openInNewWindow(params);
  }

  getTopNavigationConfiguration(): TopNavigationConfiguration {
    return new TopNavigationConfiguration(false);
  }
}
