import { Models, Services, View } from '@cc/cc-app-commons';
import { AppDataContext } from '../../../common/AppDataContext';
import { ViewModel } from '../../../../types';
import { AppContext } from '../../../common/AppContext';
import { AppOriginContext } from '../../../common/AppOriginContext';
import FreshdeskAppContextServices from './FreshdeskAppContextServices';
import TicketBasedMetricContext = View.Context.TicketBasedMetricContext;
import Context = ViewModel.Context;
import Ticket = Models.Ticket;

export class FreshdeskAppContext extends AppContext {
  public ticket: Ticket;

  static async create(
    client: any,
    ticket: Ticket,
    connector: Services.KeycloakSecuredZooServicesConnector,
    gatewayUrl: string,
    keycloak: any,
  ): Promise<Context> {
    const appContextServices = new FreshdeskAppContextServices(
      client,
      ticket,
      connector,
      gatewayUrl,
      keycloak,
    );
    const appContext = new FreshdeskAppContext(appContextServices, keycloak);
    await appContext.createAppDataContext();
    appContext.createAppMetricContext(ticket);
    appContext.addTicket(ticket);
    appContext.createOriginContext(ticket);
    return appContext;
  }

  private createAppDataContext() {
    this._data = new AppDataContext(this.services);
    return this._data.initCurrentOrderModel();
  }

  private createAppMetricContext(ticket: Ticket) {
    this._metric = new TicketBasedMetricContext(ticket, this.services);
  }

  private addTicket(ticket: Ticket) {
    this.ticket = ticket;
  }

  private createOriginContext(ticket: Ticket) {
    const originMap = new Map<string, string>([
      ['ticketId', ticket.id.toString()],
      ['source', 'freshdesk'],
    ]);
    this._origin = new AppOriginContext(originMap);
  }
}
