import { Helpers, Services } from '@cc/cc-app-commons';
import ExpandableItemsViewModel from './ExpandableItemsAbstractModel';
import OrderParcel from '../models/OrderParcel';
import mediaTypeDetector from '../util/MediaTypeDetector';
import { Order } from '../models/Order';
import { ViewModel } from './types';
import i18n from 'i18n';
import ArticleInParcelViewModel from './ArticleInParcelViewModel';
import ParcelActionsViewModel from './parcelActions/ParcelActionsViewModel';
import { orderBy } from 'lodash';

const AppActivityAction = Services.AppActivityAction;
const parcelInfoHbs = require('../../templates/order-parcels/parcelInfo.hbs');
const ClipboardHelper = Helpers.ClipboardHelper;
const { renderTo } = Helpers.ClientHelpers;
const parcelPictureNotAvailableHbs = require('../../templates/order-parcels/parcelPictureNotAvailable.hbs');

type AppActivityAction = Services.AppActivityAction;
type ClipboardHelper = Helpers.ClipboardHelper;

export default class OrderParcelsViewModel extends ExpandableItemsViewModel<OrderParcel> {
  static SHOW_MORE_CSS_CLASS_NAME = 'oa-parcels__showMore';
  static TRACKING_LINK_ELEMENT_SELECTOR = '.oa-parcelsTable__trackingLink';
  static TRACKING_LINK_COPY_BUTTON_ELEMENT_SELECTOR = '.oa-parcelsTable__copyTtidButton';
  static PARCEL_PICTURE_ICON_SELECTOR = '.oa-parcelsTable__parcelPictureIcon';
  static PARCEL_PICTURE_MODAL_SELECTOR = '#oa-parcelsTable__parcelPictureModal';
  static PARCEL_WEIGHTS_DO_NOT_MATCH_SELECTOR = '.oa-parcelsTable__weightsDoNotMatch';
  private clipboardHelpers: ClipboardHelper[] = [];
  private readonly context: ViewModel.Context;
  private articleInParcelViewModels: ArticleInParcelViewModel[] = [];
  private parcelActionViewModels: ParcelActionsViewModel[] = [];

  constructor(domElement: HTMLElement, parcels: OrderParcel[], context: ViewModel.Context) {
    super(
      domElement,
      parcels,
      Order.DEFAULT_PARCELS_SHOWN_COUNT,
      OrderParcelsViewModel.SHOW_MORE_CSS_CLASS_NAME,
    );
    this.context = context;
    this.notifyTtidCopyButtonClicked = this.notifyTtidCopyButtonClicked.bind(this);
    this.notifyTtidLinkClicked = this.notifyTtidLinkClicked.bind(this);
    this.showParcelPictureModal = this.showParcelPictureModal.bind(this);
  }

  async renderItems(): Promise<void> {
    const tplContext = {
      parcelsToShow: orderBy(
        this.itemsForTemplateContext(),
        ['isVirtual', 'shipmentDate'],
        ['asc', 'desc'],
      ),
    };
    renderTo(this.el, parcelInfoHbs(tplContext));
    this.attachPluginsAndEventHandlers();
    if (mediaTypeDetector.isStandalone()) {
      this.prepareParcelPictures();
    }
    await this.renderOrderActions();
    this.renderArticlesInParcels();
  }

  private async renderOrderActions(): Promise<void> {
    const renderFunctions = this.getItemsToShow().map(async (parcel) => {
      let componentElement;
      if (mediaTypeDetector.isPlugin()) {
        componentElement = this.el.querySelector(`[component="--parcelActions_${parcel.id}"]`);
      }
      if (mediaTypeDetector.isStandalone()) {
        componentElement = this.el.querySelector(
          `[component="--parcelActions_${parcel.id}--standalone"]`,
        );
      }
      const parcelActionsViewModel = new ParcelActionsViewModel(
        componentElement,
        this.context,
        parcel,
      );
      await parcelActionsViewModel.render();
      this.parcelActionViewModels.push(parcelActionsViewModel);
    });

    await Promise.all(renderFunctions);
  }

  private renderArticlesInParcels() {
    this.getItemsToShow().forEach((parcel) => {
      parcel.parcelArticles.forEach((parcelArticle) => {
        const wrapperElement: HTMLElement = this.el.querySelector(
          `td[data-parcel-id="${parcel.id}"][data-article-number="${parcelArticle.articleNumber}"]`,
        );
        const articleInParcelViewModel = new ArticleInParcelViewModel(
          wrapperElement,
          parcelArticle,
          this.context,
        );
        articleInParcelViewModel.render();
        this.articleInParcelViewModels.push(articleInParcelViewModel);
      });
    });
  }

  destroy(): void {
    this.detachPluginsAndEventHandlers();
    this.articleInParcelViewModels.forEach((it) => it.destroy());
    this.parcelActionViewModels.forEach((it) => it.destroy());
  }

  private attachPluginsAndEventHandlers() {
    $(this.el.querySelector(OrderParcelsViewModel.PARCEL_WEIGHTS_DO_NOT_MATCH_SELECTOR)).tooltip();

    this.el
      .querySelectorAll(OrderParcelsViewModel.TRACKING_LINK_COPY_BUTTON_ELEMENT_SELECTOR)
      .forEach((el) => {
        this.clipboardHelpers.push(new ClipboardHelper(el, 'order.parcels.copy_ttid_button'));
        el.addEventListener('click', this.notifyTtidCopyButtonClicked);
      });

    this.el.querySelectorAll(OrderParcelsViewModel.TRACKING_LINK_ELEMENT_SELECTOR).forEach((el) => {
      $(el).tooltip();
      el.addEventListener('click', this.notifyTtidLinkClicked);
    });
  }

  private prepareParcelPictures() {
    this.getItemsToShow().forEach((it) => {
      this.context.data.parcelPicture(it.id).then((parcelPictureBlob: Blob) => {
        const pictureIcon = this.el.querySelector(
          `#oa-parcelsTable__parcel--${it.id} ${OrderParcelsViewModel.PARCEL_PICTURE_ICON_SELECTOR}`,
        );
        if (parcelPictureBlob) {
          pictureIcon.classList.remove('oa-parcelsTable__parcelPictureIcon--inactive');
          pictureIcon.classList.add('oa-parcelsTable__parcelPictureIcon--active');
          pictureIcon.setAttribute('title', i18n.t('order.parcels.standalone.picture.tooltip'));
          pictureIcon.addEventListener('click', this.showParcelPictureModal);
        }
        $(pictureIcon).tooltip();
      });
    });
  }

  private detachPluginsAndEventHandlers() {
    while (this.clipboardHelpers?.length) {
      const clipboardHelper = this.clipboardHelpers.shift();
      clipboardHelper.destroy();
    }

    this.el
      .querySelectorAll(OrderParcelsViewModel.TRACKING_LINK_COPY_BUTTON_ELEMENT_SELECTOR)
      .forEach((el) => {
        el.removeEventListener('click', this.notifyTtidCopyButtonClicked);
      });

    this.el.querySelectorAll(OrderParcelsViewModel.TRACKING_LINK_ELEMENT_SELECTOR).forEach((el) => {
      $(el).tooltip('destroy');
      el.removeEventListener('click', this.notifyTtidLinkClicked);
    });

    this.el.querySelectorAll(OrderParcelsViewModel.PARCEL_PICTURE_ICON_SELECTOR).forEach((el) => {
      $(el).tooltip('destroy');
      el.removeEventListener('click', this.showParcelPictureModal);
    });
  }

  private notifyTtidCopyButtonClicked(evt: Event): void {
    this.notifyTtidActivity(evt, AppActivityAction.TTID_COPY_BUTTON__CLICKED);
  }

  private notifyTtidLinkClicked(evt: Event): void {
    this.notifyTtidActivity(evt, AppActivityAction.TTID_LINK__CLICKED);
  }

  private notifyTtidActivity(evt: Event, appActivityAction: AppActivityAction): void {
    const ttid = (evt.currentTarget as HTMLElement).dataset.ttid;
    this.context.metric.logAppActivity(appActivityAction, ttid);
  }

  private showParcelPictureModal(event: Event) {
    const parcelId = Number((event.currentTarget as HTMLElement).dataset.parcelid);
    const parcelPictureModalSelector = `${OrderParcelsViewModel.PARCEL_PICTURE_MODAL_SELECTOR}--${parcelId}`;
    $(parcelPictureModalSelector).modal({ show: true });
    this.context.data.parcelPicture(parcelId).then((parcelPictureBlob) => {
      if (parcelPictureBlob) {
        $(`${parcelPictureModalSelector} .oa-parcelsTable__parcelPictureImage`)
          .removeClass('oa-parcelsTable__parcelPictureImageLoader')
          .attr('src', URL.createObjectURL(parcelPictureBlob));
      } else {
        $(`${parcelPictureModalSelector} .modal-body`).html(parcelPictureNotAvailableHbs());
      }
    });
  }
}
