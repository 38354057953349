enum ModifyBillingAddressReason {
  MISSING_VAT = 'MISSING_VAT',
  BILLING_TO_SOMEONE_ELSE = 'BILLING_TO_SOMEONE_ELSE',
  NOT_ENOUGH_CHARACTERS = 'NOT_ENOUGH_CHARACTERS',
  INCORRECT_OR_OLD_ADDRESS = 'INCORRECT_OR_OLD_ADDRESS',
  CONTACT_DETAILS_CHANGE = 'CONTACT_DETAILS_CHANGE',
  OTHER = 'OTHER',
}

export default ModifyBillingAddressReason;
