import { Models, Utils } from '@cc/cc-app-commons';
import ShopCurrency from './ShopCurrency';

const isNumber = Utils.lodash.isNumber;

export default class Payment extends Models.BaseModel {
  paymentId: number = null;
  eventId: number = null;
  paymentState: string = null;
  paymentMethod: string = null;
  connector: string = null;
  paymentValue: Models.MonetaryValue;
  errorCode: string = null;
  errorMessage: string = null;
  aggregatedPaymentState: string = null;

  fromData(json: any, shopCurrency?: ShopCurrency): any {
    super.fromData(json);

    if (isNumber(json.paymentAmount)) {
      const paymentValue = new Models.MonetaryValue().fromData({
        currency: json.currency,
        amount: convertPaymentAmountFromMinorUnits(json.paymentAmount),
      });
      this.paymentValue = paymentValue;
    }

    return this;
  }
}

function convertPaymentAmountFromMinorUnits(amount: number) {
  return isNumber(amount) ? amount / 100 : null;
}

export const PaymentMethod = {
  DOTPAY: 'DOTPAY',
  PAYTRAIL: 'PAYTRAIL',
  PAYPAL: 'PAYPAL',
  BANCONTACT: 'BANCONTACT',
  BANCONTACT_MOBILE: 'BANCONTACT_MOBILE',
  INVOICE: 'INVOICE',
  PREPAYMENT: 'PREPAYMENT',
  IDEAL: 'IDEAL',
  CREDIT_CARD: 'CREDIT_CARD',
  DIRECT_DEBIT_SEPA: 'DIRECT_DEBIT_SEPA',
  CASH_ON_DELIVERY: 'CASH_ON_DELIVERY',
  MULTIBANCO: 'MULTIBANCO',
  TRUSTLY: 'TRUSTLY',
};

export const PaymentState = {
  INITIATED_PENDING: 'INITIATED_PENDING',
  INITIATED_SUCCESSFUL: 'INITIATED_SUCCESSFUL',
  AUTHORIZATION_PENDING: 'AUTHORIZATION_PENDING',
  AUTHORIZATION_SENT: 'AUTHORIZATION_SENT',
  AUTHORIZATION_SUCCESSFUL: 'AUTHORIZATION_SUCCESSFUL',
  AUTHORIZATION_ERROR: 'AUTHORIZATION_ERROR',
  AUTHORIZATION_FAILED: 'AUTHORIZATION_FAILED',
  CAPTURE_PENDING: 'CAPTURE_PENDING',
  CAPTURE_SENT: 'CAPTURE_SENT',
  CAPTURE_SUCCESSFUL: 'CAPTURE_SUCCESSFUL',
  CAPTURE_ERROR: 'CAPTURE_ERROR',
  CAPTURE_FAILED: 'CAPTURE_FAILED',
  REFUND_PENDING: 'REFUND_PENDING',
  REFUND_SENT: 'REFUND_SENT',
  REFUND_SUCCESSFUL: 'REFUND_SUCCESSFUL',
  REFUND_ERROR: 'REFUND_ERROR',
  REFUND_FAILED: 'REFUND_FAILED',
  CHARGED_BACK: 'CHARGED_BACK',
  CANCELLATION_PENDING: 'CANCELLATION_PENDING',
  CANCELLATION_SENT: 'CANCELLATION_SENT',
  CANCELLATION_ERROR: 'CANCELLATION_ERROR',
  CANCELLATION_FAILED: 'CANCELLATION_FAILED',
  CANCELLATION_SUCCESSFUL: 'CANCELLATION_SUCCESSFUL',
  UNKNOWN: 'UNKNOWN',
};

export const AggregatedPaymentState = {
  INITIATED: 'INITIATED',
  AUTHORIZED: 'AUTHORIZED',
  CAPTURED: 'CAPTURED',
  REFUNDED: 'REFUNDED',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
  UNKNOWN: 'UNKNOWN',
};
