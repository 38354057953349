import { ViewModel } from './types';
import ParcelArticle from '../models/ParcelArticle';
import { Helpers } from '@cc/cc-app-commons';
import ParcelPhysicalArticle from '../models/ParcelPhysicalArticle';
import { ArticleLinksModel, ArticleNumberModel } from '../models/Article';
import OrderArticleNumberViewModel from './OrderArticleNumberViewModel';

const { renderTo, render } = Helpers.ClientHelpers;
const articleInParcelTpl = require('../../templates/order-parcels/articleInParcel.hbs');
const physicalArticleNameTpl = require('../../templates/order-parcels/physicalArticleName.hbs');

export default class ArticleInParcelViewModel {
  private readonly parcelArticle: ParcelArticle;
  private readonly el: HTMLElement;
  private context: ViewModel.Context;

  private readonly PHYSICAL_ARTICLES_SELECTOR = '.oa-parcelsTable__physicalArticles';
  private readonly PHYSICAL_ARTICLES_TOGGLE_SELECTOR = '.oa-parcelsTable__togglePhysicalArticles';
  private articleNumberVms: OrderArticleNumberViewModel[] = [];

  constructor(domElement: HTMLElement, parcelArticle: ParcelArticle, context: ViewModel.Context) {
    this.parcelArticle = parcelArticle;
    this.el = domElement;
    this.context = context;
  }

  render(): void {
    renderTo(this.el, articleInParcelTpl(this.parcelArticle.toTemplateData()));
    this.renderArticleNumber();
    this.el.querySelectorAll(this.PHYSICAL_ARTICLES_TOGGLE_SELECTOR).forEach((el) => {
      el.addEventListener('click', this.togglePhysicalArticlesVisibility.bind(this));
    });
  }

  private renderArticleNumber() {
    this.el.querySelectorAll('[component="--articleNumber"]').forEach((el) => {
      const articleNumber = this.parcelArticle.articleNumber;
      const articleLinks = new ArticleLinksModel().fromData({
        articleAdminUrl: this.parcelArticle.articleAdminUrl,
        shopArticleUrl: this.parcelArticle.shopArticleUrl,
      });

      const vm = new OrderArticleNumberViewModel(
        el as HTMLSpanElement,
        new ArticleNumberModel(articleNumber, articleLinks),
        this.context,
      );
      vm.render();
      this.articleNumberVms.push(vm);
    });
  }

  destroy() {
    this.articleNumberVms.forEach((vm) => vm.destroy());
  }

  togglePhysicalArticlesVisibility(): void {
    $(this.el.querySelector(this.PHYSICAL_ARTICLES_SELECTOR)).toggle('slow');
    $(this.el.querySelector(this.PHYSICAL_ARTICLES_TOGGLE_SELECTOR)).toggleClass(
      'glyphicon-chevron-right glyphicon-chevron-down',
    );
    this.parcelArticle.parcelPhysicalArticles
      .filter((physicalArticle) => physicalArticle.isNameAndShortTextNotDefined())
      .forEach((physicalArticle) => {
        this.context.data
          .enrichPhysicalArticleWithName(physicalArticle)
          .then((physicalArticle) => this.rerenderPhysicalArticleName(physicalArticle));
      });
  }

  rerenderPhysicalArticleName(parcelPhysicalArticle: ParcelPhysicalArticle): void {
    render(
      this.el.querySelector(
        `.oa-parcelsTable__physicalArticleName[data-physical-article-id="${parcelPhysicalArticle.articleId}"]`,
      ),
      physicalArticleNameTpl(parcelPhysicalArticle),
    );
  }
}
