import { Models } from '@cc/cc-app-commons';
import { TemplateData } from './types';

export default class Customer extends Models.BaseModel implements TemplateData {
  zooId: number = undefined;
  email: string = '';
  firstName: string = '';
  lastName: string = '';
  icv: string = undefined;

  toTemplateData(...context: any): any {
    return this.toData(...context);
  }
}
