import { AppEventsHandler } from '../../../common/AppEventsHandler';
import App from '../../../../../modules/app';
import { Models, Utils } from '@cc/cc-app-commons';
import MediaTypeDetector from '../../../../../util/MediaTypeDetector';
import { resizeContainer } from '../../../../../lib/clientHelpers';
import { TicketService } from '../../../../../modules/services/TicketService';
import { ViewModel } from '../../../../types';
import Context = ViewModel.Context;
import { captureMessage } from '@sentry/browser';

type Ticket = Models.Ticket;

export class FreshdeskAppEventsHandler implements AppEventsHandler {
  private readonly client: any;
  private ticketService: TicketService;
  private ticket: Ticket;

  constructor(client: any) {
    this.client = client;
    this.ticketService = new TicketService(this.client);
  }

  async onAppContextCreated(context: Context) {
    return this.initialized().then(async () => {
      const loggedInUser = await context.user.loggedInUser;
      if (!loggedInUser.zooId) {
        captureMessage(`User email ${loggedInUser.email} does not have zooId assigned`, 'debug');
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async onAppRendered(target: App) {
    return this.initialized().then(() => resizeContainer(this.client));
  }

  async onAppLoaded(target: App) {
    return this.initialized().then(() => this.attachEventHandlers(target.renderApp.bind(target)));
  }

  private async initialized(): Promise<any> {
    return this.client.initialized().then(async () => {
      this.ticket = await this.ticketService.getTicketDataFromSystem();
    });
  }

  private attachEventHandlers(callback: Function) {
    if (MediaTypeDetector.isPlugin()) {
      this.client.events.on('ticket.propertiesUpdated', (event: any) =>
        this.onTicketPropertiesUpdated(event, callback),
      );
    }
    this.client.events.on('app.activated', () => this.onAppActivated(callback));
  }

  private onTicketPropertiesUpdated(data: any, callback: Function) {
    const changedAttributes = data.changedAttributes;
    const isCustomFieldsChanged =
      changedAttributes &&
      changedAttributes.isCustomFieldsChanged &&
      changedAttributes.isCustomFieldsChanged[1] === true;
    if (isCustomFieldsChanged) {
      callback();
    }
  }

  async onAppActivated(callback: Function) {
    const previousTicket = this.ticket;
    const actualTicket = await this.ticketService.getTicketDataFromSystem();
    this.ticket = actualTicket;
    if (this.isTicketDataChanged(previousTicket, actualTicket)) {
      return callback();
    }
    return Promise.resolve();
  }

  private isTicketDataChanged(previousTicket: Ticket, actualTicket: Ticket) {
    return !Utils._.isEqual(previousTicket, actualTicket);
  }
}
