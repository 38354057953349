import { Helpers } from '@cc/cc-app-commons';
import ErrorForViewModel from '../util/ErrorForViewModel';

const { clear, renderTo } = Helpers.ClientHelpers;
const tpl = require('../../templates/modalError.hbs');

export default class ModalErrorViewModel {
  constructor(
    private readonly el: Element,
    private readonly error: ErrorForViewModel,
  ) {}

  render() {
    renderTo(
      this.el,
      tpl({
        errorTitleTranslationKey: this.error.titleTranslationKey,
        errorMessageTranslationKey: this.error.messageTranslationKey,
      }),
    );
  }

  destroy() {
    clear(this.el);
  }
}
