import { AppEventsHandler } from '../common/AppEventsHandler';
import App from '../../../modules/app';
import { ViewModel } from '../../types';
import Context = ViewModel.Context;
import { captureMessage } from '@sentry/browser';

export class IndependentAppEventsHandler implements AppEventsHandler {
  async onAppContextCreated(context: Context) {
    const loggedInUser = await context.user.loggedInUser;
    if (!loggedInUser.zooId) {
      captureMessage(`User email ${loggedInUser.email} does not have zooId assigned`, 'debug');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onAppRendered(target: App): void {
    // Intentionally empty
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onAppLoaded(target: App): void {
    // Intentionally empty
  }
}
