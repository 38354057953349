var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class=\"u-pr-xs\">"
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(alias1,"order.header.order_relations.title.current_order_is_parent_of",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":26},"end":{"line":4,"column":98}}}))
    + ":</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/conditionals/lte.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"length") : depth0),2,{"name":"conditionals/lte","hash":{},"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":5,"column":44}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":31,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <span data-test=\"orderRelations.childOrders.list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "      </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./orderRelationItem.hbs"),depth0,{"name":"./orderRelationItem","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":8,"column":34},"end":{"line":8,"column":65}}})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "";
},"6":function(container,depth0,helpers,partials,data) {
    return ", ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"dropdown oa-orderRelation__dropdown\"\n           data-test=\"orderRelations.childOrders.dropdown\">\n        <button class=\"btn btn-link btn-sm dropdown-toggle oa-orderRelation__dropdownToggle\"\n                type=\"button\"\n                id=\"oa-orderRelation__dropdownMenu\"\n                data-toggle=\"dropdown\"\n                aria-haspopup=\"true\"\n                aria-expanded=\"true\">\n          "
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(alias1,"order.header.order_relations.show_items","items",(depth0 != null ? lookupProperty(depth0,"length") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":10},"end":{"line":20,"column":80}}}))
    + "\n          <span class=\"caret\"></span>\n        </button>\n        <ul class=\"dropdown-menu\" aria-labelledby=\"oa-orderRelation__dropdownMenu\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,depth0,{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":10},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + "        </ul>\n      </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"u-text-centered\">\n              <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":26,"column":26},"end":{"line":26,"column":61}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = container.invokePartial(require("./orderRelationItem.hbs"),depth0,{"name":"./orderRelationItem","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n            </li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "u-pb-m";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <span class=\"u-pr-xs\">"
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.header.order_relations.title.current_order_is_child_of",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":26},"end":{"line":35,"column":97}}}))
    + ":</span>\n"
    + ((stack1 = container.invokePartial(require("./orderRelationItem.hbs"),depth0,{"name":"./orderRelationItem","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-orderRelation\">\n  <span class=\"glyphicon glyphicon-arrow-right u-pl-l u-pr-l oa-orderRelation__relationArrow\"></span>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"children") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":32,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"parent") : depth0),{"name":"with","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":2},"end":{"line":37,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});