var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <a class=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"showMoreButtonCssClassName") : depth0), depth0))
    + "\" data-test=\"expandableItems.toggleButton\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"state") : depth0)) != null ? lookupProperty(stack1,"allItemsExpanded") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "  </a>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "      "
    + container.escapeExpression(__default(require("./helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.show_less_items",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":38}}}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(__default(require("./helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.show_more_items","items",(depth0 != null ? lookupProperty(depth0,"additionalItemsToShow") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":6,"column":68}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showMoreButtonVisible") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":7}}})) != null ? stack1 : "");
},"useData":true});