import { Helpers, Models, Services } from '@cc/cc-app-commons';

import { ViewModel } from '../types';

import ModifyShippingAddressReason from '../../models/ModifyShippingAddressReason';
import AddressModificationActionsModalHelper from './AddressModificationActionsModalHelper';
import addressServiceExtractor from '../../modules/services/AddressDataExtractor';
import Address from '../../models/Address';

const AppActivityAction = Services.AppActivityAction;
const { clear, renderTo } = Helpers.ClientHelpers;
const tpl = require('../../../templates/order-addresses/modifyShippingAddressModal.hbs');

export default class ModifyShippingAddressViewModel {
  private readonly el: Element;
  private readonly context: ViewModel.Context;
  private readonly onAddressModificationCallback: Function;
  private eventRemovers: Models.Remover[] = [];
  private _modifyShippingAddressActionButton: JQuery<Element>;
  private _formElement: HTMLFormElement;

  constructor(
    domElement: Element,
    context: ViewModel.Context,
    onAddressModificationCallback: Function,
  ) {
    this.el = domElement;
    this.context = context;
    this.onAddressModificationCallback = onAddressModificationCallback;
  }

  get formElement() {
    if (!this._formElement) {
      this._formElement = this.el.querySelector('form');
    }
    return this._formElement;
  }

  get modifyAddressActionButton(): JQuery<Element> {
    if (!this._modifyShippingAddressActionButton) {
      this._modifyShippingAddressActionButton = $(
        this.el.querySelector(' .oa-modifyShippingAddressModal-modifyAddressButton'),
      );
    }
    return this._modifyShippingAddressActionButton;
  }

  async render() {
    const order = await this.context.data.currentOrder;
    renderTo(
      this.el,
      tpl({
        modifyShippingAddressReasons: Object.keys(ModifyShippingAddressReason).map(
          (key) => ModifyShippingAddressReason[key as keyof typeof ModifyShippingAddressReason],
        ),
        shippingAddress: order.shippingAddress?.toData(),
      }),
    );

    this.attachPluginsAndEventHandlers();
  }

  private attachPluginsAndEventHandlers() {
    const onSubmitCallback = this.submitShippingAddressModification.bind(this);
    this.formElement.addEventListener('submit', onSubmitCallback);
    this.eventRemovers.push(
      Models.Remover.createFor(
        function (): void {
          this.formElement.removeEventListener('submit', onSubmitCallback);
        }.bind(this) // eslint-disable-line
      ),
    );

    const radioOnChangeCallback = (e: Event) => {
      if ((e.currentTarget as HTMLInputElement).value) {
        this.enableModifyAddressActionButton();
      }
    };
    this.formElement.modifyShippingAddressReason.forEach((element: HTMLInputElement) => {
      element.addEventListener('change', radioOnChangeCallback);
      this.eventRemovers.push(
        Models.Remover.createFor(
          function (): void {
            element.removeEventListener('change', radioOnChangeCallback);
          }.bind(this) // eslint-disable-line
        ),
      );
    });
  }

  private async submitShippingAddressModification(evt: Event) {
    evt.preventDefault();
    const modifyShippingAddressReason = (evt.target as HTMLFormElement).modifyShippingAddressReason
      .value as keyof typeof ModifyShippingAddressReason;

    const newAddress = new Address().fromData(addressServiceExtractor(evt));

    try {
      await this.onSubmit(ModifyShippingAddressReason[modifyShippingAddressReason], newAddress);
    } catch (e) {
      AddressModificationActionsModalHelper.handleShippingAddressChangeError(e);
    }
  }

  private enableModifyAddressActionButton() {
    this.modifyAddressActionButton.prop('disabled', false);
  }

  private async onSubmit(
    modifyShippingAddressReason: ModifyShippingAddressReason,
    newAddress: Address,
  ) {
    this.context.metric.logAppActivity(
      AppActivityAction.SHIPPING_ADDRESS_CHANGE_SUBMIT_BUTTON__CLICKED,
    );
    const order = await this.context.data.currentOrder;

    await this.context.data.modifyShippingAddress(
      order.id,
      modifyShippingAddressReason,
      newAddress,
    );

    this.onAddressModificationCallback();
  }

  private detachPluginsAndEventHandlers() {
    this.eventRemovers.forEach((eventHandlerRemover: Models.Remover) =>
      eventHandlerRemover.remove(),
    );
  }

  destroy() {
    this.detachPluginsAndEventHandlers();
    clear(this.el);
  }
}
