var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"radio\">\n          <label>\n            <input type=\"radio\" name=\"cancelParcelReason\" value=\""
    + alias1(container.lambda(depth0, depth0))
    + "\">\n            "
    + alias1(__default(require("../helpers/i18n.js")).call(alias2,__default(require("../helpers/concat.js")).call(alias2,"parcel.actions.cancellation.modal.reasons.",depth0,{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":14,"column":19},"end":{"line":14,"column":77}}}),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":79}}}))
    + "\n          </label>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "btn-block";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-cancelParcelReason\" data-test=\"cancelParcelReason\">\n\n  <form>\n    <div class=\"modal-header\">\n"
    + ((stack1 = container.invokePartial(require("../common/closeModalButton.hbs"),depth0,{"name":"../common/closeModalButton","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      <h4 class=\"modal-title\" id=\"oaCancelParcelReasonModalLabel\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"parcel.actions.cancellation.modal.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":66},"end":{"line":6,"column":116}}}))
    + "</h4>\n    </div>\n\n    <div class=\"modal-body oa-cancelParcelReason__reasons\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"parcelCancelReasons") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"modal-footer\">\n      <button type=\"button\"\n              class=\"btn btn-default btm-sm "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/layout/isMediaTypePlugin.js")).call(alias1,{"name":"layout/isMediaTypePlugin","hash":{},"data":data,"loc":{"start":{"line":22,"column":50},"end":{"line":22,"column":78}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":44},"end":{"line":22,"column":96}}})) != null ? stack1 : "")
    + "\"\n              data-dismiss=\"modal\"\n              data-test=\"abortCancelParcel\">\n        "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"default.actions.close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":25,"column":40}}}))
    + "\n      </button>\n      <button type=\"submit\" disabled=\"disabled\"\n              class=\"btn btn-primary btm-sm "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/layout/isMediaTypePlugin.js")).call(alias1,{"name":"layout/isMediaTypePlugin","hash":{},"data":data,"loc":{"start":{"line":28,"column":50},"end":{"line":28,"column":78}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":44},"end":{"line":28,"column":96}}})) != null ? stack1 : "")
    + " oa-cancelParcelReason-cancelParcelActionButton\"\n              data-test=\"submitCancelParcel\">\n        "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"parcel.actions.cancellation.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":30,"column":52}}}))
    + "\n      </button>\n    </div>\n  </form>\n\n</div>\n";
},"usePartial":true,"useData":true});