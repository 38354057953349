var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div data-test=\"orderArticleLinks.linkContainer\">\n    <a href=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"articleAdminUrl") : depth0), depth0))
    + "\"\n       target=\"_blank\"\n       class=\"oa-orderArticleLinks__link oa-orderArticleLinks__articleAdminLink\"\n       data-test=\"orderArticleLinks.link\">\n      "
    + alias1(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.articles.links.open_in_article_admin",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":6},"end":{"line":8,"column":59}}}))
    + "\n      <span class=\"glyphicon glyphicon-new-window oa-orderArticleLinks__linkIcon\"></span>\n    </a>\n  </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"oa-orderArticleLinks__linkDelimiter\" data-test=\"orderArticleLinks.linkDelimiter\">|</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div>\n    <a href=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"shopArticleUrl") : depth0), depth0))
    + "\"\n       target=\"_blank\"\n       class=\"oa-orderArticleLinks__link oa-orderArticleLinks__shopArticleLink\"\n       data-test=\"orderArticleLinks.link\">\n      "
    + alias1(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.articles.links.open_in_shop",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":6},"end":{"line":24,"column":50}}}))
    + "\n      <span class=\"glyphicon glyphicon-new-window oa-orderArticleLinks__linkIcon\"></span>\n    </a>\n  </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-orderArticleLinks\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"articleAdminUrl") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":12,"column":10}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"shopArticleUrl") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":2},"end":{"line":16,"column":10}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"shopArticleUrl") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":2},"end":{"line":28,"column":10}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});