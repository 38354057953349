import { Helpers } from '@cc/cc-app-commons';
import { ViewModel } from '../types';

const { clear, renderTo } = Helpers.ClientHelpers;
const tplFraudIcon = require('../../../templates/order-header/fraudIcon.hbs');

export default class FraudIconViewModel {
  private static TOOLTIP_ICON_ELEMENT_SELECTOR = '.oa-fraudIcon .oa-tooltipWrapper';

  private readonly el: Element;
  private readonly context: ViewModel.Context;

  constructor(domElement: Element, context: ViewModel.Context) {
    this.el = domElement;
    this.context = context;
  }

  async render() {
    const order = await this.context.data.currentOrder;
    renderTo(this.el, tplFraudIcon(order.toTemplateData()));
    this.attachPluginsAndEventHandlers();
  }

  private attachPluginsAndEventHandlers() {
    $(this.el.querySelector(FraudIconViewModel.TOOLTIP_ICON_ELEMENT_SELECTOR)).tooltip();
  }

  private detachPluginsAndEventHandlers() {
    $(this.el.querySelector(FraudIconViewModel.TOOLTIP_ICON_ELEMENT_SELECTOR)).tooltip('destroy');
  }

  destroy() {
    this.detachPluginsAndEventHandlers();
    clear(this.el);
  }
}
