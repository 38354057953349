import { Models } from '@cc/cc-app-commons';
import { Order } from './Order';
import { Tooltip } from '../types';
import OrderResolutionMetadata from './OrderResolutionMetadata';
import Ticket = Models.Ticket;

const OrderIdTooltipContent = Tooltip.Order.Id.Content;

export default class ClientBasedAppOrderResolutionMetadata extends OrderResolutionMetadata {
  private readonly ticket: Ticket;

  constructor(ticket: Ticket, order: Order) {
    super(
      ticket.customerId,
      ticket.orderId,
      order.customerId,
      OrderIdTooltipContent.TICKET_CUSTOMER_ORDER_MISMATCH,
    );
    this.ticket = ticket;
  }

  protected contentConfiguration(): Tooltip.Order.Id.Model {
    const ticket = this.ticket;
    const contentConfiguration = super.contentConfiguration();
    if (!contentConfiguration.contentKey) {
      if (ticket.customerEmail && ticket.productId) {
        contentConfiguration.context = {
          customerEmail: ticket.customerEmail,
          productId: ticket.productId,
        };
        contentConfiguration.contentKey = OrderIdTooltipContent.ORDER_BY_EMAIL_AND_PRODUCT;
      } else if (ticket.customerPhone && ticket.productId) {
        contentConfiguration.context = {
          customerPhone: ticket.customerPhone,
          productId: ticket.productId,
        };
        contentConfiguration.contentKey = OrderIdTooltipContent.ORDER_BY_PHONE_AND_PRODUCT;
      }
    }
    return contentConfiguration;
  }
}
