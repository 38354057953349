import { Models, Services } from '@cc/cc-app-commons';
import { ServerResponse } from '../../types';
import OrderV1 from '../../models/OrderV1';

export class OrdersFetchingServiceV1 {
  constructor(
    private connector: Services.KeycloakSecuredZooServicesConnector,
    private gatewayUrl: string,
  ) {}

  async fetchCustomerLastOrders(customerId: number, count = 1): Promise<OrderV1[]> {
    const query = {
      last: count,
    };

    const customerLastOrders: OrderV1[] = await this.manageOrderFetching(
      this.connector.fetchData<ServerResponse.OrderV1[]>(
        this.gatewayUrl,
        `cc-apps-api/v1/customers/${customerId}/orders`,
        query,
      ),
    );

    if (customerLastOrders.length === 0) {
      throw new Models.Errors.CustomerHasNoOrdersAppError('');
    }
    return customerLastOrders;
  }

  async fetchOrderByInvoiceId(invoiceId: string): Promise<OrderV1[]> {
    const query = {
      invoiceId: invoiceId,
    };
    return this.manageOrderFetching(
      this.connector.fetchData<ServerResponse.OrderV1>(
        this.gatewayUrl,
        `cc-apps-api/v1/orders`,
        query,
      ),
    );
  }

  async fetchOrder(orderId: number): Promise<OrderV1[]> {
    return this.manageOrderFetching(
      this.connector.fetchData<ServerResponse.OrderV1>(
        this.gatewayUrl,
        `cc-apps-api/v1/orders/${orderId}`,
        null,
      ),
    );
  }

  private async manageOrderFetching(
    promise: Promise<ServerResponse.OrderV1 | ServerResponse.OrderV1[]>,
  ): Promise<OrderV1[]> {
    let orders: ServerResponse.OrderV1 | ServerResponse.OrderV1[];
    try {
      orders = await promise;
      orders = Array.isArray(orders) ? orders : [orders];
      return orders.map((o) => new OrderV1().fromData(o));
    } catch (err) {
      if (err.status === 404) {
        throw new Models.Errors.OrdersNotFoundAppError(err);
      } else {
        throw new Models.Errors.UnexpectedAppError(err);
      }
    }
  }
}
