import { Services as ServicesWithKeycloak, Models } from '@cc/cc-app-commons';
import { Services } from '@cc/cc-app-commons/lib/js/view/context/types';
import { ServerRequest, ServerResponse } from '../../types';
import UserService = Services.UserService;

export class FeatureFlagsService {
  constructor(
    private connector: ServicesWithKeycloak.KeycloakSecuredZooServicesConnector,
    private gatewayUrl: string,
    private userService: UserService,
  ) {}

  private static V2_ENABLE_FEATURE_FLAG = 'cc-apps-api_v2-enabled-for-order-app';

  async shouldUseV2ForOrdersFetching(): Promise<boolean> {
    const user = await this.userService.loggedInUser();
    const userId = user.zooId;
    try {
      const data = await this.connector.postData<
        ServerRequest.FeatureFlags,
        ServerResponse.FeatureFlags[]
      >(this.gatewayUrl, `cc-apps-api/v1/feature-flags`, { userId });

      const v2EnabledFeatureFlag = data.find(
        (it) => it.key === FeatureFlagsService.V2_ENABLE_FEATURE_FLAG,
      );
      if (v2EnabledFeatureFlag) {
        return v2EnabledFeatureFlag.enabled;
      }
      throw new FeatureFlagNotFoundException();
    } catch (e) {
      console.warn(
        `Encountered problems while retrieving feature flag ${FeatureFlagsService.V2_ENABLE_FEATURE_FLAG}, falling back to "false"`,
        e,
      );
      return Promise.resolve(false);
    }
  }
}

class FeatureFlagNotFoundException extends Models.Errors.AppError {}
