import { Helpers, Models, Services } from '@cc/cc-app-commons';
import { ViewModel } from '../types';
import OrderActionsModalHelper from './OrderActionsModalHelper';
import { resolveCCAdminUrl } from '../../helpers/connectionTypeResolver';

const AppActivityAction = Services.AppActivityAction;

const { clear, renderTo } = Helpers.ClientHelpers;
const tpl = require('../../../templates/order-actions/refundCustomerMessage.hbs');

export default class RefundCustomerMessageViewModel {
  private readonly el: Element;
  private readonly context: ViewModel.Context;
  private eventRemovers: Models.Remover[] = [];

  constructor(el: Element, context: ViewModel.Context) {
    this.el = el;
    this.context = context;
  }

  async render() {
    const order = await this.context.data.currentOrder;
    const ccAdminUrl = await resolveCCAdminUrl();
    renderTo(
      this.el,
      tpl({
        orderId: order.id,
        url: ccAdminUrl,
      }),
    );
    this.context.metric.logAppActivity(AppActivityAction.REFUND_CUSTOMER_MESSAGE__DISPLAYED);
    this.attachPluginsAndEventHandlers();
  }

  private attachPluginsAndEventHandlers() {
    this.onCcAdminRefundLinkClick();
    this.onModalClose();
  }

  private onCcAdminRefundLinkClick() {
    const callback = this.onCcAdminRefundLinkClickCallback.bind(this);
    const ccAdminRefundLink = this.el.querySelector('.oa-modal-ccAdminRefundLink');
    ccAdminRefundLink.addEventListener('click', callback);
    this.eventRemovers.push(
      Models.Remover.createFor(
        function (): void {
          ccAdminRefundLink.removeEventListener('click', callback);
        }.bind(this) // eslint-disable-line
      ),
    );
  }

  onCcAdminRefundLinkClickCallback(evt: Event) {
    this.context.metric.logAppActivity(AppActivityAction.CC_ADMIN_REFUND_LINK__CLICKED);
    const ccAdminOrderUrl = (evt.currentTarget as HTMLElement).dataset.ccAdminOrderUrl;
    window.open(ccAdminOrderUrl);
  }

  private onModalClose() {
    const callback = this.onModalCloseCallback.bind(this);
    const closeModalButton = this.el.querySelector('.oa-modal-refundCustomerMessageCloseButton');
    closeModalButton.addEventListener('click', callback);
    this.eventRemovers.push(
      Models.Remover.createFor(
        function (): void {
          closeModalButton.removeEventListener('click', callback);
        }.bind(this) // eslint-disable-line
      ),
    );
  }

  onModalCloseCallback(evt: Event) {
    evt.preventDefault();
    OrderActionsModalHelper.getModal().close();
  }

  destroy() {
    this.detachPluginsAndEventHandlers();
    clear(this.el);
  }

  private detachPluginsAndEventHandlers() {
    this.eventRemovers.forEach((eventHandlerRemover: Models.Remover) =>
      eventHandlerRemover.remove(),
    );
  }
}
