import { Models } from '@cc/cc-app-commons';
import ShopCurrency from './ShopCurrency';
import monetaryValueFromShopCurrency from '../helpers/monetaryValueFromShopCurrency';

export default class OrderPosition extends Models.BaseModel {
  orderPositionId: number = undefined;
  quantity: number = 0;
  unitValue: number = undefined;
  unitMonetaryValue: Models.MonetaryValue = null;
  totalValue: number = undefined;
  totalMonetaryValue: Models.MonetaryValue = null;

  get key(): string {
    return undefined;
  }

  get value(): Models.MonetaryValue {
    return this.unitMonetaryValue;
  }

  fromData(json: any, shopCurrency: ShopCurrency): any {
    super.fromData(json);
    this.unitMonetaryValue = monetaryValueFromShopCurrency(shopCurrency, json.unitValue);
    this.totalMonetaryValue = monetaryValueFromShopCurrency(shopCurrency, json.totalValue);
    return this;
  }

  toTemplateData(...context: any[]): any {
    return super.toData(...context);
  }
}
