var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./cancelParcel.hbs"),depth0,{"name":"./cancelParcel","hash":{"isParcelCancellable":(depth0 != null ? lookupProperty(depth0,"isParcelCancellable") : depth0),"canCancelParcel":((stack1 = (depth0 != null ? lookupProperty(depth0,"authorization") : depth0)) != null ? lookupProperty(stack1,"canCancelParcel") : stack1)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});