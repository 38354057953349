var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"glyphicon glyphicon-credit-card u-pr-s\"></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <tr>\n    <td>"
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(alias1,"order.payment.method.header",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":23,"column":46}}}))
    + ":</td>\n    <td class=\"u-va-middle text-right\" data-test=\"orderPaymentSummary.paymentMethod\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"paymentMethodDetails") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":25,"column":6},"end":{"line":34,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n  </tr>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"label label-default\" data-test=\"orderPaymentSummary.paymentMethod.name\">"
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(alias1,__default(require("../helpers/concat.js")).call(alias1,"order.payment.method.types.",(depth0 != null ? lookupProperty(depth0,"paymentMethod") : depth0),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":27,"column":18},"end":{"line":27,"column":75}}}),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":10},"end":{"line":27,"column":78}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"paymentProvider") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":30,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span data-test=\"orderPaymentSummary.paymentMethod.provider\">("
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"paymentProvider") : depth0), depth0))
    + ")</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"default.not_available",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":33,"column":40}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-condensed u-table\" data-test=\"payment\">\n  <thead>\n  <tr>\n    <th colspan=\"2\">\n      <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/layout/isMediaTypeStandalone.js")).call(alias1,{"name":"layout/isMediaTypeStandalone","hash":{},"data":data,"loc":{"start":{"line":6,"column":14},"end":{"line":6,"column":46}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "        "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.payment.header",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":39}}}))
    + "\n      </div>\n    </th>\n  </tr>\n  </thead>\n  <tbody>\n  <tr>\n    <td>"
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.payment.total_order_value",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":16,"column":50}}}))
    + ":</td>\n    <td class=\"text-right\" data-test=\"orderPaymentSummary.totalOrderValue\">"
    + ((stack1 = container.invokePartial(require("../partials/monetaryValue.hbs"),(depth0 != null ? lookupProperty(depth0,"totalOrderValue") : depth0),{"name":"monetaryValue","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n  </tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/layout/isMediaTypeStandalone.js")).call(alias1,{"name":"layout/isMediaTypeStandalone","hash":{},"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":21,"column":40}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":2},"end":{"line":37,"column":9}}})) != null ? stack1 : "")
    + "  <tr>\n    <td class=\"u-border-none\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.payment.amount_to_be_paid",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":39,"column":30},"end":{"line":39,"column":72}}}))
    + ":</td>\n    <td class=\"u-border-none\" class=\"text-right\" data-test=\"orderPaymentSummary.toBePaid\">"
    + ((stack1 = container.invokePartial(require("../partials/monetaryValue.hbs"),(depth0 != null ? lookupProperty(depth0,"toBePaid") : depth0),{"name":"monetaryValue","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n  </tr>\n  </tbody>\n</table>";
},"usePartial":true,"useData":true});