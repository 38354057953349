import { Utils } from '@cc/cc-app-commons';

const _ = Utils._;
const envFeatureVariables: any = process.env.FEATURES;

function getFeature(featureName: string): any {
  return envFeatureVariables[featureName];
}

function isFeatureEnabled(feature: any) {
  return !_.isUndefined(feature) && !_.isNull(feature) && feature !== false;
}

class Feature {
  static isEnabled(featureName: string): boolean {
    const feature = getFeature(featureName);
    return isFeatureEnabled(feature);
  }

  static getDataFor(featureName: string): any {
    const feature = getFeature(featureName);
    if (!isFeatureEnabled(feature) || _.isBoolean(feature)) {
      return null;
    }

    return feature;
  }
}

export default Feature;
