import { Models } from '@cc/cc-app-commons';

enum Shops {
  BITIBA = 'bitiba',
  MEDOCA = 'medoca',
  WOLF_OF_WILDERNESS = 'wolfofwilderness',
  ZOOBEE = 'zoobee',
  ZOOPLUS = 'zooplus',
}

const shopDomainNameToShopMap = new Map<string, Shops>();
shopDomainNameToShopMap.set('bitiba', Shops.BITIBA);
shopDomainNameToShopMap.set('medoca', Shops.MEDOCA);
shopDomainNameToShopMap.set('wolf-of-wilderness', Shops.WOLF_OF_WILDERNESS);
shopDomainNameToShopMap.set('zoobee-shop', Shops.ZOOBEE);
shopDomainNameToShopMap.set('zooplus', Shops.ZOOPLUS);
shopDomainNameToShopMap.set('zoohit', Shops.ZOOPLUS);
shopDomainNameToShopMap.set('zoochic-eu', Shops.ZOOPLUS);

export default class Shop extends Models.BaseModel {
  shop: string;
  site: string;

  constructor(domain = '') {
    super();
    const [shop = '', ...site] = domain.split('.');
    this.shop = shop;
    this.site = site[site.length - 1] || '';
  }

  get flagCode() {
    const site = this.site || 'eu';
    return Models.Flag.getCodeForCountryCode(site);
  }

  get shopName() {
    const shop: Shops = shopDomainNameToShopMap.get(this.shop.toLowerCase());
    return shop || Shops.ZOOPLUS;
  }
}
