import { Order } from '../../models/Order';
import { toDefaultDateTimeFormat } from '../../util/date';

export default class OrderMetadata {
  index: number;
  customerId: number;
  hasNext: boolean;
  hasPrevious: boolean;
  order: any;

  constructor(index: number, isFirst: boolean, isLast: boolean, order: Order) {
    const { id, customerId, creationDate, shop, status } = order.toData();
    this.index = index;
    this.hasNext = !isLast;
    this.hasPrevious = !isFirst;
    this.customerId = customerId;
    this.order = {
      id,
      creationDate: toDefaultDateTimeFormat(creationDate),
      shop,
      status,
    };
  }
}
