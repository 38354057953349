import { Models } from '@cc/cc-app-commons';
import { Services } from '@cc/cc-app-commons/lib/js/view/context/types';
import KeycloakService from './KeycloakService';

type User = Models.User;

export default class ClientBasedAppUserService implements Services.UserService {
  constructor(
    private readonly _client: any,
    private readonly _keycloakService: KeycloakService,
  ) {}

  async loggedInUser(): Promise<User> {
    const loggedInFreshdeskUser = await this._client.data.get('loggedInUser');
    const loggedInKeycloakUser = this._keycloakService.getLoggedInKeycloakUser();
    return new Models.User().fromData({
      jobPosition: loggedInFreshdeskUser.loggedInUser.contact.job_title,
      freshdeskId: loggedInFreshdeskUser.loggedInUser.id,
      zooId: loggedInKeycloakUser.zooUserId,
      username: loggedInKeycloakUser.username,
      email: loggedInKeycloakUser.email,
    });
  }
}
