import { Helpers, Models } from '@cc/cc-app-commons';
import { Order } from '../models/Order';
import OrderViewModel from './OrderViewModel';
import { ViewModelInputData } from '../types';
import { ViewModel } from './types';
import OrderHeaderViewModel from './OrderHeaderViewModel';
import AppTopNavigationViewModel from './AppTopNavigationViewModel';

const { clear, createChildElement } = Helpers.ClientHelpers;
type Remover = Models.Remover;

export default class AppViewModel {
  el: Element;
  model: ViewModelInputData.AppViewModel;
  orders: Order[];
  currentOrderViewModel: OrderViewModel;
  context: ViewModel.Context;
  private orderHeaderViewModel: OrderHeaderViewModel;
  private appTopNavigationViewModel: AppTopNavigationViewModel;
  private currentOrderEventRemovers: Remover[] = [];

  constructor(domElement: Element, context: ViewModel.Context) {
    this.el = domElement;
    this.context = context;
    this.rerenderCurrentOrder = this.rerenderCurrentOrder.bind(this);
  }

  async render(): Promise<void> {
    await this.renderAppNavigation();
    await this.renderOrderHeader();
    await this.renderCurrentOrder();
  }

  destroy() {
    this.orderHeaderViewModel.destroy();
    this.appTopNavigationViewModel?.destroy(); // eslint-disable-line no-unused-expressions
    this.destroyCurrentOrder();
    clear(this.el);
  }

  private rerenderCurrentOrder() {
    this.destroyCurrentOrder();
    this.renderCurrentOrder();
  }

  private async renderAppNavigation() {
    const topNavigationConfiguration = this.context.interface.getTopNavigationConfiguration();
    if (!topNavigationConfiguration.shouldBeDisplayed) {
      return;
    }
    const wrapperElement = createChildElement(this.el);
    this.appTopNavigationViewModel = new AppTopNavigationViewModel(
      wrapperElement,
      topNavigationConfiguration.backToSearchUrl,
    );
    await this.appTopNavigationViewModel?.render();
  }

  private async renderOrderHeader() {
    const wrapperElement = createChildElement(this.el);
    this.orderHeaderViewModel = new OrderHeaderViewModel(wrapperElement, this.context);
    await this.orderHeaderViewModel.render();
  }

  private async renderCurrentOrder() {
    const order = await this.context.data.currentOrder;
    const orderWrapperElement = createChildElement(this.el);
    this.currentOrderViewModel = new OrderViewModel(orderWrapperElement, { order }, this.context);
    this.currentOrderViewModel.render();

    const onCurrentOrderChangeRemover = this.context.data.onCurrentOrderChange(
      this.rerenderCurrentOrder,
    );
    this.currentOrderEventRemovers.push(onCurrentOrderChangeRemover);
  }

  private destroyCurrentOrder() {
    this.currentOrderEventRemovers.forEach((remover: Remover) => remover.remove());
    this.currentOrderViewModel.destroy();
  }
}
