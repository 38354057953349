import { Models } from '@cc/cc-app-commons';
import OrderPosition from './OrderPosition';
import createProductAdminUrl from '../util/createProductAdminUrl';

export default class Discount extends OrderPosition {
  articleNumber = '';
  type = '';
  productId: number = undefined;

  get articleAdminUrl(): string {
    return createProductAdminUrl(this.productId);
  }

  get value(): Models.MonetaryValue {
    return this.unitMonetaryValue;
  }

  get key(): string {
    return this.type;
  }
}
