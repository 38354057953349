import { Helpers } from '@cc/cc-app-commons';
// @ts-ignore
import template from '../../../templates/order-timeline/orderPaymentTransactionTimelineEntry.hbs';
import '../../../templates/order-timeline/orderPaymentTransactionTimelineEntry.css';
import TimelineEntryViewModel from '../TimelineEntryViewModel';
import Payment, { AggregatedPaymentState } from '../../models/Payment';
import TimelineEntryStatus from './TimelineEntryStatus';
import { ViewModel } from '../types';
import Context = ViewModel.Context;

const { render } = Helpers.ClientHelpers;

export default class OrderPaymentTransactionTimelineEntryViewModel extends TimelineEntryViewModel {
  private readonly modelIndex: number;

  constructor(model: Payment, index: number) {
    super(model);
    this.modelIndex = index;
  }

  async renderFor(el: Element, context: Context): Promise<void> {
    const model = new OrderPaymentTransactionHelperModel(this.model, this.modelIndex, context);
    render(<any>el, template(await model.toModel()));
  }
}

class OrderPaymentTransactionHelperModel {
  private readonly model: Payment;
  private readonly modelIndex: number;

  constructor(
    model: Payment,
    modelIndex: number,
    private context: Context,
  ) {
    this.model = model;
    this.modelIndex = modelIndex;
  }

  async toModel(): Promise<object> {
    const payment: Payment = await this.context.data.enrichPaymentWithErrorDetails(this.model);
    const model: any = {
      ...payment.toData(),
    };
    model.status = this.getStatus();
    model.isStatusFailed = payment.aggregatedPaymentState === AggregatedPaymentState.FAILED;
    return model;
  }

  private getStatus(): any {
    return new TimelineEntryStatus(
      this.model.aggregatedPaymentState,
      this.modelIndex,
      stateToIconMap,
    );
  }
}

const stateToIconMap = new Map<string, string>();
stateToIconMap.set(AggregatedPaymentState.INITIATED, 'ok');
stateToIconMap.set(AggregatedPaymentState.AUTHORIZED, 'ok');
stateToIconMap.set(AggregatedPaymentState.CAPTURED, 'ok');
stateToIconMap.set(AggregatedPaymentState.REFUNDED, 'ok');
stateToIconMap.set(AggregatedPaymentState.CANCELLED, 'remove');
stateToIconMap.set(AggregatedPaymentState.FAILED, 'exclamation-sign');
stateToIconMap.set(AggregatedPaymentState.UNKNOWN, 'question-sign');
