export enum MediaType {
  PLUGIN = 'PLUGIN',
  STANDALONE = 'STANDALONE',
}

class MediaTypeDetector {
  private mediaType: string;

  constructor() {
    const mediaType = window
      .getComputedStyle(document.querySelector('#media-type-indicator'), ':before')
      .getPropertyValue('content');
    this.mediaType = mediaType?.replace(/[^\w]/g, '').toUpperCase();
  }

  currentMediaType(): MediaType {
    return MediaType[this.mediaType as keyof typeof MediaType];
  }

  isPlugin() {
    return this.is(MediaType.PLUGIN);
  }

  isStandalone() {
    return this.is(MediaType.STANDALONE);
  }

  private is(mediaType: MediaType) {
    return mediaType === this.mediaType;
  }
}

export default new MediaTypeDetector();
