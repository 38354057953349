var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span data-test=\"orderRelations."
    + alias2(__default(require("../helpers/formatting/lowercase.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"relationType") : depth0),{"name":"formatting/lowercase","hash":{},"data":data,"loc":{"start":{"line":1,"column":32},"end":{"line":1,"column":71}}}))
    + "\">\n  <span data-test=\"orderRelations."
    + alias2(__default(require("../helpers/formatting/lowercase.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"relationType") : depth0),{"name":"formatting/lowercase","hash":{},"data":data,"loc":{"start":{"line":2,"column":34},"end":{"line":2,"column":73}}}))
    + ".orderLink\">\n    <a class=\"oa-orderRelations__orderLink\" href=\""
    + alias2(__default(require("../helpers/independentAppOrderUrl.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"orderId") : depth0),{"name":"independentAppOrderUrl","hash":{},"data":data,"loc":{"start":{"line":3,"column":50},"end":{"line":3,"column":84}}}))
    + "\" target=\"_blank\"\n       data-test=\"orderRelations."
    + alias2(__default(require("../helpers/formatting/lowercase.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"relationType") : depth0),{"name":"formatting/lowercase","hash":{},"data":data,"loc":{"start":{"line":4,"column":33},"end":{"line":4,"column":72}}}))
    + ".orderLink\">\n      <strong>"
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"orderId") : depth0), depth0))
    + "</strong>\n    </a>\n  </span>\n  <span class=\"label label-default oa-orderRelation__relationReason\">\n    "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.header.order_relations.reason.reorder",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":9,"column":58}}}))
    + "\n  </span>\n</span>\n";
},"useData":true});