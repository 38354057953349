export default abstract class TimelineEntryViewModel {
  protected model: any;
  protected destroyFunctions: Function[] = [];

  protected constructor(model: any) {
    this.model = model;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderFor(el: Element, context: any): void {
    // this should be implemented in child classes
  }

  destroy() {
    this.runDestroyFunctions();
  }

  protected runDestroyFunctions(): void {
    this.destroyFunctions.forEach((fun) => fun());
  }
}
