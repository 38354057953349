import { Models } from '@cc/cc-app-commons';
import OrderBalance from './OrderBalance';
import Payment from './Payment';

export class OrderPayments extends Models.BaseModel {
  orderBalance: OrderBalance = null;
  transactions: Payment[] = [];

  constructor(orderBalance: OrderBalance, paymentTransactions: Payment[]) {
    super();
    this.orderBalance = orderBalance;
    this.transactions = paymentTransactions;
  }
}
