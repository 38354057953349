import { Helpers, Models } from '@cc/cc-app-commons';
import { ServerResponse } from '../../types';
import RefundCreation = ServerResponse.RefundCreation;

const { clear, renderTo } = Helpers.ClientHelpers;
const tpl = require('../../../templates/order-actions/refundCancelledOrderConfirmationModal.hbs');

export default class RefundCancelledOrderConfirmationViewModel {
  private readonly el: Element;
  private readonly refundResult: RefundCreation;

  constructor(el: Element, refundResult: RefundCreation) {
    this.el = el;
    this.refundResult = refundResult;
  }

  async render() {
    const { invoiceCurrency: currency, refundedAmountInInvoiceCurrency: amount } =
      this.refundResult;

    renderTo(
      this.el,
      tpl({
        refundAmount: new Models.MonetaryValue().fromData({
          currency,
          amount,
        }),
      }),
    );
  }

  destroy() {
    clear(this.el);
  }
}
