import OrderPosition from './OrderPosition';
import { Models } from '@cc/cc-app-commons';
import createProductAdminUrl from '../util/createProductAdminUrl';

export default class Article extends OrderPosition {
  articleNumber = '';
  shopArticleId: number = undefined;
  shopArticleUrl?: string = undefined;
  name = '';
  productId: number = undefined;

  get articleAdminUrl(): string {
    return createProductAdminUrl(this.productId);
  }

  get value(): Models.MonetaryValue {
    return this.unitMonetaryValue;
  }
}

export class ArticleLinksModel extends Models.BaseModel {
  articleAdminUrl?: string = undefined;
  shopArticleUrl?: string = undefined;

  hasAny() {
    return this.articleAdminUrl || this.shopArticleUrl;
  }
}

export class ArticleNumberModel extends Models.BaseModel {
  constructor(
    public articleNumber: string = '',
    public articleLinks: ArticleLinksModel = null,
  ) {
    super();
  }

  hasLinks() {
    return this.articleLinks?.hasAny();
  }
}
