import { Models } from '@cc/cc-app-commons';
import { Services } from '@cc/cc-app-commons/lib/js/view/context/types';
import KeycloakService from './KeycloakService';

type User = Models.User;

export default class IndependentAppUserService implements Services.UserService {
  constructor(private readonly _keycloakService: KeycloakService) {}

  async loggedInUser(): Promise<User> {
    const loggedInKeycloakUser = this._keycloakService.getLoggedInKeycloakUser();
    return new Models.User().fromData({
      zooId: loggedInKeycloakUser.zooUserId,
      email: loggedInKeycloakUser.email,
      username: loggedInKeycloakUser.username,
    });
  }
}
