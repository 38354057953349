var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-header\">\n  <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;</span></button>\n  <h4 class=\"modal-title\" id=\"oaParcelActionsModalLabel\">"
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"parcel.actions.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":57},"end":{"line":3,"column":88}}}))
    + "</h4>\n</div>\n<div class=\"modal-body oa-orderActionsModal-actions\">\n"
    + ((stack1 = container.invokePartial(require("./cancelParcel.hbs"),depth0,{"name":"./cancelParcel","hash":{"mode":"tile","isParcelCancellable":(depth0 != null ? lookupProperty(depth0,"isParcelCancellable") : depth0),"canCancelParcel":((stack1 = (depth0 != null ? lookupProperty(depth0,"authorization") : depth0)) != null ? lookupProperty(stack1,"canCancelParcel") : stack1)},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n\n";
},"usePartial":true,"useData":true});