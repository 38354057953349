var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"u-display-flex oa-subHeader oa-subHeader__flagAndStateWrapper\" data-test=\"orderHeader.shopAndState\">\n  <div class=\"oa-subHeader__labelWrapper\">\n    <span class=\"\">"
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.site",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":19},"end":{"line":3,"column":40}}}))
    + ": </span>\n"
    + ((stack1 = container.invokePartial(require("./flag.hbs"),depth0,{"name":"flag","hash":{"flagTitle":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"shop") : stack1)) != null ? lookupProperty(stack1,"flagCode") : stack1),"flagCode":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"shop") : stack1)) != null ? lookupProperty(stack1,"flagCode") : stack1)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"usePartial":true,"useData":true});