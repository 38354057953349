import { Helpers, Models, Services, Utils } from '@cc/cc-app-commons';
import '../../templates/order-switcher/orderSwitcher.css';
import OrderMetadata from './order/OrderMetadata';
import { ViewModel } from './types';
import { Order } from '../models/Order';
import SelectedOrderLabelViewModel from './SelectedOrderLabelViewModel';
import { setUrlQueryValue } from '../util/DocumentLocationModifier';
import { openInNewWindow } from '../util/WindowOpener';
import mediaTypeDetector from '../util/MediaTypeDetector';
import SubscriptionViewModel from './orderHeader/SubscriptionViewModel';
import FraudIconViewModel from './orderHeader/FraudIconViewModel';
import { resolveCCAdminUrl } from '../helpers/connectionTypeResolver';
import AppActivityAction = Services.AppActivityAction;
import _ = Utils._;

const tpl = require('../../templates/order-switcher/orderSwitcher.hbs');
const ClipboardHelper = Helpers.ClipboardHelper;

type Remover = Models.Remover;
type ClipboardHelper = Helpers.ClipboardHelper;

const { clear, render } = Helpers.ClientHelpers;

export default class OrderSwitcherView {
  private static TOOLTIP_ELEMENT_SELECTOR = '.oa-orderSwitcher .oa-tooltipWrapper';
  private static ORDER_CHOOSER_TOOLTIP_ELEMENT_SELECTOR =
    '.oa-orderSwitcher .oa-orderSwitcher-modalOpener';
  private static ORDER_ID_COPY_BUTTON_ELEMENT_SELECTOR = '.oa-orderSwitcher__copyOrderIdButton';
  private static ORDER_CCA_LINK_ELEMENT_SELECTOR = '.oa-orderSwitcher__orderLink';
  private static SUBSCRIPTION_ELEMENT_SELECTOR = '[component="--subscriptionInfo"]';
  private static SELECTED_ORDER_LABEL_COMPONENT_SELECTOR = '[component="--selectedOrderLabel"]';
  private static FRAUD_ICON_ELEMENT_SELECTOR = '[component="--fraudIcon"]';
  private static INDICATOR_WRAPPER_SELECTOR = `.oa-orderSwitcher-orderProblemIndicatorTooltipWrapper,
   ${OrderSwitcherView.SUBSCRIPTION_ELEMENT_SELECTOR}, 
   ${OrderSwitcherView.FRAUD_ICON_ELEMENT_SELECTOR}`;

  private el: Element;
  eventRemovers: Remover[] = [];
  ordersMetadata: OrderMetadata[];
  private selectedOrderLabelViewModel: SelectedOrderLabelViewModel;
  private clipboardHelper: ClipboardHelper;
  private context: ViewModel.Context;
  private subscriptionViewModel: SubscriptionViewModel;
  private fraudIconViewModel: FraudIconViewModel;

  constructor(domElement: Element, context: ViewModel.Context) {
    this.el = domElement;
    this.context = context;
  }

  async render(): Promise<void> {
    this.ensureDataLoaded();
    const tooltipConfig = await this.getTooltipConfiguration();
    const ccAdminUrl = await resolveCCAdminUrl();

    const parentElement = this.el.parentElement;
    const currentOrder = await this.context.data.currentOrder;
    const currentOrderMetadata = this.ordersMetadata.find(
      (metadata: OrderMetadata) => metadata.order.id === currentOrder.id,
    );
    render(
      this.el,
      tpl({
        ordersMetadata: this.ordersMetadata,
        currentOrderMetadata: currentOrderMetadata,
        url: ccAdminUrl,
        tooltipConfig,
      }),
    );
    this.el = parentElement.querySelector('.oa-orderSwitcher');

    if (mediaTypeDetector.isStandalone()) {
      this.updateOrderIdInUrl(currentOrder.id);
    }
    this.renderSelectedOrderLabel(currentOrderMetadata, tooltipConfig, ccAdminUrl);
    this.attachOrderDropdownListener((e: Event) => {
      const orderId = Number((e.currentTarget as HTMLSelectElement).dataset.orderId);
      this.setOrder(orderId);
    });

    const onCurrentOrderChangeRemover = this.context.data.onCurrentOrderChange(
      this.onCurrentOrderChange.bind(this),
    );
    this.eventRemovers.push(onCurrentOrderChangeRemover);

    this.renderSubscriptionIcon();
    this.renderFraudIcon();
    this.initHeaderTooltips();
    this.initOrderIdCopyButton();
    this.stopPropagationForIndicatorToNotTriggerStandaloneOpener();
    this.initCcaLinkClickListeners();
  }

  private async renderFraudIcon() {
    if (mediaTypeDetector.isPlugin()) {
      const componentElement = this.el.querySelector(OrderSwitcherView.FRAUD_ICON_ELEMENT_SELECTOR);
      this.fraudIconViewModel = new FraudIconViewModel(componentElement, this.context);
      this.fraudIconViewModel.render();
    }
  }

  private async renderSubscriptionIcon() {
    if (mediaTypeDetector.isPlugin()) {
      const componentElement = this.el.querySelector(
        OrderSwitcherView.SUBSCRIPTION_ELEMENT_SELECTOR,
      );
      this.subscriptionViewModel = new SubscriptionViewModel(componentElement, this.context);
      this.subscriptionViewModel.render();
    }
  }

  private updateOrderIdInUrl(orderId: number) {
    setUrlQueryValue('orderId', `${orderId}`);
  }

  private renderSelectedOrderLabel(
    orderMetadata: OrderMetadata,
    tooltipConfig: Object,
    ccAdminUrl: string,
  ) {
    const componentElement = this.el.querySelector(
      OrderSwitcherView.SELECTED_ORDER_LABEL_COMPONENT_SELECTOR,
    );
    this.selectedOrderLabelViewModel = new SelectedOrderLabelViewModel(
      componentElement,
      this.context,
      orderMetadata,
      tooltipConfig,
      ccAdminUrl,
    );
    this.selectedOrderLabelViewModel.render();
  }

  private initOrderIdCopyButton() {
    this.el
      .querySelectorAll(OrderSwitcherView.ORDER_ID_COPY_BUTTON_ELEMENT_SELECTOR)
      .forEach((el) => {
        this.clipboardHelper = new ClipboardHelper(el, 'order.header.copy_orderid_button');
        el.addEventListener('click', this.notifyOrderIdCopyButtonClicked.bind(this));
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private notifyOrderIdCopyButtonClicked(evt: Event): void {
    this.context.metric.logAppActivity(AppActivityAction.ORDER_ID_COPY_BUTTON__CLICKED);
  }

  private async ensureDataLoaded() {
    this.ordersMetadata = await this.getOrderMetadata();
  }

  private async getOrderMetadata(): Promise<OrderMetadata[]> {
    const orders = await this.context.data.orders;
    return orders.map((order: Order, index: number) => {
      return new OrderMetadata(index, !orders[index - 1], !orders[index + 1], order);
    });
  }

  private async getTooltipConfiguration(): Promise<Object> {
    const orderResolutionMetadata = await this.context.data.orderResolutionMetadata(
      await this.context.data.currentOrder,
    );
    return {
      content: orderResolutionMetadata.content,
      alertType: orderResolutionMetadata.alertType,
    };
  }

  destroy() {
    this.clipboardHelper?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.selectedOrderLabelViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.subscriptionViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.fraudIconViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.removeListeners();
    clear(this.el);
  }

  private renderForCurrentOrder() {
    this.destroy();
    this.render();
  }

  private attachOrderDropdownListener(callback: (e: Event) => any) {
    this.el
      .querySelectorAll('.oa-orderSwitcherModal .oa-orderSwitcherModal-order')
      .forEach((element) => {
        element.addEventListener('click', callback);
        this.eventRemovers.push(
          Models.Remover.createFor(
            function (): void {
              element.removeEventListener('change', callback);
          }.bind(this) // eslint-disable-line
          ),
        );
      });
  }

  private setOrder(orderId: number) {
    this.context.data.changeCurrentOrderTo(orderId);
  }

  onCurrentOrderChange() {
    this.renderForCurrentOrder();
  }

  private initHeaderTooltips() {
    $(this.el.querySelector(OrderSwitcherView.TOOLTIP_ELEMENT_SELECTOR)).tooltip();
    $(this.el.querySelector(OrderSwitcherView.ORDER_CHOOSER_TOOLTIP_ELEMENT_SELECTOR)).tooltip();
  }

  private stopPropagationForIndicatorToNotTriggerStandaloneOpener() {
    const callback = (evt: Event) => {
      evt.stopPropagation();
    };
    this.el.querySelectorAll(OrderSwitcherView.INDICATOR_WRAPPER_SELECTOR).forEach((element) => {
      element.addEventListener('mouseover', callback);
      this.eventRemovers.push(
        Models.Remover.createFor(
          function (): void {
            element.removeEventListener('mouseover', callback);
          }.bind(this) // eslint-disable-line
        ),
      );
    });
  }

  private async initCcaLinkClickListeners() {
    const callback = (event: Event) => {
      event.preventDefault();

      this.context.feedback.onCcAdminLinkClicked();
      this.context.metric.incrementCcadminLinkClickedMetric();
      this.context.metric.logAppActivity(AppActivityAction.CC_ADMIN_LINK__CLICKED);

      _.delay(openInNewWindow, 0, (event.currentTarget as HTMLAnchorElement).href);
    };

    this.el
      .querySelectorAll(OrderSwitcherView.ORDER_CCA_LINK_ELEMENT_SELECTOR)
      .forEach((element) => {
        element.addEventListener('click', callback);
        this.eventRemovers.push(
          Models.Remover.createFor(
            function (): void {
              element.removeEventListener('click', callback);
          }.bind(this) // eslint-disable-line
          ),
        );
      });
  }

  private removeListeners() {
    $(this.el.querySelector(OrderSwitcherView.TOOLTIP_ELEMENT_SELECTOR)).tooltip('destroy');
    $(this.el.querySelector(OrderSwitcherView.ORDER_CHOOSER_TOOLTIP_ELEMENT_SELECTOR)).tooltip(
      'destroy',
    );
    this.eventRemovers.forEach((remover: Remover) => remover.remove());
  }
}
