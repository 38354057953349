var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "btn-block";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-refundCancelledOrderConfirmationModalContent\" data-test=\"refundCancelledOrderConfirmationModalContent\">\n  <div class=\"modal-header\">\n"
    + ((stack1 = container.invokePartial(require("../common/closeModalButton.hbs"),depth0,{"name":"../common/closeModalButton","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <h4 class=\"modal-title\" id=\"oaOrderActionsModalLabel\" data-test=\"refundCancelledOrderConfirmationModalContent.title\">\n      "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.actions.cancellation.modal.refund_confirmation.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":5,"column":75}}}))
    + "\n    </h4>\n  </div>\n  <div class=\"modal-body u-text-centered\" data-test=\"refundCancelledOrderConfirmationModalContent.message\">\n    <span data-test=\"refundCancelledOrderConfirmationModalContent.message.header\">\n      "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.actions.cancellation.modal.refund_confirmation.message",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":10,"column":77}}}))
    + "\n    </span>\n    <div class=\"u-m-m\" data-test=\"refundCancelledOrderConfirmationModalContent.message.refundAmount\">"
    + ((stack1 = container.invokePartial(require("../partials/monetaryValue.hbs"),(depth0 != null ? lookupProperty(depth0,"refundAmount") : depth0),{"name":"monetaryValue","hash":{"textAlign":"centered"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n  </div>\n  <div class=\"modal-footer u-text-centered\" data-test=\"refundCancelledOrderConfirmationModalContent.footer\">\n    <button class=\"btn btn-primary btm-sm "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/layout/isMediaTypePlugin.js")).call(alias1,{"name":"layout/isMediaTypePlugin","hash":{},"data":data,"loc":{"start":{"line":17,"column":48},"end":{"line":17,"column":76}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":42},"end":{"line":17,"column":94}}})) != null ? stack1 : "")
    + "\"\n            data-test=\"refundCancelledOrderConfirmationModalContent.footer.btnClose\"\n            data-dismiss=\"modal\"\n    >\n      "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"default.actions.close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":21,"column":6},"end":{"line":21,"column":38}}}))
    + "\n    </button>\n  </div>\n</div>";
},"usePartial":true,"useData":true});