var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "btn-block";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-refundCancelledOrderModalContent\" data-test=\"refundCancelledOrderModalContent\">\n  <div class=\"modal-body loader\">\n    <img src=\"spinner.gif\"/>\n  </div>\n\n  <div class=\"modal-header\">\n"
    + ((stack1 = container.invokePartial(require("../common/closeModalButton.hbs"),depth0,{"name":"../common/closeModalButton","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <h4 class=\"modal-title\" id=\"oaOrderActionsModalLabel\" data-test=\"refundCancelledOrderModalContent.title\">\n      "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.actions.cancellation.modal.refund.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":9,"column":62}}}))
    + "\n    </h4>\n  </div>\n  <div class=\"modal-body u-text-centered\" data-test=\"refundCancelledOrderModalContent.message\">\n    <span data-test=\"refundCancelledOrderModalContent.message.header\">\n      "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.actions.cancellation.modal.refund.message",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":6},"end":{"line":14,"column":64}}}))
    + "\n    </span>\n    <div class=\"u-m-m\" data-test=\"refundCancelledOrderModalContent.message.refundAmount\">"
    + ((stack1 = container.invokePartial(require("../partials/monetaryValue.hbs"),(depth0 != null ? lookupProperty(depth0,"refundAmount") : depth0),{"name":"monetaryValue","hash":{"textAlign":"centered"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n  </div>\n  <div class=\"modal-footer u-text-centered\" data-test=\"refundCancelledOrderModalContent.footer\">\n    <button class=\"btn btn-default btm-sm "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/layout/isMediaTypePlugin.js")).call(alias1,{"name":"layout/isMediaTypePlugin","hash":{},"data":data,"loc":{"start":{"line":21,"column":48},"end":{"line":21,"column":76}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":42},"end":{"line":21,"column":94}}})) != null ? stack1 : "")
    + "\"\n            data-test=\"refundCancelledOrderModalContent.footer.btnClose\"\n            data-dismiss=\"modal\"\n    >\n      "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"default.actions.close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":25,"column":6},"end":{"line":25,"column":38}}}))
    + "\n    </button>\n\n    <button class=\"btn btn-primary btm-sm "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/layout/isMediaTypePlugin.js")).call(alias1,{"name":"layout/isMediaTypePlugin","hash":{},"data":data,"loc":{"start":{"line":28,"column":48},"end":{"line":28,"column":76}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":42},"end":{"line":28,"column":94}}})) != null ? stack1 : "")
    + " oa-refundCancelledOrderModalContent__refund\"\n            data-test=\"refundCancelledOrderModalContent.footer.btnAutomaticRefund\"\n    >\n      "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.actions.cancellation.modal.refund.refund_button",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":31,"column":6},"end":{"line":31,"column":70}}}))
    + "\n    </button>\n  </div>\n</div>";
},"usePartial":true,"useData":true});