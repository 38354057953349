import { Models } from '@cc/cc-app-commons';

export enum ClaimObligationType {
  UNKNOWN = 'UNKNOWN',
  KEEP = 'KEEP',
  RETURN = 'RETURN',
}

export enum ClaimCompensationType {
  CREDIT = 'CREDIT',
  REFUND = 'REFUND',
  REDELIVERY = 'REDELIVERY',
  VOUCHER = 'VOUCHER',
  NONE = 'NONE',
  UNKNOWN = 'UNKNOWN',
}

class ClaimObligation extends Models.BaseModel {
  name: ClaimObligationType = undefined;
}

class ClaimCompensation extends Models.BaseModel {
  name: ClaimCompensationType = undefined;
}

export class ClaimResolution extends Models.BaseModel {
  obligation: ClaimObligation = undefined;
  compensation: ClaimCompensation = undefined;
}
