import { Models } from '@cc/cc-app-commons';
import { ServerResponse } from '../types';
import { ClaimResolution } from './ClaimResolution';

class ClaimLink extends Models.BaseModel {
  rel = '';
  href = '';
}

export default class Claim extends Models.BaseModel {
  claimId: number = undefined;
  creationDate = '';
  resolution: ClaimResolution = undefined;
  status = '';
  links: ClaimLink[] = [];

  get isResolved(): boolean {
    return this.status === 'RESOLVED';
  }

  fromData(json: ServerResponse.Claim) {
    super.fromData(json);
    this.resolution = json.resolution ? new ClaimResolution().fromData(json.resolution) : undefined;
    return this;
  }
}
