import { Helpers, Services } from '@cc/cc-app-commons';
// @ts-ignore
import tpl from '../../templates/order-addresses/orderAddresses.hbs';
import { ViewModel } from './types';
import { Order } from '../models/Order';
import Address from '../models/Address';
import ModifyShippingAddressViewModel from './addressActions/ModifyShippingAddressViewModel';
import ModifyBillingAddressViewModel from './addressActions/ModifyBillingAddressViewModel';
import AddressModificationActionsModalHelper from './addressActions/AddressModificationActionsModalHelper';

const AppActivityAction = Services.AppActivityAction;
const { clear, renderTo } = Helpers.ClientHelpers;

export default class OrderAddressesViewModel {
  private readonly el: HTMLElement;
  private readonly context: ViewModel.Context;
  private readonly order: Order;

  private static MODIFY_SHIPPING_ADDRESS_BUTTON_SELECTOR =
    '.oa-orderAddressActionButton__modifyShippingAddress a';

  private static MODIFY_BILLING_ADDRESS_BUTTON_SELECTOR =
    '.oa-orderAddressActionButton__modifyBillingAddress a';

  constructor(domElement: HTMLElement, order: Order, context: ViewModel.Context) {
    this.el = domElement;
    this.context = context;
    this.order = order;
  }

  async render() {
    const order = await this.context.data.currentOrder;
    renderTo(
      this.el,
      tpl({
        isShippingAddressModifiable: order.shippingAddressModifiable,
        shippingAddress: this.toTemplateModel(
          'order.addresses.delivery.label',
          this.order.shippingAddress,
        ),
        isBillingAddressModifiable: order.billingAddressModifiable,
        billingAddress: this.toTemplateModel(
          'order.addresses.invoice.label',
          this.order.billingAddress,
        ),
      }),
    );
    this.attachPluginsAndEventHandlers();
  }

  private toTemplateModel(labelI18nKey: string, address: Address): object {
    return {
      labelI18nKey: labelI18nKey,
      address: address?.toData(),
    };
  }

  private attachPluginsAndEventHandlers() {
    this.el.querySelectorAll('.oa-orderAddressActionButton').forEach((el) => $(el).tooltip());

    this.addEventListenerForShippingAddressModificationButton();
    this.addEventListenerForBillingAddressModificationButton();
  }

  private addEventListenerForShippingAddressModificationButton() {
    const onShippingAddressModificationActionButtonClicked =
      this.proceedToShippingAddressModificationAction.bind(this);

    const shippingAddressModificationActionButton = this.el.querySelector(
      OrderAddressesViewModel.MODIFY_SHIPPING_ADDRESS_BUTTON_SELECTOR,
    );

    if (shippingAddressModificationActionButton) {
      shippingAddressModificationActionButton.addEventListener(
        'click',
        onShippingAddressModificationActionButtonClicked,
      );
    }
  }

  private addEventListenerForBillingAddressModificationButton() {
    const onBillingAddressModificationActionButtonClicked =
      this.proceedToBillingAddressModificationAction.bind(this);

    const billingAddressModificationActionButton = this.el.querySelector(
      OrderAddressesViewModel.MODIFY_BILLING_ADDRESS_BUTTON_SELECTOR,
    );

    if (billingAddressModificationActionButton) {
      billingAddressModificationActionButton.addEventListener(
        'click',
        onBillingAddressModificationActionButtonClicked,
      );
    }
  }

  private proceedToShippingAddressModificationAction(e: Event) {
    e.preventDefault();
    this.context.metric.logAppActivity(
      AppActivityAction.SHIPPING_ADDRESS_CHANGE_OPEN_MODAL_BUTTON__CLICKED,
    );
    const modalWrapper = AddressModificationActionsModalHelper.getModal();
    modalWrapper.renderContainer(
      ModifyShippingAddressViewModel,
      this.context,
      this.onAddressChange.bind(this),
    );
    modalWrapper.open();
  }

  private proceedToBillingAddressModificationAction(e: Event) {
    e.preventDefault();
    this.context.metric.logAppActivity(
      AppActivityAction.BILLING_ADDRESS_CHANGE_OPEN_MODAL_BUTTON__CLICKED,
    );
    const modalWrapper = AddressModificationActionsModalHelper.getModal();
    modalWrapper.renderContainer(
      ModifyBillingAddressViewModel,
      this.context,
      this.onAddressChange.bind(this),
    );
    modalWrapper.open();
  }

  private async onAddressChange() {
    this.context.data.refreshCurrentOrder();
    this.rerender();
  }

  private rerender() {
    this.destroy();
    this.render();
  }

  destroy(): void {
    clear(this.el);
  }
}
