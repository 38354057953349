import { Models, Utils } from '@cc/cc-app-commons';
import { toDefaultDateFormat } from '../util/date';
import ParcelWeight from './ParcelWeight';
import ParcelArticle from './ParcelArticle';
import { ServerResponse } from '../types';
import ParcelPhysicalArticle from './ParcelPhysicalArticle';
import { TemplateData } from './types';
import ParcelPosition = ServerResponse.ParcelPosition;

const { chain } = Utils._;

export default class OrderParcel extends Models.BaseModel implements TemplateData {
  private static PARCEL_WEIGHTS_DO_NOT_MATCH_WARNING_THRESHOLD: ParcelWeight =
    new ParcelWeight().fromData({ value: 0.4, unit: 'KG' });

  id: number = undefined;
  isVirtual: boolean = undefined;
  status = '';
  dspName = '';
  trackingNumber?: string = undefined;
  fulfilmentCenter?: string = undefined;
  parcelPoolExitDate = '';
  shipmentDate = '';
  truckDepartureDate = '';
  deliveryDate = '';
  trackingLink?: string = undefined;
  calculatedWeight?: ParcelWeight = undefined;
  realWeight?: ParcelWeight = undefined;
  parcelArticles: ParcelArticle[] = [];
  private _parcelPositions: ParcelPosition[];

  fromData(parcel: ServerResponse.Parcel, allArticlesInOrder: ServerResponse.Article[]) {
    super.fromData(parcel);
    this._parcelPositions = parcel.parcelPositions;
    this.parcelArticles = chain(this._parcelPositions)
      .groupBy((parcelPosition: ParcelPosition) => parcelPosition.orderPositionId)
      .map((parcelPositions: ParcelPosition[], orderPositionId: string) => {
        const article = this.articleByOrderPositionId(allArticlesInOrder, orderPositionId);
        if (!article) {
          console.warn(`Order position ${orderPositionId} wasn't found in order articles`);
          return null;
        }
        return new ParcelArticle().fromData({
          name: article.name,
          articleNumber: article.articleNumber,
          productId: article.productId,
          parcelPhysicalArticles: this.parcelPositionToPhysicalArticles(parcelPositions),
          shopArticleUrl: article.shopArticleUrl,
        });
      })
      .filter((it) => !!it)
      .value();
    this.calculatedWeight =
      parcel.calculatedWeight && new ParcelWeight().fromData(parcel.calculatedWeight);
    this.realWeight = parcel.realWeight && new ParcelWeight().fromData(parcel.realWeight);
    return this;
  }

  toData(...context: any): any {
    const json = super.toData(...context);
    json.parcelPositions = this._parcelPositions;
    return json;
  }

  toTemplateData(...context: any): any {
    return this.toData(...context);
  }

  private articleByOrderPositionId(articles: ServerResponse.Article[], orderPositionId: string) {
    return articles.find((article) => `${article.orderPositionId}` === orderPositionId);
  }

  private parcelPositionToPhysicalArticles(parcelPositions: ServerResponse.ParcelPosition[]) {
    return parcelPositions.map((parcelPosition) =>
      new ParcelPhysicalArticle().fromData({
        orderPositionId: parcelPosition.orderPositionId,
        articleId: parcelPosition.physicalArticleId,
        quantity: parcelPosition.physicalArticleQuantity,
      }),
    );
  }

  get parsedPoolExitDate(): string {
    if (!this.parcelPoolExitDate) {
      return null;
    }
    return toDefaultDateFormat(this.parcelPoolExitDate);
  }

  get parsedShipmentDate(): string {
    if (!this.shipmentDate) {
      return null;
    }
    return toDefaultDateFormat(this.shipmentDate);
  }

  get parsedTruckDepartureDate(): string {
    if (!this.truckDepartureDate) {
      return null;
    }
    return toDefaultDateFormat(this.truckDepartureDate);
  }

  get parsedDeliveryDate(): string {
    if (!this.deliveryDate) {
      return null;
    }
    return toDefaultDateFormat(this.deliveryDate);
  }

  get isCalcAndRealWeightSignificantlyDifferent(): boolean {
    if (!this.realWeight || !this.calculatedWeight) {
      return false;
    }
    const weightDifferenceRounded = Math.round(
      Math.abs(this.realWeight.value - this.calculatedWeight.value) * 100,
    );
    const thresholdRounded = Math.round(
      OrderParcel.PARCEL_WEIGHTS_DO_NOT_MATCH_WARNING_THRESHOLD.value * 100,
    );
    return weightDifferenceRounded >= thresholdRounded;
  }
}
