import { Helpers } from '@cc/cc-app-commons';
import { ViewModel } from '../types';
import mediaTypeDetector from '../../util/MediaTypeDetector';
import ParcelActionButtonsViewModel from './ParcelActionButtonsViewModel';
import ParcelActionsModalHelper from './ParcelActionsModalHelper';
import OrderParcel from '../../models/OrderParcel';

const { clear, renderTo } = Helpers.ClientHelpers;

const tplPlugin = require('../../../templates/parcel-actions/plugin.hbs');
const tplStandalone = require('../../../templates/parcel-actions/standalone.hbs');

export default class ParcelActionsViewModel {
  static ACTIONS_MENU_BUTTON_ELEMENT_SELECTOR = '.oa-parcelActions__actionButtonsMenuButton';

  private readonly el: Element;
  private readonly context: ViewModel.Context;
  private readonly parcel: OrderParcel;
  private parcelActionButtonsViewModel: ParcelActionButtonsViewModel;

  constructor(domElement: Element, context: ViewModel.Context, parcel: OrderParcel) {
    this.el = domElement;
    this.context = context;
    this.parcel = parcel;
  }

  async render(): Promise<void> {
    const tpl = mediaTypeDetector.isPlugin() ? tplPlugin : tplStandalone;
    renderTo(
      this.el,
      tpl({
        authorization: {
          canPerformParcelActions: this.context.authorization.isAuthorizedToPerformParcelActions(),
        },
      }),
    );
    await this.renderParcelActionButtons();
  }

  private async renderParcelActionButtons() {
    if (!this.context.authorization.isAuthorizedToPerformParcelActions()) {
      return;
    }

    if (mediaTypeDetector.isPlugin()) {
      await this.renderParcelActionButtonsInModal();
    }
    if (mediaTypeDetector.isStandalone()) {
      const containerElement = this.el.querySelector('.oa-parcelActionsModal-actions');
      this.parcelActionButtonsViewModel = new ParcelActionButtonsViewModel(
        containerElement,
        this.context,
        this.parcel,
      );
      await this.parcelActionButtonsViewModel.render();
    }
  }

  private renderParcelActionButtonsInModal() {
    const modalOpener = this.el.querySelector(
      ParcelActionsViewModel.ACTIONS_MENU_BUTTON_ELEMENT_SELECTOR,
    );
    modalOpener.addEventListener('click', () => {
      const modalWrapper = ParcelActionsModalHelper.getModal();
      modalWrapper.renderContainer(ParcelActionButtonsViewModel, this.context, this.parcel);
      modalWrapper.open();
    });
    $(modalOpener).tooltip();
  }

  destroy() {
    if (mediaTypeDetector.isPlugin()) {
      $(this.el.querySelector(ParcelActionsViewModel.ACTIONS_MENU_BUTTON_ELEMENT_SELECTOR)).tooltip(
        'destroy',
      );
    }
    this.parcelActionButtonsViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    clear(this.el);
  }
}
