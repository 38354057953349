import { Models, Services } from '@cc/cc-app-commons';
import ClaimDraft from '../../models/ClaimDraft';
import { ServerResponse } from '../../types';

export class ClaimDraftService {
  connector: Services.KeycloakSecuredZooServicesConnector;
  gateway: string;

  constructor(connector: Services.KeycloakSecuredZooServicesConnector, gateway: string) {
    this.connector = connector;
    this.gateway = gateway;
  }

  async getClaimDraftsForOrder(suborderId: string): Promise<ClaimDraft[]> {
    try {
      const claimDraftsResponse =
        (await this.connector?.fetchData<ServerResponse.ClaimDraft[]>(
          this.gateway,
          `claim-drafts-api/orders/${suborderId}/claim-drafts`,
        )) || [];
      return claimDraftsResponse.map((claimDraftResponse) =>
        new ClaimDraft().fromData(claimDraftResponse),
      );
    } catch (err) {
      throw new Models.Errors.UnexpectedAppError(err);
    }
  }
}
