import { Models } from '@cc/cc-app-commons';

enum OrderCancellationErrors {
  GENERAL_ORDER_CANCELLATION_ERROR = 'general_order_cancellation_error',
  ORDER_NOT_FOUND_ERROR = 'order_not_found_error',
  ORDER_NOT_CANCELLABLE_ERROR = 'order_not_cancellable_error',
  ORDER_ALREADY_CANCELLED_ERROR = 'order_already_cancelled_error',
}

export class OrderCancellationError extends Models.Errors.AppError {
  constructor(
    originError: any,
    orderCancellationErrorMessageCode: string = OrderCancellationErrors.GENERAL_ORDER_CANCELLATION_ERROR,
  ) {
    super(null, orderCancellationErrorMessageCode, originError);
  }
}

export class OrderNotFoundError extends OrderCancellationError {
  constructor(originError: any) {
    super(originError, OrderCancellationErrors.ORDER_NOT_FOUND_ERROR);
  }
}

export class OrderNotCancellableError extends Models.Errors.AppError {
  constructor(originError: any) {
    super(originError, OrderCancellationErrors.ORDER_NOT_CANCELLABLE_ERROR);
  }
}

export class OrderAlreadyCancelledError extends Models.Errors.AppError {
  constructor(originError: any) {
    super(originError, OrderCancellationErrors.ORDER_ALREADY_CANCELLED_ERROR);
  }
}

enum OrderCancellationRefundErrors {
  GENERAL_ORDER_CANCELLATION_REFUND_ERROR = 'general_order_cancellation_refund_error',
  ORDER_BALANCE_INFORMATION_NOT_AVAILABLE_ERROR = 'order_balance_information_not_available_error',
}

export class OrderCancellationRefundError extends OrderCancellationError {
  constructor(originError: any) {
    super(originError, OrderCancellationRefundErrors.GENERAL_ORDER_CANCELLATION_REFUND_ERROR);
  }
}

export class OrderBalanceInformationNotAvailableError extends OrderCancellationError {
  constructor(originError?: any) {
    super(originError, OrderCancellationRefundErrors.ORDER_BALANCE_INFORMATION_NOT_AVAILABLE_ERROR);
  }
}
