import { Models, Services } from '@cc/cc-app-commons';
import Claim from '../../models/Claim';
import { ServerResponse } from '../../types';

export class ClaimService {
  connector: Services.KeycloakSecuredZooServicesConnector;
  gateway: string;

  constructor(connector: Services.KeycloakSecuredZooServicesConnector, gateway: string) {
    this.connector = connector;
    this.gateway = gateway;
  }

  async getClaimsForSuborder(suborderId: string): Promise<Claim[]> {
    try {
      const query = {
        orderId: suborderId,
      };
      const claimsResponse = await this.connector.fetchData<ServerResponse.Claim[]>(
        this.gateway,
        'claim-api/v3/claims',
        query,
      );
      return claimsResponse.map((claimResponse) => new Claim().fromData(claimResponse));
    } catch (err) {
      throw new Models.Errors.UnexpectedAppError(err);
    }
  }
}
