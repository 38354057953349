import { Models } from '@cc/cc-app-commons';

export default class OrderTimelineModel extends Models.BaseModel {
  mails: Models.Mail[];

  constructor(mails: Models.Mail[]) {
    super();
    this.mails = mails;
  }
}
