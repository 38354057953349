/* global hj */
import { ViewModel } from '../../types';

// @ts-ignore
const HOTJAR_ENABLED: boolean = process.env.HOTJAR_ENABLED;

export class AppFeedbackContext implements ViewModel.FeedbackContext {
  onCcAdminLinkClicked(): void {
    this.isFeedbackFrameworkEnvSetUp() && this.triggerFeedbackForCcAdminLinkClicked();
  }

  triggerClaimsSectionSurvey(): void {
    this.isFeedbackFrameworkEnvSetUp() && this.triggerClaimsSectionSatisfactionSurvey();
  }

  private triggerFeedbackForCcAdminLinkClicked() {
    this.hotjar('trigger', 'cc-admin_link_clicked');
  }

  private triggerClaimsSectionSatisfactionSurvey() {
    this.hotjar('trigger', 'trigger_claim_section_satisfaction_survey');
  }

  private isFeedbackFrameworkEnvSetUp() {
    return HOTJAR_ENABLED && this.hotjar;
  }

  private get hotjar() {
    return hj; /* stands for HotJar tooling */
  }
}
