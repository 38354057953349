import { Models } from '@cc/cc-app-commons';
import { FreshdeskTicketContextAppError } from '../../models/Errors';

const { Ticket } = Models;
const systemTicketCustomerIdFieldName = systemTicketCustomFieldName('customer_id');
const systemTicketOrderIdFieldName = systemTicketCustomFieldName('order_id');

function systemTicketCustomFieldName(name: string): string {
  return `cf_${name}`;
}

export class TicketService {
  private client: any;

  constructor(client: any) {
    this.client = client;
  }

  async getTicketDataFromSystem() {
    function getTicketTypeDetails(ticket: any) {
      const ticketCustomFields = ticket.custom_fields ?? {};
      const nonNullTypeDetailsKey = Object.keys(ticketCustomFields)
        .filter((key) => key.startsWith('cf_details'))
        .find((key) => ticketCustomFields[key]);
      return ticketCustomFields[nonNullTypeDetailsKey];
    }

    let ticket, contact;
    try {
      ticket = (await this.client.data.get('ticket')).ticket;
      contact = (await this.client.data.get('contact')).contact;
    } catch (e) {
      throw new FreshdeskTicketContextAppError(e);
    }

    const {
      custom_fields: {
        [systemTicketCustomerIdFieldName]: customerId,
        [systemTicketOrderIdFieldName]: orderId,
      },
    } = ticket;

    return new Ticket().fromData({
      id: ticket.id,
      customerId,
      orderId,
      productId: ticket.product_id,
      contactReason: ticket.type,
      contactSubReason: getTicketTypeDetails(ticket),
      customerEmail: contact.email,
      customerPhone: contact.phone || contact.mobile,
      sourceCode: ticket.source,
      statusCode: ticket.status,
    });
  }
}
