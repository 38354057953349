import '../../../templates/order-timeline/orderTimeline.css';
import OrderTimelineViewModel from '../OrderTimelineViewModel';
import TimelineEntryViewModel from '../TimelineEntryViewModel';
import { OrderPayments } from '../../models/OrderPayments';
import Payment from '../../models/Payment';
import OrderPaymentTransactionTimelineEntryViewModel from './OrderPaymentTransactionTimelineEntryViewModel';

export default class OrderPaymentsTimelineViewModel extends OrderTimelineViewModel {
  get timelineTitleTranslationKey(): string {
    return 'payments';
  }

  async getTimelineViewModels(): Promise<TimelineEntryViewModel[]> {
    return this.context.data.orderPayments(this.order.id).then((orderPayments: OrderPayments) => {
      const transactions = [...orderPayments.transactions];
      return transactions
        .sort((t1: Payment, t2: Payment) => t2.paymentId - t1.paymentId)
        .map((model: Payment, index: number) => {
          return new OrderPaymentTransactionTimelineEntryViewModel(model, index);
        });
    });
  }
}
