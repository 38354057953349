import { Models } from '@cc/cc-app-commons';

const GATEWAY_PRIVATE_URL = process.env.GATEWAY_PRIVATE_URL;
const GATEWAY_PUBLIC_URL = process.env.GATEWAY_PUBLIC_URL;
const ARTICLE_ADMIN_PRIVATE_URL = process.env.ARTICLE_ADMIN_PRIVATE_URL;
const ARTICLE_ADMIN_PUBLIC_URL = process.env.ARTICLE_ADMIN_PUBLIC_URL;
const CC_ADMIN_PRIVATE_URL = process.env.CC_ADMIN_PRIVATE_URL;
const CC_ADMIN_PUBLIC_URL = process.env.CC_ADMIN_PUBLIC_URL;

export async function resolveArticleAdminUrl() {
  return (await isPrivateConnection()) ? ARTICLE_ADMIN_PRIVATE_URL : ARTICLE_ADMIN_PUBLIC_URL;
}
export async function resolveCCAdminUrl() {
  return (await isPrivateConnection()) ? CC_ADMIN_PRIVATE_URL : CC_ADMIN_PUBLIC_URL;
}
export async function resolveGatewayUrl() {
  return (await isPrivateConnection()) ? GATEWAY_PRIVATE_URL : GATEWAY_PUBLIC_URL;
}

export async function isPrivateConnection() {
  const connectionTypeCookie = document.cookie
    .split(';')
    .map((it) => it.trim())
    .map((it) => {
      const parts = it.split('=');
      return new Cookie(parts[0], parts[1]);
    })
    .find((it) => it.name === 'connection-type');

  if (connectionTypeCookie) {
    console.log(`connection-type cookie found with value ${connectionTypeCookie.value}`);
    return connectionTypeCookie.value === 'private';
  } else {
    console.log('Verifying private connection availability...');
    return await fetch(`${GATEWAY_PRIVATE_URL}/actuator/health`)
      .then(() => {
        console.log(`Private connection available`);
        document.cookie = 'connection-type=private';
        return true;
      })
      .catch(async () => {
        console.log(
          'Private connection not available, verifying public connection availability...',
        );
        return await fetch(`${GATEWAY_PUBLIC_URL}/actuator/health`)
          .then(() => {
            console.log(`Public connection available`);
            document.cookie = 'connection-type=public';
            return false;
          })
          .catch(() => {
            console.log(`Public connection not available, connection type not resolved`);
            throw new Models.Errors.AuthorizationError('Connection not resolved');
          });
      });
  }
}

class Cookie {
  constructor(name, value) {
    this.name = name;
    this.value = value;
  }
}
