const URL_HASH_ENTRIES_SEPARATOR = '&';

export function setUrlHashValue(hashAttributeName: string, hashAttributeValue: string) {
  const hashEntries = document.location.hash
    ? document.location.hash.substring(1).split(URL_HASH_ENTRIES_SEPARATOR)
    : [];
  const hashAttributeIndex = hashEntries.findIndex((item: string) =>
    item.startsWith(`${hashAttributeName}=`),
  );
  const hashAttributeEntry = `${hashAttributeName}=${hashAttributeValue}`;
  if (hashAttributeIndex >= 0) {
    hashEntries.splice(hashAttributeIndex, 1, hashAttributeEntry);
  } else {
    hashEntries.push(hashAttributeEntry);
  }
  const newHash = hashEntries.join(URL_HASH_ENTRIES_SEPARATOR);
  document.location.href = `#${newHash}`;
}

export function setUrlQueryValue(queryAttributeName: string, queryAttributeValue: string) {
  const queryString = document.location.search;
  const searchParams = new URLSearchParams(queryString);
  searchParams.set(queryAttributeName, queryAttributeValue);
  const updatedUrl =
    document.location.origin +
    document.location.pathname +
    '?' +
    searchParams.toString() +
    document.location.hash;
  window.history.pushState({ path: updatedUrl }, '', updatedUrl);
}
