import { Models } from '@cc/cc-app-commons';

type ResponseError = Models.Errors.ResponseError;

export class ArticleBasedDiscountsAppError extends Models.Errors.AppError {
  constructor(originError: ResponseError) {
    super(originError.status, 'article_based_discounts_error', originError);
    Object.setPrototypeOf(this, ArticleBasedDiscountsAppError.prototype); // For more details see https://github.com/Microsoft/TypeScript/issues/13965
  }
}

export class FreshdeskTicketContextAppError extends Models.Errors.AppError {
  constructor(originError: ResponseError) {
    super(originError.status, 'fd_ticket_context_error', originError);
    Object.setPrototypeOf(this, FreshdeskTicketContextAppError.prototype); // For more details see https://github.com/Microsoft/TypeScript/issues/13965
  }
}
