import { Services } from '@cc/cc-app-commons';
import { Order } from '../../models/Order';
import { ServerRequest, ServerResponse } from '../../types';
import CancelOrderReason from '../../models/CancelOrderReason';
import {
  OrderAlreadyCancelledError,
  OrderCancellationError,
  OrderCancellationRefundError,
  OrderNotCancellableError,
  OrderNotFoundError,
} from '../../models/OrderCancellationError';
import CancelledOrderRefundData from '../../models/CancelledOrderRefundData';
import RefundCreation from '../../models/RefundCreation';
import { OrdersFetchingServiceV1 } from './OrdersFetchingServiceV1';
import { OrdersFetchingServiceV2 } from './OrdersFetchingServiceV2';
import { mapToOrder } from '../../models/OrderV1ToOrderMapper';
import { FeatureFlagsService } from './FeatureFlagsService';

export class OrdersService {
  constructor(
    private connector: Services.KeycloakSecuredZooServicesConnector,
    private gatewayUrl: string,
    private ordersFetchingServiceV1: OrdersFetchingServiceV1,
    private ordersFetchingServiceV2: OrdersFetchingServiceV2,
    private featureFlagsService: FeatureFlagsService,
  ) {}

  async fetchCustomerLastOrders(customerId: number, count = 1): Promise<Order[]> {
    if (await this.featureFlagsService.shouldUseV2ForOrdersFetching()) {
      return this.ordersFetchingServiceV2.fetchCustomerLastOrders(customerId, count);
    }
    return this.ordersFetchingServiceV1
      .fetchCustomerLastOrders(customerId, count)
      .then((ordersV1) => ordersV1.map((orderV1) => mapToOrder(orderV1)));
  }

  async fetchOrderByInvoiceId(invoiceId: string): Promise<Order[]> {
    if (await this.featureFlagsService.shouldUseV2ForOrdersFetching()) {
      return this.ordersFetchingServiceV2.fetchOrderByInvoiceId(invoiceId);
    }
    return this.ordersFetchingServiceV1
      .fetchOrderByInvoiceId(invoiceId)
      .then((ordersV1) => ordersV1.map((orderV1) => mapToOrder(orderV1)));
  }

  async fetchOrder(orderId: number): Promise<Order[]> {
    if (await this.featureFlagsService.shouldUseV2ForOrdersFetching()) {
      return this.ordersFetchingServiceV2.fetchOrder(orderId);
    }
    return this.ordersFetchingServiceV1
      .fetchOrder(orderId)
      .then((ordersV1) => ordersV1.map((orderV1) => mapToOrder(orderV1)));
  }

  async isOrderCancellable(orderId: number): Promise<Boolean> {
    try {
      const response = await this.connector.fetchData<ServerResponse.IsOrderCancellable>(
        this.gatewayUrl,
        `cc-apps-api/v1/orders/${orderId}/isOrderCancellable`,
      );
      return response.isOrderCancellable;
    } catch (e) {
      console.warn(
        'Encountered problems while retrieving information about order cancellation possibilities',
        e,
      );
      return Promise.resolve(false);
    }
  }

  async cancelOrder(orderId: number, reason: CancelOrderReason, userId: number): Promise<void> {
    try {
      return await this.connector.putData<ServerRequest.OrderCancellation, void>(
        this.gatewayUrl,
        `cc-apps-api/v1/orders/${orderId}/cancel`,
        { reason, userId },
      );
    } catch (e) {
      if (e.status === 404) {
        // Not found -> order not found
        throw new OrderNotFoundError(e);
      } else if (e.status === 405) {
        // Method not allowed -> order not cancellable
        throw new OrderNotCancellableError(e);
      } else if (e.status === 409) {
        // Conflict -> order already cancelled
        throw new OrderAlreadyCancelledError(e);
      } else {
        console.warn(
          `Encountered problems while performing the order cancellation for order ${orderId}`,
          e,
        );
        throw new OrderCancellationError(e);
      }
    }
  }

  async getCancelledOrderRefundData(orderId: number): Promise<CancelledOrderRefundData> {
    const data = await this.connector.fetchData<ServerResponse.RefundData>(
      this.gatewayUrl,
      `cc-apps-api/v1/orders/${orderId}/refund-data`,
    );
    const cancelledOrderRefundData = new CancelledOrderRefundData().fromData(data);
    return Promise.resolve(cancelledOrderRefundData);
  }

  async createRefund(orderId: number, userId: number): Promise<RefundCreation> {
    try {
      const data = await this.connector.postData<
        ServerRequest.RefundCreation,
        ServerResponse.RefundCreation
      >(this.gatewayUrl, `cc-apps-api/v1/orders/${orderId}/refund`, { userId });
      const refundCreationData = new RefundCreation().fromData(data);
      return Promise.resolve(refundCreationData);
    } catch (e) {
      console.warn(
        `Encountered problems while creating refund for the cancelled order ${orderId}`,
        e,
      );
      throw new OrderCancellationRefundError(e);
    }
  }
}
