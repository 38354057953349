import { Models } from '@cc/cc-app-commons';

export default class OrderBalance extends Models.MonetaryBalance {
  date: Date = null;
  orderId: number = null;
  customerId: number = null;

  fromData(json: any): any {
    super.fromData(json);
    const { order_id: orderId, customer_id: customerId, placement_date: date } = json;

    this.orderId = orderId;
    this.customerId = customerId;
    this.date = new Date(date);

    return this;
  }
}
