import { Models } from '@cc/cc-app-commons';
import OrderPosition from './OrderPosition';

export default class Fee extends OrderPosition {
  type = '';

  get value(): Models.MonetaryValue {
    return this.unitMonetaryValue;
  }

  get key(): string {
    return this.type;
  }
}
