var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"oa-orderInfo\">\n  <div class=\"brand\">\n"
    + ((stack1 = container.invokePartial(require("./brandIcon.hbs"),depth0,{"name":"./brandIcon","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"shopAndState\">\n"
    + ((stack1 = container.invokePartial(require("../partials/shopAndState.hbs"),depth0,{"name":"shopAndState","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"usePartial":true,"useData":true});