var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <tr>\n        <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"articleId") : depth0), depth0))
    + " "
    + ((stack1 = container.invokePartial(require("./physicalArticleName.hbs"),depth0,{"name":"physicalArticleName","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n        <td class=\"oa-parcelsTable__physicalArticleQty\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"quantity") : depth0), depth0))
    + "</td>\n      </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-parcelsTable__article\">\n  <div class=\"oa-parcelsTable__togglePhysicalArticles glyphicon glyphicon-chevron-right\"></div>\n  <div>\n    <span component=\"--articleNumber\"></span>\n    <span data-test=\"orderSummary.parcel.article.name\">\n      "
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n    </span>\n  </div>\n</div>\n<div class=\"u-mb-m u-mt-s oa-parcelsTable__physicalArticles\">\n  <table class=\"table table-condensed table-hover u-table\">\n    <col width=\"85%\" />\n    <col width=\"15%\" />\n    <thead>\n    <tr>\n      <th>"
    + alias1(__default(require("../helpers/i18n.js")).call(alias2,"order.parcels.physical_articles.header_physical_articles",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":10},"end":{"line":16,"column":77}}}))
    + "</th>\n      <th>"
    + alias1(__default(require("../helpers/i18n.js")).call(alias2,"order.parcels.physical_articles.header_quantity",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":10},"end":{"line":17,"column":68}}}))
    + "</th>\n    </tr>\n    </thead>\n    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,(depth0 != null ? lookupProperty(depth0,"parcelPhysicalArticles") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":26,"column":13}}})) != null ? stack1 : "")
    + "    </tbody>\n  </table>\n</div>\n";
},"usePartial":true,"useData":true});