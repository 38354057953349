import { Helpers, Models, Services } from '@cc/cc-app-commons';
import { ViewModel } from './types';
import Claim from '../models/Claim';
import { toDefaultDateFormat } from '../util/date';
import mediaTypeDetector from '../util/MediaTypeDetector';
import { ClaimResolutionTemplateData } from './OrderClaimResolutionViewModel';

const tpl = require('../../templates/order-claims/orderClaims.hbs');
const ClipboardHelper = Helpers.ClipboardHelper;
const { clear, renderTo } = Helpers.ClientHelpers;
const AppActivityAction = Services.AppActivityAction;

export default class OrderClaimsViewModel {
  static CLAIM_COPY_CLAIM_ID_BUTTON_SELECTOR = '.oa-claims .oa-claims__copyClaimIdButton';
  static CLAIM_BROWSER_LINK_SELECTOR = '.oa-claims .oa-claims__claimLink';
  static CLAIM_RESOLUTION_OPTION_SELECTOR =
    '.oa-claims .oa-claims__resolutions .oa-claims__resolutionOptionIcon';

  private readonly eventRemovers: Models.Remover[] = [];

  constructor(
    private readonly el: HTMLElement,
    private readonly suborderId: string,
    private readonly context: ViewModel.Context,
  ) {
    this.notifyClaimBrowserLinkClicked = this.notifyClaimBrowserLinkClicked.bind(this);
  }

  async render(): Promise<void> {
    const claims: Claim[] = await this.context.data.claims(this.suborderId);

    renderTo(
      this.el,
      tpl({
        claims: claims.map((claim) => ClaimTemplateData.fromClaim(claim)),
      }),
    );

    this.attachPluginsAndEventHandlers();
    this.triggerClaimsSectionSurvey(claims);
  }

  private attachPluginsAndEventHandlers() {
    this.el.querySelectorAll(OrderClaimsViewModel.CLAIM_BROWSER_LINK_SELECTOR).forEach((el) => {
      el.addEventListener('click', this.notifyClaimBrowserLinkClicked);
      this.eventRemovers.push(
        Models.Remover.createFor(
          function (): void {
            el.removeEventListener('click', this.notifyClaimBrowserLinkClicked);
          }.bind(this), // eslint-disable-line
        ),
      );
    });

    this.el
      .querySelectorAll(OrderClaimsViewModel.CLAIM_COPY_CLAIM_ID_BUTTON_SELECTOR)
      .forEach((el) => {
        const clipboardHelper = new ClipboardHelper(el, 'order.claims.copy_claim_id_button');
        this.eventRemovers.push(Models.Remover.createFor(() => clipboardHelper.destroy()));
      });

    this.el
      .querySelectorAll(OrderClaimsViewModel.CLAIM_RESOLUTION_OPTION_SELECTOR)
      .forEach((el) => {
        $(el).tooltip({ html: true });
        this.eventRemovers.push(Models.Remover.createFor(() => $(el).tooltip('destroy')));
      });
  }

  private notifyClaimBrowserLinkClicked(): void {
    this.context.metric.logAppActivity(AppActivityAction.CLAIM_BROWSER_LINK__CLICKED);
  }

  private triggerClaimsSectionSurvey(claims: Claim[]) {
    if (mediaTypeDetector.isStandalone() && claims?.length > 0) {
      this.context.feedback.triggerClaimsSectionSurvey();
    }
  }

  destroy(): void {
    this.detachPluginsAndEventHandlers();
    clear(this.el);
  }

  private detachPluginsAndEventHandlers() {
    this.eventRemovers.forEach((eventHandlerRemover: Models.Remover) =>
      eventHandlerRemover.remove(),
    );
  }
}

class ClaimTemplateData {
  claimId: number = undefined;
  status: string = '';
  isResolved: boolean = false;
  parsedCreationDate = '';
  claimBrowserClaimUrl: string = undefined;
  resolution: ClaimResolutionTemplateData = undefined;

  static fromClaim(claim: Claim) {
    const claimTemplateData = new ClaimTemplateData();
    claimTemplateData.claimId = claim.claimId;
    claimTemplateData.status = claim.status.toLowerCase();
    claimTemplateData.isResolved = claim.isResolved;
    claimTemplateData.parsedCreationDate =
      claim.creationDate && toDefaultDateFormat(claim.creationDate);
    claimTemplateData.claimBrowserClaimUrl =
      claim.links?.find((link) => link.rel === 'claim-browser-ui')?.href ||
      `${process.env.CLAIM_BROWSER_URL}/claim/${claim.claimId}`;
    claimTemplateData.resolution = ClaimResolutionTemplateData.fromClaimResolution(
      claim.resolution,
    );
    return claimTemplateData;
  }
}
