import { Services } from '@cc/cc-app-commons';
import { Services as ServiceInterfaces } from '@cc/cc-app-commons/lib/js/view/context/types';
import PaymentService from '../../../../modules/services/PaymentService';
import { AppInterfaceService } from '../../../../modules/services/AppInterfaceService';
import { CustomerService } from '../../../../modules/services/CustomerService';
import { OrdersService } from '../../../../modules/services/OrdersService';
import ParcelPictureService from '../../../../modules/services/ParcelPictureService';
import { OrderItemService } from '../../../../modules/services/OrderItemService';
import { AppContextServices } from '../../common/AppContextServices';
import { OrderResolutionService } from '../../../../modules/services/OrderResolutionService';
import { ClaimService } from '../../../../modules/services/ClaimService';
import { ClaimDraftService } from '../../../../modules/services/ClaimDraftService';
import { ParcelService } from '../../../../modules/services/ParcelService';
import { ArticleBasedDiscountsService } from '../../../../modules/services/ArticleBasedDiscountsService';
import MetricsService = Services.MetricsService;
import MonitoringService = Services.MonitoringService;
import { AddressService } from '../../../../modules/services/AddressService';

export default class ClientBasedAppContextServices implements AppContextServices {
  payments: PaymentService;
  customerCommunicationService: Services.CustomerCommunicationService;
  parcelPictureService: ParcelPictureService;
  interface: AppInterfaceService;
  metric: MetricsService;
  monitoring: MonitoringService;
  user: ServiceInterfaces.UserService;
  customer: CustomerService;
  order: OrdersService;
  parcel: ParcelService;
  address: AddressService;
  claim: ClaimService;
  claimDraft: ClaimDraftService;
  orderItemService: OrderItemService;
  orderResolutionService: OrderResolutionService;
  articleBasedDiscountsService: ArticleBasedDiscountsService;
}
