import { ContextFactory } from '../../common/ContextFactory';
import { Models, Services } from '@cc/cc-app-commons';
import { ViewModel } from '../../../types';
import { FreshchatAppContext } from './FreshchatAppContext';
import Context = ViewModel.Context;
import { ViewModel as CommonViewModelTypes } from '@cc/cc-app-commons/lib/js/view/context/types';
import { CommonAppMetricContext } from '../../common/metrics/CommonAppMetricContext';
import FreshchatAppContextServices from './FreshchatAppContextServices';

export class FreshchatAppContextFactory implements ContextFactory {
  private readonly client: any;
  private customerId: number;

  constructor(client: any) {
    this.client = client;
  }

  async createAppContext(
    connector: Services.KeycloakSecuredZooServicesConnector,
    gatewayUrl: string,
    keycloak: any,
  ): Promise<Context> {
    await this.initialized();
    return FreshchatAppContext.create(
      this.client,
      this.customerId,
      connector,
      gatewayUrl,
      keycloak,
    );
  }

  private async initialized(): Promise<any> {
    return this.client.initialized().then(async () => {
      const userData = await this.client.data.get('user');
      const customerIdProp = userData.user.properties.find(
        (it: { name: string }) => it.name === 'customerId',
      );
      this.customerId = Number(customerIdProp.value);
    });
  }

  async createMetricContext(
    connector: Services.KeycloakSecuredZooServicesConnector,
    gatewayUrl: string,
    keycloak: any,
  ): Promise<CommonViewModelTypes.MetricContext> {
    if (!connector) {
      throw new Models.Errors.AppError(null, null, {
        message: 'Cannot create MetricContext without a connector provided',
      });
    }
    const services = new FreshchatAppContextServices(
      this.client,
      this.customerId,
      connector,
      gatewayUrl,
      keycloak,
    );
    const metricContext = new CommonAppMetricContext(services);
    return Promise.resolve(metricContext);
  }
}
