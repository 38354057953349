import { EventsApi, DataApi, InstanceApi, InterfaceApi, InitializationApi } from './apis'

const EventType = Object.freeze({
  RESPONSE: 'RESPONSE',
  EXCEPTION: 'EXCEPTION',
  EVENT: 'EVENT',
  INITIALIZATION: 'INITIALIZATION'
})

export class ProxyingFdClient {
  constructor () {
    window.addEventListener('message', this.onIncomingMessage.bind(this))
    this.actionHandlers = {}
    const boundRegisterActionHandler = this.registerActionHandler.bind(this)
    this.events = new EventsApi(boundRegisterActionHandler)
    this.data = new DataApi(boundRegisterActionHandler)
    this.instance = new InstanceApi(boundRegisterActionHandler)
    this.interface = new InterfaceApi(boundRegisterActionHandler)
    this.initialization = new InitializationApi(boundRegisterActionHandler)
  }

  registerActionHandler (actionId, actionHandler) {
    this.actionHandlers[actionId] = actionHandler
  }

  initialized () {
    return this.initialization.initialized()
  }

  onIncomingMessage (event) {
    const data = event.data
    const { actionId, payload } = data
    const dataType = data.type?.toUpperCase()
    console.log('child: onIncomingMessage', data)
    switch (dataType) {
      case EventType.RESPONSE:
        this.actionHandlers[actionId].resolveFun(payload)
        break
      case EventType.EXCEPTION:
        this.actionHandlers[actionId].rejectFun(payload)
        break
      case EventType.EVENT:
        this.events.handleEventData(data)
        break
      case EventType.INITIALIZATION:
        this.initialization.handleInitialized()
        break
    }
  }
}
