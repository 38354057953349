import { Models } from '@cc/cc-app-commons';
import TransactionalMailPluginPreviewViewModel from './TransactionalMailPluginPreviewViewModel';
import TransactionalMailStandalonePreviewViewModel from './TransactionalMailStandalonePreviewViewModel';
import mediaTypeDetector from '../../util/MediaTypeDetector';

export default (
  currentMail: Models.Mail,
  allMails: Models.Mail[],
  gatewayUrl: string,
  ccAdminUrl: string,
) => {
  if (mediaTypeDetector.isPlugin()) {
    return new TransactionalMailPluginPreviewViewModel(currentMail, allMails);
  } else {
    return new TransactionalMailStandalonePreviewViewModel(
      currentMail,
      allMails,
      gatewayUrl,
      ccAdminUrl,
    );
  }
};
