var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <strong>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "</strong>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <a class=\"oa-orderSwitcher__orderLink\" href=\""
    + alias1(__default(require("../helpers/urls/ccAdminOrderUrl.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"id") : stack1),(depth0 != null ? lookupProperty(depth0,"url") : depth0),{"name":"urls/ccAdminOrderUrl","hash":{},"data":data,"loc":{"start":{"line":17,"column":51},"end":{"line":17,"column":90}}}))
    + "\" target=\"_blank\" data-test=\"orderSwitcher.orderLink\">\n        <strong>"
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "</strong>\n      </a>\n      <div data-test=\"orderSwitcher.copyOrderIdButton\" class=\"oa-orderSwitcher__copyOrderIdButton\"\n           data-placement=\"bottom\"\n           data-clipboard-text=\""
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n        <span class=\"glyphicon glyphicon-duplicate\"></span>\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-orderSwitcher-orderItem oa-orderSwitcher-orderItem--"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\">\n  <div class=\"oa-orderSwitcher-orderNumber\">\n\n    <div class=\"oa-orderSwitcher-orderProblemIndicatorTooltip oa-tooltipWrapper\"\n         data-toggle=\"tooltip\"\n         data-placement=\"bottom\"\n         data-html=\"true\"\n         data-container=\"body\"\n         title=\""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"tooltipConfig") : depth0)) != null ? lookupProperty(stack1,"content") : stack1), depth0)) != null ? stack1 : "")
    + "\"\n    >\n      <div class=\"oa-orderSwitcher-orderProblemIndicator oa-orderSwitcher-orderProblemIndicator--"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tooltipConfig") : depth0)) != null ? lookupProperty(stack1,"alertType") : stack1)) != null ? lookupProperty(stack1,"code") : stack1), depth0))
    + "\"></div>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,__default(require("../helpers/conditionals/eq.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"listItem",{"name":"conditionals/eq","hash":{},"data":data,"loc":{"start":{"line":14,"column":10},"end":{"line":14,"column":45}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":25,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n\n  <div class=\"oa-orderSwitcher-orderDate\" data-test=\"orderSwitcher.orderDate\">\n    "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"creationDate") : stack1), depth0))
    + "\n  </div>\n\n  <div class=\"oa-orderSwitcher-shopAndState\">\n"
    + ((stack1 = container.invokePartial(require("../partials/shopAndState.hbs"),depth0,{"name":"shopAndState","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n\n</div>\n";
},"usePartial":true,"useData":true});