import { Helpers, Models, Services } from '@cc/cc-app-commons';
import { ViewModel } from '../types';
import OrderActionsModalHelper from './OrderActionsModalHelper';
import LoaderViewModel, { withLoader } from '../LoaderViewModel';
import RefundCancelledOrderConfirmationViewModel from './RefundCancelledOrderConfirmationViewModel';
import { ServerResponse } from '../../types';
import CancelledOrderRefundData from '../../models/CancelledOrderRefundData';
import RefundCreation = ServerResponse.RefundCreation;

type AppError = Models.Errors.AppError;

const AppActivityAction = Services.AppActivityAction;

const { clear, renderTo } = Helpers.ClientHelpers;
const tpl = require('../../../templates/order-actions/refundCancelledOrderModal.hbs');

export default class RefundCancelledOrderViewModel {
  private readonly el: Element;
  private readonly context: ViewModel.Context;
  private readonly refundData: CancelledOrderRefundData;
  private eventRemovers: Models.Remover[] = [];
  protected loaderView: LoaderViewModel;

  constructor(el: Element, context: ViewModel.Context, refundData: CancelledOrderRefundData) {
    this.el = el;
    this.context = context;
    this.refundData = refundData;
  }

  async render() {
    renderTo(
      this.el,
      tpl({
        refundAmount: new Models.MonetaryValue()
          .fromData({
            currency: this.refundData.invoiceCurrency,
            amount: this.refundData.refundAmountInInvoiceCurrency,
          })
          .toData(),
      }),
    );

    this.loaderView = new LoaderViewModel(this.el.querySelector('.loader'));
    this.loaderView.hide();

    this.attachPluginsAndEventHandlers();

    this.context.metric.logAppActivity(AppActivityAction.REFUND_CUSTOMER_MESSAGE__DISPLAYED);
  }

  private attachPluginsAndEventHandlers() {
    this.onPerformRefundClick();
  }

  private onPerformRefundClick() {
    const callback = this.onPerformRefundClickCallback.bind(this);
    const ccAdminRefundLink = this.el.querySelector('.oa-refundCancelledOrderModalContent__refund');
    ccAdminRefundLink.addEventListener('click', callback);
    this.eventRemovers.push(
      Models.Remover.createFor(
        function (): void {
          ccAdminRefundLink.removeEventListener('click', callback);
        }.bind(this) // eslint-disable-line
      ),
    );
  }

  onPerformRefundClickCallback() {
    withLoader(this.loaderView, async (): Promise<RefundCreation> => {
      const order = await this.context.data.currentOrder;
      return this.context.data.refundCancelledOrder(order.id);
    })
      .then((result) => {
        this.context.metric.logAppActivity(
          AppActivityAction.REFUND_CANCELLED_ORDER_ACTION__PERFORMED,
        );
        const modalWrapper = OrderActionsModalHelper.getModal();
        modalWrapper.renderContainer(RefundCancelledOrderConfirmationViewModel, result);
      })
      .catch((error: AppError) => {
        OrderActionsModalHelper.handleError(error);
      });
  }

  destroy() {
    this.detachPluginsAndEventHandlers();
    clear(this.el);
  }

  private detachPluginsAndEventHandlers() {
    this.eventRemovers.forEach((eventHandlerRemover: Models.Remover) =>
      eventHandlerRemover.remove(),
    );
  }
}
