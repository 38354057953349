import { Models } from '@cc/cc-app-commons';
import { ServerResponse } from '../types';
import { ClaimResolution } from './ClaimResolution';

export enum ClaimDraftStatus {
  CREATED = 'CREATED',
  EXECUTED = 'EXECUTED',
  REJECTED = 'REJECTED',
  FAILED = 'FAILED',
}

export default class ClaimDraft extends Models.BaseModel {
  claimDraftId: number = undefined;
  creationDate = '';
  resolution: ClaimResolution = undefined;
  status: ClaimDraftStatus;

  get isNotResolved(): boolean {
    return this.status === ClaimDraftStatus.CREATED;
  }

  fromData(json: ServerResponse.ClaimDraft) {
    super.fromData(json);
    this.status = json.status;
    this.resolution = json.resolution ? new ClaimResolution().fromData(json.resolution) : undefined;
    return this;
  }
}
