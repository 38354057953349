import { Services } from '@cc/cc-app-commons';
import { ServerRequest } from '../../types';
import ModifyShippingAddressReason from '../../models/ModifyShippingAddressReason';

import { AddressModificationError } from '../../models/AddressModificationError';
import ModifyBillingAddressReason from '../../models/ModifyBillingAddressReason';
import Address from '../../models/Address';

export class AddressService {
  connector: Services.KeycloakSecuredZooServicesConnector;
  gatewayUrl: string;

  constructor(connector: Services.KeycloakSecuredZooServicesConnector, gatewayUrl: string) {
    this.connector = connector;
    this.gatewayUrl = gatewayUrl;
  }

  async modifyShippingAddress(
    orderId: number,
    userId: number,
    modifyShippingAddressReason: ModifyShippingAddressReason,
    address: Address,
  ): Promise<void> {
    try {
      return await this.connector.putData<ServerRequest.ShippingAddressModificationRequest, void>(
        this.gatewayUrl,
        `cc-apps-api/v2/orders/${orderId}/shipping-address`,
        {
          userId: userId,
          reason: modifyShippingAddressReason,
          firstName: address.firstName,
          lastName: address.lastName,
          city: address.city,
          countryISOCode: address.countryISOCode,
          phone: address.phone,
          postalCode: address.postalCode,
          street: address.street,
        },
      );
    } catch (e) {
      //todo add more specific exception handling
      console.warn('Encountered problems during shipping address modification', e);
      throw new AddressModificationError(e);
    }
  }

  async modifyBillingAddress(
    orderId: number,
    userId: number,
    modifyBillingAddressReason: ModifyBillingAddressReason,
    address: Address,
  ): Promise<void> {
    try {
      return await this.connector.putData<ServerRequest.BillingAddressModificationRequest, void>(
        this.gatewayUrl,
        `cc-apps-api/v2/orders/${orderId}/billing-address`,
        {
          userId: userId,
          reason: modifyBillingAddressReason,
          firstName: address.firstName,
          lastName: address.lastName,
          city: address.city,
          countryISOCode: address.countryISOCode,
          phone: address.phone,
          postalCode: address.postalCode,
          street: address.street,
        },
      );
    } catch (e) {
      //todo add more specific exception handling
      console.warn('Encountered problems during billing address modification', e);
      throw new AddressModificationError(e);
    }
  }
}
