import { Models } from '@cc/cc-app-commons';
import ModalWrapper from '../ModalWrapper';
import ErrorForViewModel from '../../util/ErrorForViewModel';

type AppError = Models.Errors.AppError;

export default class AddressModificationActionsModalHelper {
  static ADDRESS_MODIFICATION_MODAL_SELECTOR = '.oa-addresses .oa-addressActionsModal';

  static getModal() {
    return ModalWrapper.getFor(
      document.querySelector(
        AddressModificationActionsModalHelper.ADDRESS_MODIFICATION_MODAL_SELECTOR,
      ),
    );
  }

  static handleShippingAddressChangeError(error: AppError) {
    AddressModificationActionsModalHelper.getModal().handleError(
      new ErrorForViewModel(error, 'order.actions.modify_shipping_address.errors'),
    );
  }

  static handleBillingAddressChangeError(error: AppError) {
    AddressModificationActionsModalHelper.getModal().handleError(
      new ErrorForViewModel(error, 'order.actions.modify_billing_address.errors'),
    );
  }
}
