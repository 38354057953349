import { Order } from '../../models/Order';
import { OrdersService } from './OrdersService';
import OrderResolutionMetadata from '../../models/OrderResolutionMetadata';
import { parse } from 'query-string';

export abstract class OrderResolutionService {
  protected readonly ordersService: OrdersService;
  protected customerId: number;
  protected orderId: number;
  protected invoiceId?: string;
  protected specificOrder: Order;
  protected lastOrders: Order[] = [];

  protected constructor(
    customerId: number,
    orderId: number,
    invoiceId: string,
    ordersService: OrdersService,
  ) {
    this.customerId = customerId;
    this.orderId = orderId;
    this.invoiceId = invoiceId;
    this.ordersService = ordersService;
  }

  async getOrders(): Promise<Order[]> {
    if (this.orderId) {
      this.specificOrder = (await this.ordersService.fetchOrder(this.orderId))[0];
    } else if (this.invoiceId) {
      this.specificOrder = (await this.ordersService.fetchOrderByInvoiceId(this.invoiceId))[0];
    }

    // The strategy is:
    // if specificOrder is fetched than get 5 last orders only if customer id from the ticket and specific order match
    if (!this.customerId) {
      this.customerId = await this.obtainCustomerId();
    }

    if (
      (this.customerId && !this.specificOrder) ||
      (this.customerId && this.customerId === this.specificOrder?.customerId)
    ) {
      this.lastOrders = await this.ordersService.fetchCustomerLastOrders(this.customerId, 5);
    }

    if (this.specificOrder) {
      const isInLastOrders = this.lastOrders.some(
        (o: Order) => this.specificOrder && o.id === this.specificOrder.id,
      );
      if (!isInLastOrders) {
        this.lastOrders = this.lastOrders.concat(this.specificOrder);
        this.lastOrders.sort((a: Order, b: Order) => {
          return Number(b.id) - Number(a.id); // descending sort
        });
      }
    }
    return this.lastOrders;
  }

  abstract async obtainCustomerId(): Promise<number>;

  currentOrderId(): number {
    return parse(window.location.search, { parseNumbers: true })?.orderId as number;
  }

  abstract async getOrderResolutionMetadata(order: Order): Promise<OrderResolutionMetadata>;
}
