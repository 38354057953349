var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-orderTimeline\" data-test=\"orderTimeline\">\n  <div class=\"loader\">\n    <img src=\"spinner.gif\"/>\n  </div>\n\n  <h5 class=\"timelineTitle\">\n    "
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(alias1,__default(require("../helpers/concat.js")).call(alias1,"order.timeline.",(depth0 != null ? lookupProperty(depth0,"title") : depth0),".title",{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":7,"column":11},"end":{"line":7,"column":52}}}),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":54}}}))
    + "\n  </h5>\n\n  <div class=\"timelineWrapper\">\n    <div class=\"timelineBar\">\n      <div class=\"timelineBar-point\"></div>\n    </div>\n    <div class=\"timeline\">\n\n    </div>\n  </div>\n\n</div>";
},"useData":true});