var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <span class=\"label "
    + ((stack1 = container.invokePartial(require("./fraudStyleClass.hbs"),depth0,{"name":"./fraudStyleClass","hash":{"type":"label"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " oa-fraudLabel__labelTittle oa-tooltipWrapper\"\n        data-test=\"orderHeader.fraudLabel\"\n        data-toggle=\"tooltip\"\n        data-placement=\"bottom\"\n        data-trigger=\"hover\"\n        title=\""
    + ((stack1 = container.invokePartial(require("./fraudTitle.hbs"),depth0,{"name":"./fraudTitle","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\"\n        data-test=\"orderData.fraudLabel\">\n    "
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.fraud.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":9,"column":32}}}))
    + "\n  </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/logic/not.js")).call(alias1,__default(require("../helpers/type/isFalse.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isFraud") : depth0),{"name":"type/isFalse","hash":{},"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":43}}}),{"name":"logic/not","hash":{},"data":data,"loc":{"start":{"line":1,"column":6},"end":{"line":1,"column":44}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":11,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});