const { v4: uuidv1 } = require('uuid')

class Apis {
  constructor (registerActionHandler) {
    this.registerActionHandler = registerActionHandler
  }

  requestAction (api, method, args) {
    return this.doAction('request', { api, method, args })
  }

  doAction (type, otherParams) {
    const actionId = this.generateActionId()
    const promise = new Promise((resolve, reject) => {
      this.registerActionHandler(actionId, {
        resolveFun: resolve,
        rejectFun: reject
      })
    })
    this.sendMessageToParent({ actionId, type, ...otherParams })
    return promise
  }

  generateActionId () {
    return uuidv1()
  }

  sendMessageToParent (message) {
    const parentWindow = window.opener ?? window.parent
    parentWindow.postMessage(message, '*')
  }
}

export class EventsApi extends Apis {
  constructor (registerActionHandler) {
    super(registerActionHandler)
    this.eventToHandler = {}
  }

  on (eventName, fun) {
    const handlers = this.eventHandlersForEventName(eventName)
    this.eventToHandler[eventName] = [...handlers, fun]

    this.subscribeForEventAction(eventName)
      .then(() => console.log(`child: got event subs confirmation for ${eventName}`))
      .catch((e) => console.log(`child: exception when subs for event ${eventName}`, e))

    return {
      remove: this._eventRemoverFunction.bind(this, eventName, fun)
    }
  }

  _eventRemoverFunction (eventName, fun) {
    const handlers = this.eventToHandler[eventName]
    let handlerIndex
    while ((handlerIndex = handlers.findIndex(handler => handler === fun)) >= 0) {
      handlers.splice(handlerIndex, 1)
    }
  }

  handleEventData (eventData) {
    const eventName = eventData.eventName
    const handlers = this.eventHandlersForEventName(eventName)
    console.log(`child: Found ${handlers.length} handlers for event ${eventName}`)
    handlers.forEach((handler) => handler(eventData.payload))
  }

  eventHandlersForEventName (eventName) {
    return this.eventToHandler[eventName] ?? []
  }

  subscribeForEventAction (eventName) {
    return this.doAction('subscribe_for_event', { eventName })
  }
}

export class InstanceApi extends Apis {
  resize (dimensions) {
    console.log('child: instance resize request ', dimensions)
    return this.requestAction('instance', 'resize', [dimensions])
  }

  openInNewWindow (params) {
    console.log('openInNewWindow (params) ', params)
    return this.doAction('open_in_new_window', params)
  }

  context () {
    console.log('child: instance context request ')
    return this.requestAction('instance', 'context', [])
  }
}

export class DataApi extends Apis {
  get (whatToGet) {
    console.log('child: data get', whatToGet)
    return this.requestAction('data', 'get', [whatToGet])
  }
}

export class InterfaceApi extends Apis {
  trigger (action, data) {
    console.log('child: interface trigger', action, data)
    return this.requestAction('interface', 'trigger', [action, data])
  }
}

export class InitializationApi extends Apis {
  resolveFun
  isInitialized = false
  initializationPromise = new Promise((resolve) => {
    this.resolveFun = resolve
  })

  initialized () {
    if (!this.isInitialized) {
      this.doAction('initialization')
    }
    return this.initializationPromise
  }

  handleInitialized () {
    if (this.resolveFun) {
      console.log('child: initialization')
      this.resolveFun()
      this.isInitialized = true
    }
  }
}
