import { AppInterfaceService, TopNavigationConfiguration } from './AppInterfaceService';
import * as WindowOpener from '../../util/WindowOpener';

export default class IndependentAppInterfaceService implements AppInterfaceService {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showModal(title: string, template: string, data: Object): void {
    // implement when needed
  }

  openAppInNewWindow(orderId?: number): void {
    const searchParams = {
      orderId: `${orderId}`,
    };
    const searchParamsStringified = new URLSearchParams(searchParams).toString();
    WindowOpener.openInNewWindow(window.location.origin + `?${searchParamsStringified}`);
  }

  getTopNavigationConfiguration(): TopNavigationConfiguration {
    return new TopNavigationConfiguration(true, window.location.origin);
  }
}
