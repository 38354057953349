import { ViewModel } from '../../types';
import { AppInterfaceContext } from './AppInterfaceContext';
import { AppContextServices } from './AppContextServices';
import { AppAuthorizationContext } from './AppAuthorizationContext';
import { AppFeedbackContext } from './AppFeedbackContext';
import { AppDataContext } from './AppDataContext';
import { ViewModel as CommonViewModelTypes } from '@cc/cc-app-commons/lib/js/view/context/types';
import { View } from '@cc/cc-app-commons';
import { AppOriginContext } from './AppOriginContext';
import OriginContext = ViewModel.OriginContext;

export abstract class AppContext implements ViewModel.Context {
  protected _data: AppDataContext;
  protected _metric: CommonViewModelTypes.MetricContext;
  protected _interface: AppInterfaceContext;
  protected _authorization: AppAuthorizationContext;
  protected _feedback: AppFeedbackContext;
  protected _user: CommonViewModelTypes.UserContext;
  protected _logging: CommonViewModelTypes.LoggingContext;
  protected _origin: AppOriginContext;
  protected readonly services: AppContextServices;
  protected readonly keycloak: any;

  protected constructor(services: AppContextServices, keycloak: any) {
    this.services = services;
    this.keycloak = keycloak;
    this.createAppInterfaceContext();
    this.createAppAuthorizationContext();
    this.createAppFeedbackContext();
    this.createAppUserContext();
    this.createAppLoggingContext();
    this.createAppOriginContext();
  }

  get origin(): OriginContext {
    return this._origin;
  }

  get data(): ViewModel.DataContext {
    return this._data;
  }

  get metric(): CommonViewModelTypes.MetricContext {
    return this._metric;
  }

  get interface(): ViewModel.InterfaceContext {
    return this._interface;
  }

  get authorization(): ViewModel.AuthorizationContext {
    return this._authorization;
  }

  get feedback(): ViewModel.FeedbackContext {
    return this._feedback;
  }

  get user(): CommonViewModelTypes.UserContext {
    return this._user;
  }

  get logging(): CommonViewModelTypes.LoggingContext {
    return this._logging;
  }

  private createAppInterfaceContext() {
    this._interface = new AppInterfaceContext(this.services);
  }

  private createAppAuthorizationContext() {
    this._authorization = new AppAuthorizationContext(this.keycloak);
  }

  private createAppFeedbackContext() {
    this._feedback = new AppFeedbackContext();
  }

  private createAppUserContext() {
    this._user = new View.Context.AppUserContext(this.services);
  }

  private createAppLoggingContext() {
    this._logging = new View.Context.AppLoggingContext(this.services);
  }

  private createAppOriginContext() {
    this._origin = new AppOriginContext(new Map());
  }
}
