var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"oa-subscription\" data-test=\"orderHeader.subscription\">\n    <div class=\"badge oa-tooltipWrapper oa-subscription-tooltip\"\n         data-toggle=\"tooltip\"\n         data-placement=\"bottom\"\n         data-html=\"true\"\n         data-container=\"body\"\n         title=\""
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(alias1,"order.subscription.tooltip",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":53}}}))
    + "\"\n    >\n      <span class=\"glyphicon glyphicon-repeat\"></span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/layout/isMediaTypeStandalone.js")).call(alias1,{"name":"layout/isMediaTypeStandalone","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":44}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":6},"end":{"line":13,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "        <span data-test=\"subscription.caption\">"
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.subscription.caption",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":47},"end":{"line":12,"column":84}}}))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/conditionals/eq.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"channel") : depth0),"SUBSCRIPTION",{"name":"conditionals/eq","hash":{},"data":data,"loc":{"start":{"line":1,"column":6},"end":{"line":1,"column":48}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":16,"column":7}}})) != null ? stack1 : "");
},"useData":true});