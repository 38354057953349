var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.fraud.tooltip",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":1,"column":32},"end":{"line":1,"column":62}}}));
},"3":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.fraud.tooltip_unknown",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":1,"column":70},"end":{"line":1,"column":108}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/type/isTrue.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isFraud") : depth0),{"name":"type/isTrue","hash":{},"data":data,"loc":{"start":{"line":1,"column":7},"end":{"line":1,"column":30}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":116}}})) != null ? stack1 : "");
},"useData":true});