import { Helpers, Models, Utils } from '@cc/cc-app-commons';
import { ViewModel } from './types';
import { Order } from '../models/Order';
import OrderSwitcherView from './OrderSwitcherView';
import mediaTypeDetector from '../util/MediaTypeDetector';
import OrderActionsViewModel from './orderActions/OrderActionsViewModel';
import { openInNewWindow } from '../util/WindowOpener';
import OrderRelationsViewModel from './orderHeader/OrderRelationsViewModel';
import SubscriptionViewModel from './orderHeader/SubscriptionViewModel';
import FraudIconViewModel from './orderHeader/FraudIconViewModel';
import FraudLabelViewModel from './orderHeader/FraudLabelViewModel';
import { resolveCCAdminUrl } from '../helpers/connectionTypeResolver';

type ClipboardHelper = Helpers.ClipboardHelper;

const { clear, createChildElement, render, renderTo } = Helpers.ClientHelpers;
const ClipboardHelper = Helpers.ClipboardHelper;
const _ = Utils._;

const tpl = require('../../templates/order-header/orderHeader.hbs');
const tplOrderInfo = require('../../templates/order-header/orderInfo.hbs');
const tplCustomerInfo = require('../../templates/order-header/customerInfo.hbs');
const dividerTpl = require('../../templates/common/divider.hbs');

export default class OrderHeaderViewModel {
  private static CUSTOMER_ID_COPY_BUTTON_ELEMENT_SELECTOR =
    '.oa-customerInfo__copyCustomerIdButton';
  private static CUSTOMER_CCA_LINK_ELEMENT_SELECTOR = '.oa-customerInfo__customerLink';
  private static CLOSE_WINDOW_BUTTON_ELEMENT_SELECTOR = '.oa-windowNavigation__buttonCloseWindow';
  private static SUBSCRIPTION_ELEMENT_SELECTOR = '[component="--subscriptionInfo"]';
  private static FRAUD_LABEL_ELEMENT_SELECTOR = '[component="--fraudLabel"]';
  private static FRAUD_ICON_ELEMENT_SELECTOR = '[component="--fraudIcon"]';

  private readonly el: Element;
  private readonly context: ViewModel.Context;
  private orderChangeListenerRemover: Models.Remover;
  orders: Order[];
  private orderSwitcherView: OrderSwitcherView;
  private orderActionsViewModel: OrderActionsViewModel;
  private orderRelationsViewModel: OrderRelationsViewModel;
  private subscriptionViewModel: SubscriptionViewModel;
  private fraudIconViewModel: FraudIconViewModel;
  private fraudLabelViewModel: FraudLabelViewModel;
  private clipboardHelpers: ClipboardHelper[] = [];

  constructor(domElement: Element, context: ViewModel.Context) {
    this.context = context;
    this.rerenderAfterOrderChange = this.rerenderAfterOrderChange.bind(this);

    render(createChildElement(domElement), tpl());
    this.el = domElement.querySelector('.oa-header');
  }

  private get currentOrder(): Promise<Order> {
    return this.context.data.currentOrder;
  }

  async render(): Promise<void> {
    await this.ensureDataLoaded();

    this.renderDivider();
    this.renderOrderInfo();
    await this.renderOrderSwitcher();
    this.renderSubscriptionInfo();
    this.renderOrderRelations();
    await this.renderCustomerInfo();
    this.renderFraudInformation();
    this.attachPluginsAndEventHandlers();
  }

  private async ensureDataLoaded(): Promise<void> {
    this.orders = await this.context.data.orders;
  }

  private async renderFraudInformation() {
    if (mediaTypeDetector.isStandalone()) {
      const fraudLabelElement = this.el.querySelector(
        OrderHeaderViewModel.FRAUD_LABEL_ELEMENT_SELECTOR,
      );
      this.fraudLabelViewModel = new FraudLabelViewModel(fraudLabelElement, this.context);
      this.fraudLabelViewModel.render();

      const fraudIconElement = this.el.querySelector(
        OrderHeaderViewModel.FRAUD_ICON_ELEMENT_SELECTOR,
      );
      this.fraudIconViewModel = new FraudIconViewModel(fraudIconElement, this.context);
      this.fraudIconViewModel.render();
    }
  }

  private async renderOrderInfo() {
    const componentElement = this.el.querySelector('[component="--orderInfo"]');
    const order = await this.currentOrder;
    renderTo(
      componentElement,
      tplOrderInfo({
        order: order.toTemplateData(),
      }),
    );
  }

  private async renderSubscriptionInfo() {
    if (mediaTypeDetector.isStandalone()) {
      const componentElement = this.el.querySelector(
        OrderHeaderViewModel.SUBSCRIPTION_ELEMENT_SELECTOR,
      );
      this.subscriptionViewModel = new SubscriptionViewModel(componentElement, this.context);
      this.subscriptionViewModel.render();
    }
  }

  private async renderOrderSwitcher() {
    const componentElement = this.el.querySelector('[component="--orderSwitcher"]');
    this.orderSwitcherView = new OrderSwitcherView(componentElement, this.context);
    await this.orderSwitcherView.render();
  }

  private async renderOrderRelations() {
    this.orderRelationsViewModel?.destroy(); // eslint-disable-line no-unused-expressions
    if (mediaTypeDetector.isStandalone()) {
      const componentElement = this.el.querySelector('[component="--orderRelations"]');
      this.orderRelationsViewModel = new OrderRelationsViewModel(componentElement, this.context);
      await this.orderRelationsViewModel.render();
    }
  }

  private renderDivider() {
    const componentElement = this.el.querySelector('[component="--divider"]');
    render(componentElement, dividerTpl({ simple: true }));
  }

  private async renderCustomerInfo() {
    if (mediaTypeDetector.isPlugin()) {
      return;
    }

    const customer = await this.context.data.customer;
    const ccAdminUrl = await resolveCCAdminUrl();
    const componentElement = this.el.querySelector('[component="--customerInfo"]');

    renderTo(
      componentElement,
      tplCustomerInfo({
        ...customer.toTemplateData(),
        url: ccAdminUrl,
      }),
    );
  }

  private attachPluginsAndEventHandlers() {
    this.el
      .querySelectorAll(OrderHeaderViewModel.CUSTOMER_ID_COPY_BUTTON_ELEMENT_SELECTOR)
      .forEach((el) => {
        this.clipboardHelpers.push(
          new ClipboardHelper(el, 'order.header.customer_info.copy_customerid_button'),
        );
      });

    this.el
      .querySelectorAll(OrderHeaderViewModel.CUSTOMER_CCA_LINK_ELEMENT_SELECTOR)
      .forEach((el) => {
        el.addEventListener('click', (event) => {
          event.preventDefault();
          this.context.feedback.onCcAdminLinkClicked();
          _.delay(openInNewWindow, 0, (event.currentTarget as HTMLAnchorElement).href);
        });
      });

    this.el
      .querySelectorAll(OrderHeaderViewModel.CLOSE_WINDOW_BUTTON_ELEMENT_SELECTOR)
      .forEach((el) => {
        el.addEventListener('click', () => {
          window.close();
        });
      });
    this.orderChangeListenerRemover = this.context.data.onCurrentOrderChange(
      this.rerenderAfterOrderChange,
    );
  }

  private rerenderAfterOrderChange() {
    this.detachPluginsAndEventHandlers();
    this.renderOrderInfo();
    this.renderOrderRelations();
    this.attachPluginsAndEventHandlers();
  }

  private detachPluginsAndEventHandlers() {
    while (this.clipboardHelpers?.length) {
      const clipboardHelper = this.clipboardHelpers.shift();
      clipboardHelper.destroy();
    }

    if (this.orderChangeListenerRemover) {
      this.orderChangeListenerRemover.remove();
      delete this.orderChangeListenerRemover;
    }
  }

  destroy() {
    this.detachPluginsAndEventHandlers();
    this.orderRelationsViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.orderSwitcherView?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.orderActionsViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.subscriptionViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.fraudLabelViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.fraudLabelViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    clear(this.el);
  }
}
