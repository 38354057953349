import { Models } from '@cc/cc-app-commons';
import { ViewModel } from '../../types';
import { AppContextServices } from './AppContextServices';
import I18n from 'i18n';
import { TopNavigationConfiguration } from '../../../modules/services/AppInterfaceService';

export class AppInterfaceContext implements ViewModel.InterfaceContext {
  private readonly services: AppContextServices;

  constructor(services: AppContextServices) {
    this.services = services;
  }

  showTransactionalMailContent(currentMail: Models.Mail, allMails: Models.Mail[]): void {
    this.services.interface.showModal(
      I18n.t('transactional_mail.modal.title', {}),
      'mailModal.html',
      {
        currentMail: currentMail,
        allMails: allMails,
      },
    );
  }

  openAppInNewWindow(orderId?: number): void {
    this.services.interface.openAppInNewWindow(orderId);
  }

  getTopNavigationConfiguration(): TopNavigationConfiguration {
    return this.services.interface.getTopNavigationConfiguration();
  }
}
