import { Helpers, Models } from '@cc/cc-app-commons';
import { TemplateData } from '../models/types';
import { PaymentMethodDetails } from './OrderSummaryViewModel';

const paymentSummaryTpl = require('../../templates/order-payment/orderPaymentSummary.hbs');

type MonetaryValue = Models.MonetaryValue;

const { renderTo } = Helpers.ClientHelpers;

export default class OrderPaymentSummaryViewModel {
  private readonly el: HTMLElement;
  private readonly model: OrderPaymentSummaryModel;

  constructor(domElement: HTMLElement, model: OrderPaymentSummaryModel) {
    this.el = domElement;
    this.model = model;
  }

  render(): void {
    renderTo(this.el, paymentSummaryTpl(this.model.toTemplateData()));
  }
}

export class OrderPaymentSummaryModel extends Models.BaseModel implements TemplateData {
  totalOrderValue: MonetaryValue;
  toBePaid: MonetaryValue;
  paymentMethodDetails: PaymentMethodDetails[];

  constructor(
    totalOrderValue: MonetaryValue,
    toBePaid: MonetaryValue,
    paymentMethodDetails: PaymentMethodDetails[],
  ) {
    super();
    this.totalOrderValue = totalOrderValue;
    this.toBePaid = toBePaid;
    const uniquePaymentMethodDetails = new Map();
    paymentMethodDetails.forEach((value) => {
      uniquePaymentMethodDetails.set(value.toString(), value);
    });
    this.paymentMethodDetails = Array.from(uniquePaymentMethodDetails.values());
  }

  toTemplateData(...context: any): any {
    return this.toData(...context);
  }
}
