const enum MsgIconSize {
  BIG = 'big',
  SMALL = 'small',
}

export default class TimelineEntryStatus {
  name: string;
  icon: string;
  size: MsgIconSize;

  constructor(statusName: string, modelIndex: number, statusToIconMap: Map<string, string>) {
    this.name = statusName.toLowerCase();
    this.icon = statusToIconMap.get(statusName);
    this.size = modelIndex === 0 ? MsgIconSize.BIG : MsgIconSize.SMALL;
  }
}
