var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div>\n      <div class=\"oa-orderSummary__suborderHeaderSection\">\n        <div data-test=\"suborderInformation\">\n          <strong>\n            "
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"order.summary.standalone.information","sellerName",(depth0 != null ? lookupProperty(depth0,"sellerName") : depth0),"index",(depth0 != null ? lookupProperty(depth0,"index") : depth0),"amount",((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"suborders"))) && lookupProperty(stack1,"length")),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":129}}}))
    + "\n              <a class=\"oa-orderSummary__orderLink\" href=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" target=\"_blank\" data-test=\"orderSummary.orderLink\">\n                <strong>"
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"order.summary.standalone.orderId","id",(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":14,"column":75}}}))
    + "</strong>\n              </a>\n          </strong>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"invoiceId") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isZooplusSuborder") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"status") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":26,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isZooplusSuborder") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n      <div id=\"_"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"u-mb-l oa-orderSummary__orderArticlesSections\">\n        <div class = \"oa-orderArticleSection\">\n          <div class=\"oa-positions\"  data-test=\"orderSummary.positions\"></div>\n          <div class=\"u-mt-m u-pr-s oa-positions__total\">\n            <div class=\"u-pr-s\"><strong>"
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"order.positions.total",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":40},"end":{"line":35,"column":72}}}))
    + ":</strong></div>\n            <div>\n              <strong>"
    + ((stack1 = container.invokePartial(require("./partials/monetaryValue.hbs"),(depth0 != null ? lookupProperty(depth0,"totalMonetaryValue") : depth0),{"name":"monetaryValue","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</strong>\n            </div>\n          </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isZooplusSuborder") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":6},"end":{"line":47,"column":13}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div data-test=\"orderSummary.invoiceId\"><strong>"
    + container.escapeExpression(__default(require("./helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.summary.invoice_id","id",(depth0 != null ? lookupProperty(depth0,"invoiceId") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":58},"end":{"line":19,"column":108}}}))
    + "</strong></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "          <div component=\"--relaxDeliveryInfo\"></div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div data-test=\"orderSummary.orderState\">"
    + alias1(__default(require("./helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.state",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":25,"column":51},"end":{"line":25,"column":73}}}))
    + ": "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "          <div component=\"--orderActions\"></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"u-mt-s u-mb-s oa-orderSummary__OrderClaimParcelSection\">\n          <div class=\"oa-orderSummary__section\" component=\"--claim-drafts\" data-test=\"orderSummary.claim-drafts\"></div>\n          <div class=\"oa-orderSummary__section\" component=\"--claims\" data-test=\"orderSummary.claims\"></div>\n          <div class=\"oa-orderSummary__section oa-parcels\" data-test=\"orderSummary.parcels\"></div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-orderSummary oa-orderSummary--standalone\">\n  <div class = \"oa-payment_address__section\">\n    <div class=\".u-mr-l oa-orderSummary__section oa-payment\" data-test=\"orderSummary.payment\"></div>\n    <div class=\"oa-orderSummary__section\" component=\"--addresses\" data-test=\"orderSummary.addresses\"></div>\n  </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"suborders") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":50,"column":11}}})) != null ? stack1 : "")
    + "\n</div>";
},"usePartial":true,"useData":true});