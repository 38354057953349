var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div id=\"_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"u-mb-m\">\n      <div class=\"oa-orderSummary__section u-mb-s\">\n        <div class=\"oa-orderSummary__suborderHeaderSection__plugin\">\n          <div class=\"orderHeaderSection__summary\">\n            <div>\n              <div data-test=\"suborderInformation\">\n               <div data-test=\"suborderInformation__numeration\"><strong>"
    + alias2(__default(require("./helpers/i18n.js")).call(alias3,"order.summary.plugin.numeration","index",(depth0 != null ? lookupProperty(depth0,"index") : depth0),"amount",((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"suborders"))) && lookupProperty(stack1,"length")),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":72},"end":{"line":11,"column":160}}}))
    + "</strong></div>\n               <div data-test=\"suborderInformation__sellerName\"><strong>"
    + alias2(__default(require("./helpers/i18n.js")).call(alias3,"order.summary.plugin.owner","sellerName",(depth0 != null ? lookupProperty(depth0,"sellerName") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":72},"end":{"line":12,"column":133}}}))
    + "</strong></div>\n                <a class=\"oa-orderSummary__orderLink\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" target=\"_blank\" data-test=\"orderSummary.orderLink\">\n                  <strong>"
    + alias2(__default(require("./helpers/i18n.js")).call(alias3,"order.summary.plugin.id","id",(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":26},"end":{"line":14,"column":68}}}))
    + "</strong>\n                </a>\n              </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"invoiceId") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":17,"column":14},"end":{"line":23,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"summary__left\">\n              <div class=\"delivery-section\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isZooplusSuborder") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":29,"column":23}}})) != null ? stack1 : "")
    + "                <div class=\"oa-orderSummary__state\" data-test=\"orderSummary.orderState\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "</div>\n              </div>\n              <div class=\"oa-orderSummary__price\"><strong>"
    + ((stack1 = container.invokePartial(require("./partials/monetaryValue.hbs"),(depth0 != null ? lookupProperty(depth0,"totalMonetaryValue") : depth0),{"name":"monetaryValue","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</strong></div>\n            </div>\n          </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isZooplusSuborder") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":10},"end":{"line":37,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isZooplusSuborder") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":41,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"oa-positions\" data-test=\"orderSummary.positions\"></div>\n        <div class=\"u-mt-s u-pr-s oa-positions__total\">\n          <div class=\"u-pr-s\"><strong>"
    + alias2(__default(require("./helpers/i18n.js")).call(alias3,"order.positions.total",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":44,"column":38},"end":{"line":44,"column":70}}}))
    + ":</strong></div>\n          <div>\n            <strong>"
    + ((stack1 = container.invokePartial(require("./partials/monetaryValue.hbs"),(depth0 != null ? lookupProperty(depth0,"totalMonetaryValue") : depth0),{"name":"monetaryValue","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</strong>\n          </div>\n        </div>\n      </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isZooplusSuborder") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":6},"end":{"line":54,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div data-test=\"orderSummary.invoiceId\">\n                  <strong>"
    + container.escapeExpression(__default(require("./helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.summary.invoice_id","id",(depth0 != null ? lookupProperty(depth0,"invoiceId") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":26},"end":{"line":19,"column":76}}}))
    + "</strong>\n                </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                <strong>"
    + container.escapeExpression(__default(require("./helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.summary.invoice_id_na",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":24},"end":{"line":22,"column":62}}}))
    + "</strong>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                  <div component=\"--relaxDeliveryInfo\"></div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "            <div component=\"--orderActions\"></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "          <div class=\"oa-orderSummary__section\" component=\"--claim-drafts\" data-test=\"orderSummary.claim-drafts\"></div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"oa-orderSummary__OrderParcelSection\">\n          <div class=\"oa-orderSummary__section oa-parcels\" data-test=\"orderSummary.parcels\"></div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"u-mr-s u-ml-s oa-orderSummary oa-orderSummary--plugin\">\n  <div class=\"oa-orderSummary__section oa-payment\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"suborders") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":56,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});