import { Models, Services } from '@cc/cc-app-commons';
import Customer from '../../models/Customer';
import { ServerResponse } from '../../types';

const { CustomerSearchParams, Errors } = Models;

export class CustomerService {
  connector: Services.KeycloakSecuredZooServicesConnector;
  gatewayUrl: string;

  constructor(connector: Services.KeycloakSecuredZooServicesConnector, gatewayUrl: string) {
    this.connector = connector;
    this.gatewayUrl = gatewayUrl;
  }

  async fetchCustomerForOrder(orderId: number): Promise<Customer> {
    let order: any;
    try {
      order = await this.connector.fetchData<ServerResponse.Order>(
        this.gatewayUrl,
        `cc-apps-api/v1/orders/${orderId}`,
        null,
      );
    } catch (err) {
      if ([404].includes(err.status)) {
        throw new Errors.OrdersNotFoundAppError(err);
      } else {
        throw new Errors.UnexpectedAppError(err);
      }
    }
    return this.fetchCustomer(order.customerId);
  }

  async fetchCustomer(customerId: number): Promise<Customer> {
    let customer, icv;
    try {
      customer = await this.connector.fetchData<ServerResponse.Customer>(
        this.gatewayUrl,
        `customer-service/v5/customer/${customerId}`,
        null,
      );
    } catch (err) {
      throw new Errors.UnexpectedAppError(err);
    }

    try {
      icv = await this.connector.fetchData<ServerResponse.InternalCustomerValue>(
        this.gatewayUrl,
        `internal-customer-value/v2/customers/${customerId}/internal-customer-value`,
        null,
      );
    } catch (e) {
      icv = undefined;
      console.warn("Encountered problems while retrieving the customer's ICV", e);
    }

    return new Customer().fromData({
      zooId: customerId,
      firstName: customer.customerDto.invoiceAddress.firstName,
      lastName: customer.customerDto.invoiceAddress.lastName,
      email: customer.customerDto.email,
      icv: icv?.segment,
    });
  }

  async fetchCustomerForTicket(
    ticket: Models.Ticket,
    withName: boolean = false,
  ): Promise<Customer> {
    const customerSearchParams = new CustomerSearchParams(
      ticket.productId,
      ticket.customerEmail,
      ticket.customerPhone,
    );
    const urlQueryParams = customerSearchParams.toUrlQueryParams(); // may throw exception

    try {
      const customer: ServerResponse.CustomerDto =
        await this.connector.fetchData<ServerResponse.CustomerDto>(
          this.gatewayUrl,
          'cc-apps-api/v1/customers',
          urlQueryParams,
        );
      return withName
        ? this.fetchCustomer(customer.zooId)
        : Promise.resolve(new Customer().fromData(customer));
    } catch (err) {
      if (err.status === 404) {
        throw new Models.Errors.CustomerNotFoundAppError(err, customerSearchParams);
      } else {
        throw new Models.Errors.UnexpectedAppError(err);
      }
    }
  }
}
