import { Models } from '@cc/cc-app-commons';
import { OrderResolutionService } from './OrderResolutionService';
import { OrdersService } from './OrdersService';
import OrderResolutionMetadata from '../../models/OrderResolutionMetadata';
import { Order } from '../../models/Order';
import IndependentAppOrderResolutionMetadata from '../../models/IndependentAppOrderResolutionMetadata';

export class IndependentAppOrderResolutionService extends OrderResolutionService {
  /* eslint-disable @typescript-eslint/no-useless-constructor */
  constructor(
    customerId: number,
    orderId: number,
    invoiceId: string,
    ordersService: OrdersService,
  ) {
    super(customerId, orderId, invoiceId, ordersService);
  }
  /* eslint-enable @typescript-eslint/no-useless-constructor */

  async getOrders(): Promise<Order[]> {
    if (!this.orderId && !this.customerId && !this.invoiceId) {
      throw new Models.Errors.MissingSearchCriteriaAppError();
    }
    return super.getOrders();
  }

  async obtainCustomerId(): Promise<number> {
    return Promise.resolve(this.customerId);
  }

  async getOrderResolutionMetadata(order: Order): Promise<OrderResolutionMetadata> {
    return new IndependentAppOrderResolutionMetadata(this.customerId, this.orderId, order);
  }
}
