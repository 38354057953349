import { Services } from '@cc/cc-app-commons';
import { Services as ServiceInterfaces } from '@cc/cc-app-commons/lib/js/view/context/types';
import PaymentService from '../../../modules/services/PaymentService';
import { CustomerService } from '../../../modules/services/CustomerService';
import { OrdersService } from '../../../modules/services/OrdersService';
import ParcelPictureService from '../../../modules/services/ParcelPictureService';
import { OrderItemService } from '../../../modules/services/OrderItemService';
import IndependentAppUserService from '../../../modules/services/IndependentAppUserService';
import { AppContextServices } from '../common/AppContextServices';
import { AppInterfaceService } from '../../../modules/services/AppInterfaceService';
import IndependentAppInterfaceService from '../../../modules/services/IndependentAppInterfaceService';
import { OrderResolutionService } from '../../../modules/services/OrderResolutionService';
import { IndependentAppOrderResolutionService } from '../../../modules/services/IndependentAppOrderResolutionService';
import { ClaimService } from '../../../modules/services/ClaimService';
import { ClaimDraftService } from '../../../modules/services/ClaimDraftService';
import { ParcelService } from '../../../modules/services/ParcelService';
import KeycloakService from '../../../modules/services/KeycloakService';
import { ArticleBasedDiscountsService } from '../../../modules/services/ArticleBasedDiscountsService';
import MetricsService = Services.MetricsService;
import MonitoringService = Services.MonitoringService;
import { OrdersFetchingServiceV1 } from '../../../modules/services/OrdersFetchingServiceV1';
import { OrdersFetchingServiceV2 } from '../../../modules/services/OrdersFetchingServiceV2';
import { FeatureFlagsService } from '../../../modules/services/FeatureFlagsService';
import { AddressService } from '../../../modules/services/AddressService';

const APP_NAME = process.env.APP_NAME;

export default class IndependentAppContextServices implements AppContextServices {
  payments: PaymentService;
  customerCommunicationService: Services.CustomerCommunicationService;
  parcelPictureService: ParcelPictureService;
  interface: AppInterfaceService;
  metric: MetricsService;
  monitoring: MonitoringService;
  user: ServiceInterfaces.UserService;
  customer: CustomerService;
  order: OrdersService;
  parcel: ParcelService;
  address: AddressService;
  claim: ClaimService;
  claimDraft: ClaimDraftService;
  orderItemService: OrderItemService;
  orderResolutionService: OrderResolutionService;
  articleBasedDiscountsService: ArticleBasedDiscountsService;

  constructor(
    customerId: number,
    orderId: number,
    invoiceId: string,
    connector: Services.KeycloakSecuredZooServicesConnector,
    gatewayUrl: string,
    keycloak: any,
  ) {
    this.payments = new PaymentService(connector, gatewayUrl);
    this.customerCommunicationService = new Services.CustomerCommunicationService(
      connector,
      `${gatewayUrl}/customer-communication-audit/v2`,
    );
    this.parcelPictureService = new ParcelPictureService(connector, gatewayUrl);
    this.interface = new IndependentAppInterfaceService();
    this.metric = new MetricsService(connector, `${gatewayUrl}/cc-apps-api/v1`, APP_NAME);
    this.user = new IndependentAppUserService(new KeycloakService(keycloak));
    this.monitoring = new MonitoringService(this.metric, this.user);
    this.customer = new CustomerService(connector, gatewayUrl);
    this.order = new OrdersService(
      connector,
      gatewayUrl,
      new OrdersFetchingServiceV1(connector, gatewayUrl),
      new OrdersFetchingServiceV2(connector, gatewayUrl),
      new FeatureFlagsService(connector, gatewayUrl, this.user),
    );
    this.parcel = new ParcelService(connector, gatewayUrl);
    this.address = new AddressService(connector, gatewayUrl);
    this.claim = new ClaimService(connector, gatewayUrl);
    this.claimDraft = new ClaimDraftService(connector, gatewayUrl);
    this.orderItemService = new OrderItemService(connector, gatewayUrl);
    this.orderResolutionService = new IndependentAppOrderResolutionService(
      customerId,
      orderId,
      invoiceId,
      this.order,
    );
    this.articleBasedDiscountsService = new ArticleBasedDiscountsService(connector, gatewayUrl);
  }
}
