import { Helpers, Models, Services, Utils } from '@cc/cc-app-commons';
import { Order } from '../models/Order';
import OrderPositionsViewModel from './OrderPositionsViewModel';
import PositionModel, { PositionsModel } from '../models/PositionModel';
import { ViewModelInputData } from '../types';
import OrderParcelsViewModel from './OrderParcelsViewModel';
import OrderWarning from '../models/OrderWarning';
import orderWarningViewModelFactory from './orderWarning/orderWarningViewModelFactory';
import OrderPaymentSummaryViewModel, {
  OrderPaymentSummaryModel,
} from './OrderPaymentSummaryViewModel';
import { OrderPayments } from '../models/OrderPayments';
import OrderAddressesViewModel from './OrderAddressesViewModel';
import OrderClaimsViewModel from './OrderClaimsViewModel';
import OrderClaimDraftsViewModel from './OrderClaimDraftsViewModel';
import mediaTypeDetector, { MediaType } from '../util/MediaTypeDetector';
import { ViewModel } from './types';
import OrderActionsViewModel from './orderActions/OrderActionsViewModel';
import RelaxDeliveryViewModel from './orderHeader/RelaxDeliveryViewModel';
import { resolveCCAdminUrl } from '../helpers/connectionTypeResolver';
import Context = ViewModel.Context;
import AppActivityAction = Services.AppActivityAction;
import { openInNewWindow } from '../util/WindowOpener';
import _ = Utils._;

type Remover = Models.Remover;

const { clear, renderTo } = Helpers.ClientHelpers;

const orderSummaryTemplates = new Map<string, any>([
  [MediaType.PLUGIN, require('../../templates/orderSummary-plugin.hbs')],
  [MediaType.STANDALONE, require('../../templates/orderSummary-standalone.hbs')],
]);

export class PaymentMethodDetails {
  paymentMethod: string;
  paymentProvider?: string;

  constructor(paymentMethod: string, paymentProvider?: string) {
    this.paymentProvider = paymentProvider;
    this.paymentMethod = paymentMethod;
  }

  toString(): string {
    return JSON.stringify(this);
  }
}

export default class OrderSummaryViewModel {
  private readonly el: HTMLElement;
  private model: ViewModelInputData.OrderViewModel;
  private orderActionsViewModel: OrderActionsViewModel;
  private orderPaymentViewModel: OrderPaymentSummaryViewModel;
  private orderParcelsViewModel: OrderParcelsViewModel;
  private orderPositionsViewModel: OrderPositionsViewModel;
  private orderAddressesViewModel: OrderAddressesViewModel;
  private orderClaimsViewModel: OrderClaimsViewModel;
  private orderClaimDraftsViewModel: OrderClaimDraftsViewModel;
  private order: Order;
  private readonly context: Context;
  private relaxDeliveryViewModel: RelaxDeliveryViewModel;

  private static SUBORDER_LINK_ELEMENT_SELECTOR = '.oa-orderSummary__orderLink';
  private static MIRAKL_BASE_LINK = process.env.MIRAKL_URL;
  eventRemovers: Remover[] = [];

  constructor(domElement: HTMLElement, model: ViewModelInputData.OrderViewModel, context: any) {
    this.el = domElement;
    this.model = model;
    const { order } = model;
    this.order = order;
    this.context = context;
  }

  async render() {
    const tpl = orderSummaryTemplates.get(mediaTypeDetector.currentMediaType().toString());

    const ccAdminBaseLink = await resolveCCAdminUrl();
    this.enhancedSuborderByLinkToAdmins(ccAdminBaseLink);

    renderTo(this.el, tpl(this.order.toTemplateData()));

    this.renderOrderPaymentSummary();
    this.renderOrderPositions();
    this.renderOrderParcels();
    this.renderOrderWarnings();
    this.renderAddresses();
    this.renderClaims();
    this.renderClaimDrafts();
    this.renderOrderActions();
    this.renderRelaxDeliveryInfo();
    this.initLinksClickListeners();
  }

  private initLinksClickListeners() {
    const callback = (event: Event) => {
      event.preventDefault();
      const eventHref = (event.currentTarget as HTMLAnchorElement).href;
      if (eventHref.includes(OrderSummaryViewModel.MIRAKL_BASE_LINK)) {
        this.context.metric.logAppActivity(AppActivityAction.MIRAKL_LINK__CLICKED);
      } else {
        this.context.metric.logAppActivity(AppActivityAction.CC_ADMIN_LINK__CLICKED);
      }
      _.delay(openInNewWindow, 0, eventHref);
    };

    this.el
      .querySelectorAll(OrderSummaryViewModel.SUBORDER_LINK_ELEMENT_SELECTOR)
      .forEach((element) => {
        element.addEventListener('click', callback);
        this.eventRemovers.push(
          Models.Remover.createFor(
            function (): void {
              element.removeEventListener('click', callback);
            }.bind(this) // eslint-disable-line
          ),
        );
      });
  }

  private enhancedSuborderByLinkToAdmins(ccAdminBaseLink: string) {
    this.order.suborders.map((so) => {
      const ccAdminLink = this.buildCCAdminLink(ccAdminBaseLink, so.id);
      const linkToMirakl = this.buildMiraklLink(so.id);
      so.url = so.isZooplusSuborder ? ccAdminLink : linkToMirakl;
    });
  }

  private buildCCAdminLink(ccAdminBaseLink: string, suborderId: string) {
    return ccAdminBaseLink + `/phpadmin/cc/customer/detail/oId-${suborderId}`;
  }

  private buildMiraklLink(suborderId: string) {
    return OrderSummaryViewModel.MIRAKL_BASE_LINK + `/mmp/operator/order/${suborderId}`;
  }

  private async renderRelaxDeliveryInfo() {
    const componentElement = this.el.querySelector('[component="--relaxDeliveryInfo"]');
    this.relaxDeliveryViewModel = new RelaxDeliveryViewModel(componentElement, this.context);
    this.relaxDeliveryViewModel.render();
  }

  async renderOrderPaymentSummary(): Promise<void> {
    const orderPayments: OrderPayments = await this.context.data.orderPayments(this.order.id);
    const toBePaid = orderPayments.orderBalance?.balance?.negate();
    const paymentMethods = orderPayments.transactions?.map(
      (t) => new PaymentMethodDetails(t.paymentMethod, t.connector),
    );
    this.orderPaymentViewModel = new OrderPaymentSummaryViewModel(
      this.el.querySelector('.oa-payment'),
      new OrderPaymentSummaryModel(this.order.totalMonetaryValue, toBePaid, paymentMethods),
    );
    this.orderPaymentViewModel.render();
  }

  renderOrderActions(): void {
    const componentElement = this.el.querySelector('[component="--orderActions"]');
    if (componentElement) {
      this.orderActionsViewModel = new OrderActionsViewModel(componentElement, this.context);
      this.orderActionsViewModel.render();
    }
  }

  renderOrderPositions(): void {
    this.model.order.suborders.forEach((suborder) => {
      const { articles = [], discounts = [], fees = [] } = suborder;
      const positions: PositionModel[] = articles.concat(discounts).concat(fees);
      this.orderPositionsViewModel = new OrderPositionsViewModel(
        this.el.querySelector(`#_${suborder.id} .oa-positions`),
        new PositionsModel(positions, suborder.id),
        this.context,
      );
      this.orderPositionsViewModel.render();
    });
  }

  renderOrderParcels(): void {
    this.model.order.suborders.forEach((suborder) => {
      if (suborder.isZooplusSuborder) {
        this.orderParcelsViewModel = new OrderParcelsViewModel(
          this.el.querySelector(`#_${suborder.id} .oa-parcels`),
          suborder.parcels,
          this.context,
        );
        this.orderParcelsViewModel.render();
      }
    });
  }

  renderOrderWarning(orderWarning: OrderWarning, suborderId: string): void {
    const warningViewModel = orderWarningViewModelFactory(this.el, orderWarning, suborderId);
    if (warningViewModel) {
      warningViewModel.render();
    }
  }

  renderOrderWarnings(): void {
    this.model.order.suborders.forEach((suborder) =>
      suborder.warnings.forEach((orderWarning) =>
        this.renderOrderWarning(orderWarning, suborder.id),
      ),
    );
  }

  renderAddresses() {
    if (mediaTypeDetector.isPlugin()) {
      return;
    }
    this.orderAddressesViewModel = new OrderAddressesViewModel(
      this.el.querySelector('[component="--addresses"]'),
      this.order,
      this.context,
    );
    this.orderAddressesViewModel.render();
  }

  renderClaims() {
    if (mediaTypeDetector.isPlugin()) {
      return;
    }
    this.order.suborders.forEach((suborder) => {
      if (suborder.isZooplusSuborder) {
        this.orderClaimsViewModel = new OrderClaimsViewModel(
          this.el.querySelector(`#_${suborder.id} [component="--claims"]`),
          suborder.id,
          this.context,
        );
        this.orderClaimsViewModel.render();
      } else {
        return;
      }
    });
  }

  renderClaimDrafts() {
    this.order.suborders.forEach((suborder) => {
      if (suborder.isZooplusSuborder) {
        this.orderClaimDraftsViewModel = new OrderClaimDraftsViewModel(
          this.el.querySelector(`#_${suborder.id} [component="--claim-drafts"]`),
          suborder.id,
          this.context,
        );
        this.orderClaimDraftsViewModel.render();
      } else {
        return;
      }
    });
  }

  destroy(): void {
    this.orderPaymentViewModel?.render(); /* eslint-disable-line no-unused-expressions */
    this.orderPositionsViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.orderParcelsViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.orderClaimsViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.orderClaimDraftsViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.orderAddressesViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.relaxDeliveryViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.removeListeners();
    clear(this.el);
  }

  private removeListeners() {
    this.eventRemovers.forEach((remover: Remover) => remover.remove());
  }
}
