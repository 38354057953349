import { Models, Services } from '@cc/cc-app-commons';

type ResponseError = Models.Errors.ResponseError;
const { Errors } = Models;

export default class ParcelPictureService {
  connector: Services.KeycloakSecuredZooServicesConnector;
  gateway: string;

  constructor(connector: Services.KeycloakSecuredZooServicesConnector, gateway: string) {
    this.connector = connector;
    this.gateway = gateway;
  }

  async fetchParcelPicture(parcelId: number): Promise<Blob> {
    try {
      return await this.connector.fetchBinaryData(
        this.gateway,
        `parcel-pictures/v1/parcel/${parcelId}`,
      );
    } catch (e) {
      if (this.errorMeansNotAvailable(e)) {
        return null;
      }
      throw new Errors.UnexpectedAppError(e);
    }
  }

  private errorMeansNotAvailable(e: ResponseError): boolean {
    return [404, 500, 501].includes(e.status);
  }
}
