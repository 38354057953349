var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"oa-orderActions__actionButtonsDelimiter\"></div>";
},"3":function(container,depth0,helpers,partials,data) {
    return " <div class=\"oa-orderActions__actionButtonsDelimiter\"></div>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-orderActionsModal-actions\">\n<div>\n"
    + ((stack1 = container.invokePartial(require("./goToClaimWizardPlugin.hbs"),depth0,{"name":"./goToClaimWizardPlugin","hash":{"orderId":(depth0 != null ? lookupProperty(depth0,"orderId") : depth0),"canUseClaimWizard":((stack1 = (depth0 != null ? lookupProperty(depth0,"authorization") : depth0)) != null ? lookupProperty(stack1,"canUseClaimWizard") : stack1)},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/logic/and.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"authorization") : depth0)) != null ? lookupProperty(stack1,"canCancelOrder") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"authorization") : depth0)) != null ? lookupProperty(stack1,"canUseClaimWizard") : stack1),{"name":"logic/and","hash":{},"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":8,"column":85}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":8,"column":153}}})) != null ? stack1 : "")
    + "\n  <div>\n"
    + ((stack1 = container.invokePartial(require("./cancelOrderPlugin.hbs"),depth0,{"name":"./cancelOrderPlugin","hash":{"isOrderCancellable":(depth0 != null ? lookupProperty(depth0,"isOrderCancellable") : depth0),"canCancelOrder":((stack1 = (depth0 != null ? lookupProperty(depth0,"authorization") : depth0)) != null ? lookupProperty(stack1,"canCancelOrder") : stack1)},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isArticleBasedDiscountsEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":15,"column":110}}})) != null ? stack1 : "")
    + "\n  <div> "
    + ((stack1 = container.invokePartial(require("./fetchArticleBasedDiscountsInfoPlugin.hbs"),depth0,{"name":"./fetchArticleBasedDiscountsInfoPlugin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n</div>\n";
},"usePartial":true,"useData":true});