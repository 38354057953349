import { Helpers } from '@cc/cc-app-commons';
import { ViewModel } from '../types';
import mediaTypeDetector from '../../util/MediaTypeDetector';
import { OrderRelationType } from '../../models/OrderRelation';

const { clear, renderTo } = Helpers.ClientHelpers;
const tpl = require('../../../templates/order-header/orderRelations.hbs');

export default class OrderRelationsViewModel {
  private readonly el: Element;
  private readonly context: ViewModel.Context;

  constructor(domElement: Element, context: ViewModel.Context) {
    this.el = domElement;
    this.context = context;
  }

  async render(): Promise<void> {
    const currentOrder = await this.context.data.currentOrder;
    const currentSuborder = currentOrder.suborders.find((it) => it.isZooplusSuborder);
    const hasRelatedOrders = currentSuborder?.relatedOrders?.length > 0;

    if (mediaTypeDetector.isStandalone() && hasRelatedOrders) {
      const data = {
        parent: currentSuborder.relatedOrders.filter(
          (relation) => relation.relationType === OrderRelationType.PARENT,
        )[0],
        children: currentSuborder.relatedOrders.filter(
          (relation) => relation.relationType === OrderRelationType.CHILD,
        ),
      };
      renderTo(this.el, tpl(data));
    }
  }

  destroy() {
    clear(this.el);
  }
}
