import { Models } from '@cc/cc-app-commons';
import { TemplateData } from './types';

export class PositionsModel extends Models.BaseModel implements TemplateData {
  suborderId: string;
  positions: PositionModel[];

  constructor(positions: PositionModel[], suborderId: string) {
    super();
    this.suborderId = suborderId;
    this.positions = positions;
  }

  fromData(json: any): PositionsModel {
    const { positions } = json;
    this.positions = positions.map((it: any) => new PositionModel().fromData(it));
    return this;
  }

  toTemplateData(...context: any): any {
    return this.toData(...context);
  }
}

export default class PositionModel extends Models.BaseModel implements TemplateData {
  orderPositionId: number = undefined;
  name: string;
  quantity: number;
  value: Models.MonetaryValue;
  totalValue: Models.MonetaryValue;
  articleNumber?: string;
  articleAdminUrl?: string;
  shopArticleUrl?: string;
  shopArticleId: number;
  shopArticleVariant?: string;
  key?: string;

  toTemplateData(...context: any): any {
    return this.toData(...context);
  }
}
