import { Helpers, Models, Services } from '@cc/cc-app-commons';
import CancelParcelReason from '../../models/CancelOrderReason';
import { ViewModel } from '../types';
import OrderParcel from '../../models/OrderParcel';
import ErrorForViewModel from '../../util/ErrorForViewModel';
import ParcelActionsModalHelper from './ParcelActionsModalHelper';

const AppActivityAction = Services.AppActivityAction;
const { clear, renderTo } = Helpers.ClientHelpers;
const tpl = require('../../../templates/parcel-actions/cancelParcelReason.hbs');

export default class CancelParcelReasonViewModel {
  private readonly el: Element;
  private readonly context: ViewModel.Context;
  private readonly parcel: OrderParcel;
  private readonly onParcelCancelCallback: Function;
  private eventRemovers: Models.Remover[] = [];
  private _cancelParcelActionButton: JQuery<Element>;
  private _reasonsSection: JQuery<Element>;
  private _formElement: HTMLFormElement;

  constructor(
    domElement: Element,
    context: ViewModel.Context,
    parcel: OrderParcel,
    onCancelCallback: Function,
  ) {
    this.el = domElement;
    this.context = context;
    this.parcel = parcel;
    this.onParcelCancelCallback = onCancelCallback;
  }

  get formElement() {
    if (!this._formElement) {
      this._formElement = this.el.querySelector('form');
    }
    return this._formElement;
  }

  get reasonsSection(): JQuery<Element> {
    if (!this._reasonsSection) {
      this._reasonsSection = $(this.el.querySelector(' .oa-cancelParcelReason__reasons'));
    }
    return this._reasonsSection;
  }

  get cancelParcelActionButton(): JQuery<Element> {
    if (!this._cancelParcelActionButton) {
      this._cancelParcelActionButton = $(
        this.el.querySelector(' .oa-cancelParcelReason-cancelParcelActionButton'),
      );
    }
    return this._cancelParcelActionButton;
  }

  render() {
    renderTo(
      this.el,
      tpl({
        parcelCancelReasons: Object.keys(CancelParcelReason).map(
          (key) => CancelParcelReason[key as keyof typeof CancelParcelReason],
        ),
      }),
    );
    this.disableCancelParcelButton();

    this.attachPluginsAndEventHandlers();
  }

  private attachPluginsAndEventHandlers() {
    const onSubmitCallback = this.submitParcelCancel.bind(this);
    this.formElement.addEventListener('submit', onSubmitCallback);
    this.eventRemovers.push(
      Models.Remover.createFor(
        function (): void {
          this.formElement.removeEventListener('submit', onSubmitCallback);
        }.bind(this) // eslint-disable-line
      ),
    );

    const radioOnChangeCallback = (e: Event) => {
      if ((e.currentTarget as HTMLInputElement).value) {
        this.enableCancelParcelButton();
      }
    };
    this.formElement.cancelParcelReason.forEach((element: HTMLInputElement) => {
      element.addEventListener('change', radioOnChangeCallback);
      this.eventRemovers.push(
        Models.Remover.createFor(
          function (): void {
            element.removeEventListener('change', radioOnChangeCallback);
          }.bind(this) // eslint-disable-line
        ),
      );
    });
  }

  private enableCancelParcelButton() {
    this.cancelParcelActionButton.prop('disabled', false);
  }

  private disableCancelParcelButton() {
    this.cancelParcelActionButton.prop('disabled', true);
  }

  private async submitParcelCancel(evt: Event) {
    evt.preventDefault();
    const cancelParcelReasonKey = (evt.target as HTMLFormElement).cancelParcelReason
      .value as keyof typeof CancelParcelReason;
    try {
      await this.onParcelCancel(CancelParcelReason[cancelParcelReasonKey]);
    } catch (e) {
      ParcelActionsModalHelper.getModal().handleError(
        new ErrorForViewModel(e, 'parcel.actions.cancellation.modal.errors'),
      );
    }
  }

  private async onParcelCancel(cancelParcelReason: CancelParcelReason) {
    await this.context.metric.logAppActivity(
      AppActivityAction.PARCEL_CANCELLATION_ACTION__PERFORMED,
    );
    const order = await this.context.data.currentOrder;
    await this.context.data.cancelParcel(this.parcel.id, order.id, cancelParcelReason);
    const modalWrapper = ParcelActionsModalHelper.getModal();
    modalWrapper.onModalDismissal(() => {
      this.onParcelCancelCallback();
      modalWrapper.destroy();
    });
    modalWrapper.close();
  }

  private detachPluginsAndEventHandlers() {
    this.eventRemovers.forEach((eventHandlerRemover: Models.Remover) =>
      eventHandlerRemover.remove(),
    );
  }

  destroy() {
    this.detachPluginsAndEventHandlers();
    clear(this.el);
  }
}
