import ShopCurrency from '../models/ShopCurrency';
import { Models } from '@cc/cc-app-commons';

export default function monetaryValueFromShopCurrency(
  shopCurrency: ShopCurrency,
  value: number | string,
): Models.MonetaryValue {
  return Models.MonetaryValue.fromShopValue(
    value,
    shopCurrency.currency,
    0, // we don't support exchangeRate anymore
  );
}
