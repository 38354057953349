import { ContextFactory } from '../common/ContextFactory';
import { Models, Services } from '@cc/cc-app-commons';
import { ViewModel } from '../../types';
import { ViewModel as CommonViewModelTypes } from '@cc/cc-app-commons/lib/js/view/context/types';
import { IndependentAppContext } from './IndependentAppContext';
import IndependentAppContextServices from './IndependentAppContextServices';
import { CommonAppMetricContext } from '../common/metrics/CommonAppMetricContext';
import Context = ViewModel.Context;

export class IndependentAppContextFactory implements ContextFactory {
  protected readonly customerId: number;
  protected readonly orderId: number;
  protected readonly invoiceId?: string;

  constructor(customerId: number, orderId: number, invoiceId: string) {
    this.customerId = customerId;
    this.orderId = orderId;
    this.invoiceId = invoiceId;
  }

  async createAppContext(
    connector: Services.KeycloakSecuredZooServicesConnector,
    gatewayUrl: string,
    keycloak: any,
  ): Promise<Context> {
    return IndependentAppContext.create(
      this.customerId,
      this.orderId,
      this.invoiceId,
      connector,
      gatewayUrl,
      keycloak,
    );
  }

  async createMetricContext(
    connector: Services.KeycloakSecuredZooServicesConnector,
    gatewayUrl: string,
    keycloak: any,
  ): Promise<CommonViewModelTypes.MetricContext> {
    if (!connector) {
      throw new Models.Errors.AppError(null, null, {
        message: 'Cannot create MetricContext without a connector provided',
      });
    }
    const services = new IndependentAppContextServices(
      this.customerId,
      this.orderId,
      this.invoiceId,
      connector,
      gatewayUrl,
      keycloak,
    );
    const metricContext = new CommonAppMetricContext(services);
    return Promise.resolve(metricContext);
  }
}
