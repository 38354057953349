import { Models, Services } from '@cc/cc-app-commons';
import { ViewModel as CommonViewModelTypes } from '@cc/cc-app-commons/lib/js/view/context/types';
import { ViewModel } from '../../../types';

export class CommonAppMetricContext implements CommonViewModelTypes.MetricContext {
  private readonly services: CommonViewModelTypes.ContextServices;
  private readonly dataContext: ViewModel.DataContext;

  constructor(
    services: CommonViewModelTypes.ContextServices,
    dataContext: ViewModel.DataContext = null,
  ) {
    this.services = services;
    this.dataContext = dataContext;
  }

  async incrementCcadminLinkClickedMetric() {
    const tags = await this.prepareTags();
    this.services.metric.incrementMetric('freshdesk_ccadmin_link_clicked', tags);
  }

  private async prepareTags() {
    if (this.dataContext) {
      const currentOrder = await this.dataContext.currentOrder;
      return [
        {
          name: 'site',
          value: currentOrder.domain,
        },
      ];
    }
    return null;
  }

  async logAppActivity(eventType: Services.AppActivityAction, data?: string): Promise<void> {
    const user = await this.services.user.loggedInUser();
    this.services.metric.logActivity(eventType, {} as Models.Ticket, user, data);
  }
}
