import { Models, Services } from '@cc/cc-app-commons';
import OrderBalance from '../../models/OrderBalance';
import Payment from '../../models/Payment';
import { OrderPayments } from '../../models/OrderPayments';
import ShopCurrency from '../../models/ShopCurrency';

type ResponseError = Models.Errors.ResponseError;
const { Errors } = Models;

export default class PaymentService {
  connector: Services.KeycloakSecuredZooServicesConnector;
  gatewayUrl: string;

  constructor(connector: Services.KeycloakSecuredZooServicesConnector, gatewayUrl: string) {
    this.connector = connector;
    this.gatewayUrl = gatewayUrl;
  }

  async fetchOrderPayments(orderId: number, shopCurrency: ShopCurrency): Promise<OrderPayments> {
    const orderBalanceInfoFromApi: OrderBalance = await this.fetchOrderBalance(orderId);
    const transactions: Payment[] = await this.fetchPaymentTransactions(orderId, shopCurrency);

    return new OrderPayments(orderBalanceInfoFromApi, transactions);
  }

  async fetchOrderBalance(orderId: number): Promise<OrderBalance> {
    try {
      const orderBalanceJson = await this.connector.fetchData(
        this.gatewayUrl,
        `customer-account-balance/v1/order-balances/${orderId}`,
      );
      return new OrderBalance().fromData(orderBalanceJson);
    } catch (e) {
      if (this.errorMeansNotAvailable(e)) {
        return null;
      }
      throw new Errors.UnexpectedAppError(e);
    }
  }

  async fetchPaymentTransactions(orderId: number, shopCurrency: ShopCurrency): Promise<Payment[]> {
    try {
      const paymentsJson: any = await this.connector.fetchData(
        this.gatewayUrl,
        `zpp-api/v2/orders/${orderId}/payments?paymentFilter=ALL&aggregatedInfo=true`,
      );
      return paymentsJson.paymentsData?.map((payment: any) => {
        return new Payment().fromData(payment, shopCurrency);
      });
    } catch (e) {
      if (this.errorMeansNotAvailable(e)) {
        return [];
      }
      throw new Errors.UnexpectedAppError(e);
    }
  }

  async enrichPaymentWithErrorDetails(payment: Payment): Promise<Payment> {
    try {
      const { eventId, paymentId } = payment;
      const paymentsDetailsJson: any = await this.connector.fetchData(
        this.gatewayUrl,
        `zpp-api/v2/payments/${paymentId}/transactions`,
      );
      const applicablePaymentDetailsJson = paymentsDetailsJson.partnerTransactionsData.find(
        (paymentJson: any) => {
          paymentJson.eventId = eventId;
        },
      );
      const errorDataJson = {
        errorCode: applicablePaymentDetailsJson?.errorCode,
        errorMessage: applicablePaymentDetailsJson?.errorMessage,
      };
      return payment.fromData(errorDataJson);
    } catch (e) {
      if (this.errorMeansNotAvailable(e)) {
        return payment;
      }
      throw new Errors.UnexpectedAppError(e);
    }
  }

  private errorMeansNotAvailable(e: ResponseError): boolean {
    return [404, 500, 501].includes(e.status);
  }
}
