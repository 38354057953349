var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "bg-"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tooltipConfig") : depth0)) != null ? lookupProperty(stack1,"alertType") : stack1)) != null ? lookupProperty(stack1,"code") : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"oa-orderSwitcherModal-order "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/conditionals/eq.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"id") : stack1),((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"currentOrderMetadata") : depths[1])) != null ? lookupProperty(stack1,"order") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"conditionals/eq","hash":{},"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":27,"column":82}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":52},"end":{"line":27,"column":127}}})) != null ? stack1 : "")
    + "\"\n                 data-dismiss=\"modal\" data-order-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("./orderItem.hbs"),depth0,{"name":"./orderItem","hash":{"url":(depth0 != null ? lookupProperty(depth0,"url") : depth0),"type":"listItem","order":(depth0 != null ? lookupProperty(depth0,"order") : depth0)},"data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "oa-orderSwitcherModal-order--current";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-orderSwitcher\">\n  <nav class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/conditionals/neq.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tooltipConfig") : depth0)) != null ? lookupProperty(stack1,"alertType") : stack1)) != null ? lookupProperty(stack1,"code") : stack1),"info",{"name":"conditionals/neq","hash":{},"data":data,"loc":{"start":{"line":2,"column":20},"end":{"line":2,"column":76}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":120}}})) != null ? stack1 : "")
    + "\" data-test=\"orderSwitcher\">\n\n    <div class=\"oa-orderSwitcher-header\">\n      <!-- modal opener -->\n      <div class=\"oa-button oa-button--icon oa-orderSwitcher-modalOpener\"\n           data-toggle=\"modal\" data-target=\".oa-orderSwitcherModal\" data-placement=\"bottom\" data-trigger=\"hover\"\n           title=\""
    + container.escapeExpression(__default(require("../helpers/i18n.js")).call(alias1,"order_switcher.order_chooser.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":18},"end":{"line":8,"column":63}}}))
    + "\"\n           data-test=\"orderSwitcher.modalOpener\">\n        <span class=\"glyphicon glyphicon-chevron-down\"></span>\n      </div>\n      <div class=\"oa-orderSwitcher-selectedOrderLabelWrapper\" component=\"--selectedOrderLabel\"></div>\n    </div>\n  </nav>\n\n  <!-- Modal -->\n  <div class=\"modal fade oa-orderSwitcherModal\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"oaOrderSwitcherModalLabel\" data-test=\"orderSwitcherModal\">\n    <div class=\"modal-dialog modal-lg\" role=\"document\">\n      <div class=\"modal-content\">\n        <div class=\"modal-header\">\n          <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;</span></button>\n          <h4 class=\"modal-title\" id=\"oaOrderSwitcherModalLabel\">Choose order</h4>\n        </div>\n        <div class=\"modal-body oa-orderSwitcherModal-orderList\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"ordersMetadata") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":10},"end":{"line":31,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});