import { Helpers, Models, Services } from '@cc/cc-app-commons';
// @ts-ignore
import orderTimelineTransactionalMailTemplate from '../../../templates/order-timeline/orderTransactionalMailTimelineEntry.hbs';
import { toDefaultDateFormat } from '../../util/date';
import TimelineEntryViewModel from '../TimelineEntryViewModel';
import TimelineEntryStatus from './TimelineEntryStatus';
import { ViewModel } from '../types';
import TransactionalMailPreviewViewModel from './TransactionalMailPreviewViewModel';
import transactionalMailPreviewViewModelFactory from './transactionalMailPreviewViewModelFactory';
import AppActivityAction = Services.AppActivityAction;

const { render } = Helpers.ClientHelpers;

export default class TransactionalMailTimelineEntryViewModel extends TimelineEntryViewModel {
  private readonly modelIndex: number;
  private readonly allMails: Models.Mail[];
  private readonly transactionalMailPreviewViewModel: TransactionalMailPreviewViewModel;

  private static showTransactionalMailContentElementSelector =
    '.oa-timelineEntry-showMailContent a';

  constructor(
    model: Models.Mail,
    modelIndex: number,
    allMails: Models.Mail[],
    gatewayUrl: string,
    ccAdminUrl: string,
  ) {
    super(model);
    this.modelIndex = modelIndex;
    this.allMails = allMails;
    this.transactionalMailPreviewViewModel = transactionalMailPreviewViewModelFactory(
      this.model,
      this.allMails,
      gatewayUrl,
      ccAdminUrl,
    );
  }

  renderFor(el: Element, context: any): void {
    const parentEl = <Element>el.parentNode;
    render(
      <any>el,
      orderTimelineTransactionalMailTemplate(
        new TransactionalMailHelperModel(this.model, this.modelIndex).toModel(),
      ),
    );
    el = parentEl.querySelector(`.oa-timelineEntry:nth-child(${this.modelIndex + 1})`);

    this.initShowTransactionalMailDetails(el, context);
  }

  private initShowTransactionalMailDetails(el: Element, context: ViewModel.Context) {
    $(el).on(
      'click',
      TransactionalMailTimelineEntryViewModel.showTransactionalMailContentElementSelector,
      null,
      (e) => {
        e.preventDefault();
        this.transactionalMailPreviewViewModel.renderFor(context);
        this.destroyFunctions.push(
          function () {
            this.transactionalMailPreviewViewModel.destroy();
          }.bind(this),
        );
        context.metric.logAppActivity(AppActivityAction.EMAIL_CONTENT__LOADED);
      },
    );
    this.destroyFunctions.push(function () {
      $(el).off(
        'click',
        TransactionalMailTimelineEntryViewModel.showTransactionalMailContentElementSelector,
      );
    });
  }
}

class TransactionalMailHelperModel {
  private readonly model: Models.Mail;
  private readonly modelIndex: number;

  constructor(model: Models.Mail, modelIndex: number) {
    this.model = model;
    this.modelIndex = modelIndex;
  }

  toModel(): object {
    const model: any = {
      ...this.model,
    };
    model.date = toDefaultDateFormat(this.model.date);
    model.status = this.getStatus();
    model.lastStatusDetails = this.model.lastStatusDetails;
    model.email = this.model.email;

    model.isOpened = this.model.isOpened;
    model.isClicked = this.model.isClicked;
    model.isAddedToSpam = this.model.isAddedToSpam;
    model.hasLastStatusDetails = this.model.hasLastStatusDetails;
    return model;
  }

  private getStatus(): any {
    return new TimelineEntryStatus(this.model.status, this.modelIndex, msgStatusToIconMap);
  }
}

const msgStatusToIconMap = new Map<string, string>();
msgStatusToIconMap.set(Models.MailStatus.SENT, 'arrow-up');
msgStatusToIconMap.set(Models.MailStatus.PENDING, 'arrow-down');
msgStatusToIconMap.set(Models.MailStatus.NOT_SENT, 'stop');
msgStatusToIconMap.set(Models.MailStatus.OTHER, 'flash');
