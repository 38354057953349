import { Models } from '@cc/cc-app-commons';

export default abstract class TransactionalMailPreviewViewModel {
  protected readonly currentMail: Models.Mail;
  protected readonly allMails: Models.Mail[];

  constructor(currentMail: Models.Mail, allMails: Models.Mail[]) {
    this.currentMail = currentMail;
    this.allMails = allMails;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderFor(context: any): void {
    // this should be implemented in child classes
  }
}
