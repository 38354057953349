var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span data-test=\"timelineEntry.orderPaymentTransaction.provider\">("
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"connector") : depth0), depth0))
    + ")</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<br/>\n        (<em>"
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.timeline.payments.header.failure_reason",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":13},"end":{"line":24,"column":69}}}))
    + ": <span data-test=\"timelineEntry.orderPaymentTransaction.errorMessage\">"
    + alias2(__default(require("../helpers/withDefault.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"errorMessage") : depth0),__default(require("../helpers/i18n.js")).call(alias1,"default.unknown",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":167},"end":{"line":24,"column":191}}}),{"name":"withDefault","hash":{},"data":data,"loc":{"start":{"line":24,"column":140},"end":{"line":24,"column":193}}}))
    + "</span></em>)";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../partials/monetaryValue.hbs"),(depth0 != null ? lookupProperty(depth0,"paymentValue") : depth0),{"name":"monetaryValue","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"default.not_available",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":33,"column":40}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-timelineEntry oa-timelineEntry-orderPaymentTransaction\">\n  <div class=\"oa-timelineEntry-timeMarker\" title=\""
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"payment_transaction.status_header",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":50},"end":{"line":2,"column":94}}}))
    + ": "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,__default(require("../helpers/concat.js")).call(alias1,"payment_transaction.status.",((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":2,"column":103},"end":{"line":2,"column":153}}}),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":96},"end":{"line":2,"column":155}}}))
    + "\">\n    <div class=\"oa-timelineEntry-icon oa-timelineEntry-icon--"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"size") : stack1), depth0))
    + " oa-timelineEntry-icon--"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">\n      <div class=\"oa-timelineEntryIcon-waiting\">\n        <div class=\"glyphicon glyphicon-"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"icon") : stack1), depth0))
    + "\"></div>\n      </div>\n    </div>\n    <div class=\"oa-timelineEntry-date\">\n      <span class=\"oa-timelineEntry-header\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.timeline.payments.header.payment_id",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":44},"end":{"line":9,"column":96}}}))
    + ":</span><span data-test=\"timelineEntry.orderPaymentTransaction.id\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"paymentId") : depth0), depth0))
    + "</span>\n    </div>\n  </div>\n  <div class=\"oa-timelineEntry-content\">\n    <div>\n      <span class=\"oa-timelineEntry-header\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.timeline.payments.header.payment_method",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":44},"end":{"line":14,"column":100}}}))
    + ":</span>\n        <span data-test=\"timelineEntry.orderPaymentTransaction.method\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"paymentMethod") : depth0), depth0))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"connector") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div>\n      <span class=\"oa-timelineEntry-header\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.timeline.payments.header.status",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":21,"column":44},"end":{"line":21,"column":92}}}))
    + ":</span><span data-test=\"timelineEntry.orderPaymentTransaction.state\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"paymentState") : depth0), depth0))
    + "</span>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isStatusFailed") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":6},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + "</div>\n    <div class=\"oa-timelineEntry-paymentContent\">\n      <div class=\"oa-timelineEntry-header\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.timeline.payments.header.amount",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":43},"end":{"line":28,"column":91}}}))
    + ":</div>\n      <div data-test=\"timelineEntry.orderPaymentTransaction.value\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/logic/not.js")).call(alias1,__default(require("../helpers/nullOrUndefined.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"paymentValue") : depth0)) != null ? lookupProperty(stack1,"amount") : stack1),{"name":"nullOrUndefined","hash":{},"data":data,"loc":{"start":{"line":30,"column":28},"end":{"line":30,"column":65}}}),{"name":"./logic/not","hash":{},"data":data,"loc":{"start":{"line":30,"column":13},"end":{"line":30,"column":66}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":30,"column":6},"end":{"line":34,"column":15}}})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n</div>";
},"usePartial":true,"useData":true});