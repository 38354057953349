var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "oa-orderAddressActionButton--inactive";
},"3":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.actions.modify_shipping_address.tooltip.active",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":49},"end":{"line":3,"column":112}}}));
},"5":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order.actions.modify_shipping_address.tooltip.inactive",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":120},"end":{"line":3,"column":185}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"oa-orderAddressActionButton oa-orderAddressActionButton__modifyShippingAddress\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/logic/not.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isShippingAddressModifiable") : depth0),{"name":"logic/not","hash":{},"data":data,"loc":{"start":{"line":2,"column":18},"end":{"line":2,"column":59}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":106}}})) != null ? stack1 : "")
    + "\"\n       title=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isShippingAddressModifiable") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":192}}})) != null ? stack1 : "")
    + "\"\n       data-placement=\"bottom\"\n       data-test=\"modifyShippingAddressButton\"\n       data-test-order-id=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"orderId") : depth0), depth0))
    + "\">\n\n    <a>\n      <span class=\"glyphicon glyphicon-pencil u-pr-s\"></span>\n      <span class=\"oa-orderActionButton__label\"> "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.actions.modify_shipping_address.button.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":49},"end":{"line":10,"column":110}}}))
    + "</span>\n    </a>\n</span>\n\n";
},"useData":true});