import { Config, Models, Modules } from '@cc/cc-app-commons';
import TransactionalMailPreviewViewModel from './TransactionalMailPreviewViewModel';

export default class TransactionalMailStandalonePreviewViewModel extends TransactionalMailPreviewViewModel {
  private readonly el: HTMLElement;
  private readonly mailPreviewIframe: HTMLIFrameElement;
  private readonly mailModalPromise: Promise<Modules.MailModal>;
  private readonly ccAdminUrl: string;
  private readonly gatewayUrl: string;
  private static MAIL_CONTENT_PREVIEW_CONTAINER_SELECTOR = '.oa-app .oa-mailsStandalone';
  private static MAIL_CONTENT_SELECTOR = '.oa-mailStandaloneContent';
  private static CLOSE_MAIL_PREVIEW_BUTTON_SELECTOR = '.oa-mailsStandalonePreviewCloseButton';
  private static MAIL_CONTENT_PREVIEW_IFRAME_SELECTOR = 'iframe[name="mailPreviewContainer"]';
  private static MAIL_MODAL_LOCATION = 'mailModal.html';

  private static APP_TOP_MENU_SIZE = 100;
  private static XL_MARGIN_SIZE = 32;
  private static MAIL_PREVIEW_HEADER_SIZE = 68;

  constructor(
    currentMail: Models.Mail,
    allMails: Models.Mail[],
    gatewayUrl: string,
    ccAdminUrl: string,
  ) {
    super(currentMail, allMails);
    this.el = document.querySelector(
      TransactionalMailStandalonePreviewViewModel.MAIL_CONTENT_PREVIEW_CONTAINER_SELECTOR,
    );
    this.mailPreviewIframe = this.el.querySelector(
      TransactionalMailStandalonePreviewViewModel.MAIL_CONTENT_PREVIEW_IFRAME_SELECTOR,
    ) as HTMLIFrameElement;
    this.mailModalPromise = this.prepareMailModal();
    this.gatewayUrl = gatewayUrl;
    this.ccAdminUrl = ccAdminUrl;
  }

  private prepareMailModal(): Promise<Modules.MailModal> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      $(this.mailPreviewIframe).on('load', () => {
        resolve(this.buildMailModal());
        $(this.mailPreviewIframe).off('load');
      });
      this.mailPreviewIframe.src = TransactionalMailStandalonePreviewViewModel.MAIL_MODAL_LOCATION;
    });
  }

  private buildMailModal(): Modules.MailModal {
    const configuration = new Config.EnvironmentConfiguration()
      .setAppName('cc-order-app')
      .setCcAdminUrl(`${this.ccAdminUrl}`)
      .setCustomerCommunicationAuditUrl(`${this.gatewayUrl}/customer-communication-audit/v2`);
    return new Modules.MailModal(null, configuration, this.mailPreviewIframe.contentDocument.body);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderFor(context: any): void {
    this.adjustMailPreviewSize();
    this.show();
    this.renderMailModal();
    this.registerHandlers();
  }

  private renderMailModal() {
    this.mailModalPromise.then((mailModal) => {
      mailModal.render({ currentMail: this.currentMail, allMails: this.allMails });
    });
  }

  private show() {
    this.el.style.display = 'initial';
  }

  private adjustMailPreviewSize() {
    this.el.style.height = `${this.calculateMailPreviewHeight()}px`;
    const mailContent = this.el.querySelector(
      TransactionalMailStandalonePreviewViewModel.MAIL_CONTENT_SELECTOR,
    ) as HTMLElement;
    mailContent.style.height = `${this.calculateMailPreviewContentHeight()}px`;
  }

  private calculateMailPreviewHeight(): number {
    return (
      window.innerHeight -
      TransactionalMailStandalonePreviewViewModel.APP_TOP_MENU_SIZE -
      2 * TransactionalMailStandalonePreviewViewModel.XL_MARGIN_SIZE
    );
  }

  private calculateMailPreviewContentHeight(): number {
    return (
      this.calculateMailPreviewHeight() -
      TransactionalMailStandalonePreviewViewModel.MAIL_PREVIEW_HEADER_SIZE -
      2 * TransactionalMailStandalonePreviewViewModel.XL_MARGIN_SIZE
    );
  }

  private registerHandlers() {
    $(this.el).on(
      'click',
      TransactionalMailStandalonePreviewViewModel.CLOSE_MAIL_PREVIEW_BUTTON_SELECTOR,
      null,
      (e) => {
        e.preventDefault();
        this.hide();
      },
    );
  }

  private hide() {
    this.el.style.display = 'none';
    this.destroy();
  }

  destroy() {
    $(this.el).off(
      'click',
      TransactionalMailStandalonePreviewViewModel.CLOSE_MAIL_PREVIEW_BUTTON_SELECTOR,
    );
    $(this.mailPreviewIframe).off('load');
    this.mailModalPromise?.then((mailModal) => mailModal.destroy());
  }
}
