var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-addresses\">\n"
    + ((stack1 = container.invokePartial(require("./addressActionsModal.hbs"),depth0,{"name":"./addressActionsModal","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <div class=\"u-ml-l u-mr-l oa-addressEntry\" data-test=\"orderAddresses.shippingAddress\">\n    <table class=\"table table-condensed u-table oa-addressEntry\">\n    <thead>\n    <tr>\n      <th>\n        <div>\n          <span class=\"glyphicon glyphicon-home u-pr-s\"></span>\n          "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"shippingAddress") : depth0)) != null ? lookupProperty(stack1,"labelI18nKey") : stack1),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":10},"end":{"line":10,"column":47}}}))
    + ":\n        </div>\n        </th>\n    </tr>\n    </thead>\n      <tbody>\n"
    + ((stack1 = container.invokePartial(require("./orderAddressBody.hbs"),(depth0 != null ? lookupProperty(depth0,"shippingAddress") : depth0),{"name":"./orderAddressBody","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      <tr>\n        <td class=\"oa-button u-border-top-none\">\n          <div>\n"
    + ((stack1 = container.invokePartial(require("./modifyShippingAddressButton.hbs"),depth0,{"name":"./modifyShippingAddressButton","hash":{"isShippingAddressModifiable":(depth0 != null ? lookupProperty(depth0,"isShippingAddressModifiable") : depth0)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "          </div>\n        </td>\n      </tr>\n      </tbody>\n    </table>\n  </div>\n\n  <div class=\"u-ml-l u-mr-l oa-addressEntry\" data-test=\"orderAddresses.billingAddress\">\n    <table class=\"table table-condensed u-table oa-addressEntry\">\n    <thead>\n    <tr>\n      <th>\n        <div>\n          <span class=\"glyphicon glyphicon-home u-pr-s\"></span>\n          "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"billingAddress") : depth0)) != null ? lookupProperty(stack1,"labelI18nKey") : stack1),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":10},"end":{"line":35,"column":46}}}))
    + ":\n        </div>\n        </th>\n    </tr>\n    </thead>\n      <tbody>\n"
    + ((stack1 = container.invokePartial(require("./orderAddressBody.hbs"),(depth0 != null ? lookupProperty(depth0,"billingAddress") : depth0),{"name":"./orderAddressBody","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      <tr>\n        <td class=\"oa-button u-border-top-none\">\n          <div>\n"
    + ((stack1 = container.invokePartial(require("./modifyBillingAddressButton.hbs"),depth0,{"name":"./modifyBillingAddressButton","hash":{"isBillingAddressModifiable":(depth0 != null ? lookupProperty(depth0,"isBillingAddressModifiable") : depth0)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "          </div>\n        </td>\n      </tr>\n      </tbody>\n    </table>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});