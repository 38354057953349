enum ModifyShippingAddressReason {
  ORDERING_FOR_SOMEONE_ELSE = 'ORDERING_FOR_SOMEONE_ELSE',
  INCORRECT_OR_OLD_ADDRESS = 'INCORRECT_OR_OLD_ADDRESS',
  CUSTOMER_AWAY = 'CUSTOMER_AWAY',
  CONTACT_DETAILS_CHANGE = 'CONTACT_DETAILS_CHANGE',
  NOT_ENOUGH_CHARACTERS = 'NOT_ENOUGH_CHARACTERS',
  OTHER = 'OTHER',
}

export default ModifyShippingAddressReason;
