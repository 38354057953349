import { Helpers } from '@cc/cc-app-commons';
import { Order } from '../models/Order';
import { ViewModelInputData } from '../types';
import TimelineEntriesViewModel from './TimelineEntriesViewModel';
import LoaderViewModel, { withLoader } from './LoaderViewModel';
// @ts-ignore
import orderTimelineTemplate from '../../templates/order-timeline/orderTimeline.hbs';
import '../../templates/order-timeline/orderTimeline.css';
import TimelineEntryViewModel from './TimelineEntryViewModel';
import { ViewModel } from './types';
import Context = ViewModel.Context;

const { clear, renderTo } = Helpers.ClientHelpers;

export default abstract class OrderTimelineViewModel {
  protected readonly el: HTMLElement;
  protected order: Order;
  protected context: Context;
  protected loaderView: LoaderViewModel;
  private destroyFunctions: Function[] = [];

  constructor(domElement: HTMLElement, model: ViewModelInputData.OrderViewModel, context: Context) {
    this.el = domElement;
    const { order } = model;
    this.order = order;
    this.context = context;
  }

  abstract get timelineTitleTranslationKey(): string;

  async getTimelineViewModels(): Promise<TimelineEntryViewModel[]> {
    return [];
  }

  render(): void {
    renderTo(this.el, orderTimelineTemplate({ title: this.timelineTitleTranslationKey }));
    this.loaderView = new LoaderViewModel(this.el.querySelector('.loader'));

    withLoader(this.loaderView, async () => {
      return this.getTimelineViewModels().then((models: TimelineEntryViewModel[]) => {
        const timelineViewModel = new TimelineEntriesViewModel(this.el, models, this.context);
        timelineViewModel.render();
        this.destroyFunctions.push(function () {
          timelineViewModel.destroy();
        });
      });
    });
  }

  destroy(): void {
    this.destroyFunctions.forEach((fun) => fun());
    clear(this.el);
  }
}
