import { Helpers } from '@cc/cc-app-commons';
import { ViewModel } from '../types';
import mediaTypeDetector from '../../util/MediaTypeDetector';
import OrderActionButtonsViewModel from './OrderActionButtonsViewModel';

const { clear, renderTo } = Helpers.ClientHelpers;

const tplPlugin = require('../../../templates/order-actions/plugin.hbs');
const tplStandalone = require('../../../templates/order-actions/standalone.hbs');

export default class OrderActionsViewModel {
  private readonly el: Element;
  private readonly context: ViewModel.Context;
  private orderActionButtonsViewModel: OrderActionButtonsViewModel;

  constructor(domElement: Element, context: ViewModel.Context) {
    this.el = domElement;
    this.context = context;
  }

  async render(): Promise<void> {
    const tpl = mediaTypeDetector.isPlugin() ? tplPlugin : tplStandalone;
    const canPerformOrderActions = await this.canPerformOrderActions();
    renderTo(
      this.el,
      tpl({
        canPerformOrderActions,
      }),
    );

    if (canPerformOrderActions) {
      await this.renderOrderActionButtons();
    }
  }

  private async canPerformOrderActions() {
    const isArticleBasedDiscountsEnabledForCurrentOrder =
      await this.context.data.isArticleBasedDiscountsEnabledForCurrentOrder();
    return (
      this.context.authorization.isAuthorizedToOpenClaimWizard() ||
      this.context.authorization.isAuthorizedToCancelOrder() ||
      isArticleBasedDiscountsEnabledForCurrentOrder
    );
  }

  private async renderOrderActionButtons() {
    const containerElement = this.el.querySelector('.oa-orderActionsModal-actions');
    this.orderActionButtonsViewModel = new OrderActionButtonsViewModel(
      containerElement,
      this.context,
    );
    await this.orderActionButtonsViewModel.render();
  }

  destroy() {
    this.orderActionButtonsViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    clear(this.el);
  }
}
