import App from './app';
import { LocationSearchParam } from '../models/LocationSearchParam';
import AppContextFactoryProvider from './appContextFactoryProvider';
import * as Sentry from '@sentry/browser';

export function createApp(searchParams) {
  Sentry.init({
    dsn: 'https://cdb439fbadfded41aa16ba1b06755e35@o354215.ingest.us.sentry.io/4506950289588224',
    integrations: [Sentry.browserTracingIntegration()],
    environment: process.env.NODE_ENV,
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    sampleRate: 1.0, // Sends 100% of errors
  });

  let contextFactory;
  let appEventsHandler;
  const customerId = searchParams[LocationSearchParam.customerId];
  const invoiceId = searchParams[LocationSearchParam.invoiceId];
  const orderId = searchParams[LocationSearchParam.orderId];
  const ticketId = searchParams[LocationSearchParam.ticketId];

  const appContextFactoryProvider = new AppContextFactoryProvider(
    ticketId,
    customerId,
    orderId,
    invoiceId,
  );

  contextFactory = appContextFactoryProvider.createAppContextFactory(searchParams.client);
  appEventsHandler = appContextFactoryProvider.createEventHandlerFactory(searchParams.client);
  return new App(contextFactory, appEventsHandler);
}
