var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <tr>\n    <td colspan=\"3\" class=\"oa-parcels__noParcels\" data-test=\"orderSummary.parcel.noParcels\">\n"
    + ((stack1 = container.invokePartial(require("../partials/noResultsFound.hbs"),depth0,{"name":"noResultsFound","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </td>\n  </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/logic/not.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"parcelsToShow") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"logic/not","hash":{},"data":data,"loc":{"start":{"line":1,"column":6},"end":{"line":1,"column":40}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});