var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<img class=\"oa-icon\" src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"shop") : stack1)) != null ? lookupProperty(stack1,"shopName") : stack1), depth0))
    + "_logo.png\" alt=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"shop") : stack1)) != null ? lookupProperty(stack1,"shopName") : stack1), depth0))
    + "\" data-test=\"orderHeader.brandIcon\"/>\n";
},"useData":true});