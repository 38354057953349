import I18n from 'i18n';

export default function (translationKey, ...contextKeysAndValues) {
  contextKeysAndValues.pop(); // the last argument is the Handlebars context object which is not desired here
  let context = {};
  try {
    // array [key1, value1, key2, value2, ...]
    if (contextKeysAndValues.length > 1) {
      for (let i = 0; i < contextKeysAndValues.length; i += 2) {
        context[contextKeysAndValues[i]] = contextKeysAndValues[i + 1];
      }
    }
    // context object given as arg
    if (contextKeysAndValues.length === 1) {
      context = Object.assign({}, contextKeysAndValues[0]);
    }
  } catch (e) {
    console.error(e.message);
  }
  return I18n.t(translationKey, context);
}
