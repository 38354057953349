import { Helpers, Services } from '@cc/cc-app-commons';
import { Order } from '../models/Order';
// @ts-ignore
import orderTemplate from '../../templates/order.hbs';
import { ViewModelInputData } from '../types';
import OrderSummaryViewModel from './OrderSummaryViewModel';
import OrderTimelineViewModel from './OrderTimelineViewModel';
import OrderCommunicationTimelineViewModel from './orderTimeline/OrderCommunicationTimelineViewModel';
import OrderPaymentsTimelineViewModel from './orderTimeline/OrderPaymentsTimelineViewModel';
import OrderActionsViewModel from './orderActions/OrderActionsViewModel';
import { setUrlHashValue } from '../util/DocumentLocationModifier';
import AppActivityAction = Services.AppActivityAction;

const { clear, createChildElement, renderTo } = Helpers.ClientHelpers;

export default class OrderViewModel {
  el: HTMLElement;
  model: ViewModelInputData.OrderViewModel;
  order: Order;
  context: any;
  private orderActionsViewModel: OrderActionsViewModel;
  orderSummaryViewModel: OrderSummaryViewModel;
  orderCommunicationTimelineViewModel: OrderTimelineViewModel;
  orderPaymentsTimelineViewModel: OrderTimelineViewModel;

  constructor(domElement: HTMLElement, model: ViewModelInputData.OrderViewModel, context: any) {
    this.el = domElement;
    this.model = model;
    const { order } = model;
    this.order = order;
    this.context = context;
  }

  get orderHeaderModel() {
    return {
      order: this.order.toTemplateData(),
    };
  }

  render(): void {
    renderTo(this.el, orderTemplate(this.orderHeaderModel));
    this.renderOrderSummary();
    this.initTabs();
  }
  initTabs() {
    function addTabInfoToUrl(tabIdentifier: string) {
      setUrlHashValue('tab', tabIdentifier);
    }

    $('#orderSummaryTabLink').on('shown.bs.tab', () => addTabInfoToUrl('summary'));
    $('#orderTimelineTabLink').on('shown.bs.tab', () => addTabInfoToUrl('timeline'));
    addTabInfoToUrl('summary');

    $('#orderTimelineTabLink').on('shown.bs.tab', this.renderOrderTimeline.bind(this));
  }

  renderOrderSummary(): void {
    if (!this.orderSummaryViewModel) {
      this.orderSummaryViewModel = new OrderSummaryViewModel(
        this.el.querySelector('.oa-orderSummaryContainer'),
        this.model,
        this.context,
      );
      this.orderSummaryViewModel.render();
    }
  }

  renderOrderTimeline(): void {
    this.context.metric.logAppActivity(AppActivityAction.ORDER_TIMELINE_TAB__LOADED);
    this.renderOrderPaymentsTimeline();
    this.renderOrderCommunicationTimeline();
  }

  private renderOrderCommunicationTimeline(): void {
    if (!this.orderCommunicationTimelineViewModel) {
      const timelineWrapperElement = createChildElement(
        this.el.querySelector('.oa-orderCommunicationTimelineContainer'),
      );
      this.orderCommunicationTimelineViewModel = new OrderCommunicationTimelineViewModel(
        timelineWrapperElement,
        this.model,
        this.context,
      );
      this.orderCommunicationTimelineViewModel.render();
    }
  }

  private renderOrderPaymentsTimeline(): void {
    if (!this.orderPaymentsTimelineViewModel) {
      const timelineWrapperElement = createChildElement(
        this.el.querySelector('.oa-orderPaymentTransactionsTimelineContainer'),
      );
      this.orderPaymentsTimelineViewModel = new OrderPaymentsTimelineViewModel(
        timelineWrapperElement,
        this.model,
        this.context,
      );
      this.orderPaymentsTimelineViewModel.render();
    }
  }

  destroy(): void {
    $('#orderTimelineTabLink').off('shown.bs.tab');
    $('#orderSummaryTabLink').off('shown.bs.tab');
    this.orderCommunicationTimelineViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.orderPaymentsTimelineViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.orderSummaryViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    this.orderActionsViewModel?.destroy(); /* eslint-disable-line no-unused-expressions */
    clear(this.el);
  }
}
