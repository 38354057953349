import { Models } from '@cc/cc-app-commons';

export default class ParcelPhysicalArticle extends Models.BaseModel {
  orderPositionId: number = undefined;
  articleId: number = undefined;
  quantity: number = undefined;
  name: string = undefined;
  shortText: string = undefined;

  isNameAndShortTextNotDefined(): boolean {
    return !this.name && !this.shortText;
  }
}
