import { Models } from '@cc/cc-app-commons';
import { Order, Suborder } from '../../../models/Order';
import OrderTimelineModel from '../../../models/OrderTimelineModel';
import { ViewModel } from '../../types';
import { AppContextServices } from './AppContextServices';
import { OrderPayments } from '../../../models/OrderPayments';
import Payment from '../../../models/Payment';
import Customer from '../../../models/Customer';
import ShopArticle from '../../../models/ShopArticle';
import CancelOrderReason, { CancelParcelReason } from '../../../models/CancelOrderReason';
import ParcelPhysicalArticle from '../../../models/ParcelPhysicalArticle';
import CancelledOrderRefundData from '../../../models/CancelledOrderRefundData';
import RefundCreation from '../../../models/RefundCreation';
import OrderResolutionMetadata from '../../../models/OrderResolutionMetadata';
import Claim from '../../../models/Claim';
import ClaimDraft from '../../../models/ClaimDraft';
import { memoizeAsync } from 'utils-decorators';
import OrderParcel from '../../../models/OrderParcel';
import Feature from '../../../util/Feature';
import Shop from '../../../models/Shop';
import ArticleBasedDiscount from '../../../models/ArticleBasedDiscount';
import ModifyShippingAddressReason from '../../../models/ModifyShippingAddressReason';
import ModifyBillingAddressReason from '../../../models/ModifyBillingAddressReason';
import Address from '../../../models/Address';

type Remover = Models.Remover;

const APP_DATA_CACHING_MILLIS: number = +process.env.APP_DATA_CACHING_MILLIS;

export class AppDataContext implements ViewModel.DataContext {
  protected readonly services: AppContextServices;
  private _orders: Promise<Order[]>;
  private readonly _parcelPictures: Map<Number, Blob> = new Map();
  protected _currentOrder: Order;
  protected _currentOrderReactive: Models.ObservableReactiveModel<Order>;

  constructor(services: AppContextServices) {
    this.services = services;
  }

  async initCurrentOrderModel() {
    const orderId = this.determineCurrentOrderId();
    let orderIdIndex = (await this.orders)?.findIndex((order) => order.id === orderId);
    orderIdIndex = orderIdIndex >= 0 ? orderIdIndex : 0;
    const currentOrder = (await this.orders)[orderIdIndex];
    this._currentOrder = new Order().fromData(currentOrder.toData());
    this._currentOrderReactive = new Models.ObservableReactiveModel(this._currentOrder);
  }

  determineCurrentOrderId(): number {
    return this.services.orderResolutionService.currentOrderId();
  }

  onCurrentOrderChange(callback: Function): Remover {
    return this._currentOrderReactive.onChange(async () => {
      const order = await this.currentOrder;
      callback(order);
    });
  }

  async changeCurrentOrderTo(orderId: number): Promise<void> {
    await this.order(orderId).then((order) => this._currentOrder.fromData(order.toData()));
  }

  get currentOrder(): Promise<Order> {
    return Promise.resolve(this._currentOrder);
  }

  async refreshCurrentOrder() {
    const currentOrderId = (await this.currentOrder).id;
    const refreshedOrder = (await this.services.order.fetchOrder(currentOrderId))[0];
    const orders = await this.orders;
    const currentOrderIndexInArray = orders?.findIndex((order) => order.id === currentOrderId);
    orders.splice(currentOrderIndexInArray, 1, refreshedOrder);
    this._currentOrderReactive.model.fromData(refreshedOrder.toData());
  }

  get orders(): Promise<Order[]> {
    if (this._orders === undefined) {
      this._orders = this.services.orderResolutionService.getOrders();
    }
    return this._orders;
  }

  async order(id: number): Promise<Order> {
    const orders = await this.orders;
    return Promise.resolve(orders?.filter((o) => o.id === id)[0]);
  }

  orderHistory(orderId: number): Promise<OrderTimelineModel> {
    return this.services.customerCommunicationService
      .fetchMailsForOrder(Number(orderId))
      .then((mails: Models.Mail[]) => {
        return new OrderTimelineModel(mails);
      });
  }

  claims(suborderId: string): Promise<Claim[]> {
    return this.services.claim.getClaimsForSuborder(suborderId);
  }

  claimDrafts(suborderId: string): Promise<ClaimDraft[]> {
    return this.services.claimDraft.getClaimDraftsForOrder(suborderId);
  }

  parcelPicture(parcelId: number): Promise<Blob> {
    if (this._parcelPictures.has(parcelId)) {
      return Promise.resolve(this._parcelPictures.get(parcelId));
    }
    return this.services.parcelPictureService.fetchParcelPicture(parcelId).then((it) => {
      this._parcelPictures.set(parcelId, it);
      return it;
    });
  }

  async orderArticleVariantData(shopArticleId: number, suborderId: string): Promise<ShopArticle> {
    const suborder = (await this.currentOrder).suborders.find((so) => so.id === suborderId);
    return this.services.orderItemService.fetchArticleVariantData(shopArticleId, suborder.locale);
  }

  @memoizeAsync(APP_DATA_CACHING_MILLIS)
  async orderPayments(orderId: number): Promise<OrderPayments> {
    const relevantOrder: Order = await this.order(orderId);
    if (!relevantOrder) {
      throw new Models.Errors.OrdersNotFoundAppError(
        new Error(`No relevant order with id ${orderId} found`),
      );
    }
    return this.services.payments.fetchOrderPayments(orderId, relevantOrder.shopCurrency);
  }

  async isOrderCancellable(orderId: number): Promise<Boolean> {
    return this.services.order.isOrderCancellable(orderId);
  }

  async cancelOrder(orderId: number, reason: CancelOrderReason): Promise<void> {
    const { zooId } = await this.services.user.loggedInUser();
    return this.services.order.cancelOrder(orderId, reason, zooId);
  }

  async isParcelCancellable(parcelId: number): Promise<Boolean> {
    return this.services.parcel.isParcelCancellable(parcelId);
  }

  async getCancellableParcels(suborder: Suborder): Promise<OrderParcel[]> {
    const cancellableParcels = [];
    for (const parcel of suborder?.parcels) {
      const isParcelCancellable = await this.isParcelCancellable(parcel.id);
      if (isParcelCancellable) {
        cancellableParcels.push(parcel);
      }
    }
    return cancellableParcels;
  }

  async cancelParcel(parcelId: number, orderId: number, reason: CancelParcelReason): Promise<void> {
    const { username, zooId } = await this.services.user.loggedInUser();
    const result = await this.services.parcel.cancelParcel(
      parcelId,
      orderId,
      reason,
      username,
      zooId,
    );
    if (result.error) {
      throw result.error;
    }
  }

  async modifyShippingAddress(
    orderId: number,
    modifyShippingAddressReason: ModifyShippingAddressReason,
    address: Address,
  ): Promise<void> {
    const { zooId } = await this.services.user.loggedInUser();
    return this.services.address.modifyShippingAddress(
      orderId,
      zooId,
      modifyShippingAddressReason,
      address,
    );
  }

  async modifyBillingAddress(
    orderId: number,
    modifyBillingAddressReason: ModifyBillingAddressReason,
    address: Address,
  ): Promise<void> {
    const { zooId } = await this.services.user.loggedInUser();
    return this.services.address.modifyBillingAddress(
      orderId,
      zooId,
      modifyBillingAddressReason,
      address,
    );
  }

  async getCancelledOrderRefundData(orderId: number): Promise<CancelledOrderRefundData> {
    return this.services.order.getCancelledOrderRefundData(orderId);
  }

  async refundCancelledOrder(orderId: number): Promise<RefundCreation> {
    const { zooId } = await this.services.user.loggedInUser();
    return this.services.order.createRefund(orderId, zooId);
  }

  async enrichPaymentWithErrorDetails(payment: Payment): Promise<Payment> {
    return this.services.payments.enrichPaymentWithErrorDetails(payment);
  }

  enrichPhysicalArticleWithName(
    parcelPhysicalArticle: ParcelPhysicalArticle,
  ): Promise<ParcelPhysicalArticle> {
    return this.services.orderItemService.enrichPhysicalArticleWithName(parcelPhysicalArticle);
  }

  orderResolutionMetadata(order: Order): Promise<OrderResolutionMetadata> {
    return this.services.orderResolutionService.getOrderResolutionMetadata(order);
  }

  get customer(): Promise<Customer> {
    return this.currentOrder.then((order) =>
      this.services.customer.fetchCustomer(order.customerId),
    );
  }

  async enrichCurrentOrderWithArticleBasedDiscountsData(): Promise<void> {
    const currentOrder = await this.currentOrder;
    const suborders = currentOrder.suborders;
    const zooSuborder = suborders.find((so) => so.isZooplusSuborder);

    try {
      const abdData: ArticleBasedDiscount[] =
        await this.services.articleBasedDiscountsService.getAbdTrackingData(
          zooSuborder.id,
          currentOrder.shopCurrency,
        );
      zooSuborder.articleBasedDiscounts = abdData;
      currentOrder.suborders = [zooSuborder, ...suborders.filter((so) => !so.isZooplusSuborder)];
    } catch (e) {
      console.warn('Error occurred when fetching Article Based Discounts data', e.toString());
    }
  }

  async isArticleBasedDiscountsEnabledForCurrentOrder(): Promise<boolean> {
    const featureName = 'ABD';
    if (!Feature.isEnabled(featureName)) {
      return false;
    }

    const order = await this.currentOrder;
    const featureData: any = Feature.getDataFor(featureName);

    if (featureData === null) {
      return true;
    }

    const featureDomains: string[] = featureData.domains;
    const matchesDomain = featureDomains.some((domain: string) => {
      const { shop, site } = new Shop(domain);
      return shop && shop === order.shop?.shop && site && site === order.shop?.site;
    });

    return matchesDomain;
  }
}
