import { Helpers, Utils } from '@cc/cc-app-commons';
// @ts-ignore
import orderPositionsTemplate from '../../templates/orderPositions.hbs';
import PositionModel, { PositionsModel } from '../models/PositionModel';
import ExpandableItemsViewModel from './ExpandableItemsAbstractModel';
import { Order } from '../models/Order';
import { ViewModel } from './types';
import ShopArticle from '../models/ShopArticle';
import mediaTypeDetector from '../util/MediaTypeDetector';
import OrderArticleNumberViewModel from './OrderArticleNumberViewModel';
import { ArticleLinksModel, ArticleNumberModel } from '../models/Article';
import ArticleBasedDiscount from '../models/ArticleBasedDiscount';

import '../../article-based-discounts.css';

const { renderTo } = Helpers.ClientHelpers;

export default class OrderPositionsViewModel extends ExpandableItemsViewModel<PositionModel> {
  static SHOW_MORE_CSS_CLASS_NAME = 'oa-positions__showMore';
  static ORDER_ITEM_VARIANT_NOT_AVAILABLE_CSS_CLASS_NAME = 'oa-positionsItemVariant--notAvailable';

  private readonly context: ViewModel.Context;
  private model: PositionsModel;
  private articleNumberVms: OrderArticleNumberViewModel[] = [];
  private articleBasedDiscountsLoaded = false;

  constructor(domElement: HTMLElement, model: PositionsModel, context: ViewModel.Context) {
    super(
      domElement,
      model.positions,
      Order.DEFAULT_POSITIONS_SHOWN_COUNT,
      OrderPositionsViewModel.SHOW_MORE_CSS_CLASS_NAME,
    );
    this.model = model;
    this.context = context;
  }

  async renderItems(): Promise<void> {
    const tplContext = {
      positionsToShow: this.itemsForTemplateContext(),
      articleBasedDiscounts: await this.getArticleBasedDiscountsByOrderPositionId(),
      articleBasedDiscountsLoaded: this.articleBasedDiscountsLoaded,
    };

    renderTo(this.el, orderPositionsTemplate(tplContext));
    this.renderArticleNumbers();
    this.loadArticleVariantDescription();
  }

  private async getArticleBasedDiscountsByOrderPositionId(): Promise<any> {
    const currentOrder = await this.context.data.currentOrder;
    const zooSuborder = currentOrder.suborders.find((so) => so.isZooplusSuborder);
    if (zooSuborder) {
      const abd = zooSuborder.articleBasedDiscounts;
      this.articleBasedDiscountsLoaded = !!(abd && abd.length);

      return (abd || []).reduce((result: any, item: ArticleBasedDiscount) => {
        result[item.orderPositionId] = item.toTemplateData(currentOrder.shopCurrency);
        return result;
      }, {});
    }
  }

  private renderArticleNumbers() {
    const articleNumberToArticleLinks = Utils._.reduce(
      this.model.positions,
      (result: any, position: PositionModel) => {
        result[position.articleNumber] = new ArticleLinksModel().fromData({
          articleAdminUrl: position.articleAdminUrl,
          shopArticleUrl: position.shopArticleUrl,
        });
        return result;
      },
      {},
    );
    this.el.querySelectorAll('[component="--articleNumber"]').forEach((el) => {
      const articleNumber = (el as HTMLSpanElement).dataset.articlenumber;
      const articleLinks = articleNumberToArticleLinks[articleNumber];
      const vm = new OrderArticleNumberViewModel(
        el as HTMLSpanElement,
        new ArticleNumberModel(articleNumber, articleLinks),
        this.context,
      );
      vm.render();
      this.articleNumberVms.push(vm);
    });
  }

  private loadArticleVariantDescription() {
    if (mediaTypeDetector.isPlugin()) {
      return;
    }

    this.items
      .filter((item: PositionModel) => this.isSimpleOrderArticle(item) && !item.shopArticleVariant)
      .forEach((item: PositionModel) => this.loadOrderArticleVariantData(item));
  }

  private isSimpleOrderArticle = (item: PositionModel) => !item.key;

  private loadOrderArticleVariantData(item: PositionModel) {
    this.context.data
      .orderArticleVariantData(item.shopArticleId, this.model.suborderId)
      .then((shopArticle: ShopArticle) => {
        item.shopArticleVariant = shopArticle?.variantText;
        this.renderOrderPositionItemVariant(item);
      });
  }

  private renderOrderPositionItemVariant(orderPosition: PositionModel) {
    const el = this.el.querySelector(
      `.oa-positionsItem[data-articleNumber="${orderPosition.articleNumber}"] .oa-positionsItemVariant`,
    );
    if (orderPosition.shopArticleVariant) {
      el.classList.remove(OrderPositionsViewModel.ORDER_ITEM_VARIANT_NOT_AVAILABLE_CSS_CLASS_NAME);
      renderTo(el, orderPosition.shopArticleVariant);
    } else {
      el.classList.add(OrderPositionsViewModel.ORDER_ITEM_VARIANT_NOT_AVAILABLE_CSS_CLASS_NAME);
    }
  }

  destroy(): void {
    this.articleNumberVms?.forEach((vm) =>
      vm.destroy(),
    ); /* eslint-disable-line no-unused-expressions */
  }
}
