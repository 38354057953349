import OrderTimelineModel from '../../models/OrderTimelineModel';
import '../../../templates/order-timeline/orderTimeline.css';
import OrderTimelineViewModel from '../OrderTimelineViewModel';
import TimelineEntryViewModel from '../TimelineEntryViewModel';
import TransactionalMailTimelineEntryViewModel from './TransactionalMailTimelineEntryViewModel';
import { Models } from '@cc/cc-app-commons';
import { resolveCCAdminUrl, resolveGatewayUrl } from '../../helpers/connectionTypeResolver';

export default class OrderCommunicationTimelineViewModel extends OrderTimelineViewModel {
  get timelineTitleTranslationKey(): string {
    return 'communication';
  }

  async getTimelineViewModels(): Promise<TimelineEntryViewModel[]> {
    const gatewayUrl = await resolveGatewayUrl();
    const ccAdminUrl = await resolveCCAdminUrl();
    return this.context.data
      .orderHistory(this.order.id)
      .then((orderTimeline: OrderTimelineModel) => {
        return orderTimeline.mails.map((model: Models.Mail, index, array) => {
          return new TransactionalMailTimelineEntryViewModel(
            model,
            index,
            array,
            gatewayUrl,
            ccAdminUrl,
          );
        });
      });
  }
}
