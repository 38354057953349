var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>"
    + ((stack1 = __default(require("./helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"translationKey") : depth0),(depth0 != null ? lookupProperty(depth0,"context") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":1,"column":5},"end":{"line":1,"column":38}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});