import './initGlobals';
import { parse } from 'query-string';
import { createApp } from '../modules/appFactory';

$(document).ready(function () {
  const searchParams = getSearchParams();
  const app = createApp(searchParams);
  app.init();
});

function getSearchParams() {
  const params = parse(window.location.search);
  if (params.customerId) {
    params.customerId = +params.customerId;
  }
  if (params.orderId) {
    params.orderId = +params.orderId;
  }
  if (params.ticketId) {
    params.ticketId = +params.ticketId;
  }
  return params;
}
