var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"alert alert-warning\" role=\"alert\">"
    + container.escapeExpression(__default(require("./helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"initial_data_form.warnings.no_data_provided.message",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":50},"end":{"line":7,"column":112}}}))
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-appInitialDataForm\" data-test=\"appInitialDataForm\">\n  <h4 class=\"text-center\">\n    "
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"initial_data_form.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":38}}}))
    + "\n  </h4>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noDataProvided") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":8,"column":9}}})) != null ? stack1 : "")
    + "\n  <form>\n    <div class=\"form-group\">\n      <label for=\"order-id\">\n        "
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"initial_data_form.order_id",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":13,"column":45}}}))
    + ":\n      </label>\n      <input name=\"order-id\" id=\"order-id\" type=\"number\" min=\"1\" class=\"form-control\"/>\n    </div>\n    <div class=\"form-group\">\n      "
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"default.or",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":6},"end":{"line":18,"column":27}}}))
    + "\n    </div>\n    <div class=\"form-group\">\n      <label for=\"customer-id\">\n        "
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"initial_data_form.customer_id",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":22,"column":48}}}))
    + ":\n      </label>\n      <input name=\"customer-id\" id=\"customer-id\" type=\"number\" min=\"1\" class=\"form-control\"/>\n    </div>\n    <div class=\"form-group\">\n        "
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"default.or",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":27,"column":29}}}))
    + "\n    </div>\n    <div class=\"form-group\">\n        <label for=\"invoice-id\">\n            "
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"initial_data_form.invoice_id",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":31,"column":51}}}))
    + ":\n        </label>\n        <input name=\"invoice-id\" id=\"invoice-id\" type=\"text\" class=\"form-control\"/>\n    </div>\n    <button type=\"submit\" class=\"btn btn-primary btn-block\">\n      "
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"initial_data_form.submit_form",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":36,"column":6},"end":{"line":36,"column":46}}}))
    + "\n    </button>\n  </form>\n</div>\n";
},"useData":true});