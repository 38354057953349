import { Services } from '@cc/cc-app-commons';
import CancelOrderReason from '../../models/CancelOrderReason';
import ParcelCancellationResult from '../../models/ParcelCancellationResult';
import { ParcelCancellationError } from '../../models/ParcelCancellationError';
import { ParcelCancellationStatus, ServerResponse } from '../../types';

export class ParcelService {
  connector: Services.KeycloakSecuredZooServicesConnector;
  gatewayUrl: string;

  constructor(connector: Services.KeycloakSecuredZooServicesConnector, gatewayUrl: string) {
    this.connector = connector;
    this.gatewayUrl = gatewayUrl;
  }

  async isParcelCancellable(parcelId: number): Promise<Boolean> {
    try {
      const response =
        await this.connector.fetchData<ServerResponse.ParcelCancellationStatusResponse>(
          this.gatewayUrl,
          `cc-apps-api/v1/parcels/${parcelId}/cancellation-status`,
        );
      return response.parcelCancellationStatus === ParcelCancellationStatus.CANCELLABLE;
    } catch (e) {
      console.warn(
        'Encountered problems while retrieving information about parcel cancellation possibilities',
        e,
      );
      return Promise.resolve(false);
    }
  }

  async cancelParcel(
    parcelId: number,
    orderId: number,
    reason: CancelOrderReason,
    username: string,
    userId: number,
  ): Promise<ParcelCancellationResult> {
    try {
      const response = await this.connector.putData<
        Object,
        ServerResponse.ParcelCancellationResponse
      >(this.gatewayUrl, `cc-apps-api/v1/parcels/${parcelId}/cancel`, {
        orderId,
        reason,
        username,
        userId,
      });
      return new ParcelCancellationResult().fromData(response);
    } catch (e) {
      console.warn(
        `Encountered problems while performing the parcel cancellation for parcel ${parcelId}`,
        e,
      );
      throw new ParcelCancellationError(e);
    }
  }
}
