import { ContextFactory } from '../../../common/ContextFactory';
import { Models, Services, View } from '@cc/cc-app-commons';
import { TicketService } from '../../../../../modules/services/TicketService';
import { ViewModel } from '../../../../types';
import { FreshdeskAppContext } from './FreshdeskAppContext';
import { ViewModel as CommonViewModelTypes } from '@cc/cc-app-commons/lib/js/view/context/types';
import FreshdeskAppContextServices from './FreshdeskAppContextServices';
import Context = ViewModel.Context;
import TicketBasedMetricContext = View.Context.TicketBasedMetricContext;

type Ticket = Models.Ticket;

export class FreshdeskAppContextFactory implements ContextFactory {
  private readonly client: any;
  private ticketService: TicketService;
  private ticket: Ticket;

  constructor(client: any) {
    this.client = client;
    this.ticketService = new TicketService(this.client);
  }

  async createAppContext(
    connector: Services.KeycloakSecuredZooServicesConnector,
    gatewayUrl: string,
    keycloak: any,
  ): Promise<Context> {
    await this.initialized();
    return FreshdeskAppContext.create(this.client, this.ticket, connector, gatewayUrl, keycloak);
  }

  private async initialized(): Promise<any> {
    return this.client.initialized().then(async () => {
      this.ticket = await this.ticketService.getTicketDataFromSystem();
    });
  }

  async createMetricContext(
    connector: Services.KeycloakSecuredZooServicesConnector,
    gatewayUrl: string,
    keycloak: any,
  ): Promise<CommonViewModelTypes.MetricContext> {
    if (!connector) {
      throw new Models.Errors.AppError(null, null, {
        message: 'Cannot create MetricContext without a connector provided',
      });
    }
    await this.initialized();
    const services = new FreshdeskAppContextServices(
      this.client,
      this.ticket,
      connector,
      gatewayUrl,
      keycloak,
    );
    const metricContext = new TicketBasedMetricContext(this.ticket, services);
    return Promise.resolve(metricContext);
  }
}
