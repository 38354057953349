var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "&#10;"
    + alias1(__default(require("../helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"transactional_mail.last_status_details_header",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":187},"end":{"line":2,"column":243}}}))
    + ": "
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"lastStatusDetails") : depth0), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"oa-timelineEntry-status\">\n        <div class=\"glyphicon glyphicon-eye-open\" title=\"Opened the email\"></div>\n      </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"oa-timelineEntry-status\">\n        <div class=\"glyphicon glyphicon-link\" title=\"Clicked the link\"></div>\n      </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"oa-timelineEntry-status\">\n        <div class=\"glyphicon glyphicon-exclamation-sign\" title=\"Added to spam\"></div>\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-timelineEntry oa-timelineEntry-transactionalMail\">\n  <div class=\"oa-timelineEntry-timeMarker\" title=\""
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"transactional_mail.status_header",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":50},"end":{"line":2,"column":93}}}))
    + ": "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,__default(require("../helpers/concat.js")).call(alias1,"transactional_mail.status.",((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":2,"column":102},"end":{"line":2,"column":151}}}),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":95},"end":{"line":2,"column":153}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasLastStatusDetails") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":153},"end":{"line":2,"column":273}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"oa-timelineEntry-icon oa-timelineEntry-icon--"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"size") : stack1), depth0))
    + " oa-timelineEntry-icon--"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">\n      <div class=\"oa-timelineEntryIcon-waiting\">\n        <div class=\"glyphicon glyphicon-"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"icon") : stack1), depth0))
    + "\"></div>\n      </div>\n    </div>\n    <div class=\"oa-timelineEntry-date\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"date") : depth0), depth0))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isOpened") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isClicked") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAddedToSpam") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"oa-timelineEntry-receiverInfo\">\n    <div class=\"oa-timelineEntry-email\">\n      "
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\n    </div>\n  </div>\n  <div class=\"oa-timelineEntry-content\">\n    <div class=\"oa-timelineEntry-header\">\n      "
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"subject") : depth0), depth0))
    + "\n    </div>\n    <div class=\"oa-timelineEntry-showMailContent\">\n      <a href=\"#\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"transactional_mail.show_mail_content",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":18},"end":{"line":35,"column":65}}}))
    + "</a>\n    </div>\n  </div>\n</div>";
},"useData":true});