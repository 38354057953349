import _ from 'lodash';
import { Helpers } from '@cc/cc-app-commons';
import { stringify, parse } from 'query-string';
import { LocationSearchParam } from '../models/LocationSearchParam';

const { clear, renderTo } = Helpers.ClientHelpers;

const tpl = require('../../templates/appInitialDataForm.hbs');

type TemplateParams = { [key: string]: any };

export default class AppInitialDataFormViewModel {
  static FORM_SELECTOR = 'form';
  el: Element;
  onFormSubmitHandler: (e: Event) => void;
  static defaultRenderParams = {
    noDataProvided: false,
  };

  constructor(domElement: Element) {
    this.el = domElement;
    this.onFormSubmitHandler = this.onFormSubmit.bind(this);
  }

  async render(params: TemplateParams = {}): Promise<void> {
    renderTo(this.el, tpl(params));
    this.attachPluginsAndEventHandlers();
  }

  async rerender(params: TemplateParams): Promise<void> {
    this.destroy();
    await this.render(params);
  }

  attachPluginsAndEventHandlers() {
    this.el
      .querySelector(AppInitialDataFormViewModel.FORM_SELECTOR)
      .addEventListener('submit', this.onFormSubmitHandler);
  }

  onFormSubmit(evt: Event) {
    evt.preventDefault();
    const form = evt.currentTarget as HTMLFormElement;
    const oIdString = form['order-id'].value;
    const cIdString = form['customer-id'].value;
    const iIdString = form['invoice-id'].value;

    const newLocationSearchParams: { [key: string]: number | string } = {};
    if (!_.isEmpty(oIdString)) {
      newLocationSearchParams[LocationSearchParam.orderId] = +oIdString;
    }
    if (!_.isEmpty(cIdString)) {
      newLocationSearchParams[LocationSearchParam.customerId] = +cIdString;
    }
    if (!_.isEmpty(iIdString)) {
      newLocationSearchParams[LocationSearchParam.invoiceId] = iIdString;
    }

    if (_.isEmpty(newLocationSearchParams)) {
      this.rerender({ noDataProvided: true });
      return;
    }

    const currentLocationSearchParams = parse(window.location.search, { parseNumbers: true });
    window.location.search = stringify({
      ...currentLocationSearchParams,
      ...newLocationSearchParams,
    });
  }

  destroy() {
    this.detachPluginsAndEventHandlers();
    clear(this.el);
  }

  private detachPluginsAndEventHandlers() {
    this.el
      .querySelector(AppInitialDataFormViewModel.FORM_SELECTOR)
      .addEventListener('submit', this.onFormSubmitHandler);
  }
}
