import ModalErrorViewModel from './ModalErrorViewModel';
import ErrorForViewModel from '../util/ErrorForViewModel';

let instance: ModalWrapper;

/*
As ModalWrapper is a singleton it is important to call getFor() just before modal opening, otherwise
multiple instances of ModalWrapper will be constructed and only the last one would be available.

The main problem with current approach is that ModalWrapper's responsibility is too vast - it should only "wrap"
modal, instances of ModalWrapper should be handled by adequate ModalHelpers.

Potential refactoring should consist of:
- checking if everything works fine (only relevant handlers are called etc.) if close() is called when multiple
  ModalWrapper instances exist
- checking if the modal's content is properly handled when modal is rendered with content and then reopened
- ModalWrapper should not be a singleton anymore, adequate ModalHelpers should manage ModalWrapper instances
- getFor() method should be removed
 */
export default class ModalWrapper {
  private modalHtmlElement: Element;
  private modalElement: JQuery<Element>;
  private modalContentViewModel: any;

  public static getFor(modalElement: Element): ModalWrapper {
    if (instance?.modalHtmlElement !== modalElement) {
      instance?.close().destroy(); /* eslint-disable-line no-unused-expressions */
      instance = new ModalWrapper(modalElement);
    }
    return instance;
  }

  private constructor(modalElement: Element) {
    this.modalHtmlElement = modalElement;
    this.modalElement = $(modalElement);
    this.init();
  }

  private init() {
    /* eslint-disable-next-line no-unused-expressions */
    this.modalElement?.on('hidden.bs.modal', this.destroyModalContentViewModel.bind(this));
    this.close();
  }

  get modalContentElement() {
    /* eslint-disable-next-line no-unused-expressions */
    return this.modalElement?.find('.modal-content')[0];
  }

  open() {
    /* eslint-disable-next-line no-unused-expressions */
    this.modalElement?.modal('show');
    return this;
  }

  close() {
    /* eslint-disable-next-line no-unused-expressions */
    this.modalElement?.modal('hide');
    return this;
  }

  onModalDismissal(handler: any) {
    /* eslint-disable-next-line no-unused-expressions */
    this.modalElement?.on('hidden.bs.modal', handler);
  }

  renderContainer(ViewModelClazz: any, ...constructorArgs: any[]) {
    this.destroyModalContentViewModel();
    this.modalContentViewModel = new ViewModelClazz(this.modalContentElement, ...constructorArgs);
    this.modalContentViewModel.render();
    return this;
  }

  handleError(error: ErrorForViewModel) {
    this.renderContainer(ModalErrorViewModel, error);
  }

  private destroyModalContentViewModel() {
    if (this.modalContentViewModel) {
      this.modalContentViewModel.destroy();
      delete this.modalContentViewModel;
    }
    return this;
  }

  destroy() {
    this.destroyModalContentViewModel();
    this.modalElement?.off('hidden.bs.modal'); /* eslint-disable-line no-unused-expressions */
    delete this.modalElement;
  }
}
