import dateFormat from 'dateformat';

export const FORMATS = {
  DEFAULT_DATE: 'dd.mm.yyyy',
  DEFAULT_DATE_TIME: 'dd.mm.yyyy, hh:MM',
};

export function toDefaultDateFormat(date: Date | string): string {
  return formatDate(FORMATS.DEFAULT_DATE, date);
}

export function toDefaultDateTimeFormat(date: Date | string): string {
  return formatDate(FORMATS.DEFAULT_DATE_TIME, date);
}

function formatDate(format: string, date: Date | string): string {
  return dateFormat(date, format);
}
