import OrderWarningViewModel from './OrderWarningViewModel';

export default class OrderParcelsWarningViewModel extends OrderWarningViewModel {
  private suborderId: string;
  constructor(domElement: HTMLElement, suborderId: string) {
    super(domElement, 'order_parcels_warning');
    this.suborderId = suborderId;
  }

  get targetRenderElement(): HTMLElement {
    return this.domElement.querySelector(`#_${this.suborderId} .oa-parcels`);
  }
}
