import { Models, Services } from '@cc/cc-app-commons';
import PaymentService from '../../../../../modules/services/PaymentService';
import FreshdeskAppInterfaceService from '../../../../../modules/services/freshdesk/FreshdeskAppInterfaceService';
import ClientBasedAppUserService from '../../../../../modules/services/ClientBasedAppUserService';
import { CustomerService } from '../../../../../modules/services/CustomerService';
import { OrdersService } from '../../../../../modules/services/OrdersService';
import ParcelPictureService from '../../../../../modules/services/ParcelPictureService';
import { OrderItemService } from '../../../../../modules/services/OrderItemService';
import { ClientBasedAppOrderResolutionService } from '../../../../../modules/services/ClientBasedAppOrderResolutionService';
import { ClaimService } from '../../../../../modules/services/ClaimService';
import { ClaimDraftService } from '../../../../../modules/services/ClaimDraftService';
import { ParcelService } from '../../../../../modules/services/ParcelService';
import KeycloakService from '../../../../../modules/services/KeycloakService';
import { ArticleBasedDiscountsService } from '../../../../../modules/services/ArticleBasedDiscountsService';
import ClientBasedAppContextServices from '../../common/ClientBasedAppContextServices';
import MetricsService = Services.MetricsService;
import MonitoringService = Services.MonitoringService;
import Ticket = Models.Ticket;
import { OrdersFetchingServiceV1 } from '../../../../../modules/services/OrdersFetchingServiceV1';
import { OrdersFetchingServiceV2 } from '../../../../../modules/services/OrdersFetchingServiceV2';
import { FeatureFlagsService } from '../../../../../modules/services/FeatureFlagsService';
import { AddressService } from '../../../../../modules/services/AddressService';

const APP_NAME = process.env.APP_NAME;

export default class FreshdeskAppContextServices extends ClientBasedAppContextServices {
  constructor(
    client: any,
    ticket: Ticket,
    connector: Services.KeycloakSecuredZooServicesConnector,
    gatewayUrl: string,
    keycloak: any,
  ) {
    super();
    this.payments = new PaymentService(connector, gatewayUrl);
    this.customerCommunicationService = new Services.CustomerCommunicationService(
      connector,
      `${gatewayUrl}/customer-communication-audit/v2`,
    );
    this.parcelPictureService = new ParcelPictureService(connector, gatewayUrl);
    this.interface = new FreshdeskAppInterfaceService(client, ticket);
    this.metric = new MetricsService(connector, `${gatewayUrl}/cc-apps-api/v1`, APP_NAME);
    this.user = new ClientBasedAppUserService(client, new KeycloakService(keycloak));
    this.monitoring = new MonitoringService(this.metric, this.user);
    this.customer = new CustomerService(connector, gatewayUrl);
    this.order = new OrdersService(
      connector,
      gatewayUrl,
      new OrdersFetchingServiceV1(connector, gatewayUrl),
      new OrdersFetchingServiceV2(connector, gatewayUrl),
      new FeatureFlagsService(connector, gatewayUrl, this.user),
    );
    this.parcel = new ParcelService(connector, gatewayUrl);
    this.address = new AddressService(connector, gatewayUrl);
    this.claim = new ClaimService(connector, gatewayUrl);
    this.claimDraft = new ClaimDraftService(connector, gatewayUrl);
    this.orderItemService = new OrderItemService(connector, gatewayUrl);
    this.orderResolutionService = new ClientBasedAppOrderResolutionService(
      ticket,
      this.order,
      this.customer,
      this.monitoring,
    );
    this.articleBasedDiscountsService = new ArticleBasedDiscountsService(connector, gatewayUrl);
  }
}
