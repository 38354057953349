var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./orderItemPlugin.hbs"),depth0,{"name":"./orderItemPlugin","hash":{"tooltipConfig":(depth0 != null ? lookupProperty(depth0,"tooltipConfig") : depth0),"type":(depth0 != null ? lookupProperty(depth0,"type") : depth0),"order":(depth0 != null ? lookupProperty(depth0,"order") : depth0)},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./orderItemStandalone.hbs"),depth0,{"name":"./orderItemStandalone","hash":{"url":(depth0 != null ? lookupProperty(depth0,"url") : depth0),"tooltipConfig":(depth0 != null ? lookupProperty(depth0,"tooltipConfig") : depth0),"type":(depth0 != null ? lookupProperty(depth0,"type") : depth0),"order":(depth0 != null ? lookupProperty(depth0,"order") : depth0)},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/layout/isMediaTypePlugin.js")).call(alias1,{"name":"layout/isMediaTypePlugin","hash":{},"data":data,"loc":{"start":{"line":1,"column":6},"end":{"line":1,"column":34}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});