import { Helpers, Models } from '@cc/cc-app-commons';
// @ts-ignore
import showMoreItems from '../../templates/showMoreItems.hbs';
import mediaTypeDetector from '../util/MediaTypeDetector';
import { TemplateData } from '../models/types';

type ReactiveModel = Models.ReactiveModel<State>;

const { createChildElement, renderTo } = Helpers.ClientHelpers;

export default abstract class ExpandableItemsAbstractModel<T extends TemplateData> {
  el: HTMLElement;
  showMoreItemsElementClassName: string;
  state: State;
  stateReactive: ReactiveModel;
  items: Array<T>;
  defaultNumberOfItems: number;

  constructor(
    domElement: HTMLElement,
    items: Array<T>,
    defaultNumberOfItems: number,
    showMoreItemsElementClassName: string,
  ) {
    this.el = domElement;
    this.state = new State();
    this.stateReactive = new Models.ReactiveModel(this.state, this.notifyChanged.bind(this));
    this.showMoreItemsElementClassName = showMoreItemsElementClassName;
    this.items = items;
    this.defaultNumberOfItems = mediaTypeDetector.isPlugin() ? defaultNumberOfItems : items.length;
    this._attachListeners();
  }

  notifyChanged(): void {
    this.render();
  }

  async render(): Promise<void> {
    await this.renderItems();
    this.renderShowMoreButton(createChildElement(this.el));
  }

  async renderItems(): Promise<void> {
    throw new Error('Method renderItems() should be implemented in a child class');
  }

  renderShowMoreButton(domElement: HTMLElement): void {
    renderTo(domElement, showMoreItems(this.templateContext()));
  }

  _attachListeners(): void {
    this.el.addEventListener('click', (e) => {
      if (this._isShowMoreElement(e.target as HTMLElement)) {
        this.state.toggleAllItemsExpanded();
      }
    });
  }

  _isShowMoreElement(targetElement: HTMLElement): boolean {
    return targetElement.classList.contains(this.showMoreItemsElementClassName);
  }

  getItemsToShow(): Array<T> {
    const numberOfItemsVisible = this.state.allItemsExpanded
      ? this.items.length
      : this.defaultNumberOfItems;
    return this.items.slice(0, numberOfItemsVisible);
  }

  templateContext() {
    return {
      showMoreButtonCssClassName: this.showMoreItemsElementClassName,
      showMoreButtonVisible: this.items.length > this.defaultNumberOfItems,
      additionalItemsToShow: this.items.length - this.defaultNumberOfItems,
      state: this.state,
    };
  }

  itemsForTemplateContext(): Array<Object> {
    return this.getItemsToShow().map((it: T) => it.toTemplateData());
  }
}

class State {
  allItemsExpanded = false;

  toggleAllItemsExpanded() {
    this.allItemsExpanded = !this.allItemsExpanded;
  }
}
