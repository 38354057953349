import TransactionalMailPreviewViewModel from './TransactionalMailPreviewViewModel';

export default class TransactionalMailPluginPreviewViewModel extends TransactionalMailPreviewViewModel {
  renderFor(context: any): void {
    context.interface.showTransactionalMailContent(this.currentMail, this.allMails);
  }

  destroy() {
    // Intentionally blank
  }
}
