import { Models } from '@cc/cc-app-commons';
import Shop from './Shop';
import OrderParcel from './OrderParcel';
import Article from './Article';
import { ServerResponse } from '../types';
import Discount from './Discount';
import Fee from './Fee';
import PositionModel from './PositionModel';
import OrderWarning, { orderWarningFactory } from './OrderWarning';
import { toDefaultDateTimeFormat } from '../util/date';
import ShopCurrency from './ShopCurrency';
import monetaryValueFromShopCurrency from '../helpers/monetaryValueFromShopCurrency';
import Address from './Address';
import OrderRelation from './OrderRelation';
import { TemplateData } from './types';
import ArticleBasedDiscount from './ArticleBasedDiscount';
import RelaxDeliveryStatus from './RelaxDeliveryStatus';

class OrderStatus extends Models.BaseModel {
  code = '';
  description = '';
}

export class Suborder extends Models.BaseModel {
  id: string = undefined;
  invoiceId: string = undefined;
  locale = '';
  index: number = undefined;
  sellerName: string = undefined;
  orderType: string = undefined;
  status = new OrderStatus();
  articles: PositionModel[] = [];
  discounts: PositionModel[] = [];
  fees: PositionModel[] = [];
  articleBasedDiscounts: ArticleBasedDiscount[] = undefined;
  parcels: OrderParcel[] = [];
  totalValue: number = null;
  totalMonetaryValue: Models.MonetaryValue = null;
  isZooplusSuborder: boolean = null;
  warnings: OrderWarning[] = [];
  relaxDeliveryStatus: RelaxDeliveryStatus = undefined;
  relatedOrders: OrderRelation[] = [];
  url: String = undefined;

  fromData(json: ServerResponse.Suborder, index: number, currency: ShopCurrency): any {
    super.fromData(json);

    this.articles = (json.articles || []).map((article) =>
      new Article().fromData(article, currency),
    );
    this.totalMonetaryValue = monetaryValueFromShopCurrency(currency, json.totalValue);
    this.discounts = (json.discounts || []).map((discount) =>
      new Discount().fromData(discount, currency),
    );
    this.fees = (json.fees || []).map((fee) => new Fee().fromData(fee, currency));
    this.parcels = (json.parcels || []).map((parcel) =>
      new OrderParcel().fromData(parcel, json.articles),
    );
    this.isZooplusSuborder = json.orderType.startsWith('1P-');
    this.index = index + 1;
    this.warnings = (json.warnings || []).map((warning) => orderWarningFactory(warning));
    this.relaxDeliveryStatus = new RelaxDeliveryStatus().fromData(json.relaxDeliveryInfoResponse);

    return this;
  }

  toData() {
    const json = super.toData();
    json.relaxDeliveryInfoResponse = json.relaxDeliveryStatus;
    return json;
  }

  toTemplateData(...context: any): any {
    return super.toData(...context);
  }
}

export class Order extends Models.BaseModel implements TemplateData {
  static DEFAULT_POSITIONS_SHOWN_COUNT = 3;
  static DEFAULT_PARCELS_SHOWN_COUNT = 2;

  id: number = undefined;
  customerId: number = undefined;
  domain = '';
  creationDate = '';
  shop: Shop = undefined;
  shopCurrency: ShopCurrency = null;
  isFraud: boolean = undefined;
  relaxDeliveryStatus: RelaxDeliveryStatus = undefined;
  channel = '';
  totalValue: number = null;
  totalMonetaryValue: Models.MonetaryValue = null;
  shippingAddress?: Address = undefined;
  billingAddress?: Address = undefined;
  billingAddressModifiable: boolean = undefined;
  shippingAddressModifiable: boolean = undefined;
  suborders: Suborder[] = [];

  get parsedDate(): string {
    return toDefaultDateTimeFormat(this.creationDate);
  }

  fromData(json: ServerResponse.Order) {
    super.fromData(json);
    const currency = ShopCurrency.create(json.currency);
    return this.generalFromData(json, this.domain)
      .monetaryDataFromData(json, currency)
      .subordersFromData(json, currency)
      .addressesFromData(json)
      .isFraudFromData(json);
  }

  private subordersFromData(json: ServerResponse.Order, currency: ShopCurrency): Order {
    this.suborders = json.suborders.map<Suborder>((so, i) =>
      new Suborder().fromData(so, i, currency),
    );
    return this;
  }

  private generalFromData(json: ServerResponse.Order, domain: string): Order {
    this.shop = new Shop(domain);
    return this;
  }

  private monetaryDataFromData(json: ServerResponse.Order, currency: ShopCurrency): Order {
    this.shopCurrency = currency;
    this.totalMonetaryValue = monetaryValueFromShopCurrency(currency, json.totalValue);
    return this;
  }

  private addressesFromData(json: ServerResponse.Order): Order {
    this.shippingAddress = json.shippingAddress
      ? new Address().fromData(json.shippingAddress)
      : undefined;
    this.billingAddress = json.billingAddress
      ? new Address().fromData(json.billingAddress)
      : undefined;
    return this;
  }

  private isFraudFromData(json: ServerResponse.Order): Order {
    this.isFraud = json.isFraud;
    return this;
  }

  toData() {
    const json = super.toData();
    json.currency = this.shopCurrency.currency;
    json.suborders = this.suborders.map((so) => so.toData());
    return json;
  }

  toTemplateData(...context: any): any {
    return super.toData(...context);
  }
}
