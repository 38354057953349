import { Services } from '@cc/cc-app-commons';
import { ViewModel } from '../../../../types';
import { IndependentAppContextFactory } from '../../../independent/IndependentAppContextFactory';
import { FreshdeskAppTicketIndependentContext } from './FreshdeskAppTicketIndependentContext';
import Context = ViewModel.Context;

export class FreshdeskAppTicketIndependentContextFactory extends IndependentAppContextFactory {
  private readonly client: any;

  constructor(client: any, customerId: number, orderId: number, invoiceId: string) {
    super(customerId, orderId, invoiceId);
    this.client = client;
  }

  async createAppContext(
    connector: Services.KeycloakSecuredZooServicesConnector,
    gatewayUrl: string,
    keycloak: any,
  ): Promise<Context> {
    return FreshdeskAppTicketIndependentContext.create(
      this.client,
      this.customerId,
      this.orderId,
      this.invoiceId,
      connector,
      gatewayUrl,
      keycloak,
    );
  }
}
