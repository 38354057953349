import { AppEventsHandler } from '../../../common/AppEventsHandler';
import App from '../../../../../modules/app';
import { resizeContainer } from '../../../../../lib/clientHelpers';
import { ViewModel } from '../../../../types';
import Context = ViewModel.Context;
import { captureMessage } from '@sentry/browser';

export class FreshdeskAppTicketIndependentEventsHandler implements AppEventsHandler {
  private readonly client: any;

  constructor(client: any) {
    this.client = client;
  }

  async onAppContextCreated(context: Context) {
    const loggedInUser = await context.user.loggedInUser;
    if (!loggedInUser.zooId) {
      captureMessage(`User email ${loggedInUser.email} does not have zooId assigned`, 'debug');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async onAppRendered(target: App) {
    resizeContainer(this.client);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onAppLoaded(target: App): void {
    // Intentionally empty
  }
}
