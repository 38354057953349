import { Services } from '@cc/cc-app-commons';
import { AppDataContext } from '../../../common/AppDataContext';
import { ViewModel } from '../../../../types';
import { AppContext } from '../../../common/AppContext';
import { CommonAppMetricContext } from '../../../common/metrics/CommonAppMetricContext';
import FreshdeskAppTicketIndependentContextServices from './FreshdeskAppTicketIndependentContextServices';
import Context = ViewModel.Context;

export class FreshdeskAppTicketIndependentContext extends AppContext {
  static async create(
    client: any,
    customerId: number,
    orderId: number,
    invoiceId: string,
    connector: Services.KeycloakSecuredZooServicesConnector,
    gatewayUrl: string,
    keycloak: any,
  ): Promise<Context> {
    const appContextServices = new FreshdeskAppTicketIndependentContextServices(
      client,
      customerId,
      orderId,
      invoiceId,
      connector,
      gatewayUrl,
      keycloak,
    );
    const appContext = new FreshdeskAppTicketIndependentContext(appContextServices, keycloak);
    await appContext.createAppDataContext();
    appContext.createAppMetricContext();
    return appContext;
  }

  private createAppDataContext() {
    this._data = new AppDataContext(this.services);
    return this._data.initCurrentOrderModel();
  }

  private createAppMetricContext() {
    this._metric = new CommonAppMetricContext(this.services, this.data);
  }
}
