var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"oa-orderActionButton oa-orderActionButton--listItem oa-orderActionButton__fetchArticleBasedDiscountsInfo\"\n     title=\""
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"features.abd.actions.article_based_discounts.tooltip",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":75}}}))
    + "\"\n     data-placement=\"bottom\"\n     data-test=\"fetchArticleBasedDiscountsInfoButton\">\n  <a>\n    <div class=\"oa-orderActionButton__icon\">\n      <i class=\"glyphicon percent icon\"></i>\n    </div>\n    <div class=\"oa-orderActionButton__label\">"
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,"features.abd.actions.article_based_discounts.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":45},"end":{"line":10,"column":106}}}))
    + "</div>\n  </a>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isArticleBasedDiscountsEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "");
},"useData":true});