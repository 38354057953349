var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"oa-windowNavigation\">\n        <button type=\"button\" class=\"close oa-windowNavigation__buttonCloseWindow\">\n          <span class=\"glyphicon glyphicon-remove\"></span>\n        </button>\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-header\">\n\n  <div class=\"oa-orderInfoContainer\">\n    <div component=\"--orderInfo\" class=\"u-flex\"></div>\n  </div>\n  <div component=\"--divider\"></div>\n  <div class=\"oa-orderNavigationContainer\">\n    <div class=\"oa-orderNavigation\">\n      <div class=\"oa-currentlyDisplayedOrderHeaderInfoWrapper\">\n        <div component=\"--fraudIcon\"></div>\n        <div component=\"--orderSwitcher\"></div>\n        <div component=\"--orderRelations\"></div>\n      </div>\n      <div component=\"--subscriptionInfo\"></div>\n      <div component=\"--customerInfo\"></div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../helpers/layout/isMediaTypeStandalone.js")).call(alias1,{"name":"layout/isMediaTypeStandalone","hash":{},"data":data,"loc":{"start":{"line":17,"column":10},"end":{"line":17,"column":42}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n\n</div>\n";
},"useData":true});