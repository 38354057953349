import { AppInterfaceService, TopNavigationConfiguration } from '../AppInterfaceService';
import { Models } from '@cc/cc-app-commons';
import Ticket = Models.Ticket;

export default class FreshdeskAppInterfaceService implements AppInterfaceService {
  private readonly _client: any;
  private readonly _ticket: any;

  constructor(client: any, ticket?: Ticket) {
    this._client = client;
    this._ticket = ticket;
  }

  showModal(title: string, template: string, data: Object) {
    this._client.interface.trigger('showModal', {
      title,
      template,
      data,
    });
  }

  openAppInNewWindow(orderId?: number): void {
    const params: { [key: string]: string | number } = {};
    orderId && (params.orderId = orderId);
    this._ticket && (params.ticketId = this._ticket.id);
    this._client.instance.openInNewWindow(params);
  }

  getTopNavigationConfiguration(): TopNavigationConfiguration {
    return new TopNavigationConfiguration(false);
  }
}
