var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-orderSwitcher-orderList oa-orderSwitcher-selectedOrderLabel\" data-test=\"orderSwitcher.selectedOrderLabel\">\n"
    + ((stack1 = container.invokePartial(require("./orderItem.hbs"),depth0,{"name":"./orderItem","hash":{"url":(depth0 != null ? lookupProperty(depth0,"url") : depth0),"tooltipConfig":(depth0 != null ? lookupProperty(depth0,"tooltipConfig") : depth0),"type":"headerItem","order":(depth0 != null ? lookupProperty(depth0,"order") : depth0)},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});