import { Helpers, Models } from '@cc/cc-app-commons';
import { ViewModel } from '../types';
import mediaTypeDetector from '../../util/MediaTypeDetector';
import CancelParcelReasonViewModel from './CancelParcelReasonViewModel';
import OrderParcel from '../../models/OrderParcel';
import ParcelActionsModalHelper from './ParcelActionsModalHelper';

const { clear, renderTo } = Helpers.ClientHelpers;
const tplPlugin = require('../../../templates/parcel-actions/pluginParcelActionButtonsForModal.hbs');
const tplStandalone = require('../../../templates/parcel-actions/standaloneParcelActionButtons.hbs');

export default class ParcelActionButtonsViewModel {
  private static PARCEL_ACTIONS_SELECTOR = '.oa-parcelActions .oa-parcelActionButton';
  private static CANCEL_PARCEL_BUTTON_SELECTOR =
    '.oa-parcelActions .oa-parcelActionButton__cancelParcel a';

  el: Element;
  private readonly context: ViewModel.Context;
  eventRemovers: Models.Remover[] = [];
  private readonly parcel: OrderParcel;

  constructor(el: Element, context: ViewModel.Context, parcel: OrderParcel) {
    this.el = el;
    this.context = context;
    this.parcel = parcel;
  }

  async render() {
    await this.renderParcelActions();
    this.attachPluginsAndEventHandlers();
  }

  private async renderParcelActions() {
    const tpl = mediaTypeDetector.isPlugin() ? tplPlugin : tplStandalone;

    const isParcelCancellable = await this.context.data.isParcelCancellable(this.parcel.id);
    renderTo(
      this.el,
      tpl({
        parcelId: this.parcel.id,
        isParcelCancellable: isParcelCancellable,
        authorization: {
          canCancelParcel: this.context.authorization.isAuthorizedToCancelParcel(),
        },
      }),
    );
  }

  private attachPluginsAndEventHandlers() {
    this.el
      .querySelectorAll(ParcelActionButtonsViewModel.PARCEL_ACTIONS_SELECTOR)
      .forEach((el) => $(el).tooltip());

    const onCancelParcelActionButtonClicked = this.proceedToParcelCancellationAction.bind(this);
    const cancelParcelActionButton = this.el.querySelector(
      ParcelActionButtonsViewModel.CANCEL_PARCEL_BUTTON_SELECTOR,
    );
    if (cancelParcelActionButton) {
      cancelParcelActionButton.addEventListener('click', onCancelParcelActionButtonClicked);
      this.eventRemovers.push(
        Models.Remover.createFor(
          function (): void {
            cancelParcelActionButton.removeEventListener(
              'click',
              onCancelParcelActionButtonClicked,
            );
          }.bind(this) // eslint-disable-line
        ),
      );
    }
  }

  private proceedToParcelCancellationAction(e: Event) {
    e.preventDefault();
    const modalWrapper = ParcelActionsModalHelper.getModal();
    modalWrapper.renderContainer(
      CancelParcelReasonViewModel,
      this.context,
      this.parcel,
      this.onParcelCancel.bind(this),
    );
    modalWrapper.open();
  }

  private async onParcelCancel() {
    this.context.data.refreshCurrentOrder();
    if (mediaTypeDetector.isStandalone()) {
      this.rerender();
    }
  }

  private rerender() {
    this.destroy();
    this.render();
  }

  private detachPluginsAndEventHandlers() {
    this.eventRemovers.forEach((eventHandlerRemover: Models.Remover) =>
      eventHandlerRemover.remove(),
    );
  }

  destroy() {
    this.detachPluginsAndEventHandlers();
    clear(this.el);
  }
}
