import { Helpers, Models, Services } from '@cc/cc-app-commons';
import { ViewModel } from './types';
import ClaimDraft from '../models/ClaimDraft';
import { toDefaultDateFormat } from '../util/date';
import { ClaimResolutionTemplateData } from './OrderClaimResolutionViewModel';

import tpl from '../../templates/order-claim-drafts/orderClaimDrafts.hbs';
const { clear, renderTo } = Helpers.ClientHelpers;
const AppActivityAction = Services.AppActivityAction;

export default class OrderClaimDraftsViewModel {
  static CLAIM_DRAFT_LINK_SELECTOR = '.oa-claim-drafts .oa-claim-drafts__claimLink';
  static CLAIM_RESOLUTION_OPTION_SELECTOR =
    '.oa-claim-drafts .oa-claim-drafts__resolutions .oa-claim-drafts__resolutionOptionIcon';

  private readonly eventRemovers: Models.Remover[] = [];

  constructor(
    private readonly el: HTMLElement,
    private readonly suborderId: string,
    private readonly context: ViewModel.Context,
  ) {
    this.notifyClaimBrowserLinkClicked = this.notifyClaimBrowserLinkClicked.bind(this);
  }

  async render(): Promise<void> {
    const hasPermissions = this.context.authorization.isAuthorizedToBrowseClaimDrafts();
    let claimDrafts: ClaimDraft[] = [];
    if (hasPermissions) {
      claimDrafts = await this.context.data.claimDrafts(this.suborderId);
    }

    renderTo(
      this.el,
      tpl({
        claimDrafts: claimDrafts
          .map((claimDraft) => ClaimDraftTemplateData.fromClaimDraft(claimDraft))
          .filter((claimDraft) => claimDraft.isNotResolved),
      }),
    );

    this.attachPluginsAndEventHandlers();
  }

  private attachPluginsAndEventHandlers() {
    this.el.querySelectorAll(OrderClaimDraftsViewModel.CLAIM_DRAFT_LINK_SELECTOR).forEach((el) => {
      el.addEventListener('click', this.notifyClaimBrowserLinkClicked);
      this.eventRemovers.push(
        Models.Remover.createFor(
          function (): void {
            el.removeEventListener('click', this.notifyClaimBrowserLinkClicked);
          }.bind(this), // eslint-disable-line
        ),
      );
    });

    this.el
      .querySelectorAll(OrderClaimDraftsViewModel.CLAIM_RESOLUTION_OPTION_SELECTOR)
      .forEach((el) => {
        $(el).tooltip({ html: true });
        this.eventRemovers.push(Models.Remover.createFor(() => $(el).tooltip('destroy')));
      });
  }

  private notifyClaimBrowserLinkClicked(): void {
    this.context.metric.logAppActivity(AppActivityAction.CLAIM_DRAFT_LINK__CLICKED);
  }

  destroy(): void {
    this.detachPluginsAndEventHandlers();
    clear(this.el);
  }

  private detachPluginsAndEventHandlers() {
    this.eventRemovers.forEach((eventHandlerRemover: Models.Remover) =>
      eventHandlerRemover.remove(),
    );
  }
}

class ClaimDraftTemplateData {
  claimDraftId: number = undefined;
  isNotResolved: boolean = false;
  parsedCreationDate = '';
  claimDraftUrl: string = undefined;
  resolution: ClaimResolutionTemplateData = undefined;

  static fromClaimDraft(claimDraft: ClaimDraft) {
    const claimDraftTemplateData = new ClaimDraftTemplateData();
    claimDraftTemplateData.claimDraftId = claimDraft.claimDraftId;
    claimDraftTemplateData.isNotResolved = claimDraft.isNotResolved;
    claimDraftTemplateData.parsedCreationDate =
      claimDraft.creationDate && toDefaultDateFormat(claimDraft.creationDate);
    claimDraftTemplateData.claimDraftUrl = `${process.env.CLAIM_DRAFT_URL}/draft/${claimDraft.claimDraftId}`;
    claimDraftTemplateData.resolution = ClaimResolutionTemplateData.fromClaimResolution(
      claimDraft.resolution,
    );
    return claimDraftTemplateData;
  }
}
