import { Helpers } from '@cc/cc-app-commons';

const { clear, renderTo } = Helpers.ClientHelpers;

const tpl = require('../../templates/appTopNavigation.hbs');
const dividerTpl = require('../../templates/common/divider.hbs');

export default class AppTopNavigationViewModel {
  private readonly el: Element;
  private readonly backToSearchUrl: string;

  constructor(domElement: Element, backToSearchUrl: string) {
    this.el = domElement;
    this.backToSearchUrl = backToSearchUrl;
  }

  async render(): Promise<void> {
    renderTo(this.el, tpl({ backToSearchUrl: this.backToSearchUrl }));
    const componentElement = this.el.querySelector('[component="--divider"]');
    renderTo(componentElement, dividerTpl({ simple: true }));
  }

  destroy() {
    clear(this.el);
  }
}
