var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "btn-block";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-modalErrorContent\" data-test=\"modalErrorContent\">\n  <div class=\"modal-header\">\n"
    + ((stack1 = container.invokePartial(require("./common/closeModalButton.hbs"),depth0,{"name":"../common/closeModalButton","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <h4 class=\"modal-title\" id=\"oaErrorModalLabel\">"
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"default.title.error",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":51},"end":{"line":4,"column":81}}}))
    + "</h4>\n  </div>\n\n  <div class=\"modal-body oa-modalErrorContent__error\">\n    <div class=\"alert alert-warning text-center\">\n      <h4 class=\"oa-modalErrorContent__errorTitle\">"
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"errorTitleTranslationKey") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":51},"end":{"line":9,"column":84}}}))
    + "</h4>\n      <div class=\"oa-modalErrorContent__errorMessage\">"
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"errorMessageTranslationKey") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":54},"end":{"line":10,"column":89}}}))
    + "</div>\n    </div>\n  </div>\n\n  <div class=\"modal-footer\">\n    <button type=\"button\"\n            class=\"btn btn-default btm-sm "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/layout/isMediaTypePlugin.js")).call(alias1,{"name":"layout/isMediaTypePlugin","hash":{},"data":data,"loc":{"start":{"line":16,"column":48},"end":{"line":16,"column":76}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":42},"end":{"line":16,"column":94}}})) != null ? stack1 : "")
    + "\"\n            data-dismiss=\"modal\"\n            data-test=\"buttonClose\">\n      "
    + alias2(__default(require("./helpers/i18n.js")).call(alias1,"default.actions.close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":6},"end":{"line":19,"column":38}}}))
    + "\n    </button>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});