import { Order } from './models/Order';
import { ClaimDraftStatus } from './models/ClaimDraft';
import ModifyShippingAddressReason from './models/ModifyShippingAddressReason';
import ModifyBillingAddressReason from './models/ModifyBillingAddressReason';

declare global {
  let hj: any; /* stands for HotJar tooling */
}

export namespace ServerResponse {
  export interface Customer {
    customerDto: CustomerDto;
  }

  export interface CustomerDto {
    zooId: number;
    email: string;
    invoiceAddress: InvoiceAddress;
    deliveryAddress: DeliveryAddress;
  }

  interface CustomerAddress {
    firstName: string;
    lastName: string;
    company: string;
    postCode: string;
    street: string;
    city: string;
    country: string;
  }

  export type InvoiceAddress = CustomerAddress;
  export type DeliveryAddress = CustomerAddress;

  export interface InternalCustomerValue {
    segment: string;
  }

  export interface OrderV1 {
    creationDate: string;
    currency: string;
    exchangeRate: number;
    totalValue: number;
    domain: string;
    id: number;
    invoiceId: string;
    customerId: number;
    parcels: Parcel[];
    articles: Article[];
    discounts: Discount[];
    fees: Fee[];
    status: Status;
    warnings?: OrderWarning[];
    shippingAddress?: OrderAddress;
    billingAddress?: OrderAddress;
    relaxDeliveryInfoResponse?: RelaxDeliveryInfoResponse;
    isFraud: boolean;
    channel: string;
  }

  export interface Order {
    creationDate: string;
    currency: string;
    domain: string;
    id: number;
    customerId: number;
    totalValue: number;
    isFraud: boolean;
    channel: string;
    shippingAddress?: OrderAddress;
    billingAddress?: OrderAddress;
    suborders: Suborder[];
  }

  export interface Suborder {
    totalValue: number;
    id: string;
    invoiceId: string;
    locale: string;
    sellerName: 'zooplus' | 'bitiba' | string;
    orderType: '1P-REGULAR' | '3P-REGULAR' | '1P-AUTOSHIPMENT' | '1P-CUSTOMERCARE' | string;
    parcels?: Parcel[];
    articles: Article[];
    discounts?: Discount[];
    fees: Fee[];
    status: Status;
    warnings?: OrderWarning[];
    relaxDeliveryInfoResponse?: RelaxDeliveryInfoResponse;
  }

  export interface RelaxDeliveryInfoResponse {
    maxWorkingDays: number;
    isRelaxDelivery: boolean;
  }

  export interface OrderWarning {
    code: string;
    message: string;
  }

  export interface Article extends OrderPosition {
    orderPositionId: number;
    articleNumber: string;
    name: string;
    productId: number;
    shopArticleId?: number;
    shopArticleUrl?: string;
  }

  export interface Discount extends OrderPosition {
    articleNumber?: string;
    type: string;
  }

  export interface Fee extends OrderPosition {
    type: string;
  }

  export interface Parcel {
    id?: string;
    isVirtual?: boolean;
    status?: string;
    trackingNumber?: string;
    fulfilmentCenter?: string;
    dspName?: string;
    parcelPoolExitDate: string;
    shipmentDate: string;
    truckDepartureDate: string;
    deliveryDate?: string;
    trackingLink?: string;
    parcelPositions: ParcelPosition[];
    calculatedWeight?: ParcelWeight;
    realWeight?: ParcelWeight;
  }

  export interface ParcelPosition {
    orderPositionId: number;
    physicalArticleId: number;
    physicalArticleQuantity: number;
  }

  export interface ParcelCancellationStatusResponse {
    parcelCancellationStatus: ParcelCancellationStatus;
  }

  export interface ParcelCancellationResponse {
    success: boolean;
    reason?: string;
  }

  export interface OrderPosition {
    quantity: number;
    unitValue: number;
    totalValue: number;
    calculatedWeight?: ParcelWeight;
    realWeight?: ParcelWeight;
  }

  export interface ParcelWeight {
    value: number;
    unit: string;
  }

  export interface Status {
    code: string;
    description: string;
  }

  export interface OrderAddress {
    firstName: string;
    lastName: string;
    company: string;
    street: string;
    city: string;
    postalCode: string;
    countryISOCode: string;
    phone?: string;
  }

  export interface IsOrderCancellable {
    isOrderCancellable: boolean;
  }

  export interface PhysicalArticleMasterdata {
    productName: string;
    shortText: string;
  }

  export interface RefundData {
    isRefundPossible: boolean;
    refundAmountInInvoiceCurrency: number;
    invoiceCurrency: string;
    refundMethod: string;
  }

  export interface RefundCreation {
    refundedAmountInInvoiceCurrency: number;
    invoiceCurrency: string;
    refundMethod: string;
  }

  export interface Claim {
    claimId: number;
    orderId: number;
    creationDate: string;
    resolution: ClaimResolution;
    status: string;
    links: ClaimLinks[];
  }

  export interface ClaimDraft {
    claimDraftId: number;
    orderId: number;
    creationDate: string;
    resolution: ClaimResolution;
    status: ClaimDraftStatus;
  }

  export interface ClaimResolution {
    obligation: ClaimObligation;
    compensation: ClaimCompensation;
  }

  export interface ClaimObligation {
    name: string;
  }

  export interface ClaimCompensation {
    name: string;
  }

  export interface ClaimLinks {
    rel: string;
    href: string;
  }

  export interface ArticleWithDiscount {
    articleId: number;
    orderedArticleQuantity: number;
    orderPositionId: number;
    actualPriceEur: number;
    actualPrice: number;
    currency: string;
    discountValue: number;
    discountType: string;
  }

  export interface FeatureFlags {
    key: string;
    enabled: boolean;
  }
}

export namespace ServerRequest {
  interface UserAction {
    userId: number;
  }

  export interface OrderCancellation extends UserAction {
    reason: string;
  }

  export interface RefundCreation extends UserAction {}

  export interface FeatureFlags extends UserAction {}

  export interface CoreFieldsAddress extends UserAction {
    firstName: string;
    lastName: string;
    street: string;
    city: string;
    postalCode: string;
    countryISOCode: string;
    phone: string;
  }

  export interface ShippingAddressModificationRequest extends CoreFieldsAddress {
    reason: ModifyShippingAddressReason;
  }

  export interface BillingAddressModificationRequest extends CoreFieldsAddress {
    reason: ModifyBillingAddressReason;
  }
}

export namespace Tooltip {
  export namespace Order {
    export namespace Id {
      export interface Model {
        namespace: string;
        contentKey: Content;
        context: Object;
      }

      export enum Content {
        TICKET_CUSTOMER_ORDER_MISMATCH = 'ticket_customer_order_mismatch',
        CUSTOMER_ORDER_MISMATCH = 'customer_order_mismatch',
        ORDER_BY_ORDER_ID = 'order_by_order_id',
        ORDER_BY_CUSTOMER_ID = 'order_by_customer_id',
        ORDER_BY_EMAIL_AND_PRODUCT = 'order_by_email_and_product',
        ORDER_BY_PHONE_AND_PRODUCT = 'order_by_phone_and_product',
      }
    }
  }
}

export namespace ViewModelInputData {
  export interface OrderViewModel {
    order: Order;
  }

  export interface AppViewModel {
    orders: Order[];
  }
}

export enum ParcelCancellationStatus {
  CANCELLABLE = 'CANCELLABLE',
  NON_CANCELLABLE = 'NON_CANCELLABLE',
  ALREADY_CANCELLED = 'ALREADY_CANCELLED',
  UNKNOWN = 'UNKNOWN',
}
