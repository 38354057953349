import { ViewModel } from '../../types';

export class AppAuthorizationContext implements ViewModel.AuthorizationContext {
  private keycloak: any;

  constructor(keycloak: any) {
    this.keycloak = keycloak;
  }

  isAuthorizedToOpenClaimWizard(): boolean {
    return this.hasPermissions('cs.hyperion.claim.create');
  }

  isAuthorizedToCancelOrder(): boolean {
    return this.hasPermissions('cc.order.cancel');
  }

  isAuthorizedToPerformParcelActions(): boolean {
    return this.isAuthorizedToCancelParcel();
  }

  isAuthorizedToCancelParcel(): boolean {
    return this.hasPermissions('cc.parcel.cancel');
  }

  isAuthorizedToRefundCancelledOrder(): boolean {
    return this.hasPermissions('cc.order.refund');
  }

  isAuthorizedToBrowseClaimDrafts(): boolean {
    return this.hasPermissions('cs.hyperion.claimdraft.browse');
  }

  private hasPermissions(permissions: string | Array<String>): boolean {
    const actualPermissions = this.keycloak.tokenParsed.permissions || [];
    return [].concat(permissions).every((permission) => actualPermissions.includes(permission));
  }
}
