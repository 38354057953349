import { Helpers, Models, Services } from '@cc/cc-app-commons';

import { ViewModel } from '../types';

import ModifyBillingAddressReason from '../../models/ModifyBillingAddressReason';
import AddressModificationActionsModalHelper from './AddressModificationActionsModalHelper';
import Address from '../../models/Address';
import addressServiceExtractor from '../../modules/services/AddressDataExtractor';

const AppActivityAction = Services.AppActivityAction;
const { clear, renderTo } = Helpers.ClientHelpers;
const tpl = require('../../../templates/order-addresses/modifyBillingAddressModal.hbs');

export default class ModifyBillingAddressViewModel {
  private readonly el: Element;
  private readonly context: ViewModel.Context;
  private readonly onAddressModificationCallback: Function;
  private eventRemovers: Models.Remover[] = [];
  private _modifyBillingAddressActionButton: JQuery<Element>;
  private _formElement: HTMLFormElement;

  constructor(
    domElement: Element,
    context: ViewModel.Context,
    onAddressModificationCallback: Function,
  ) {
    this.el = domElement;
    this.context = context;
    this.onAddressModificationCallback = onAddressModificationCallback;
  }

  get formElement() {
    if (!this._formElement) {
      this._formElement = this.el.querySelector('form');
    }
    return this._formElement;
  }

  get modifyAddressActionButton(): JQuery<Element> {
    if (!this._modifyBillingAddressActionButton) {
      this._modifyBillingAddressActionButton = $(
        this.el.querySelector(' .oa-modifyBillingAddressModal-modifyAddressButton'),
      );
    }
    return this._modifyBillingAddressActionButton;
  }

  async render() {
    const order = await this.context.data.currentOrder;
    renderTo(
      this.el,
      tpl({
        modifyBillingAddressReasons: Object.keys(ModifyBillingAddressReason).map(
          (key) => ModifyBillingAddressReason[key as keyof typeof ModifyBillingAddressReason],
        ),
        billingAddress: order.billingAddress?.toData(),
      }),
    );

    this.attachPluginsAndEventHandlers();
  }

  private attachPluginsAndEventHandlers() {
    const onSubmitCallback = this.submitBillingAddressModification.bind(this);
    this.formElement.addEventListener('submit', onSubmitCallback);
    this.eventRemovers.push(
      Models.Remover.createFor(
        function (): void {
          this.formElement.removeEventListener('submit', onSubmitCallback);
        }.bind(this) // eslint-disable-line
      ),
    );

    const radioOnChangeCallback = (e: Event) => {
      if ((e.currentTarget as HTMLInputElement).value) {
        this.enableModifyAddressActionButton();
      }
    };
    this.formElement.modifyBillingAddressReason.forEach((element: HTMLInputElement) => {
      element.addEventListener('change', radioOnChangeCallback);
      this.eventRemovers.push(
        Models.Remover.createFor(
          function (): void {
            element.removeEventListener('change', radioOnChangeCallback);
          }.bind(this) // eslint-disable-line
        ),
      );
    });
  }

  private async submitBillingAddressModification(evt: Event) {
    evt.preventDefault();
    const modifyBillingAddressReason = (evt.target as HTMLFormElement).modifyBillingAddressReason
      .value as keyof typeof ModifyBillingAddressReason;

    const newAddress = new Address().fromData(addressServiceExtractor(evt));

    try {
      await this.onSubmit(ModifyBillingAddressReason[modifyBillingAddressReason], newAddress);
    } catch (e) {
      AddressModificationActionsModalHelper.handleBillingAddressChangeError(e);
    }
  }

  private enableModifyAddressActionButton() {
    this.modifyAddressActionButton.prop('disabled', false);
  }

  private async onSubmit(
    modifyBillingAddressReason: ModifyBillingAddressReason,
    newAddress: Address,
  ) {
    this.context.metric.logAppActivity(
      AppActivityAction.BILLING_ADDRESS_CHANGE_SUBMIT_BUTTON__CLICKED,
    );
    const order = await this.context.data.currentOrder;

    await this.context.data.modifyBillingAddress(order.id, modifyBillingAddressReason, newAddress);

    this.onAddressModificationCallback();
  }

  private detachPluginsAndEventHandlers() {
    this.eventRemovers.forEach((eventHandlerRemover: Models.Remover) =>
      eventHandlerRemover.remove(),
    );
  }

  destroy() {
    this.detachPluginsAndEventHandlers();
    clear(this.el);
  }
}
