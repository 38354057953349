import { Models } from '@cc/cc-app-commons';
import ModalWrapper from '../ModalWrapper';
import ErrorForViewModel from '../../util/ErrorForViewModel';

type AppError = Models.Errors.AppError;

export default class ParcelActionsModalHelper {
  static PARCEL_ACTIONS_MODAL_SELECTOR = '.oa-parcelActions .oa-parcelActionsModal';

  static getModal() {
    return ModalWrapper.getFor(
      document.querySelector(ParcelActionsModalHelper.PARCEL_ACTIONS_MODAL_SELECTOR),
    );
  }

  static handleError(error: AppError) {
    ParcelActionsModalHelper.getModal().handleError(
      new ErrorForViewModel(error, 'parcel.actions.cancellation.modal.errors'),
    );
  }
}
