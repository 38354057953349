var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/i18n.js")).call(alias1,"order.claims.status.header",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":33},"end":{"line":6,"column":70}}}))
    + ": "
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,__default(require("../helpers/concat.js")).call(alias1,"order.claims.status.",(depth0 != null ? lookupProperty(depth0,"status") : depth0),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":6,"column":79},"end":{"line":6,"column":117}}}),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":72},"end":{"line":6,"column":119}}}))
    + "<br/>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oa-claims__resolutionOption\" data-test=\"claim.resolutions.option\">\n  <div class=\"oa-claims__resolutionOptionIcon\"\n       data-toggle=\"tooltip\"\n       data-placement=\"bottom\"\n       data-trigger=\"hover\"\n       title=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isResolved") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":14},"end":{"line":6,"column":131}}})) != null ? stack1 : "")
    + alias2(__default(require("../helpers/i18n.js")).call(alias1,__default(require("../helpers/concat.js")).call(alias1,"order.claims.resolution.",((stack1 = (depth0 != null ? lookupProperty(depth0,"resolution") : depth0)) != null ? lookupProperty(stack1,"translationKeyPhrase") : stack1),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":7,"column":17},"end":{"line":7,"column":84}}}),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":9},"end":{"line":7,"column":86}}}))
    + "\"\n  >\n    <i class=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"resolution") : depth0)) != null ? lookupProperty(stack1,"icon") : stack1), depth0))
    + " icon icon--large\"></i>\n  </div>\n</div>";
},"useData":true});